<template>
  <div
    id="app"
    :class="`${pageType}-page`"
  >
    <AdminHeader v-if="status && pageType !== 'authentication'" />
    <UpdatePassword />
    <RouterView class="main" />
  </div>
</template>

<script>
  import { identifySegmentUser } from '@watchtowerbenefits/es-utils-public';

  import {
    mapState,
    mapActions,
    mapStores,
  } from 'pinia';
  import { useLaunchDarklyStore } from '@/stores/launchDarkly.js';
  import { useAdminsStore } from '@/stores/admins.js';
  import { useAuthStore } from '@/stores/auth.js';
  import { useBrokersStore } from '@/stores/brokers.js';
  import { useCarriersStore } from '@/stores/carriers.js';
  import { useUserInfoStore } from '@/stores/userInfo.js';

  // third party
  import { setUser as SentrySetUser } from '@sentry/vue';
  import LogRocket from 'logrocket';
  // utils
  import { config } from '@/utils/config.js';
  import { identifyLaunchDarklyUser } from '@/utils/launchDarkly.js';
  import { canUseThirdParty } from '@/utils/general.js';

  // components
  import AdminHeader from '@/components/AdminHeader.vue';
  import UpdatePassword from '@/components/Modal/UpdatePassword.vue';

  // some third party services we only want to run in non-features environments
  const isNonFeaturesEnvironment = config.analyticsEnabled(['production', 'staging', 'qa']);

  export default {
    name: 'App',
    components: {
      AdminHeader,
      UpdatePassword,
    },
    computed: {
      ...mapState(useLaunchDarklyStore, {
        launchDarklyConfig: 'config',
      }),
      ...mapStores(useUserInfoStore),
      ...mapState(useUserInfoStore, ['id']),
      ...mapState(useAuthStore, ['status']),
      /**
       * PageType is used to determine which header to display.
       *
       * @returns {string}
       */
      pageType() {
        return this.$route?.meta.pageType || 'default';
      },
    },
    watch: {
      status: {
        /**
         * handles initial data gathering for brokers, carriers, and admin users and adds them to the store
         *
         * @param {boolean} status
         */
        handler(status) {
          if (status) {
            this.loadAdminUsers();
            this.loadCarriers();
            this.loadBrokers();
          }
        },
        deep: true,
        immediate: true,
      },
      id: {
        /**
         * handle identifying the user to various services after sign in is complete
         *
         * @param {number} id
         */
        handler(id) {
          // if we are on our production site
          if ((isNonFeaturesEnvironment || canUseThirdParty('sentry') || canUseThirdParty('logrocket')) && id) {
            const userInfo = { ...this.userInfoStore.$state };

            if (isNonFeaturesEnvironment || canUseThirdParty('logrocket')) {
              // Send this users info to LogRocket
              LogRocket.identify(id, userInfo);
            }
            if (isNonFeaturesEnvironment || canUseThirdParty('sentry')) {
              // Send this users info  to Sentry
              SentrySetUser({
                userInfo,
              });
            }

            if (isNonFeaturesEnvironment || canUseThirdParty('segment')) {
              // because in admin, we turn roles into a flat array, we can't depend on the roles logic in .identify
              identifySegmentUser({
                userInfo,
                roles: userInfo.roles.map((role) => ({ name: role })),
              });
            }
          }
        },
        immediate: true,
      },
      /**
       * update launch darkly identity whenever user key is changed and has a value
       *
       * @param {string} newKey
       */
      'launchDarklyConfig.user.key': function (newKey) {
        if (!newKey) {
          return;
        }

        // Always identify the user to LD.
        identifyLaunchDarklyUser(this.launchDarklyConfig);
      },
      $route: {
        /**
         * when the route changes we need to tell segment the page has changed
         */
        handler() {
          if (this.useAnalytics) {
            window.analytics && window.analytics.identify(
              this.id,
              { ...this.userInfoStore.$state },
            );
            //  Tell segment that a page view has occurred
            const pagePayload = {
              name: this.$route.name,
              path: this.$route.path,
              url: window.location.href,
            };

            window.analytics && window.analytics.page(
              this.$route.name,
              pagePayload,
            );
          }
        },
        immediate: true,
      },
    },
    methods: {
      ...mapActions(useAdminsStore, ['loadAdminUsers']),
      ...mapActions(useBrokersStore, ['loadBrokers']),
      ...mapActions(useCarriersStore, ['loadCarriers']),
    },
  };
</script>

<style lang="scss">
  body, html {
    height: 100%;
    -ms-overflow-style: -ms-autohiding-scrollbar;

    &.rates-page {
      overflow: hidden;
    }
  }

  #app {
    height: 100%;
    display: flex;
    flex-direction: column;

    &.management-page {
      padding-top: 56px;
    }

    &.authentication-page {
      justify-content: center;
      min-height: 100vh;
    }
  }

  .small-tf-logo {
    display: inline-block;
    width: 66px;
    height: 59px;
    margin: 0 auto 35px;
    text-indent: -10000px;

    &,
    .auth-form & {
      background: {
        /* stylelint-disable-next-line declaration-no-important */
        image: url(assets/logo.svg) !important;
        size: cover;
      }
    }
  }
</style>
