<template>
  <ElForm :model="localContainer">
    <ElFormItem
      key="Class description"
      prop="description"
      :rules="[
        { validator: validate, trigger: 'blur' },
      ]"
    >
      <ElInput
        v-model="localContainer.description"
        type="textarea"
        :disabled="['saving', 'deleting'].includes(container.state)"
        :rows="2"
        placeholder="Enter class description"
        data-test="container description"
      />
      <p
        v-if="container.state === 'editing' && duplicateContainerName"
        class="form-disclaimer"
      >
        <span
          class="has-error"
          v-text="'Class descriptions must be unique.'"
        />
      </p>
    </ElFormItem>
    <ContainerDescriptionActions
      :container="container"
      :disabled-save-btn="duplicateContainerName"
      :local-container="localContainer"
      data-test="container description actions"
      @cancelSave="cancelSave"
    />
  </ElForm>
</template>

<script>
  import { mapState, mapActions } from 'pinia';
  import ValidationUtil from '@/utils/validation.js';
  import { useProductContainersStore } from '@/stores/productContainers.js';
  import ContainerDescriptionActions from './ContainerDescriptionActions.vue';

  /**
   * Class-based Container
   *
   * @vuedoc
   * @exports ClassBasedContainer
   * @category Components
   */
  export default {
    name: 'ClassBasedContainer',
    components: { ContainerDescriptionActions },
    props: {
      container: {
        type: Object,
        required: true,
        default: () => {},
      },
    },
    data() {
      return {
        localContainer: {
          description: this.container.description,
        },
      };
    },
    computed: {
      ...mapState(useProductContainersStore, ['currentContainers']),
      /**
       * Verify that we aren't reusing any container names that are already used in this (project) product
       *
       * @returns {boolean}
       */
      duplicateContainerName() {
        const container = {
          ...this.container,
          description: this.localContainer.description,
        };

        return this.localContainer.description
          && ValidationUtil.checkDuplicateContainerNames(container, this.currentContainers);
      },
    },
    methods: {
      ...mapActions(useProductContainersStore, ['setContainerState']),
      /**
       * resets local data and store if save is cancelled
       */
      cancelSave() {
        // Set the localContainer.description back to the original description
        if (this.localContainer.description !== this.container.description) {
          this.localContainer.description = this.container.description;
        }
        this.setContainerState({
          container: this.container,
          containerState: 'reviewing',
        });
      },
      /**
       * Validate the container description
       *
       * @param {object} rule
       * @param {string} value
       * @param {Function} callback
       */
      validate(rule, value, callback) {
        if (!value) {
          callback(new Error('Class description is required.'));
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .el-form,
  .read-only-description {
    align-items: center;
  }

  .el-form {
    flex: 1;
  }

  .el-form-item {
    flex-grow: 1;
    margin-bottom: 0;
  }

  /* stylelint-disable declaration-no-important  */
  :deep(.el-textarea__inner) {
    min-height: 63px !important;
    resize: none;
  }

  :deep(.el-popover.attribute-more-popover) {
    top: 54px !important;
  }
  /* stylelint-enable declaration-no-important  */
</style>
