<template>
  <ElForm
    ref="brokerManagementForm"
    :model="localBroker"
    :rules="rules"
    label-position="top"
    @submit.native.prevent="saveBrokerInfo"
  >
    <div class="broker-info">
      <h4>Broker name</h4>

      <ElFormItem
        for="broker-name"
        prop="name"
        size="medium"
      >
        <ElInput
          v-model="localBroker.name"
          name="name-input"
          data-test="broker name input"
          placeholder="Enter name"
        />
      </ElFormItem>

      <h4>Operating states</h4>

      <ElFormItem
        prop="operatingStateIds"
        size="medium"
      >
        <TfMultiSelect
          v-model="operatingStateIds"
          data-test="operating states"
          :append-to-body="false"
          :label="'name'"
          :total-options-available="statesList.length"
          :options="statesList"
          empty-text="No states available"
          popper-class="states-multi-select-popover multi-select-popover max-height"
          value-key="id"
        />
      </ElFormItem>

      <div class="web-styling">
        <h4>Web styling</h4>

        <ElFormItem prop="web_styling.primary_color">
          <label>Primary color</label>
          <ElInput
            v-model="localBroker.web_styling.primary_color"
            data-test="primary color input"
            name="primary-color-input"
            placeholder="Enter color"
          >
            <template #prepend>
              <div :style="`background-color:${localBroker.web_styling.primary_color}`" />
            </template>
          </ElInput>
        </ElFormItem>

        <ElFormItem
          prop="web_styling.secondary_color"
        >
          <label>Secondary color</label>
          <ElInput
            v-model="localBroker.web_styling.secondary_color"
            name="primary-color-input"
            data-test="secondary color input"
            placeholder="Enter color"
          >
            <template #prepend>
              <div :style="`background-color:${localBroker.web_styling.secondary_color}`" />
            </template>
          </ElInput>
        </ElFormItem>

        <ElFormItem prop="web_styling.primary_color_variation1">
          <label>Primary color variation 1</label>
          <ElInput
            v-model="localBroker.web_styling.primary_color_variation1"
            name="primary-color-input"
            placeholder="Enter color"
            data-test="primary color variation 1"
          >
            <template #prepend>
              <div :style="`background-color:${localBroker.web_styling.primary_color_variation1}`" />
            </template>
          </ElInput>
        </ElFormItem>

        <ElFormItem prop="web_styling.primary_color_variation2">
          <label>Primary color variation 2</label>
          <ElInput
            v-model="localBroker.web_styling.primary_color_variation2"
            name="primary-color-input"
            data-test="primary color variation 2"
            placeholder="Enter color"
          >
            <template #prepend>
              <div :style="`background-color:${localBroker.web_styling.primary_color_variation2}`" />
            </template>
          </ElInput>
        </ElFormItem>

        <ElFormItem prop="web_styling.primary_color_variation3">
          <label>Primary color variation 3</label>
          <ElInput
            v-model="localBroker.web_styling.primary_color_variation3"
            name="primary-color-input"
            data-test="primary color variation 3"
            placeholder="Enter color"
          >
            <template #prepend>
              <div :style="`background-color:${localBroker.web_styling.primary_color_variation3}`" />
            </template>
          </ElInput>
        </ElFormItem>
      </div>

      <h4>
        Presentation Assistant
        <TfTooltip data-test="presentation assistant text">
          <!-- TODO LC-563 -->
          <span
            slot="reference"
            class="label"
          >
            <AppIcon icon="fa-solid fa-circle-question" data-test="to presentation assistant info" />
          </span>
          Enabling the Presentation Assistant for a broker will allow that broker to download the data reference sheet for a project
        </TfTooltip>
      </h4>

      <ElFormItem
        prop="presentation_assistant_enabled"
        size="medium"
      >
        <ElSwitch
          v-model="localBroker.presentation_assistant_enabled"
          data-test="broker presentation-assistant-enabled toggle"
        />
      </ElFormItem>
    </div>

    <div class="btn-group align-start form-actions save-broker-info">
      <AppButton
        :is-loading="formSubmitting"
        name="submit-button"
        data-test="submit button"
        type="primary"
        native-type="submit"
        text="Save settings"
        @click="saveBrokerInfo"
      />
    </div>

    <div
      v-if="brokerId"
      class="uploads"
    >
      <LogoUpload
        :action="`${apiUrl}/v1/admin/brokers/${brokerId}`"
        :logo-url="localBroker.logo_url"
        @updateLogo="updateLogo"
      />
    </div>
  </ElForm>
</template>

<script>
  // pinia
  import { mapWritableState, mapState, mapActions } from 'pinia';
  import { useBrokersStore } from '@/stores/brokers.js';
  // 3rd party
  import { cloneDeep } from 'lodash';
  // services
  import BrokerService from '@/services/broker.js';
  import CarrierService from '@/services/carrier.js';
  // components
  import LogoUpload from '@/components/Management/LogoUpload.vue';
  import { config } from '@/utils/config.js';

  const apiUrl = config.VUE_APP_API_URL;

  /**
   * views/BrokerManagement/ Component
   *
   * @vuedoc
   * @exports EditBrokerSettings
   * @category Views
   */
  export default {
    name: 'EditBrokerSettings',
    components: {
      LogoUpload,
    },
    props: {
      brokerId: {
        type: [Number, String],
        default: null,
      },
    },
    data() {
      return {
        apiUrl,
        addingInvitableCarrier: false,
        localBroker: {},
        statesList: [],
        invitableCarriersMetaData: {
          columns: [
            {
              prop: 'name',
              label: 'Carrier name',
            },
          ],
          children: [],
        },
        formSubmitting: false,
        newInvitableCarrierId: null,
        newInvitableCarrierOrganizationAdmin: false,
        panes: [
          {
            label: 'Users',
            name: 'EditBrokerUsers',
          },
          {
            label: 'Invitable carriers',
            name: 'EditBrokerInvitableCarriers',
          },
          {
            label: 'Settings',
            name: 'EditBrokerSettings',
          },
        ],
        rules: {
          name: {
            required: true, type: 'string', message: 'Please enter the broker\'s name.', trigger: 'blur',
          },
          'web_styling.primary_color': [
            {
              required: true,
              validator: this.checkHexColor,
              trigger: 'blur',
            },
          ],
          'web_styling.secondary_color': [
            {
              required: true,
              validator: this.checkHexColor,
              trigger: 'blur',
            },
          ],
          'web_styling.primary_color_variation1': [
            {
              required: true,
              validator: this.checkHexColor,
              trigger: 'blur',
            },
          ],
          'web_styling.primary_color_variation2': [
            {
              required: true,
              validator: this.checkHexColor,
              trigger: 'blur',
            },
          ],
          'web_styling.primary_color_variation3': [
            {
              required: true,
              validator: this.checkHexColor,
              trigger: 'blur',
            },
          ],
        },
        operatingStateIds: [],
      };
    },
    computed: {
      ...mapState(useBrokersStore, ['currentBroker']),
      ...mapWritableState(useBrokersStore, ['currentBrokerId']),
    },
    created() {
      this.currentBrokerId = this.brokerId;

      if (this.currentBroker) {
        this.setLocalBrokerVariables(this.currentBroker);
      } else {
        const newBroker = {
          name: null,
          alternate_logo_url: '',
          carrier_rating_cutoff: null,
          carriers: [],
          email_domains: [],
          logo_url: '',
          operating_states: [],
          parent_id: null,
          web_styling: {},
          presentation_assistant_enabled: false,
        };

        this.setLocalBrokerVariables(newBroker);
      }

      CarrierService
        .getStatesList()
        .then((data) => {
          this.$set(this, 'statesList', data.states);
        })
        .catch(() => {
          this.displayToast({
            message: 'There was an error retrieving the states list.',
          });
        });
    },
    methods: {
      ...mapActions(useBrokersStore, ['addBroker', 'updateBroker']),
      /**
       * Save the broker info (minus logos)
       */
      saveBrokerInfo() {
        this.$refs.brokerManagementForm.validate((valid) => {
          if (valid) {
            this.formSubmitting = true;
            const brokerData = {
              name: this.localBroker.name,
              carriers: this.localBroker.carriers,
              operating_state_ids: this.operatingStateIds,
              web_styling: this.localBroker.web_styling,
              presentation_assistant_enabled: this.localBroker.presentation_assistant_enabled,
            };

            // if this is a new broker
            if (!this.localBroker.id) {
              BrokerService
                .addNewBroker(brokerData)
                .then(({ broker }) => {
                  this.formSubmitting = false;
                  this.localBroker = cloneDeep(broker);
                  this.addBroker(broker);
                  this.displayToast({
                    message: 'Broker record has been created.',
                    type: 'success',
                  });
                  this.$router.replace({
                    name: 'EditBrokerSettings',
                    params: { brokerId: broker.id },
                  });
                })
                .catch(() => {
                  this.formSubmitting = false;
                  this.displayToast({
                    message: 'There was an error while trying to create the broker record.',
                  });
                });
            } else {
              // if this is an existing broker
              BrokerService
                .saveBrokerInfo(this.currentBroker.id, brokerData)
                .then(({ broker }) => {
                  this.formSubmitting = false;
                  this.setLocalBrokerVariables(broker);
                  this.updateBroker(broker);
                  this.displayToast({
                    message: 'The broker settings have been updated.',
                    type: 'success',
                  });
                })
                .catch(() => {
                  this.formSubmitting = false;
                  this.displayToast({
                    message: 'There was an error saving the broker settings.',
                  });
                });
            }
          }
        });
      },
      /**
       * Set the local broker object and update the operatingStateIds array accordingly.
       *
       * @param {object} broker
       */
      setLocalBrokerVariables(broker) {
        this.$set(this, 'localBroker', cloneDeep(broker));
        this.$set(this, 'operatingStateIds', broker.operating_states.map(({ id }) => id));
      },
      /**
       * Validator to make sure the hex codes are valid
       *
       * @param {object} rule
       * @param {string} value
       * @param {Function} callback
       */
      checkHexColor(rule, value, callback) {
        const expression = /^#[0-9a-f]{3,6}$/i;

        if (value && value.match(expression)) {
          callback();

          return;
        }

        callback(new Error('Please enter a valid color.'));
      },
      /**
       * Upload the logo passed up from the logoUpload component
       *
       * @param {object} payload
       * @param {string} payload.file
       */
      updateLogo({ file }) {
        const formData = new FormData();

        formData.append('logo', file);

        BrokerService
          .saveBrokerInfo(this.localBroker.id, formData)
          .then(({ broker }) => {
            this.localBroker = cloneDeep(broker);
            this.displayToast({
              message: 'The logo has been updated.',
              type: 'success',
            });
          })
          .catch(() => {
            this.displayToast({
              message: 'There was an issue uploading the logo.',
            });
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
.broker-info {
  max-width: 750px;
}

.save-broker-info {
  justify-content: flex-end;
}

:deep() {
  .max-height {
    max-height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
}

label {
  font-size: 14px;
  display: block;
  margin-bottom: 10px;
}

.web-styling {
  :deep(.el-input-group__prepend) {
    padding: 0;

    > div {
      width: 24px;
      height: 28px;
    }
  }
}
</style>
