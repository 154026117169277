<template>
  <div>
    <LfcBrokerUserInviteTable
      v-bind="{
        brokerId,
        userIsAdmin,
        emailDomains: currentBroker.email_domains,
        checkInviteBrokerUserMethod,
        getBrokerUserInvitesMethod,
        inviteBrokerUserMethod,
        resendBrokerUserInviteMethod,
        customInviteMethod: true,
      }"
      @inviteCtaClicked="$router.push({ name: 'BrokerInviteUsers' })"
    />
  </div>
</template>

<script>
  import { mapWritableState, mapState } from 'pinia';
  import { useBrokersStore } from '@/stores/brokers.js';
  import { useUserInfoStore } from '@/stores/userInfo.js';
  import {
    checkInviteBrokerUser,
    inviteBrokerUser,
    resendBrokerUserInvite,
  } from '@/services/user.js';
  import { getBrokerUserInvites } from '@/services/broker.js';
  import { LfcBrokerUserInviteTable } from '@watchtowerbenefits/lfc-components';

  /**
   * View open broker user invitations
   *
   * @vuedoc
   * @exports BrokerUserInvites
   * @category Views
   */

  export default {
    name: 'BrokerUserInvites',
    components: { LfcBrokerUserInviteTable },
    props: {
      brokerId: {
        type: [Number, String],
        default: null,
      },
    },
    data: () => ({
      getBrokerUserInvitesMethod: getBrokerUserInvites,
      resendBrokerUserInviteMethod: resendBrokerUserInvite,
      checkInviteBrokerUserMethod: checkInviteBrokerUser,
      inviteBrokerUserMethod: inviteBrokerUser,
    }),
    computed: {
      ...mapState(useBrokersStore, ['currentBroker']),
      ...mapState(useUserInfoStore, { userRoles: 'roles' }),
      ...mapWritableState(useBrokersStore, ['currentBrokerId']),
      /**
       * Check if the user is an admin
       *
       * @returns {boolean}
       */
      userIsAdmin() {
        return this.userRoles.some((role) => role.includes('admin'));
      },
    },
    created() {
      this.currentBrokerId = this.brokerId;
    },
  };
</script>
