<template>
  <div
    class="table-parent attribute-table"
    data-test="category table parent"
  >
    <h5
      class="attribute-name"
      :class="{ hidden: isHidden }"
    >
      {{ attribute.name }}
      <AppButton
        key="toggle-attribute"
        data-test="toggle eye"
        type="secondary"
        tabindex="-1"
        :class="[{ hidden: isHidden }, 'hide-button fa-solid']"
        :icon="!isHidden ? 'fa-eye' : 'fa-eye-slash'"
        :text="isHidden ? 'Enable' : 'Disable'"
        size="icon"
        @click="toggleAttribute(isHidden)"
      />
    </h5>
    <table
      :class="[
        { 'hidden-attribute': isHidden },
        'table table-child',
      ]"
    >
      <thead>
        <tr>
          <th />
          <th>Subtype group</th>
          <th>Value</th>
          <th colspan="2">
            <span
              v-if="containerTypeSelection"
              v-text="containerTypeSelection.toLowerCase() === 'class' ? 'Class(es)' : 'Plan(s)'"
            />
          </th>
        </tr>
      </thead>
      <template v-if="!isHidden">
        <TierGroup
          v-for="(tierGroup, tierGroupIndex) in sortedTierGroups"
          :key="`tier-group-idx-${tierGroupIndex}`"
          :attribute-id="attribute.id"
          :attribute-name="attribute.name"
          :category="category"
          :normalized-values="attribute.normalized_values"
          :read-only-attribute="readOnlyAttribute"
          :sorted-tier-groups="sortedTierGroups"
          :unused-container-ids="unusedContainerIds"
          :tier-group="tierGroup"
          :tier-group-index="tierGroupIndex"
          @addRow="onAddRow"
          @containerChange="onContainerChange"
          @containersHide="onContainersHide"
          @deleteRow="onDeleteRow"
          @edit="readOnlyAttribute = false"
          @tierGroupChange="onTierGroupChange"
        />
      </template>
      <tbody v-else>
        <ValueRow
          :attribute-id="attribute.id"
          :hidden-attribute="true"
        />
      </tbody>
    </table>
  </div>
</template>

<script>
  import Vue from 'vue';
  import { mapState, mapActions, mapWritableState } from 'pinia';
  import { useAnnotationsStore } from '@/stores/annotations.js';
  import {
    cloneDeep, groupBy, isEqual, uniq, xor,
  } from 'lodash';
  import TierGroup from '@/components/PlanDesign/Table/TierGroup.vue';
  import MathUtil from '@/utils/math.js';
  import ValueRow from '@/components/PlanDesign/Table/ValueRow.vue';
  import ProductService from '@/services/product.js';
  import { useProjectProductStore } from '@/stores/projectProduct.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useProductContainersStore } from '@/stores/productContainers.js';
  import { useProductStructuresStore } from '@/stores/productStructures.js';
  import { useProductSelectionsStore } from '@/stores/productSelections.js';
  import { useProductAttributesStore } from '@/stores/productAttributes.js';

  /**
   * Plan Design Table - One Per Attribute
   *
   * @vuedoc
   * @exports PlanDesignTable
   * @category Components
   */
  export default {
    name: 'PlanDesignTable',
    components: {
      TierGroup,
      ValueRow,
    },
    inject: ['adminShortcut'],
    props: {
      attribute: {
        type: Object,
        default: () => ({}),
      },
      category: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        dropdownWidth: 0,
        clonedAttribute: {},
        MathUtil,
        readOnlyAttribute: false,
        sortedTierGroups: [],
        unusedContainerIds: [],
        xor,
      };
    },
    computed: {
      ...mapWritableState(useProductAttributesStore, [
        'attributesHidden',
        'planDesignErrorCount',
      ]),
      ...mapState(useProductStructuresStore, ['availablePlanDesignTierGroups']),
      ...mapState(useProjectProductStore, ['validPlanDesign']),
      ...mapState(useProjectStore, ['readOnlyMode']),
      ...mapState(useAnnotationsStore, ['annotationsByPlanDesignAttributeId']),
      ...mapState(useProductContainersStore, [
        'containerTypeSelection',
        'currentContainers',
      ]),
      ...mapState(useProductSelectionsStore, [
        'activeAttributeId',
        'selectedProductId',
      ]),
      ...mapState(useProductAttributesStore, ['lastAttributeUpdated']),
      /**
       * checks if attribute is hidden
       *
       * @returns {boolean}
       */
      isHidden() {
        return this.attributesHidden[this.attribute.id];
      },
      /**
       * Is this attribute currently being edited
       *
       * @returns {boolean}
       */
      isActive() {
        return this.activeAttributeId === this.attribute.id;
      },
    },
    watch: {
      /**
       * sets readonly attribute to readonly mode when mode is set/changed
       */
      readOnlyMode() {
        this.readOnlyAttribute = this.readOnlyMode;
      },
      /**
       * changes readonly attribute to readonly mode if plan design is valid
       *
       * @param {boolean} valid
       */
      validPlanDesign(valid) {
        if (valid) {
          this.readOnlyAttribute = this.readOnlyMode;
        }
      },
      /**
       * If an attribute is updated we need to check if it was this attribute and if the values need to be updated
       */
      lastAttributeUpdated() {
        if (this.lastAttributeUpdated.id === this.clonedAttribute.id && !this.isActive) {
          this.clonedAttribute = cloneDeep(this.attribute);
          this.setupPlanTables();
        }
      },
    },
    created() {
      this.clonedAttribute = cloneDeep(this.attribute);
      this.setupPlanTables(true);

      // B&B bypass: will use toggleAttribute to delete attribute values
      if (this.adminShortcut && !this.isHidden) {
        this.toggleAttribute(false);
      }
    },
    mounted() {
      if (this.$el.querySelector) {
        const multiSelectDropdown = this.$el.querySelector('.multi-select-dropdown');

        if (multiSelectDropdown) {
          multiSelectDropdown.removeAttribute('tabindex');
        }
      }
    },
    methods: {
      ...mapActions(useAnnotationsStore, ['deleteAnnotation']),
      /**
       * Look through all our values and return the first value/row that doesn't have a containerGroup associated to it
       *
       * @returns {object}
       */
      findFirstEmptyValue() {
        for (let tierGroupIndex = 0; tierGroupIndex < this.sortedTierGroups.length; tierGroupIndex += 1) {
          const tierGroup = this.sortedTierGroups[tierGroupIndex];

          for (let containerGroupIndex = 0; containerGroupIndex < tierGroup.containerGroups.length; containerGroupIndex += 1) {
            const containerGroups = tierGroup.containerGroups[containerGroupIndex];
            const emptyGroup = containerGroups.find((containerGroup) => !containerGroup.project_products_container_ids.length);

            if (emptyGroup) {
              return emptyGroup;
            }
          }
        }

        return null;
      },
      /**
       * Fires when the containers TfMultiSelect is hidden - we then figure out if containers were removed and add them to a blank row
       *
       * @param {object} containers
       */
      onContainersHide(containers) {
        this.planDesignErrorCount = 0;
        const removedItems = containers.initialValues.filter((e) => containers.value.indexOf(e) < 0);

        if (removedItems.length) {
          const emptyValue = this.findFirstEmptyValue();

          if (emptyValue) {
            emptyValue.project_products_container_ids = removedItems;
          }
        }
      },
      /**
       * The main setup of this component
       *
       * @param {boolean} initialSetup - If this is the first time setting up the table we check if this is in "readOnlyMode"
       */
      setupPlanTables(initialSetup) {
        const allContainers = this.currentContainers.map((container) => container.id);
        let inUseContainers = [];

        // if (this.clonedAttribute.grouped_values.length) {
        if (this.attribute.grouped_values.length) {
          // group all the grouped_values into tierGroups
          this.attribute.grouped_values.forEach((group) => {
            inUseContainers = inUseContainers.concat(group.project_products_container_ids);
          });

          inUseContainers = uniq(inUseContainers);
          this.unusedContainerIds = allContainers.filter((id) => !inUseContainers.includes(id));
          // After deleting a row, we'll have an array of unused containers
          if (this.unusedContainerIds.length) {
            // If we have unused containers we'll create a new 'empty' value with the unused containers
            const newRow = this.createEmptyValue({ containerIds: this.unusedContainerIds });

            this.clonedAttribute.grouped_values.push(newRow);
          }

          const sortedTierGroups = this.groupByTierGroup(this.clonedAttribute.grouped_values);

          // Go through each tier group to see
          sortedTierGroups.forEach((tierGroup, tierGroupIndex) => {
            // run through the tier groups and group the containers
            // if this is a tier group we make sure we have all the subtypes included
            if (tierGroup.id) {
              tierGroup.values.forEach((subtype) => {
                // If there is no existing subtype
                if (!subtype.tier_subtype_id) {
                  // this value was saved but is missing some of it's subtypes
                  const newValue = this.createEmptyValue({
                    tierGroupId: tierGroup.id,
                    subtypeId: subtype.tier_subtype_id,
                    subtypeName: subtype.tier_subtype_name,
                  });

                  tierGroup.values.push(newValue);
                }
              });
            }
            sortedTierGroups[tierGroupIndex].containerGroups = this.groupByContainers(tierGroup.values);
            // this isn't super necessary but I'm just cleaning it up so I can read the code easier since values isn't used anymore
            sortedTierGroups[tierGroupIndex].values = [];
          });
          if (this.readOnlyMode && initialSetup) {
            let readOnlyValue = true;

            if (!this.isHidden) {
              const allValues = this.getAllValues(sortedTierGroups);

              allValues.forEach((value) => {
                if (!value.value || !value.project_products_container_ids.length) {
                  readOnlyValue = false;
                }
              });
            }
            this.readOnlyAttribute = readOnlyValue;
          }
          this.$set(this, 'sortedTierGroups', sortedTierGroups);
        } else {
          this.readOnlyAttribute = this.readOnlyMode;
        }
      },
      /**
       * group values by tier group
       *
       * @param {Array} values
       * @returns {Array}
       */
      groupByTierGroup(values) {
        const sortedTierGroups = [
          {
            id: null, // default to `null` for attributes w/o tier groups
            name: 'None',
            values: [],
          },
        ];

        values.forEach((value) => {
          // If this value is in a tier group, we look up the tier group to find the same tier group ID
          if (value.tier_group_id) {
            let tierGroupIndex = sortedTierGroups.findIndex((tierGroup) => tierGroup.id === value.tier_group_id);

            // If there is no tier grouping in sortedTierGroups, create a new one
            if (tierGroupIndex === -1) {
              // I think there's a timing issue here where `availablePlanDesignTierGroups` is unavailable
              // and causing a js error for creating a name
              sortedTierGroups.push({
                id: value.tier_group_id,
                name: this.availablePlanDesignTierGroups.length
                  ? this.availablePlanDesignTierGroups.find((tierGroup) => tierGroup.tier_group_id === value.tier_group_id).tier_group_name
                  : 'None',
                values: [],
              });
              // Find the new tier group index
              tierGroupIndex = sortedTierGroups.findIndex((tierGroup) => tierGroup.id === value.tier_group_id);
            }
            // add to value to the sortedTierGroups with the index from above.
            sortedTierGroups[tierGroupIndex].values.push(cloneDeep(value));
          } else {
            // this gets added to the 'none' group which is the first
            sortedTierGroups[0].values.push(cloneDeep(value));
          }
        });

        return sortedTierGroups;
      },
      /**
       * groups values by container
       *
       * @param {Array} values
       * @returns {Array}
       */
      groupByContainers(values) {
        let containerGroups;

        if (values.length > 1) {
          containerGroups = groupBy(values, (value) => value.project_products_container_ids.sort());
          containerGroups = Object.keys(containerGroups).map((k) => containerGroups[k]);
        } else {
          containerGroups = [values];
        }

        return this.sortTierGroups(containerGroups);
      },
      /**
       * Delete all the values of this attribute (usually when hiding it)
       *
       * @param {Array} containerArray
       * @returns {Promise}
       */
      async deleteAttributeValues(containerArray = null) {
        this.planDesignErrorCount = 0;
        const containerIds = !containerArray
          ? this.currentContainers.map((container) => container.id)
          : uniq(containerArray);

        this.deleteAssociatedAnnotations(containerIds);

        await ProductService.deletePlanDesignValue({
          attributeId: this.clonedAttribute.id,
          containerIds,
          productId: this.selectedProductId,
        });
      },
      /**
       * When values are deleted we also remove any associated annotations from the store
       *
       * @param {Array} containers
       */
      deleteAssociatedAnnotations(containers) {
        // grab every annotation associated to this attribute
        const annotations = this.annotationsByPlanDesignAttributeId({
          productId: this.selectedProductId,
          planDesignAttributeId: this.clonedAttribute.id,
          rollup: false,
        });
        // filter them to only ones that match these containers
        const annotationsToDelete = annotations.filter((annotation) => containers.includes(annotation.plan_design_values[0].project_products_container_id));

        annotationsToDelete.forEach((annotation) => {
          this.deleteAnnotation(annotation.id);
        });
      },
      /**
       * When a user clicks the eye ball icon we should hit the api and delete the attributes values
       * Then we flip the isHidden boolean and show the disabled state
       *
       * @param {boolean} disabled
       */
      async toggleAttribute(disabled) {
        this.planDesignErrorCount = 0;
        if (disabled) {
          const newValue = this.createEmptyValue({});
          const group = { containerGroups: [[newValue]] };

          group.containerGroups[0][0].project_products_container_ids = this.currentContainers.map((container) => container.id);
          const value = group.containerGroups[0][0];

          await this.updatePlanValue({
            containerIds: value.project_products_container_ids,
            subtypeId: value.tier_subtype_id,
            tierGroupId: value.tier_group_id,
            value: value.value,
          });
          this.$set(this.sortedTierGroups, 0, group);
          this.readOnlyAttribute = false;
          this.$set(this.attributesHidden, this.clonedAttribute.id, false);
        } else {
          this
            .deleteAttributeValues()
            .then(() => {
              const sortedTierGroups = [
                { containerGroups: [] },
              ];

              this.$set(this, 'sortedTierGroups', sortedTierGroups);
              this.$set(this.attributesHidden, this.clonedAttribute.id, true);
              this.readOnlyAttribute = this.readOnlyMode;
            })
            .catch(() => {
              this.displayToast({
                message: 'There was an error deleting the attribute values.',
              });
            });
        }
      },
      /**
       * Delete the current row's values
       *
       * @param {object} $event
       */
      onDeleteRow($event) {
        this.planDesignErrorCount = 0;
        const { containerGroup, containerGroupIndex, tierGroupIndex } = $event;

        containerGroup.forEach((value) => {
          // Add these ids to the unusedContainerIds array
          value.project_products_container_ids.forEach((id) => {
            if (!this.unusedContainerIds.includes(id)) {
              this.unusedContainerIds.push(id);
            }
          });
          this.deletePlanDesignValue({ containerIds: value.project_products_container_ids });
        });
        // Delete from the container
        Vue.delete(this.sortedTierGroups[tierGroupIndex].containerGroups, containerGroupIndex);
      },
      /**
       * Remove empty rows from this tierGroup
       *
       * @param {string} value
       */
      removeEmptyRows(value) {
        this.sortedTierGroups.forEach((tierGroup) => {
          tierGroup.containerGroups.forEach((containerGroup) => {
            containerGroup.forEach((groupValue, groupValueIndex) => {
              if (!groupValue.value && !groupValue.project_products_container_ids.length && !isEqual(value, groupValue)) {
                containerGroup.splice(groupValueIndex, 1);
              }
            });
          });
        });
      },
      /**
       * Add a new row to this tier group
       * Row Info can container the `rowInfo` (so we can hide the ellipsis menu) or `containerIDs` if we want to automatically add containers to the new row
       *
       * @param {object} options
       * @param {Array} options.containerIds
       * @param {object} options.rowInfo
       */
      onAddRow({ containerIds = [], rowInfo }) {
        this.planDesignErrorCount = 0;
        const updatedRowInfo = rowInfo;

        if (rowInfo) {
          updatedRowInfo.popoverVisible = false;
        }

        const newRow = this.createEmptyValue({ containerIds });
        const tierGroupIndex = this.sortedTierGroups.findIndex((tierGroup) => !tierGroup.id);

        // IF: There is any existing tier group with this ID (even `null`) => push new row to that container Group
        // ELSE: push to the sortedTierGroups array with the new Row
        if (tierGroupIndex !== -1) {
          this.sortedTierGroups[tierGroupIndex].containerGroups.push([newRow]);
        } else {
          this.sortedTierGroups.push(
            {
              id: null,
              containerGroups: [[newRow]],
            },
          );
        }

        this.sortedTierGroups[tierGroupIndex].containerGroups = this.sortTierGroups(this.sortedTierGroups[tierGroupIndex].containerGroups);
      // Otherwise, push to a new sorted tier group (with null name/id)
      },
      /**
       * Create an empty value so new values can be entered
       *
       * @param {object} options
       * @param {Array} options.containerIds
       * @param {number} options.subtypeId
       * @param {string} options.subtypeName
       * @param {number} options.tierGroupId
       * @returns {object}
       */
      createEmptyValue({
        containerIds = null,
        subtypeId = null,
        subtypeName = null,
        tierGroupId = null,
      }) {
        return {
          ids: containerIds || [MathUtil.getRandomNumber()],
          project_products_container_ids: containerIds || [],
          tier_group_id: tierGroupId,
          tier_subtype_id: subtypeId,
          tier_subtype_name: subtypeName,
          value: null,
        };
      },
      /**
       * Called when the user changes the container selection from the multi select
       * We need to make a call to the api to reflect the change
       * We may also "auto-magically" add a new row for the user
       *
       * @param {object} $event
       */
      onContainerChange($event) {
        this.planDesignErrorCount = 0;
        const { value, containerGroup } = $event;
        const { newItems, removedItems, values } = $event.containers;
        // this can happen from changes in the annotation modal
        // if this value isn't active then we don't want to take any action
        let containersArray = [];
        let rowsWithNoContainers = 0;

        if (removedItems.length) {
          containerGroup.forEach((groupValue, groupValueIndex) => {
            const currentSubtype = groupValue.tier_subtype_id || null;

            containerGroup[groupValueIndex].project_products_container_ids = values;
            // Delete the value with the removed Items
            this.deletePlanDesignValue({
              containerIds: removedItems,
              subtypeId: currentSubtype,
            });

            this.unusedContainerIds = this.unusedContainerIds.concat(removedItems);
          });
        }

        if (newItems.length) {
          containerGroup.forEach(async (groupValue, groupValueIndex) => {
            containerGroup[groupValueIndex].project_products_container_ids = values;
            // the subtype isn't matching
            await this.updatePlanValue({
              containerIds: values,
              subtypeId: groupValue.tier_subtype_id,
              tierGroupId: groupValue.tier_group_id,
              value: groupValue.value,
            });
          });
        }
        // AUTO MAGIC BEGINS
        // We need to count if there are any empty rows (so we don't add any new ones)
        // We also need to come with a "containersArray" to see if more rows are needed
        this.sortedTierGroups.forEach((tierGroup) => {
          tierGroup.containerGroups.forEach((containerGroups) => {
            containerGroups.forEach((groupValue) => {
              // if the new value doesn't have a tier group then we count all containers
              // but if this groupValue doesn't have a tier group we also count them all
              if (!value.tier_subtype_id || !groupValue.tier_subtype_id) {
                containersArray = containersArray.concat(groupValue.project_products_container_ids);
              } else if (value.tier_subtype_id === groupValue.tier_subtype_id) {
                // now I'm assuming one of these is a subtype so we only count the containers if the subtypes match
                containersArray = containersArray.concat(groupValue.project_products_container_ids);
              }
              if (!groupValue.project_products_container_ids.length) {
                rowsWithNoContainers += 1;
              }
            });
          });
          containersArray = uniq(containersArray);
        });

        if (containersArray.length === this.currentContainers.length) {
          this.removeEmptyRows(value);
        }

        if (removedItems.length
          && !rowsWithNoContainers
          && containersArray.length < this.currentContainers.length
        ) {
          this.onAddRow({
            rowInfo: value,
            containerIds: removedItems,
          });
        }

        // Remove any new Items to unused unusedContainerIds
        if (newItems.length) {
          newItems.forEach((id) => {
            const idIndex = this.unusedContainerIds.findIndex((containerId) => containerId === id);

            if (idIndex !== -1) {
              this.unusedContainerIds.splice(idIndex, 1);
            }
          });
        }
      // delete any values that were removed from the change
      // AUTO MAGIC ENDS
      },
      /**
       * Called when the user changes the tier group of an attribute
       *
       * @param {object} options
       * @param {Array} options.containerGroup
       * @param {number} options.containerGroupIndex
       * @param {object} options.tierGroup
       * @param {number} options.tierGroupId
       * @param {number} options.tierGroupIndex
       */
      onTierGroupChange({
        containerGroup,
        containerGroupIndex,
        tierGroup,
        tierGroupId,
        tierGroupIndex,
      }) {
        this.planDesignErrorCount = 0;
        const clonedTierGroup = cloneDeep(tierGroup);
        const containerArray = containerGroup[0].project_products_container_ids;
        const newTierGroup = this.availablePlanDesignTierGroups.find((availablePlanDesignTierGroup) => availablePlanDesignTierGroup.tier_group_id === tierGroupId);
        let clonedTierGroups = cloneDeep(this.sortedTierGroups);

        this
          .deleteAttributeValues(containerArray)
          .then(() => {
            const newRows = this.createNewPlanDesignRows([containerArray], newTierGroup);

            // save these new values (if there are containers)
            clonedTierGroup.name = newTierGroup ? newTierGroup.tier_group_name : null;
            clonedTierGroup.containerGroups[containerGroupIndex] = newRows;
            this.$set(clonedTierGroups, tierGroupIndex, clonedTierGroup);
            const allValues = this.getAllValues(clonedTierGroups);

            clonedTierGroups = this.groupByTierGroup(allValues);
            clonedTierGroups.forEach((currentClonedTierGroup, currentClonedTierGroupIndex) => {
              clonedTierGroups[currentClonedTierGroupIndex].containerGroups = this.groupByContainers(currentClonedTierGroup.values);
            });
            this.$set(this, 'sortedTierGroups', clonedTierGroups);
            this.$nextTick(() => {
              // I want to keep focus inside this component
              // we check where the focus is when data is updated
              // and we want to keep the focus here so it doesn't update again unnecessarily
              this.$el.querySelector('input').focus();
            });
          })
          .catch(() => {
            this.displayToast({
              message: 'There was an error deleting the containers.',
            });
          });
      },
      /**
       * gets all values of a given set of tierGroups
       *
       * @param {Array} tierGroups
       * @returns {Array}
       */
      getAllValues(tierGroups) {
        const values = [];

        tierGroups.forEach((tierGroup) => {
          tierGroup.containerGroups.forEach((containerGroup) => {
            containerGroup.forEach((value) => {
              values.push(value);
            });
          });
        });

        return values;
      },
      /**
       * Create multiple new rows to enter new data
       *
       * @param {Array} containerArray
       * @param {object} tierGroup
       * @returns {Array}
       */
      createNewPlanDesignRows(containerArray, tierGroup) {
        const rows = [];
        const subtypes = tierGroup ? tierGroup.tier_subtypes : [{}];

        // save the new blank values
        containerArray.forEach((group) => {
          subtypes.forEach(async (subtype) => {
            const row = this.createEmptyValue({
              containerIds: group,
              subtypeId: subtype.subtype_id,
              subtypeName: subtype.subtype_name,
              tierGroupId: tierGroup ? tierGroup.tier_group_id : null,
            });

            rows.push(row);

            if (row.project_products_container_ids.length) {
              await this.updatePlanValue({
                containerIds: row.project_products_container_ids,
                subtypeId: row.tier_subtype_id,
                tierGroupId: row.tier_group_id,
                value: row.value,
              });
            }
          });
        });

        return rows;
      },
      /**
       * sorts tier groups by given values
       *
       * @param {Array} values
       * @returns {Array} sorted tier groups
       */
      sortTierGroups(values) {
        if (!values[0].length) {
          return values;
        }

        const sortedIds = [];
        const sortBy = (array) => array.sort((a, b) => {
          const containerA = this.currentContainers.find((container) => container.id === a);
          const containerB = this.currentContainers.find((container) => container.id === b);

          return containerA.position - containerB.position;
        });

        // Loop through each container and grab the first container ID of the first value group
        values.forEach((value, valueIndex) => {
          const sortedTierGroup = sortBy(value[0].project_products_container_ids);

          sortedIds.push({
            id: sortedTierGroup[0],
            valueIndex,
          });
        });

        return sortedIds
          .sort((a, b) => {
            const containerA = this.currentContainers.find((container) => container.id === a.id);
            const containerB = this.currentContainers.find((container) => container.id === b.id);

            if (!containerA && containerB) {
              return 1;
            }

            if (containerA && !containerB) {
              return -1;
            }

            if (!containerA && !containerB) {
              return 0;
            }

            return containerA.position - containerB.position;
          })
          .map((obj) => values[obj.valueIndex]);
      },
      /**
       * API call to PATCH the Plan Design Value. `attributeId` and `productId` are part of this component so no need to pass these.
       *
       * @param {object} options
       * @param {Array} options.containerIds
       * @param {number} options.subtypeId
       * @param {number} options.tierGroupId
       * @param {string} options.value
       */
      updatePlanValue({
        containerIds,
        subtypeId,
        tierGroupId,
        value,
      }) {
        this.planDesignErrorCount = 0;
        ProductService.updatePlanValue({
          attributeId: this.clonedAttribute.id,
          containerIds,
          productId: this.selectedProductId,
          subtypeId,
          tierGroupId,
          value,
        });
      },
      /**
       * API call to DELETE the Plan Design Value. `attributeId` and `productId` are part of this component so no need to pass these.
       *
       * @param {object} options
       * @param {Array} options.containerIds
       * @param {number} options.subtypeId
       */
      async deletePlanDesignValue({ containerIds, subtypeId = null }) {
        this.deleteAssociatedAnnotations(containerIds);
        await ProductService.deletePlanDesignValue({
          attributeId: this.clonedAttribute.id,
          containerIds,
          productId: this.selectedProductId,
          subtypeId,
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  h5 {
    font-size: 14px;
    color: var(--tf-blue);
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    padding: 9px 15px 8px 10px; // want to allow for scroll bar
    margin: 0;
    line-height: 14px;
    box-shadow: inset 0 -1px 0 0 var(--tf-gray-light-medium);

    &.hidden {
      color: var(--tf-gray-medium);
    }
  }

  :deep(.app-button.has-icon.hide-button) {
    &.hidden i {
      color: var(--tf-gray-medium);
    }

    i {
      font-style: normal;
      width: 14px;
      height: 13px;
    }
  }

  /* stylelint-disable selector-max-compound-selectors */
  .table-child {
    border-left: 0;
    margin-left: 20px;
    width: calc(100% - 20px);

    thead {
      tr {
        border-bottom: 2px var(--tf-gray-light-medium) solid;
      }

      th {
        padding: 4px 5px;
        font-weight: normal;

        &:first-child {
          padding: 0;
        }

        &:nth-child(2) {
          padding-left: 19px;
        }

        &:last-child {
          padding-right: 13px;
        }
      }
    }
  }
  /* stylelint-enable selector-max-compound-selectors */
</style>
