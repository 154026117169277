<template>
  <div class="file-tabs-wrapper">
    <button
      :class="{ open: fileManagerVisible }"
      class="file-button"
      data-test="file button"
      @click="toggleFileManager"
    >
      <AppIcon
        v-if="!fileManagerVisible"
        icon="fa-solid fa-file"
      />
      <AppIcon
        v-else
        icon="fa-solid fa-times"
      />
    </button>
    <button
      :class="{ open: pageToolbarVisible }"
      class="ellipsis-button"
      @click="togglePdfViewerToolbar('page')"
    >
      <AppIcon
        v-if="!pageToolbarVisible"
        icon="fa-solid fa-ellipsis-v"
      />
      <AppIcon
        v-else
        icon="fa-solid fa-times"
      />
    </button>
    <TfTabs
      v-model="activeFileId"
      type="file"
      closable
    >
      <ElTabPane
        v-for="item in fileTabs"
        :key="item.id"
        :name="item.name"
      >
        <!-- TODO LC-563 -->
        <div
          slot="label"
          class="label-wrapper"
          data-test="file tab"
        >
          <TfTooltip
            :append-to-body="true"
          >
            <span
              slot="reference"
              class="label"
            >
              {{ item.label }}
            </span>
            {{ item.label }}
          </TfTooltip>
          <span
            class="close"
            @click.stop="closeTab(item)"
          ><AppIcon icon="fa-solid fa-times" /></span>
        </div>
      </ElTabPane>
    </TfTabs>
    <div v-if="fileManagerVisible">
      <div
        class="backdrop"
        @click="toggleFileManager"
      />
      <FileManager
        @approveClose="receiveFileManagerCloseApprove"
      />
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions, mapWritableState } from 'pinia';
  import { useFilesStore } from '@/stores/files.js';
  import { useFileViewerStore } from '@/stores/fileViewer.js';
  import { usePdfViewerStore } from '@/stores/pdfViewer.js';
  import FileManager from '@/components/FileViewer/FileManager/index.vue';
  import { useProjectStore } from '@/stores/project.js';

  /**
   * Tabs at the top of the pdf viewer to swap out what PDF the user is viewing
   *
   * @vuedoc
   * @exports FileTabs
   * @category Components
   */
  export default {
    name: 'FileTabs',
    components: {
      FileManager,
    },
    data() {
      return {
        fileManagerVisible: false,
      };
    },
    computed: {
      ...mapState(useFilesStore, ['fileTabs']),
      ...mapState(useFileViewerStore, [
        'getActiveFileId',
        'openFileIds',
      ]),
      ...mapState(usePdfViewerStore, [
        'pageToolbarVisible',
      ]),
      ...mapWritableState(useFileViewerStore, [
        'requestFileManagerClose',
        'requestTabClose',
        'requestTabFocus',
      ]),
      ...mapState(useProjectStore, ['currentProject']),
      /**
       * Getter/setter to get the current project's active file id and update it if the tab changes.
       */
      activeFileId: {
        get() {
          return this.getActiveFileId;
        },
        set(value) {
          this.setActiveFileId({
            projectId: this.currentProject.id,
            fileId: value,
          });
        },
      },
      /**
       * shorthand access to the openFileIds for this project
       *
       * @returns {Array}
       */
      projectOpenFileIds() {
        return this.openFileIds[this.currentProject.id];
      },
    },
    watch: {
      fileTabs: {
        /**
         * If only one tab is open and it has JUST been opened we should focus on it
         *
         * @param {Array} newFiles
         * @param {Array} oldFiles
         */
        handler(newFiles, oldFiles) {
          const oneFileOpen = (!oldFiles || oldFiles.length === 0) && newFiles?.length > 0;

          if (oneFileOpen) {
            this.setActiveFileId({
              projectId: this.currentProject.id,
              fileId: `${newFiles[0].id}`,
            });

            this.requestTabFocus = null;
          }
        },
        immediate: true,
      },
      /**
       * If the fileManager requested a tab to be closed, check if it's
       * the active tab and if so, switch to another source
       *
       * @param {number} fileId
       */
      requestTabClose(fileId) {
        if (fileId && this.getActiveFileId === `${fileId}`) {
          this.selectAnotherTab(fileId);
          this.requestTabClose = null;
        }
      },
      /**
       * If the fileManager or value rows requested a tab to be focused
       *
       * @param {number} fileId
       */
      requestTabFocus(fileId) {
        if (fileId) {
          // if not open yet, open it
          if (!this.projectOpenFileIds.includes(fileId)) {
            this.setFileOpen({
              projectId: this.currentProject.id,
              fileId,
              value: true,
            });
          }

          // delay focusing on tab just a little bit
          this.$nextTick(() => {
            this.setActiveFileId({
              projectId: this.currentProject.id,
              fileId: `${fileId}`,
            });
            this.requestTabFocus = null;
          });
        }
      },
    },
    methods: {
      ...mapActions(useFilesStore, ['selectAnotherTab']),
      ...mapActions(useFileViewerStore, ['setFileOpen', 'setActiveFileId']),
      ...mapActions(usePdfViewerStore, ['togglePdfViewerToolbar']),
      /**
       * Request the File manager permission to close it
       */
      toggleFileManager() {
        if (this.fileManagerVisible) {
          this.requestFileManagerClose = true;
        } else {
          // disable scrolling
          document.getElementsByTagName('body')[0]
            .classList
            .add('lock-scrolling');
          this.fileManagerVisible = true;
        }
      },
      /**
       * File Manager had no pending items, close approved
       */
      receiveFileManagerCloseApprove() {
        // re-enable scrolling
        document.getElementsByTagName('body')[0]
          .classList
          .remove('lock-scrolling');
        this.fileManagerVisible = false;
        this.requestFileManagerClose = false;
      },
      /**
       * Close a tab
       *
       * @param {object} item
       */
      closeTab(item) {
        // switch to another tab if this is the currently visible source
        if (this.getActiveFileId === `${item.id}`) {
          this.selectAnotherTab(item.id);
        }
        this.$nextTick(() => {
          // close the file
          this.setFileOpen({
            projectId: this.currentProject.id,
            fileId: item.id,
            value: false,
          });
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .file-tabs-wrapper {
    position: relative;
    width: calc(100% - 14px);
    z-index: 20;
    height: 45px;
  }

  .backdrop {
    position: fixed;
    top: 57px;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 23;
    background: rgba(0, 0, 0, .2);
  }

  .file-button,
  .ellipsis-button {
    position: absolute;
    top: 0;
    left: 0;
    height: 46px;
    z-index: 20;
    margin: 0;
    padding: 0;
    border: 1px var(--tf-gray-medium) solid;
    border-top-color: var(--tf-gray);

    svg {
      color: var(--tf-gray);
    }

    &.open {
      background: var(--tf-blue);

      i {
        color: var(--tf-base-light);
      }
    }
  }

  .close {
    height: 100%;
    display: flex;
    align-items: center;
    padding-right: 8px;
  }

  .file-button {
    width: 54px;

    &.open {
      z-index: 24;
    }
  }

  .ellipsis-button {
    width: 21px;
    left: auto;
    right: 0;
  }
</style>
