<template>
  <div
    :class="[
      { 'editing-form': showFormInputs },
      { 'is-alternative': product.alternative },
      'product-form',
    ]"
  >
    <ElForm
      :class="`product-form-${product.alternative ? 'alternative' : 'base'}`"
      class="product-form-data"
      @submit.native.prevent="onSubmit"
    >
      <div
        v-if="product.alternative"
        class="product-label"
      >
        <ProductLabel
          ref="productLabel"
          v-model="localValue.label"
          :disabled="isSaving"
          :enable-product-label-link="enableProductLabelLink"
          :is-editing="showFormInputs"
          :product="product"
          placeholder="Enter alternative label"
          data-test="Enter alternative label"
          @click="onClickRouterLink"
          @isDuplicate="isDuplicate = $event"
          @onKeydownEnter="onKeydownEnter"
        />
      </div>
      <template v-else>
        <div class="product-type">
          <ElSelect
            v-if="isNewProduct"
            v-model="localValue.productType"
            data-test="select product type"
            no-match-text="Product not found"
            placeholder="Select product"
            filterable
          >
            <ElOption
              v-for="productType in availableProductTypes"
              :key="productType.id"
              :data-test="productType.name"
              :label="productType.name"
              :disabled="activeProjectProducts.some(product => productType.id === product.product_type_id)"
              :value="productType.id"
            />
          </ElSelect>
          <AppButton
            v-else
            data-test="product type button"
            :is-disabled="isEditing"
            :text="productTypeButtonText"
            class="readonly-text"
            type="primary"
            size="text"
            @click.native="onClickRouterLink"
          />
        </div>
        <!-- Inforce Carrier -->
        <div class="inforce-carrier">
          <ElSelect
            v-if="showFormInputs"
            v-model="localValue.carrier"
            :disabled="disableCarrier"
            filterable
            no-match-text="Carrier not found"
            data-test="select carrier"
            placeholder="Select carrier"
          >
            <ElOptionGroup
              v-for="group in productCarriers"
              :key="group.label"
              :label="group.label"
            >
              <ElOption
                v-for="carrier in group.options"
                :key="carrier.id"
                :data-test="carrier.name"
                :label="carrier.name"
                :value="carrier.id"
              />
            </ElOptionGroup>
          </ElSelect>
        </div>
        <!-- Product Label -->
        <div class="product-label">
          <ProductLabel
            ref="productLabel"
            v-model="localValue.label"
            :disabled="!value.productType || isSaving"
            :is-editing="showFormInputs"
            :product="product"
            @isDuplicate="isDuplicate = $event"
            @onKeydownEnter="onSubmit"
          />
        </div>
        <ProductPolicyIds
          ref="productPolicyIds"
          v-model="localValue.policyIds"
          :is-editing="showFormInputs"
          :is-new="product.isNewProduct"
        />

        <!-- Prior Coverage -->
        <div
          v-if="showFormInputs"
          class="prior-coverage"
        >
          <ElFormItem
            for="value.priorCoverage"
            size="medium"
          >
            <ElCheckbox
              v-model="localValue.priorCoverage"
              data-test="prior coverage"
              :disabled="disablePriorCoverage || isSaving"
              label="Prior coverage"
            />
          </ElFormItem>
        </div>
      </template>
    </ElForm>
    <!-- Product actions -->
    <div
      class="product-form-actions"
      :class="{ 'is-editing': isEditing }"
    >
      <div class="btn-group align-end">
        <!-- btn: cancel edit -->
        <AppButton
          v-if="showFormInputs"
          :is-disabled="isSaving"
          data-test="cancel"
          icon="fa-solid fa-times"
          size="icon"
          type="secondary"
          @click.native="onClickCancel"
        />
        <!-- btn: save product -->
        <AppButton
          v-if="showFormInputs"
          :is-disabled="disableSave || isSaving"
          :is-loading="isSaving"
          data-test="submit"
          icon="fa-solid fa-check"
          size="icon"
          type="affirm"
          @click.native="onSubmit"
        />
        <!-- btn: edit product -->
        <AppButton
          v-if="!isEditing && !isNewProduct && product.alternative"
          data-test="alt edit button"
          icon="fa-solid fa-pencil"
          size="text"
          type="primary"
          text="Edit"
          @click.native="$emit('update:isEditing', true)"
        />
      </div>

      <StateBadge
        v-if="(product.alternative && !isEditing) || (product.alternative && isNewProduct)"
        :state="productState.current"
      />
    </div>
  </div>
</template>

<script>
  // components
  import ProductLabel from '@/components/Product/ProductLabel.vue';
  import ProductPolicyIds from '@/components/Product/ProductPolicyIds.vue';
  import StateBadge from '@/components/StateBadge.vue';
  import { isEqual } from 'lodash';
  // services
  import ProductService from '@/services/product.js';
  // utils
  import getProductState from '@/utils/productState.js';
  // pinia
  import { mapState, mapWritableState, mapActions } from 'pinia';
  import { useCarriersStore } from '@/stores/carriers.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useProjectProductStore } from '@/stores/projectProduct.js';
  import { useProductSelectionsStore } from '@/stores/productSelections.js';

  /**
   * Product form
   *
   * @vuedoc
   * @exports ProductForm
   * @category Components
   */
  export default {
    name: 'ProductForm',
    components: {
      ProductLabel,
      ProductPolicyIds,
      StateBadge,
    },
    props: {
      isEditing: {
        type: Boolean,
        default: false,
      },
      product: {
        type: Object,
        default: () => ({
          inforce_product: {
            carrier_id: null,
            state: '',
            policy_ids: [],
          },
          product_type_id: null,
          plan_type: null,
          prior_coverage: true,
        }),
      },
      // This prop is being received from the localForm property on the v-model in the parent
      value: {
        type: Object,
        default: () => ({
          carrier: null,
          label: null,
          priorCoverage: true,
          productType: null,
          policyIds: [],
        }),
      },
    },
    data() {
      return {
        isDuplicate: false,
        isSaving: false,
        certifiedCarriers: [],
      };
    },
    computed: {
      ...mapState(useCarriersStore, ['getAvailableCarriers']),
      ...mapState(useProjectProductStore, ['activeProjectProducts']),
      ...mapState(useProjectStore, ['currentProject']),
      ...mapWritableState(useProjectProductStore, ['selectedProjectProductId']),
      ...mapWritableState(useProjectStore, ['projectPanelActive']),
      ...mapState(useProductSelectionsStore, ['availableProductTypes']),
      /**
       * Returns the base product of the alternative product if applicable.
       *
       * @returns {object}
       */
      baseProduct() {
        return this.activeProjectProducts.find(
          (product) => !product.alternative && product.product_type_id === this.value.productType,
        );
      },
      /**
       * Evaluates if the carrier dropdown should be disabled based on various parameters.
       *
       * @returns {boolean}
       */
      disableCarrier() {
        return this.disablePriorCoverage
          || !this.value.priorCoverage
          || !this.value.productType
          || this.isSaving;
      },
      /**
       * Returns a boolean based on if the carrier dropdown and prior coverage checkbox should be disabled based on the project state.
       *
       * @returns {boolean}
       */
      disablePriorCoverage() {
        return this.currentProject.state === 'rfp_completed';
      },
      /**
       * Evaluates if the save button should be disabled based on various parameters.
       *
       * @returns {boolean}
       */
      disableSave() {
        const alternativeRequired = this.product.alternative && !this.value.label;
        const carrierRequired = this.value.priorCoverage && !this.value.carrier;

        return alternativeRequired
          || carrierRequired
          || this.isDuplicate
          || this.isSaving
          || this.hasNoChanges
          || !this.value.productType;
      },
      /**
       * Evaluates if product label should be a link based on the base product state.
       *
       * @returns {boolean}
       */
      enableProductLabelLink() {
        return !this.isEditing
          && this.baseProduct
          && this.baseProduct.inforce_product.state === 'completed';
      },
      /**
       * Returns an object representing the current and next states for a given product
       *
       * @returns {object}
       */
      productState() {
        return getProductState(this.product.inforce_product.state);
      },
      /**
       * Returns a boolean based on if the product is new and not in the DB yet.
       *
       * @returns {boolean}
       */
      isNewProduct() {
        return this.product.isNewProduct;
      },
      /**
       * Evaluates if anything is different between the form and product data.
       *
       * @returns {boolean}
       */
      hasNoChanges() {
        const productInfo = {
          carrier: this.product.inforce_product.carrier_id,
          label: this.product.label,
          priorCoverage: this.product.prior_coverage,
          productType: this.product.product_type_id,
          policyIds: this.product.inforce_product.policy_ids,
        };

        return Object.keys(productInfo).every(
          (key) => {
            if (key === 'policyIds') {
              const hasNoPolicyIds = !this.value.policyIds || (productInfo.policyIds.length === 0
                && (this.value.policyIds.length === 1 && this.value.policyIds[0] === '')
              );

              if (hasNoPolicyIds) return true;

              return isEqual(productInfo.policyIds, this.value.policyIds);
            }

            return (productInfo[key]) === this.value[key];
          },
        );
      },
      /**
       * Filters the availableCarriers based on if that carrier supports the selected product.
       * Filter out 'No Prior Coverage'
       *
       * @returns {Array}
       */
      nonCertifiedCarriers() {
        const nonCertCarriers = this.getAvailableCarriers.filter(({ id }) => !this.certifiedCarriers.find(
          (certifiedCarrier) => certifiedCarrier.id === id,
        ));

        // this filters out 'Other Carrier', id: 159, & 'No Prior Coverage', id: 49. The filter on this return
        // can be removed when Other Carrier & No Prior Coverage is removed from the BE with ticket SEA-89
        return nonCertCarriers.filter(({ id }) => ![49, 159].includes(+id));
      },
      /**
       * Returns an array grouped by "certified" and "non-certified" carriers.
       *
       * @returns {Array}
       */
      productCarriers() {
        return [
          {
            label: 'Certified carriers',
            options: this.certifiedCarriers,
          },
          {
            label: 'Non-certified carriers',
            options: this.nonCertifiedCarriers,
          },
        ];
      },
      /**
       * returns a concatenated string containing the product type name and carrier if one exists
       *
       * @returns {string}
       */
      productTypeButtonText() {
        const carrierName = this.isEditing
          ? ''
          : ` - ${this.product.inforce_carrier_name || 'None'}`;

        return this.product.product_type_name + carrierName;
      },
      /**
       * Evaluates if the product is new or is being edited.
       *
       * @returns {boolean}
       */
      showFormInputs() {
        return this.isEditing || this.isNewProduct;
      },
      /**
       * Clone the model prop to be used in the template. The v-models in the template will emit to the parent. We're not sure where that is though...b/c :robot-shrug:.
       *
       * @returns {object}
       */
      localValue() {
        return this.value;
      },
    },
    watch: {
      'localValue.productType': {
        /**
         * Update the list of certified carriers that offer the current product type
         *
         * @param {number} newValue
         * @param {number} oldValue
         */
        async handler(newValue, oldValue) {
          if (newValue && newValue !== oldValue) {
            try {
              const { carriers } = await ProductService.getProductTypeOfferingCarriers(newValue);

              this.$set(this, 'certifiedCarriers', carriers);
            } catch {
              this.displayToast({
                message: 'Error getting certified carriers.',
              });
            }
          }
        },
        immediate: true,
      },
    },
    methods: {
      ...mapActions(useProjectProductStore, [
        'removeNewProjectProduct',
        'updateActiveProjectProduct',
        'addActiveProjectProduct',
      ]),
      /**
       * If canceling a new product, Changes isEditing to false and emits to the parent for any further actions.
       */
      onClickCancel() {
        if (this.isNewProduct) {
          this.removeNewProjectProduct(this.product.id);
        } else {
          this.$emit('onClickCancel');
        }
      },
      /**
       * Set the active product in the store, push the router, and toggle the product panel.
       */
      onClickRouterLink() {
        this.projectPanelActive = !this.projectPanelActive;

        if (Number(this.product.id) !== Number(this.selectedProjectProductId)) {
          this.$router
            .replace({
              name: 'ProjectSetup',
              params: {
                projectId: this.currentProject.id,
                projectProductId: this.product.id,
              },
            });
        }

        this.selectedProjectProductId = this.product.id;
      },
      /**
       * If the save button isn't disabled, trigger the form to submit.
       */
      onKeydownEnter() {
        if (this.disableSave) {
          return;
        }

        this.onSubmit();
      },
      /**
       * Saves the product to the database with a POST or PATCH call and emits to the parent on success.
       */
      async onSubmit() {
        const carrierId = this.value.priorCoverage
          ? this.value.carrier
          : null;
        const cleanPolicyIds = this.value.policyIds?.filter((id) => id !== '') ?? [];
        let productData = {
          label: this.value.label,
          plan_type: 'Policy',
          prior_coverage: this.value.priorCoverage,
          product_type_id: this.value.productType,
          carrier_id: carrierId,
          policy_ids: cleanPolicyIds,
        };

        this.isSaving = true;

        if (this.isNewProduct) {
          if (this.product.alternative) {
            productData = {
              ...productData,
              base_product_id: this.product.baseProductId,
            };
          }
          try {
            const product = await ProductService.createNewProduct(
              this.currentProject.inforce_document.id,
              productData,
              this.product.alternative,
            );

            this.addActiveProjectProduct({
              containers: product.project_products_containers,
              product: product.project_product,
            });
            this.removeNewProjectProduct(this.product.id);
          } catch {
            this.displayToast({
              message: 'Product alternate names need to be unique.',
            });
          } finally {
            this.isSaving = false;
          }
        } else {
          try {
            const { product } = await ProductService.updateProductDetails(
              this.product.inforce_product.id,
              productData,
            );
            const activeProductIdx = this.activeProjectProducts.findIndex(
              ({ id }) => id === this.product.id,
            );
            const updatedProduct = {
              activeProductIdx,
              product: product.project_product,
            };

            this.localValue.policyIds = isEqual(cleanPolicyIds, []) ? [''] : cleanPolicyIds;

            this.updateActiveProjectProduct(updatedProduct);
          } catch (error) {
            let message = 'There was an error updating the project. Please contact customer support.';

            if (error.data.message.includes('No-prior-coverage project_products are not allowed on Projects::InitialEmployerBuild type projects')) {
              message = 'Renaming alternates on legacy Initial Employer Build projects is not supported. Please contact customer support for assistance.';
            }

            this.displayToast({
              message,
            });
          } finally {
            this.isSaving = false;
            this.$emit('update:isEditing', false);
          }
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  :deep() {
    .el-form-item {
      margin-bottom: 0;
    }

    .el-select {
      width: 100%;
    }
  }

  .product {
    &-form {
      display: flex;
      padding: 20px 12px;

      &-actions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-left: auto;

        .is-alternative & {
          min-width: 188px;
        }

        &.is-editing {
          min-width: auto;
        }

        .btn-group {
          align-items: flex-start;
          height: 100%;
        }

        .app-button + .app-button {
          margin-left: 10px;
        }
      }

      &-data {
        display: flex;
        flex: {
          wrap: wrap;
          grow: 1;
        }
        margin-right: 20px;
      }
    }

    &-type {
      width: calc(50% - 10px);
      margin-right: 10px;
    }
  }

  .product-label {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .el-form-item {
      flex-grow: 1;
    }
  }

  .product-label,
  .prior-coverage {
    width: 100%;
  }

  .inforce-carrier,
  .product-type {
    .editing-form & {
      margin-bottom: 10px;
    }
  }

  .inforce-carrier {
    width: 50%;
  }

  .readonly-text,
  :deep(.readonly-text) {
    display: flex;
    align-items: center;
    min-height: 30px;
    font-size: 14px;

    &.app-button.is-disabled {
      color: var(--tf-base-dark);
      font-weight: 400;
    }
  }
</style>
