<template>
  <div class="document-container">
    <h4>Document type</h4>
    <div class="document-selector">
      <ElSelect
        v-model="selectedDocumentType"
        :disabled="isSelectedDocumentTypeDisabled"
        size="small"
        no-data-text="No available documents"
        placeholder="Select document type"
        data-test="select document input"
      >
        <ElOption
          v-for="(document, index) in availableDocuments"
          :key="index"
          data-test="document option"
          :label="document"
          :value="document"
        />
      </ElSelect>
      <template v-if="selectedDocumentType">
        <div
          v-if="isDocumentTypeEditable"
        >
          <AppButton
            icon="fa-solid fa-pencil"
            size="icon"
            type="primary"
            @click="isEditing = true"
          />
        </div>
        <div
          v-if="selectedDocumentType !== jobSetupDocumentType || isEditing"
          class="btn-group align-end"
        >
          <AppButton
            :is-disabled="!selectedDocumentType"
            type="decline"
            size="icon"
            icon="fa-solid fa-times"
            @click="clearDocumentSelection"
          />
          <AppButton
            data-test="submit button"
            :is-loading="isSaving"
            :is-disabled="!selectedDocumentType"
            icon="fa-solid fa-check"
            size="icon"
            type="affirm"
            @click="saveDocument"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import {
    mapActions,
    mapState,
  } from 'pinia';
  import { useTacticEngineStore } from '@/stores/tacticEngine.js';
  import { useFileViewerStore } from '@/stores/fileViewer.js';

  export default {
    name: 'DocumentType',
    data() {
      return {
        documents: [],
        error: false,
        isSaving: false,
        isEditing: false,
        selectedDocumentType: null,
      };
    },
    computed: {
      ...mapState(useTacticEngineStore, [
        'jobSetups',
        'jobs',
        'jobSetupDocumentType',
      ]),
      ...mapState(useFileViewerStore, ['getActiveFileId']),
      /**
       * Filtered list of available documents extracted from the existing job setups
       *
       * @returns {Array}
       */
      availableDocuments() {
        const documents = [];

        this.jobSetups.forEach(({ search_template_document_types: docTypes }) => {
          docTypes.forEach((docType) => {
            if (!documents.includes(docType)) {
              documents.push(docType);
            }
          });
        });

        return documents;
      },
      /**
       * Disable the document type selector if
       * - There are no job setups
       * - There is a previously selected document type
       * - We're not editing the field
       * - The selected option matches the previously selected type
       *
       * @returns {boolean}
       */
      isSelectedDocumentTypeDisabled() {
        if (!this.jobSetups.length) {
          return true;
        }

        return !!this.jobSetupDocumentType && this.selectedDocumentType === this.jobSetupDocumentType && !this.isEditing;
      },
      /**
       * The document type should not be editable if there are any previously run jobs
       *
       * @returns {boolean}
       */
      isDocumentTypeEditable() {
        return this.selectedDocumentType === this.jobSetupDocumentType && !this.isEditing && !this.jobs.length;
      },
    },
    watch: {
      jobSetupDocumentType: {
        /**
         * When the job setup document type is defined, copy the value to selectedDocumentType
         * unless there are no job setups. Since we can't set document_type on the backend to null
         * we want to ignore the value if there are no setups.
         */
        handler() {
          if (this.jobSetups.length) {
            this.selectedDocumentType = this.jobSetupDocumentType || null;
          }
        },
        immediate: true,
      },
      availableDocuments: {
        /**
         * When the user creates a job setup, if they haven't already defined a document type
         * check if there is only one available document type for the setup and toss it into
         * the dropdown as a "prefilled" selection.
         */
        handler() {
          if (!this.jobSetupDocumentType && this.availableDocuments.length === 1) {
            // eslint-disable-next-line prefer-destructuring
            this.selectedDocumentType = this.availableDocuments[0];
          } else if (!this.jobSetupDocumentType && !this.availableDocuments.length) {
            this.selectedDocumentType = null;
          }
        },
        deep: true,
      },
    },
    methods: {
      ...mapActions(useTacticEngineStore, [
        'setJobSetupDocumentType',
      ]),
      /**
       * Save the selected Document
       */
      async saveDocument() {
        if (this.jobSetupDocumentType !== this.selectedDocumentType) {
          try {
            await this.setJobSetupDocumentType(this.getActiveFileId, this.selectedDocumentType);
          } catch {
            this.displayToast({
              message: 'There was an error saving the document type selection.',
            });
          } finally {
            this.isEditing = false;
          }
        } else {
          this.isEditing = false;
        }
      },
      /**
       * Reset the selected document
       */
      clearDocumentSelection() {
        this.selectedDocumentType = this.jobSetupDocumentType;
        if (this.selectedDocumentType) {
          this.isEditing = false;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
h4 {
  font-size: 16px;
}

.document-container {
  padding: 20px 10px;
  margin-top: 20px;
  background-color: var(--tf-gray-light);
  border: 1px solid var(--tf-gray-light-medium);
}

.document-selector {
  display: flex;

  .el-select {
    margin-right: 20px;
    border-color: var(--tf-gray-light-medium);
  }
}
</style>
