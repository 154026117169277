/**
 * Validation Utility File
 *
 * @exports Validation
 * @category Utils
 */
export default {
  /**
   * Get the validation type based on the attributes name
   *
   * @param {string} attributeName
   * @returns {boolean}
   */
  getValidationType(attributeName = '') {
    let validationType;

    switch (attributeName.toLowerCase()) {
      case 'aggregate corridor':
        validationType = 'percentage-whole-number';
        break;
      case 'rate guarantee':
        validationType = 'whole-number';
        break;
      default:
        validationType = 'string';
    }

    return validationType;
  },
  /**
   * Loop through the containers array and verify that localContainer isn't duplicating any descriptions
   *
   * @param {object} localContainer
   * @param {Array} containers
   * @returns {boolean}
   */
  checkDuplicateContainerNames(localContainer, containers) {
    const duplicateContainerNames = containers.filter((container) => (container.id !== localContainer.id)
        && container.description
        && container.description.toLowerCase() === localContainer.description.toLowerCase());

    return Boolean(duplicateContainerNames.length);
  },
  /**
   * Check if the key pressed on an input was a number
   *
   * @param {Event} event
   * @returns {boolean}
   */
  isNumberKey(event) {
    const charCode = event.which ? event.which : event.keyCode;
    const numericKey = (charCode >= 48 && charCode <= 57) || (charCode >= 96 && charCode <= 105);
    const isNanKey = charCode > 31 && !numericKey;

    if (isNanKey) {
      event.preventDefault();
    }

    return isNanKey;
  },
};
