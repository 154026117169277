<template>
  <div>
    <DashboardApiTable
      show-filter-button
      show-title
      dashboard-type="my_collaborations"
      title="Collaborator"
    />
    <DashboardApiTable
      show-filter-button
      show-title
      dashboard-type="my_projects"
      title="Owner"
    />
  </div>
</template>

<script>
  import DashboardApiTable from '@/components/Dashboard/IndexApi.vue';

  /**
   * Project Table to display all the products owned by user.
   *
   * @vuedoc
   * @exports DashboardOwner
   * @category Views
   */
  export default {
    name: 'DashboardOwner',
    components: {
      DashboardApiTable,
    },
  };
</script>
