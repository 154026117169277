<template>
  <div v-loading="loadingData">
    <!-- Attribute Selector -->
    <ElFormItem
      key="Attribute"
      label="Attribute:"
      :class="{ 'has-multiple-annotations': valueAnnotations.length > 1 }"
    >
      <ElSelect
        ref="attributeSelect"
        v-model="selectedAttributeValue"
        :disabled="disableDropdown"
        autocomplete
        filterable
        no-match-text="Attribute not found"
        placeholder="Select attribute"
        @change="onAttributeChange"
      >
        <ElOptionGroup
          v-for="category in categories"
          :key="`category-${category.id}`"
          :label="category.name"
        >
          <template v-for="attribute in category.categorized_attributes">
            <ElOption
              v-if="attribute.plans && attribute.plans.some(plan => plan.values.length)"
              :key="`attribute-dropdown-${category.id}-${attribute.id}`"
              :value="`${category.id}-${attribute.id}`"
              :label="attribute.name"
            />
          </template>
        </ElOptionGroup>
      </ElSelect>
    </ElFormItem>

    <!-- Subtype Group Selector -->
    <div id="form-tier-group">
      <div>
        <ElFormItem
          key="Tier group"
          label="Tier group:"
        >
          <!-- Tier Group Selector -->
          <ElSelect
            ref="tierGroupSelect"
            v-model="selectedTierGroupId"
            placeholder="Select"
            :disabled="(!tierGroupOptions.length || disableDropdown)"
            @change="onTierGroupChange"
          >
            <ElOption
              v-for="tierGroup in tierGroupOptions"
              :key="tierGroup.tier_group_id"
              :label="tierGroup.tier_group_name"
              :value="tierGroup.tier_group_id"
            />
          </ElSelect>
        </ElFormItem>
      </div>
      <!-- Subtype Selector -->
      <div>
        <ElFormItem
          key="Subtype"
          label="Subtype(s):"
        >
          <TfMultiSelect
            ref="subtypeSelect"
            v-model="selectedSubtypeIds"
            :append-to-body="false"
            :disable-click="(!subtypeOptions.length || disableDropdown)"
            :options="subtypeOptions"
            :total-options-available="subtypeOptions.length"
            empty-text="No subtypes available"
            label="subtype_name"
            popper-class="multi-select-popover"
            value-key="subtype_id"
          />
        </ElFormItem>
      </div>
    </div>

    <!-- Plan/Class Selector -->
    <ElFormItem
      key="Plans"
      label="Plan(s):"
    >
      <TfMultiSelect
        ref="containerSelect"
        v-model="selectedContainerIds"
        :append-to-body="false"
        :disable-click="(!selectedTierGroup || disableDropdown)"
        :options="containerOptions"
        :total-options-available="selectedProduct.containers.length"
        empty-text="No plans available"
        label="description"
        popper-class="annotation-multi-select-popover multi-select-popover"
        value-key="id"
      />
    </ElFormItem>

    <!-- Value Selector -->
    <ElFormItem
      key="Value"
      label="Value:"
    >
      <TfInputWithOptions
        v-model="selectedValueText"
        :append-validation-type="['percentage-whole-number'].includes(validationType)"
        :auto-collapse="false"
        :attribute-id="selectedAttribute ? selectedAttribute.id : null"
        :disabled="disableDropdown"
        :options="selectedAttribute ? selectedAttribute.normalized_values : []"
        :width="460"
        :validation-type="validationType"
      />
    </ElFormItem>

    <ModalFooter
      :selected-container-ids="selectedContainerIds"
      :selected-product-id="selectedProductId"
      :selected-attribute-id="selectedAttributeId"
      :selected-tier-group-id="selectedTierGroupId"
      :selected-subtype-ids="selectedSubtypeIds"
      @save="saveAnnotation"
    />
  </div>
</template>

<script>
  import { mapState, mapActions, mapWritableState } from 'pinia';
  import { useFileViewerStore } from '@/stores/fileViewer.js';
  import { useAnnotationsStore } from '@/stores/annotations.js';
  import { usePdfAnnotationStore } from '@/stores/pdfAnnotation.js';
  // 3rd party
  import { captureException, addBreadcrumb } from '@sentry/vue';
  // utils
  import ValidationUtil from '@/utils/validation.js';
  // services
  import ProductService from '@/services/product.js';
  // components
  import ModalFooter from '@/components/AnnotationModal/Create/Footer.vue';
  import { useProjectProductStore } from '@/stores/projectProduct.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useProductSelectionsStore } from '@/stores/productSelections.js';
  import { useProductAttributesStore } from '@/stores/productAttributes.js';
  /**
   * Annotation Modal Form for products that use templated values.
   *
   * @vuedoc
   * @exports FormForTemplate
   * @category Components
   */
  export default {
    name: 'FormForTemplate',
    components: {
      ModalFooter,
    },
    props: {
      missingContainers: {
        type: Boolean,
        default: false,
      },
      selectedProduct: {
        type: Object,
        default: () => ({}),
      },
      selectedProductId: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        categories: null,
        loadingData: false,
        selectedAttributeValue: null,
        selectedAttributeId: null,
        selectedCategoryId: null,
        selectedContainerIds: [],
        selectedSubtypeIds: [],
        selectedTierGroupId: null,
        selectedValueText: null,
      };
    },
    computed: {
      ...mapState(useProductSelectionsStore, ['activeValueIds']),
      ...mapState(useProjectProductStore, ['selectedProjectProductId']),
      ...mapState(useProjectStore, ['currentProject']),
      ...mapState(useFileViewerStore, ['activeFileIds']),
      ...mapState(useAnnotationsStore, ['annotationsByPlanDesignValueIds']),
      ...mapWritableState(usePdfAnnotationStore, ['wordIdsError']),
      ...mapState(usePdfAnnotationStore, [
        'getAnnotatedWordIdsAreValid',
        'getAnnotationText',
        'isSaving',
        'newAnnotationWordIds',
      ]),
      /**
       * Returns an array of all annotations for the active attribute ID.
       * There is a timing issue with editing annotations so we should return an empty array while isSaving is true.
       *
       * @returns {Array}
       */
      valueAnnotations() {
        return this.annotationsByPlanDesignValueIds(this.activeValueIds);
      },
      /**
       * Returns an array of container options based on what plans use the associated tier group.
       *
       * @returns {Array}
       */
      containerOptions() {
        return this.selectedTierGroup
          ? this.selectedAttribute.plans.filter((plan) => plan.values[0].tier_group_id === this.selectedTierGroupId)
          : [];
      },
      /**
       * Boolean to disable dropdowns based on annotation actions or if the containers have not fully been set up.
       *
       * @returns {boolean}
       */
      disableDropdown() {
        return this.isSaving || this.missingContainers;
      },
      /**
       * Returns a boolean to see if the product selected in the modal is the same selected product selected in the data entry section.
       *
       * @returns {boolean}
       */
      isActiveProjectProduct() {
        return Number(this.selectedProjectProductId) === this.selectedProduct.id;
      },
      /**
       * Returns the category object (or null) from the product categories based on the selected category ID.
       *
       * @returns {object}
       */
      selectedCategory() {
        return this.selectedCategoryId
          ? this.categories.find((category) => category.id === this.selectedCategoryId)
          : null;
      },
      /**
       * Returns the attribute object (or null) from the selected category based on the selected attribute ID.
       *
       * @returns {object}
       */
      selectedAttribute() {
        return this.selectedCategory && this.selectedAttributeId
          ? this.selectedCategory.categorized_attributes.find((attribute) => attribute.id === this.selectedAttributeId)
          : null;
      },
      /**
       * Returns the tier group object (or null) from the tier group options based on the selected tier group ID.
       *
       * @returns {object}
       */
      selectedTierGroup() {
        return this.tierGroupOptions.length
          ? this.tierGroupOptions.find((tierGroup) => tierGroup.tier_group_id === this.selectedTierGroupId)
          : null;
      },
      /**
       * Returns an array of subtype options based on the selected tier group.
       *
       * @returns {Array}
       */
      subtypeOptions() {
        return this.selectedTierGroup
          ? this.selectedTierGroup.tier_subtypes
          : [];
      },
      /**
       * Returns an array of tier group options based on what tier groups the plans use for the selected attribute.
       *
       * @returns {Array}
       */
      tierGroupOptions() {
        return this.selectedAttribute
          ? [...new Set(this.selectedAttribute.plans
            .map((plan) => plan.values.map((value) => value.tier_group_id))
            .reduce((array, value) => array.concat(value), []))].map((id) => (id
            ? this.tierGroups.find((tierGroup) => tierGroup.tier_group_id === id)
            : {
              tier_group_id: null,
              tier_group_layout: null,
              tier_group_name: 'None',
              tier_subtypes: [],
            })).sort((a, b) => a.tier_group_id - b.tier_group_id)
          : [];
      },
      /**
       * Returns the validation type for this attribute.
       *
       * @returns {boolean}
       */
      validationType() {
        return this.selectedAttribute ? ValidationUtil.getValidationType(this.selectedAttribute.name) : null;
      },
    },
    watch: {
      /**
       * activeValueIds changes when hovering over annotation tick marks or selecting from the data entry section.
       */
      activeValueIds() {
        this.getProductDetails();
      },
      /**
       * Watch this in case the product changes from a base => alt and vice versa.
       */
      selectedProductId() {
        this.getProductDetails();
      },
    },
    mounted() {
      this.getProductDetails();
    },
    methods: {
      ...mapActions(useProductAttributesStore, ['setCategoriesAndAttributes']),
      ...mapActions(useAnnotationsStore, ['deleteAnnotation']),
      ...mapActions(usePdfAnnotationStore, ['setPdfAnnotationModalActionState']),

      /**
       * Method used to get all of the product details and tier groups for selected product.
       *
       * @returns {object} returnProduct
       */
      async getProductDetails() {
        this.loadingData = true;
        let returnProduct = {};
        const productDetails = ProductService
          .getProductDetails(this.selectedProduct.inforce_product.id);
        const tierGroups = ProductService
          .getAvailableTierGroups(this.selectedProduct.product_type_id);

        try {
          const [product, { tier_groups }] = await Promise.all([productDetails, tierGroups]);

          returnProduct = product;
          this.containers = product.project_products_containers;
          this.tierGroups = tier_groups.plan_design_tier_groups;
          this.categories = this.setCategoriesAndAttributes({
            product,
            containers: this.containers,
            tierGroups: this.tierGroups,
            updateStore: false,
            usePlanTemplates: true,
          });

          this.selectedValueText = this.getAnnotationText;
          this.loadingData = false;

          return product;
        } catch (error) {
          this.displayToast({
            message: error,
          });
        }

        return returnProduct;
      },
      /**
       * Method to assign selected category, attribute, and selected container IDs based.
       * This also clears out the selected subtype and tier group IDs.
       *
       * @param {number} value
       */
      onAttributeChange(value) {
        // Split the value since the value is containerID-attributeId
        const [selectedCategoryId, selectedAttributeId] = value.split('-');

        this.selectedCategoryId = Number(selectedCategoryId);
        this.selectedAttributeId = Number(selectedAttributeId);
        // Clear out selected values for Tier Group and Subtype
        this.selectedSubtypeIds = [];
        this.selectedTierGroupId = null;
        // Set containers array to all container options
        this.selectedContainerIds = this.containerOptions.map((container) => container.id);
      },
      /**
       * Method to the assign selectedSubtypeIds to be all of the tier group subtypes
       * and the selectedContainerIds to the plan that use this tier group.
       */
      onTierGroupChange() {
        this.selectedSubtypeIds = this.selectedTierGroup.tier_subtypes.map((subtype) => subtype.subtype_id);
        this.selectedContainerIds = this.containerOptions.map((container) => container.id);
      },
      /**
       * Method used to patch annotation.
       */
      async saveAnnotation() {
        if (this.getAnnotatedWordIdsAreValid) {
          const annotation = {
            word_ids: this.newAnnotationWordIds,
            source_id: this.activeFileIds[this.currentProject.id],
          };
          const containerIds = this.selectedContainerIds;
          const createPlansFromActiveStoreProduct = true;
          const value = this.selectedValueText;
          // find any values already saved for this attribute that match the selectedContainerIds
          const originalValuesWithSameContainerIds = this.selectedAttribute
            .grouped_values
            .filter(
              (groupedValue) => groupedValue.project_products_container_ids
                .filter((id) => this.selectedContainerIds.includes(id))
                .length,
            );

          if (originalValuesWithSameContainerIds.length) {
            /**
             * Currently this component only works with dental products,
             * and with dental products the modal actually can't save
             * a value that's a different tier group than what's on the
             * "right side of the screen".
             * But, just in case that business logic changes, this will
             * delete any values that have a different tier group (and
             * the same container) as the value being saved in this modal.
             * DR - 04/26/21
             */
            const promises = originalValuesWithSameContainerIds.reduce((accumulator, currentValue) => {
              if (currentValue.tier_group_id !== this.selectedTierGroupId) {
                const valueAnnotations = this.annotationsByPlanDesignValueIds(currentValue.ids);

                valueAnnotations.forEach(({ parts }) => {
                  parts.forEach(({ id }) => this.deleteAnnotation(id));
                });
                accumulator.push(ProductService.deletePlanDesignValue({
                  attributeId: this.selectedAttributeId,
                  containerIds: currentValue.project_products_container_ids,
                  productId: this.selectedProductId,
                  subtypeId: currentValue.tier_subtype_id,
                }));
              }

              return accumulator;
            }, []);

            await Promise.all(promises);
          }

          // Save plan values that don't have tier groups
          if (!this.selectedTierGroupId) {
            try {
              await this.updatePlanValue({
                annotation,
                containerIds,
                createPlansFromActiveStoreProduct,
                subtypeId: null,
                tierGroupId: null,
                updatePlanAttribute: this.isActiveProjectProduct,
                value,
              });

              this.$emit('addAnnotation');
            } catch (error) {
              this.displayToast({
                message: error,
              });
            }

            return;
          }

          // Save annotations for each of the selected subtypes.
          this.selectedSubtypeIds.forEach(async (id, subtypeIndex) => {
            const isLast = subtypeIndex === this.selectedSubtypeIds.length - 1;

            try {
              await this.updatePlanValue({
                annotation,
                createPlansFromActiveStoreProduct,
                containerIds,
                subtypeId: id,
                tierGroupId: this.selectedTierGroupId,
                updatePlanAttribute: this.isActiveProjectProduct && isLast,
                value,
              });

              if (isLast) {
                this.$emit('addAnnotation');
              }
            } catch (error) {
              this.displayToast({
                message: error,
              });
            }
          });
        } else {
          this.setPdfAnnotationModalActionState({
            actionKey: 'annotationSaving',
            actionState: false,
          });
          this.wordIdsError = 'There is something wrong with the word selection for this annotation. Please try again, and if this problem persists, contact the development team.';

          addBreadcrumb({
            category: 'method',
            data: {
              wordIds: this.newAnnotationWordIds,
              sourceId: this.activeFileIds[this.currentProject.id],
              attribute: this.selectedAttribute,
              text: 'invalid word ids',
            },
            level: 'debug',
          });
          captureException('invalid word ids');
        }
      },
      /**
       * API call to PATCH the Plan Design Value. `attributeId` and `productId` are part of
       * this component so no need to pass these. We only want to update the plan attribute
       * in the store if the selected product in the modal is the same as the selected product
       * on the right side.
       *
       * @param {object} options
       * @param {object} options.annotation
       * @param {Array} options.containerIds
       * @param {boolean} options.createPlansFromActiveStoreProduct
       * @param {number} options.subtypeId
       * @param {number} options.tierGroupId
       * @param {boolean} options.updatePlanAttribute
       * @param {string} options.value
       * @returns {Promise}
       */
      updatePlanValue({
        annotation = null,
        containerIds,
        createPlansFromActiveStoreProduct = false,
        subtypeId,
        tierGroupId,
        updatePlanAttribute,
        value,
      }) {
        const categoryIndex = this.categories.findIndex((category) => category.id === this.selectedCategoryId);
        const attributeIndex = this.categories[categoryIndex].categorized_attributes.findIndex((attribute) => attribute.id === this.selectedAttributeId);

        return ProductService.updatePlanValue({
          annotation,
          attributeId: this.selectedAttribute.id,
          attributeIndex,
          categoryIndex,
          containerIds,
          createPlansFromActiveStoreProduct,
          productId: this.selectedProductId,
          subtypeId,
          tierGroupId,
          updatePlanAttribute,
          value,
        });
      },
    },
  };
</script>
