<template>
  <span>
    <AppButton
      :is-disabled="adminShortcut || validating"
      :icon="`fa-solid ${btnInfo.icon}`"
      :is-loading="validating"
      :type="btnInfo.type"
      :text="btnText"
      data-test="validate"
      size="text-small"
      @click="onClick"
    />
  </span>
</template>

<script>
  import { useProjectProductStore } from '@/stores/projectProduct.js';
  import { mapState } from 'pinia';
  import { useProductAttributesStore } from '@/stores/productAttributes.js';

  /**
   * The Validate button for Plan and Class based products
   *
   * @vuedoc
   * @exports Validate Button
   * @category Components
   */
  export default {
    name: 'BtnValidate',
    inject: ['adminShortcut'],
    props: {
      attributeType: {
        type: String,
        default: 'plan',
      },
      validationErrors: {
        type: Number,
        default: 0,
      },
    },
    computed: {
      ...mapState(useProductAttributesStore, [
        'validatingPlanDesignAttributes',
        'validatingRates',
      ]),
      ...mapState(useProjectProductStore, [
        'validPlanDesign',
        'validRateStructure',
      ]),
      /**
       * Text for the button.
       *
       * @returns {string}
       */
      btnText() {
        if (this.isValid) return `${this.capitalize(this.attributeType)}s validated`;
        if (this.showErrorText) return `${this.validationErrors} Issue${this.validationErrors > 1 ? 's' : ''} Found`;

        return `Validate ${this.attributeType}s`;
      },
      /**
       * We show the error text only if we are not currently validating and there are errors
       * or there are no attributes enabled
       *
       * @returns {boolean}
       */
      showErrorText() {
        return !(this.validating)
          && (this.validationErrors);
      },
      /**
       * Return the icon and button type
       *
       * @returns {object}
       */
      btnInfo() {
        let icon = 'fa-check-circle';
        let type = 'affirm';

        if (!this.isValid) {
          icon = (this.validationErrors) ? 'fa-redo' : 'fa-check-circle';
          type = (this.validationErrors) ? 'decline' : 'primary';
        }

        return {
          icon,
          type,
        };
      },
      /**
       * If the current group of attributes is valid or not
       *
       * @returns {boolean}
       */
      isValid() {
        if (this.attributeType === 'plan') {
          return this.validPlanDesign;
        }

        return this.validRateStructure;
      },
      validating() {
        return !Object.keys(this.validatingPlanDesignAttributes).every((key) => !this.validatingPlanDesignAttributes[key])
          && !Object.keys(this.validatingRates).every((key) => !this.validatingRates[key]);
      },
    },
    methods: {
      /**
       * Capitalize the first letter of a string
       *
       * @param {string} value
       * @returns {string}
       */
      capitalize(value) {
        return value.charAt(0).toUpperCase() + value.slice(1);
      },
      /**
       * On click we Change the "ATTRIBUTE_VALIDATING_STATE" so we show the loading sign
       * We also emit up 'validate' to start the actual validation process
       */
      onClick() {
        if (this.isValid) {
          return;
        }

        this.$emit('validate');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .app-button.app-button.is-success {
    cursor: text;
  }
</style>
