<template>
  <div
    class="dashboard-filter-panel"
    :class="{ 'filter-panel-always-open': !showFilterButton }"
  >
    <div
      v-for="(filter, index) in appliedFilters"
      :key="index"
      class="dashboard-control-group"
    >
      <template v-if="filter.type === 'checkboxes'">
        <div class="filter-control-label">
          {{ filter.label }}
        </div>
        <template v-if="Array.isArray(filter.props)">
          <div
            v-for="(prop, propIndex) in filter.props"
            :key="prop"
          >
            <ElCheckboxGroup v-model="filter.value[propIndex]">
              <ElCheckbox
                v-for="opt in prop === 'singleProp' ? filter.options : filter.options[propIndex]"
                :key="opt.label"
                :label="opt"
                :name="opt.label"
                :data-test="`${opt.label} result`"
                class="filter-control-checkbox"
                @change="$emit('update:filters', appliedFilters)"
              >
                {{ opt.label }}
              </ElCheckbox>
            </ElCheckboxGroup>
          </div>
        </template>
        <template v-else>
          <ElCheckboxGroup v-model="filter.value">
            <ElCheckbox
              v-for="opt in filter.options"
              :key="opt.label"
              :label="opt"
              :name="opt.label"
              :data-test="`${opt.label} result`"
              class="filter-control-checkbox"
              @change="$emit('update:filters', appliedFilters)"
            >
              {{ opt.label }}
            </ElCheckbox>
          </ElCheckboxGroup>
        </template>
      </template>
      <template v-else-if="filter.type === 'multiselect'">
        <div class="multiselect">
          <ElInput
            v-model="search[index]"
            :placeholder="`Filter by ${filter.label}`"
            :data-test="`filter by ${filter.label.toLowerCase()}`"
            prefix-icon="fa-solid fa-search"
            class="filter-control-search"
          />
          <div class="multiselect-options">
            <ElCheckboxGroup v-model="filter.value">
              <ElCheckbox
                v-for="option in filteredOptions(filter, index)"
                :key="option.label"
                :label="option"
                :name="option.label"
                class="filter-control-checkbox"
                :data-test="`${option.label} result`"
                @change="$emit('update:filters', appliedFilters)"
              >
                {{ option.label }}
              </ElCheckbox>
            </ElCheckboxGroup>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'DashboardFilters',
    props: {
      filters: {
        type: Array,
        required: true,
      },
      showFilterButton: {
        type: Boolean,
        default: false,
      },
    },
    data: (vm) => ({
      appliedFilters: vm.filters,
      search: {},
    }),
    watch: {
      /**
       * Update the local filters
       *
       * @param {Array} newFilters
       */
      filters(newFilters) {
        this.appliedFilters = newFilters;

        this.appliedFilters.forEach(({ value }, index) => {
          if (!value.length) {
            this.search[index] = '';
          }
        });
      },
    },
    methods: {
      /**
       * Display a subset of options, with selected options on top and search matches below
       *
       * @param {object} filter
       * @param {number} index
       * @returns {Array}
       */
      filteredOptions(filter, index) {
        if (!filter) {
          return [];
        }

        const options = filter.options.filter((f) => filter.value.includes(f.value));
        const regex = RegExp(['', this.search[index]].join('').replace(/\W| /g, '.*'), 'i');
        const searchOptions = filter.options.filter(
          (f) => regex.test(f.label) && !filter.value.includes(f.value),
        ).sort((a, b) => (regex.exec(a.label).index < regex.exec(b.label).index ? -1 : 1))
          .slice(0, 50);

        return [
          ...options,
          ...searchOptions,
        ];
      },
    },
  };
</script>

<style lang="scss" scoped>
  .dashboard-filter-panel {
    flex-grow: 1;
    position: relative;
    min-width: 250px;
    width: 300px;

    &.filter-panel-always-open {
      padding: 0 16px 16px;
    }
  }

  .dashboard-control-group {
    position: relative;

    .filter-control-label {
      margin-bottom: 5px;
    }

    .filter-control-search {
      position: relative;

      :deep(.el-input__prefix) {
        width: 22px;
        top: -6px;
      }
    }

    .filter-control-checkbox {
      margin-top: 5px;
      margin-left: 0;
      display: flex;
      flex-wrap: nowrap;
    }
  }

  .multiselect {
    margin-bottom: 16px;
  }

  .multiselect-options {
    padding: 4px 0 4px 10px;
    border-left: 1px solid var(--tf-gray-medium);
    border-right: 1px solid var(--tf-gray-medium);
    border-bottom: 1px solid var(--tf-gray-medium);
    background-color: var(--tf-base-light);
    height: 70px;
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
  }
</style>

<style lang="scss">
  .filter-popover {
    background-color: var(--tf-gray-light);
  }
</style>
