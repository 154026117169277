<template>
  <div class="btn-group">
    <AppButton
      v-if="currentContainers.length === 1"
      :is-disabled="container.state === 'editing'"
      :text="`Edit ${isClassBased ? 'class description' : 'plan label'}`"
      type="secondary"
      icon="fa-solid fa-pencil"
      size="icon"
      data-test="edit button"
      @click="editContainer"
    />
    <!-- ELSE: show show the popover -->
    <TfPopover
      v-else
      v-model="popoverVisible"
      placement="left"
      :offset="-40"
      :visible-arrow="false"
      :append-to-body="false"
      popper-class="attribute-more-popover"
      width="122"
      trigger="click"
    >
      <!-- TODO LC-563 -->
      <AppButton
        slot="reference"
        class="ellipsis-button"
        type="secondary"
        icon="fa-solid fa-ellipsis-v"
        size="icon"
        data-test="more button"
        text="More"
      />
      <AppButton
        class="close-more-button"
        type="secondary"
        icon="fa-solid fa-times"
        size="icon"
        text="Close"
        @click="popoverVisible = !popoverVisible"
      />
      <div class="button-group">
        <AppButton
          :is-disabled="['editing', 'deleting'].includes(container.state)"
          :text="`${usesPlanTemplates ? 'Edit plan' : `Edit ${isClassBased ? 'class' : 'plan'}`}`"
          type="secondary"
          icon="fa-solid fa-pencil"
          size="text-small"
          @click="editContainer"
        />
        <AppButton
          v-if="!container.newId || !isDefaultDescription"
          :is-disabled="container.state === 'saving'"
          :is-loading="container.state === 'deleting'"
          :text="`Delete ${isClassBased ? 'class' : 'plan'}`"
          type="secondary"
          icon="fa-solid fa-trash-alt"
          size="text-small"
          data-test="delete button"
          @click="deleteContainer"
        />
      </div>
    </TfPopover>
  </div>
</template>

<script>
  import { useAnnotationsStore } from '@/stores/annotations.js';
  import { useProjectProductStore } from '@/stores/projectProduct.js';
  import { useProductContainersStore } from '@/stores/productContainers.js';
  import { mapState, mapActions, mapWritableState } from 'pinia';
  import { useProductSelectionsStore } from '@/stores/productSelections.js';

  /**
   * Container Actions
   *
   * @vuedoc
   * @exports ContainerActions
   * @category Components
   */
  export default {
    name: 'ContainerActions',
    props: {
      container: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        popoverVisible: false,
      };
    },
    computed: {
      ...mapState(useProductSelectionsStore, ['selectedProductId']),
      ...mapState(useProductContainersStore, [
        'currentContainers',
        'isClassBased',
      ]),
      ...mapWritableState(useProductContainersStore, [
        'isProductContainersLoaded',
      ]),
      ...mapState(useProjectProductStore, ['usesPlanTemplates']),
      /**
       * checks if a container's description is the default description
       *
       * @returns {boolean}
       */
      isDefaultDescription() {
        return this.container?.description.toLowerCase() === 'not provided' || this.container?.description.toLowerCase().endsWith('details');
      },
    },
    methods: {
      ...mapActions(useProductContainersStore, [
        'setContainerState',
        'deleteProductContainer',
        'syncProductDetails',
      ]),
      ...mapActions(useAnnotationsStore, ['deleteContainerAnnotations']),
      /**
       * Call the `deleteProductContainer` to delete the container.
       * `syncProductDetails` is called after to update the rate/plan values
       */
      async deleteContainer() {
        if (!this.container.newId) {
          this.isProductContainersLoaded = false;
        }
        try {
          await this.deleteProductContainer({
            container: this.container,
            projectProductId: this.selectedProductId,
          });
          // delete all annotation.plan_design_values for this container id,
          // and the whole annotation if there's no values left.
          this.deleteContainerAnnotations(this.container.id);

          // This will eventually be moved to an action but OOS for this PR
          if (!this.container.newId) {
            this.syncProductDetails();
          }
        } catch {
          this.displayToast({
            message: 'There was an error deleting the container.',
          });
        }
      },
      /**
       * Commit an `editing` state to container in the store.
       */
      editContainer() {
        this.popoverVisible = false;
        this.setContainerState({
          container: this.container,
          containerState: 'editing',
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
.btn-group {
  align-items: center;
}

.ellipsis-button {
  width: 25px;
}

:deep([disabled] i) {
  color: var(--tf-gray-medium);
}

/* stylelint-disable declaration-no-important  */
:deep(.el-popover.attribute-more-popover) {
  right: 6px !important;
  margin-top: 15px;
}

.close-more-button.is-icon {
  position: absolute;
  right: -1px;
  top: -20px;
  padding: 1px 3px;
  z-index: 1;
  background-color: var(--tf-base-light) !important;
  border-radius: 4px 4px 0 0;
  border: {
    left: 1px solid var(--tf-blue);
    right: 1px solid var(--tf-blue);
    top: 1px solid var(--tf-blue);
  }
}
/* stylelint-enable declaration-no-important  */
</style>
