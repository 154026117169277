<template>
  <div class="plan-design-entry">
    <div
      v-for="category in planDesignCategories"
      :key="`category-${category.id}`"
    >
      <h4 class="category-name">
        {{ category.name }}
      </h4>
      <template v-for="attribute in category.categorized_attributes">
        <PlanDesignTable
          v-if="attribute.name.toLowerCase() !== 'rate guarantee'"
          :key="`${attribute.id}-${category.name} :: ${attribute.name}`"
          :active="activeAttributeId === attribute.id"
          :category="category"
          :attribute="attribute"
        />
      </template>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'pinia';
  import PlanDesignTable from '@/components/PlanDesign/Table/index.vue';
  import { useProductStructuresStore } from '@/stores/productStructures.js';
  import { useProductSelectionsStore } from '@/stores/productSelections.js';

  /**
   * Plan Design Entry Component
   *
   * @vuedoc
   * @exports PlanDesignEntry
   * @category Components
   */
  export default {
    name: 'PlanDesignEntry',
    components: {
      PlanDesignTable,
    },
    computed: {
      ...mapState(useProductStructuresStore, ['planDesignCategories']),
      ...mapState(useProductSelectionsStore, ['activeAttributeId']),
    },
  };
</script>

<style lang="scss" scoped>
  .plan-design-entry {
    text-align: left;
  }

  h4.category-name {
    font-size: 14px;
    color: var(--tf-blue);
    text-transform: uppercase;
    padding: 14px 10px;
    margin: 0;
    box-shadow: inset 0 -1px 0 0 var(--tf-gray-light-medium);
  }

  h5.attribute-name {
    font-size: 14px;
    color: var(--tf-blue);
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    padding: 9px 14px 9px 10px;
    margin: 0;
    line-height: 14px;
    box-shadow: inset 0 -1px 0 0 var(--tf-gray-light-medium);

    svg {
      width: 14px;
      height: 13px;
    }
  }
</style>
