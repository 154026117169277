import { defineStore } from 'pinia';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    confirmed: false,
    inactive: false,
    status: false,
    tempPasswordSent: false,
    passwordChangeRequired: false,
  }),

  actions: {
    /**
     * Set auth status and confirmed on condition
     *
     * @param {string} status
     */
    setAuthStatus(status) {
      this.status = status;

      if (!status) {
        this.confirmed = false;
      }
    },
  },
});
