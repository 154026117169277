<template>
  <DashboardApiTable
    dashboard-type="closed"
    title="Closed projects"
  />
</template>

<script>
  import DashboardApiTable from '@/components/Dashboard/IndexApi.vue';

  /**
   * Project Table to display all the products owned by in the "closed" state.
   *
   * @vuedoc
   * @exports DashboardClosed
   * @category Views
   */
  export default {
    name: 'DashboardClosed',
    components: {
      DashboardApiTable,
    },
  };
</script>
