import { defineStore } from 'pinia';
import { useAuthStore } from '@/stores/auth.js';

export const useUserInfoStore = defineStore('userInfo', {
  state: () => ({
    id: null,
    broker: null,
    name: '',
    firstName: '',
    lastName: '',
    email: '',
    roles: [],
    department: '',
  }),
  actions: {
    /**
     * Set auth status and confirmed on condition
     *
     * @param {object} info
     * @param {Array} info.roles
     * @param {boolean} info.confirmed
     * @param {string} info.temp_password
     */
    setUserInfo({
      confirmed, temp_password, ...info
    }) {
      const auth = useAuthStore();

      auth.confirmed = confirmed;
      auth.passwordChangeRequired = temp_password;

      this.id = info.id;
      this.broker = info.broker;
      this.email = info.email;
      this.department = info.department;
      this.firstName = info.first_name;
      this.lastName = info.last_name;
      this.name = `${info.first_name} ${info.last_name}`;
      this.roles = info.roles?.length ? info.roles.map(({ name }) => name) : [];
    },
  },
});
