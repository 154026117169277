import { defineStore } from 'pinia';

export const usePanelsStore = defineStore('panels', {
  state: () => ({
    openPanels: [],
    panels: [],
  }),
  getters: {
    isPanelOpen: (state) => (name) => state.openPanels.includes(name),
  },
  actions: {
    /**
     * Register a panel so that it's state can be tracked in the left, right, or it's own group
     *
     * @param {object} payload
     * @param {string} payload.name
     * @param {boolean} payload.startOpen
     */
    registerPanel({ name, startOpen }) {
      if (this.panels.map(({ name: panelName }) => panelName).includes(name)) return;
      if (startOpen) this.openPanels.push(name);

      this.panels.push({
        name,
        startOpen,
      });
    },
    /**
     * toggles panel name in or out of openPanels array
     *
     * @param {string} name
     */
    setPanel(name) {
      if (this.openPanels.includes(name)) {
        this.openPanels = this.openPanels.filter((panelName) => name !== panelName);
      } else {
        this.openPanels.push(name);
      }
    },
    /**
     * resets store state
     */
    resetPanelsStore() {
      this.$reset();
    },
  },
});
