import axios from 'axios';
import { getAxiosConfig } from '@watchtowerbenefits/es-utils-public';
import { tfPatch } from '@/utils/apiClient.js';
import { config } from '@/utils/config.js';

const apiUrl = `${config.VUE_APP_API_URL}/v1/admin`;

/**
 * Get the dashboard projects
 *
 * @param {string} projectType
 * @param {object} obj
 * @param {number} obj.pageSelected
 * @param {number} obj.pageLimit
 * @param {object} obj.filters
 * @param {Array} obj.sorting
 * @returns {Promise}
 */
export function getDashboardProjects(projectType, {
  pageSelected,
  pageLimit,
  sorting,
  filters,
}) {
  const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);
  const url = `${apiUrl}/project_dashboard/${projectType}/view?current_page=${pageSelected}&per_page=${pageLimit}`;

  return new Promise((resolve, reject) => {
    axios
      .post(url, { filter_by: filters, order_by: sorting }, axiosConfig)
      .then((response) => resolve(response.data.project_dashboard_view_data))
      .catch((e) => reject(e));
  });
}

/**
 * Get the dashboard projects
 *
 * @param {string} projectType
 * @returns {Promise}
 */
export function getDashboardFilterOptions(projectType) {
  const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);
  const url = `${apiUrl}/project_dashboard/${projectType}/filters`;

  return new Promise((resolve, reject) => {
    axios
      .get(url, axiosConfig)
      .then((response) => resolve(response.data.project_filters))
      .catch((e) => reject(e));
  });
}
/**
 * Change the projects state
 *
 * @param {number} projectId
 * @param {string} state
 * @param {object} payload
 * @returns {Promise}
 */
export function setProjectState(projectId, state, payload = {}) {
  return tfPatch(
    `${apiUrl}/projects/${projectId}/${state}`,
    payload,
  );
}

/**
 * Start policy audit
 *
 * @param {object} projectId
 * @returns {Promise}
 */
export function startPolicyAudit(projectId) {
  const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);
  const payload = { placement_event_id: projectId };
  const url = `${apiUrl}/policy_audits`;

  return new Promise((resolve, reject) => {
    axios
      .post(url, payload, axiosConfig)
      .then(({ data }) => resolve(data))
      .catch((error) => reject(error));
  });
}
/**
 * Get info for a specific project
 *
 * @param {number} projectId
 * @returns {Promise}
 */
export async function getProjectInfo(projectId) {
  const url = `${apiUrl}/projects/${projectId}`;
  const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);

  try {
    const { data } = await axios
      .get(url, axiosConfig);

    return data;
  } catch (error) {
    return error;
  }
}

/**
 * Project API calls
 *
 * @exports Project
 * @category Services
 */
export default {
  /**
   * Change the (broker) owner of a specific project
   *
   * @param {object} projectId
   * @param {number} userId
   * @returns {Promise}
   */
  assignProjectBrokerOwner(projectId, userId) {
    const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);
    const data = { user_id: userId };
    const url = `${apiUrl}/projects/${projectId}/assign_owner`;

    return new Promise((resolve, reject) => {
      axios
        .patch(url, data, axiosConfig)
        .then((response) => resolve(response.data.project.owner_id))
        .catch((e) => reject(e));
    });
  },
  /**
   * Pass in the user credentials and validate them against the api server
   *
   * @param {object} projectId
   * @param {number} userId
   * @returns {Promise}
   */
  changeProjectOwner(projectId, userId) {
    const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);
    const data = { wt_owner_id: userId };
    const url = `${apiUrl}/projects/${projectId}`;

    return new Promise((resolve, reject) => {
      axios
        .patch(url, data, axiosConfig)
        .then((response) => resolve(response.data))
        .catch((e) => reject(e));
    });
  },
  /**
   * Get Info related to specific Document
   *
   * @param {number} documentId
   * @returns {Promise}
   */
  getDocumentInfo(documentId) {
    const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);

    return new Promise((resolve, reject) => {
      const url = `${apiUrl}/documents/${documentId}`;

      axios
        .get(url, axiosConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * Generate an in-force packet for a project
   *
   * @param {number} projectId
   * @returns {Promise}
   */
  generateInforcePacket(projectId) {
    const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);

    return new Promise((resolve, reject) => {
      const url = `${apiUrl}/projects/${projectId}/generate_inforce_packet`;

      axios
        .get(url, axiosConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * Gets the project products from a given project
   *
   * @param {string|number} projectId
   * @returns {Promise}
   */
  getProjectProducts(projectId) {
    const url = `${apiUrl}/projects/${projectId}/project_products`;

    return new Promise((resolve, reject) => {
      const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);

      axios
        .get(url, axiosConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * Gets the projects based on the parameters passed.
   *
   * @param {string} projectType
   * @returns {Promise}
   */
  getProjects(projectType) {
    const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);
    const url = `${apiUrl}/projects/${projectType}`;

    return new Promise((resolve, reject) => {
      axios
        .get(url, axiosConfig)
        .then((response) => resolve(response.data))
        .catch((e) => reject(e));
    });
  },
  /**
   * Removes a broker user from a given a given project
   *
   * @param {string|number} projectId
   * @param {string|number} userId
   * @returns {Promise}
   */
  removeProjectBrokerUsers(projectId, userId) {
    return new Promise((resolve, reject) => {
      const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);
      const url = `${apiUrl}/projects/${projectId}/broker_project_users`;

      axiosConfig.data = {
        user_id: userId,
      };
      axios
        .delete(url, axiosConfig)
        .then(() => {
          resolve();
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * Resetting a project
   * all proposals will be deleted and the project will revert to pre-invitation status
   *
   * @param {number} projectId
   * @returns {Promise}
   */
  resetProject(projectId) {
    const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);

    return new Promise((resolve, reject) => {
      const url = `${apiUrl}/projects/${projectId}/reset_project`;

      axios
        .post(url, { confirmation: 'CONFIRM' }, axiosConfig)
        .then((response) => resolve(response.data))
        .catch((e) => reject(e.response));
    });
  },
  /**
   * Patch project data based on parameters.
   *
   * @param {number} projectId
   * @param {object} data
   * @returns {Promise}
   */
  setProjectData(projectId, data) {
    return new Promise((resolve, reject) => {
      const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);
      const url = `${apiUrl}/projects/${projectId}`;

      axios
        .patch(url, data, axiosConfig)
        .then((response) => resolve(response.data))
        .catch((e) => reject(e));
    });
  },
  /**
   * Add broker users for a project
   *
   * @param {number} projectId
   * @param {Array} userIds
   * @returns {Promise}
   */
  addProjectBrokerUsers(projectId, userIds) {
    return new Promise((resolve, reject) => {
      const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);
      const url = `${apiUrl}/projects/${projectId}/broker_project_users`;
      const data = {
        user_ids: userIds,
      };

      axios
        .patch(url, data, axiosConfig)
        .then((response) => {
          resolve(response.data.project.broker_project_users);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * Update Project Details
   *
   * @param {number} projectId
   * @param {object} data
   * @returns {Promise}
   */
  updateProjectInfo(projectId, data) {
    return new Promise((resolve, reject) => {
      const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);
      const url = `${apiUrl}/projects/${projectId}`;

      axios
        .patch(url, data, axiosConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * Update Project Product Details
   *
   * @param {number} projectProductId
   * @param {object} data
   * @returns {Promise}
   */
  updateProjectProduct(projectProductId, data) {
    return new Promise((resolve, reject) => {
      const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);
      const url = `${apiUrl}/project_products/${projectProductId}`;

      axios
        .patch(url, data, axiosConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * Get Broker Info tab data
   *
   * @param {number} projectId
   */
  getInitialClientSetupInfo(projectId) {
    return new Promise((resolve, reject) => {
      const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);
      const url = `${apiUrl}/projects/${projectId}/initial_client_setup_info`;

      axios
        .get(url, axiosConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
