import { tfGet } from '@/utils/apiClient.js';

import axios from 'axios';
import { getAxiosConfig } from '@watchtowerbenefits/es-utils-public';
import { config } from '@/utils/config.js';

const apiUrl = config.VUE_APP_API_URL;
const cookieNameSpace = config.VUE_APP_COOKIE_NAMESPACE;

/**
 * User API calls
 *
 * @exports User
 * @category Services
 */

/**
 * Invite a new broker user
 *
 * @param {object} data - Data to create the new user
 * @returns {Promise}
 */
export async function inviteBrokerUser(data) {
  const axiosConfig = getAxiosConfig(cookieNameSpace);
  const url = `${apiUrl}/v1/admin/user_invites`;

  try {
    const response = await axios.post(url, data, axiosConfig);

    return response.data;
  } catch (error) {
    return error;
  }
}

/**
 * Check if a broker user has already been invited or has an account
 *
 * @param {object} data - Data to check if the new user
 * @returns {Promise}
 */
export async function checkInviteBrokerUser(data) {
  return new Promise((resolve, reject) => {
    const axiosConfig = getAxiosConfig(cookieNameSpace);
    const url = `${apiUrl}/v1/eligibility?email=${encodeURIComponent(data)}`;

    axios
      .get(url, axiosConfig)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * Resend an invitation email to an already invited user
 *
 * @param {number} id
 * @returns {Promise}
 */
export async function resendBrokerUserInvite(id) {
  const axiosConfig = getAxiosConfig(cookieNameSpace);
  const url = `${apiUrl}/v1/admin/user_invites/${id}/resend`;

  try {
    const response = await axios
      .patch(url, {}, axiosConfig);

    return response.data;
  } catch (error) {
    return error;
  }
}

/**
 * Gets all the ThreeFlow admin users.
 *
 * @returns {Promise}
 */
export async function getUsers() {
  return tfGet(`${apiUrl}/v1/admin/users`);
}

export default {
  /**
   * Gets all the ThreeFlow admin users. THIS IS TEMPORARY UNTIL API IS DONE.
   *
   * @returns {Promise}
   */
  getUsers() {
    const axiosConfig = getAxiosConfig(import.meta.env.VUE_APP_COOKIE_NAMESPACE);
    const url = `${apiUrl}/v1/admin/users`;

    return new Promise((resolve, reject) => {
      axios
        .get(url, axiosConfig)
        .then((response) => resolve(response.data))
        .catch((e) => reject(e.response));
    });
  },
  /**
   * Add a new user
   *
   * @param {object} data - Data to create the new user
   * @returns {Promise}
   */
  addNewUser(data) {
    return new Promise((resolve, reject) => {
      const axiosConfig = getAxiosConfig(cookieNameSpace);
      const url = `${apiUrl}/v1/admin/users`;

      axios
        .post(url, data, axiosConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * Get user details
   *
   * @param {number} userId - The users Id
   * @returns {Promise}
   */
  getUserInfo(userId) {
    return new Promise((resolve, reject) => {
      const axiosConfig = getAxiosConfig(cookieNameSpace);
      const url = `${apiUrl}/v1/admin/users/${userId}`;

      axios
        .get(url, axiosConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * Update an existing user
   *
   * @param {object} userId - Id of the user that we're updating
   * @param {object} data - Data to update the user
   * @returns {Promise}
   */
  updateUser(userId, data) {
    return new Promise((resolve, reject) => {
      const axiosConfig = getAxiosConfig(cookieNameSpace);
      const url = `${apiUrl}/v1/admin/users/${userId}`;

      axios
        .patch(url, data, axiosConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
