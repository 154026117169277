<template>
  <div class="product">
    <div class="product-container">
      <ProductForm
        v-model="localForm"
        data-test="product container"
        :is-editing.sync="isEditing"
        :product="product"
        @onClickCancel="onClickCancel"
      />
      <StateBadge
        v-if="!(isEditing || product.isNewProduct)"
        :state="productState.current"
      />
      <div
        :class="[
          'product-actions',
          { editing: isEditing },
        ]"
      >
        <template v-if="!(isEditing || product.isNewProduct)">
          <span>
            {{ collaboratorName }}
          </span>
          <div class="btn-group">
            <AppButton
              data-test="edit button"
              icon="fa-solid fa-pencil-alt"
              size="text"
              type="primary"
              :is-disabled="isSoldCoverageAudit"
              text="Edit"
              @click="isEditing = true"
            />
            <AppButton
              v-if="currentProject.type !== 'Projects::InitialEmployerBuild'"
              data-test="add alternative"
              icon="fa-solid fa-plus"
              size="text"
              type="primary"
              :is-disabled="isSoldCoverageAudit"
              text="Add alternative"
              @click="addAlternative"
            />
          </div>
        </template>
        <BtnDelete
          v-if="isEditing"
          :project-product="product"
        />
      </div>
    </div>
    <div
      v-if="alternativeProducts.length"
      class="alternative-products"
    >
      <AlternativeProductLayout
        v-for="alternativeProduct in alternativeProducts"
        :key="alternativeProduct.id"
        :product="alternativeProduct"
      />
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'pinia';
  // utils
  import MathUtil from '@/utils/math.js';
  import getProductState from '@/utils/productState.js';
  // components
  import AlternativeProductLayout from '@/components/Product/AlternativeProductLayout.vue';
  import BtnDelete from '@/components/Product/BtnDelete.vue';
  import ProductForm from '@/components/Product/ProductForm.vue';
  import StateBadge from '@/components/StateBadge.vue';
  import { useProjectProductStore } from '@/stores/projectProduct.js';
  import { useProjectStore } from '@/stores/project.js';

  /**
   * Individual Base Products layout.
   *
   * @vuedoc
   * @exports BaseProductLayout
   * @category Components
   */
  export default {
    name: 'BaseProductLayout',
    components: {
      AlternativeProductLayout,
      BtnDelete,
      ProductForm,
      StateBadge,
    },
    props: {
      product: {
        type: Object,
        default: () => ({
          inforce_product: {
            carrier_id: null,
            state: '',
            policy_ids: [],
          },
          product_type_id: null,
          plan_type: null,
          prior_coverage: true,
        }),
      },
      admins: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        isEditing: false,
        isDeleting: false,
        localForm: {},
      };
    },
    computed: {
      ...mapState(useProjectStore, ['currentProject']),
      ...mapState(useProjectProductStore, [
        'activeProjectProducts',
        'newProjectProducts',
      ]),
      /**
       * Returns an array products with the same product type ID from the new and active products.
       *
       * @returns {Array}
       */
      alternativeProducts() {
        return [
          ...this.newProjectProducts,
          ...this.activeProjectProducts,
        ].filter(
          (product) => product.alternative && product.product_type_id === this.product.product_type_id,
        );
      },
      /**
       * Filters the active products for base products.
       *
       * @returns {Array}
       */
      activeBaseProducts() {
        return this.activeProjectProducts.filter((product) => !product.alternative);
      },
      /**
       * Evaluate the current project type to disable items in the template.
       *
       * @returns {boolean}
       */
      isSoldCoverageAudit() {
        return this.currentProject
          && this.currentProject.type === 'Projects::PolicyAudit';
      },
      /**
       * Returns an object representing the current and next states for a given product
       *
       * @returns {object}
       */
      productState() {
        return getProductState(this.product.inforce_product.state);
      },
      /**
       * returns an admin based on the product's owner id
       * as admins array and product load asynchronously, we return a blank string until then
       *
       * @returns {string}
       */
      collaboratorName() {
        const admin = this.admins.find(({ id }) => id === this.product.inforce_product.wt_owner_id);

        return admin ? admin.fullName : 'Unassigned';
      },
    },
    mounted() {
      this.updateLocalForm(this.product);
    },
    methods: {
      ...mapActions(useProjectProductStore, ['addNewProjectProduct']),
      /**
       * Add a temporary alternative object to the newAlternatives array.
       */
      addAlternative() {
        this.addNewProjectProduct({
          alternative: true,
          baseProductId: this.product.inforce_product.id,
          id: MathUtil.getRandomNumber(),
          inforce_product: { carrier_id: null, state: 'not_started' },
          isNewProduct: true,
          label: '',
          product_type_id: this.product.product_type_id,
          prior_coverage: false,
        });
      },
      /**
       * Toggle isEditing variable and reset localForm data.
       */
      onClickCancel() {
        this.updateLocalForm(this.product);
        this.isEditing = false;
      },
      /**
       * Update the local form object based on the product that is passed.
       *
       * @param {object} product
       */
      updateLocalForm(product) {
        const policyIds = product.inforce_product.policy_ids.length === 0
          ? ['']
          : [...product.inforce_product.policy_ids];

        this.localForm = {
          carrier: product.inforce_product.carrier_id,
          label: product.label,
          priorCoverage: product.prior_coverage,
          productType: product.product_type_id,
          policyIds,
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .product {
    border-bottom: 1px solid var(--tf-gray-medium);

    &-form {
      flex: 1;
    }

    &-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      text-align: left;
      flex-wrap: wrap;

      &:hover {
        background-color: var(--tf-blue-light);
      }

      .alternative-products & {
        box-sizing: border-box;
        border-left: 1px solid var(--tf-gray-medium);
        align-items: center;
        border-bottom: 1px solid var(--tf-gray-medium);

        &:last-child {
          border-bottom: 0;
        }
      }
    }

    &-actions {
      width: 100%;
      padding: 0 12px 12px;
      display: flex;
      box-sizing: border-box;

      &.editing {
        background: var(--tf-gray-light);
        padding: 0;
        justify-content: center;
      }

      .btn-group {
        margin-left: auto;
      }

      button + button {
        /* stylelint-disable declaration-no-important */
        margin-left: 15px !important;
      }
    }
  }

  .alternative-products {
    border-top: 1px solid var(--tf-gray-medium);
    padding-left: 20px;
  }

  .tf-badge {
    margin: {
      top: 20px;
      right: 12px;
    }
  }
</style>
