export const createPlans = ({
  containers,
  groupedValues,
  tierGroups,
}) => {
  if (!containers) {
    return [];
  }

  return containers
    .sort((a, b) => a.position - b.position)
    .map((container, containerIndex) => {
      let planValues = groupedValues.filter((values) => values.project_products_container_ids.includes(container.id));

      // "Grouped values" come with an array of the containers and the
      // value ids inside of it; However we need to separate them out to
      // associate annotations to the individual values, so I'm breaking
      // them apart here. If we could get this association from the API
      // it'd be better then assuming they come in the same order
      // every time - DR: 3/27/2020
      planValues = planValues.map((value) => {
        const clonedValue = { ...value };

        if (value.ids.length > 1) {
          clonedValue.ids = [value.ids[containerIndex]];
          clonedValue.project_products_container_ids = [container.id];
        }

        return clonedValue;
      });
      // Get associated Tier Group and ID used for some default values throughout the components
      const associatedTierGroup = tierGroups.find((tierGroup) => tierGroup.tier_group_name === container.container_type_name);
      const associatedTierGroupId = associatedTierGroup
        ? associatedTierGroup.tier_group_id
        : null;
      // Tier Group ID for any existing values.
      const tierGroupId = planValues.length
        ? planValues.length && planValues[0].tier_group_id
        : null;
      // Subtypes array is created only if there is a tier group ID from the template or saved value
      const subtypes = tierGroupId
        ? associatedTierGroup.tier_subtypes
        : [];
      // Create an array of uniq planValues. A blank array is set if there are no values
      const uniqValues = [...new Set(planValues.map((group) => group.value))];
      // Return boolean based on if there is more than one subtype associated with a saved or template value and the uniq values length doesn't match.
      const copyByDefault = subtypes.length > 1 && subtypes.length !== uniqValues.length;
      // If there are no values, test if there an associated tier group and if there is more than one subtype.
      const copyFromEmpty = !planValues.length && associatedTierGroup && associatedTierGroup.tier_subtypes.length > 1;

      return {
        associatedTierGroupId,
        copyEnabled: (copyByDefault || copyFromEmpty),
        description: container.description,
        id: container.id,
        values: planValues,
      };
    });
};
