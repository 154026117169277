import { defineStore } from 'pinia';

export const useBrokerTeamStore = defineStore('brokerTeam', {
  state: () => ({
    brokerId: null,
    brokerTeamMembers: [],
    employer: {},
    isInitialBuild: false,
    isLoaded: false,
    isLoadedFromLocation: '',
    placementEventTeamMembers: [],
  }),
  getters: {
    /**
     * returns an array of broker team member uer ids
     *
     * @param {object} state
     * @returns {Array} - An array of broker team member uer ids
     */
    brokerTeamMemberIds: (state) => state.brokerTeamMembers.map(({ user_id: id }) => id),
    /**
     * returns an array of placement event team team member uer ids
     *
     * @param {object} state
     * @returns {Array} - An array of placement event team member uer ids
     */
    placementEventTeamMemberIds: (state) => state.placementEventTeamMembers.map(({ id }) => id),
  },
  actions: {
    /**
     * resets store state
     */
    resetBrokerTeamStore() {
      this.$reset();
    },
  },
});
