<template>
  <li
    class="list-item"
    :class="{ edit: editState }"
    :data-id="item.id"
    :data-test="`${item.name} file`"
  >
    <div
      :key="`list-item-${item.id}`"
      :class="[
        'list-item-container',
        { 'pending-container': convertingFile },
        { 'file-container': !convertingFile },
        { 'error-container': errorState },
        { 'edit-container': editState },
      ]"
    >
      <template v-if="!editState">
        <template v-if="!convertingFile">
          <ElCheckbox
            v-if="item.status === 'conversion_completed'"
            :class="{ offset: item.disableModify }"
            :value="open"
            @change="handleOpenChange()"
          />
          <span
            class="label"
            :class="{ clickable: item.status === 'conversion_completed' }"
            @click="handleOpenChange()"
            v-text="sourceName"
          />
        </template>
        <!-- End Converted FIle -->

        <template v-if="convertingFile">
          <span class="label">{{ sourceName ? sourceName.substr(0, 24) : null }} is {{ retrying ? 'being reloaded' : 'processing' }}&hellip;</span>
          <AppIcon
            icon="fa-solid fa-circle-notch"
            class="loader"
          />
        </template>
        <!-- End Converting File -->

        <AppButton
          v-if="showRetryButton"
          :is-disabled="retrying"
          data-test="retry button"
          type="primary"
          icon="fa-solid fa-redo"
          size="text-small"
          text="Retry"
          @click="reprocessItem"
        />
        <AppButton
          v-if="!item.disableModify"
          class="edit-icon"
          data-test="modify button"
          type="primary"
          icon="fa-solid fa-pencil"
          size="icon-small"
          text="Modify"
          @click="handleEditClick('open')"
        />
        <AppButton
          v-if="categoryType !== 'NonContractualFile'"
          data-test="download source"
          :is-disabled="isDownloading"
          type="primary"
          icon="fa-regular fa-arrow-down-to-line"
          size="icon"
          @click="downloadFile"
        />
      </template>
      <!-- End Base State -->

      <!-- Edit State -->
      <template v-else>
        <AppIcon
          icon="fa-regular fa-grip-lines-vertical"
          data-test="item handle"
          alt="Handle"
        />
        <div class="input-wrapper">
          <ElInput
            v-model="editableName.filename"
            size="medium"
          >
            <template #append>
              {{ editableName.extension }}
            </template>
          </ElInput>
        </div>
        <AppButton
          v-if="!item.uploaded"
          data-test="delete button"
          type="secondary"
          icon="fa-solid fa-trash-alt"
          size="icon"
          text="Delete"
          @click="handleEditClick('delete')"
        />
        <AppButton
          data-test="decline button"
          type="decline"
          icon="fa-solid fa-times"
          size="icon"
          text="Cancel"
          @click="handleEditClick('cancel')"
        />
        <AppButton
          data-test="affirm button"
          type="affirm"
          icon="fa-solid fa-check"
          size="icon"
          text="Confirm"
          @click="handleEditClick('confirm')"
        />
      </template>
    </div>
    <div
      v-if="item.name.toLowerCase() !== 'broker info' && categoryItemLabels.length"
      class="list-item-category-labels"
    >
      <CategoryLabel
        v-for="{ label, type } in categoryItemLabels"
        :key="`category-${label}`"
        :value="label"
        :type="type"
        size="large"
      />
    </div>

    <div
      v-if="errorState"
      class="error-message"
    >
      {{ errorState }}
    </div>
  </li>
</template>

<script>
  import FileService from '@/services/file.js';

  import { mapActions, mapState, mapWritableState } from 'pinia';
  import { useFilesStore } from '@/stores/files.js';
  import { useFileViewerStore } from '@/stores/fileViewer.js';
  import CategoryLabel from '@/components/FileViewer/FileManager/CategoryLabel.vue';

  /**
   * File Manager Category Item
   *
   * @vuedoc
   * @exports FileManagerCategoryItem
   * @category Components
   */
  export default {
    name: 'FileManagerCategoryItem',
    components: {
      CategoryLabel,
    },
    props: {
      item: {
        type: Object,
        default: () => ({}),
      },
      categoryType: {
        type: String,
        default: '',
      },
      editState: {
        type: Boolean,
        default: false,
      },
      projectId: {
        type: Number,
        default: () => null,
      },
      open: {
        type: Boolean,
        default: false,
      },
    },
    data: (vm) => ({
      editableName: {},
      errorState: null,
      isDownloading: false,
      isUploaded: false, // local property to indicate file upload status
      categoryItemMovedFrom: vm.item.moved, // clone from the "item" prop to mutate locally.
      retrying: false,
      sourceName: vm.item.name, // clone from the "item" prop to mutate locally.
    }),
    computed: {
      ...mapState(useFileViewerStore, [
        'getActiveFileId',
        'requestFileManagerClose',
      ]),
      ...mapWritableState(useFileViewerStore, [
        'requestTabClose',
        'requestTabFocus',
      ]),
      /**
       * A list of category item labels built from the item prop. Used to display different types and styles of badges in the correct order.
       *
       * @returns {boolean}
       */
      categoryItemLabels() {
        const carrierItemLabels = this.item.carriers
          ? this.item.carriers
            .map(({ name }) => ({
              label: name,
              type: 'carrier',
            }))
          : [];
        let sortedAssetLabels = [];

        if (this.item.asset_labels) {
          sortedAssetLabels = this.item.asset_labels
            .slice() // To avoid mutating the original array
            .sort((a, b) => a.position - b.position)
            .map(({ label }) => {
              const formattedLabel = label
                .toLowerCase() === 'rate information'
                ? 'Rate info'
                : label;
              const calculatedType = label
                .toLowerCase()
                .startsWith('no')
                ? 'missing-document-category'
                : 'document-category';

              return {
                label: formattedLabel,
                type: calculatedType,
              };
            });
        }

        return carrierItemLabels.concat(sortedAssetLabels);
      },
      /**
       * If the API is still processing the file
       *
       * @returns {boolean}
       */
      convertingFile() {
        return ['compressing_source', 'conversion_started'].includes(this.item.status);
      },
      /**
       * If this item can be closed or it is in the middle of being edited
       *
       * @returns {boolean}
       */
      canClose() {
        return this.isUploaded !== true
          && this.fullName === this.sourceName;
      },
      /**
       * Concat the editableNames together (we use this to check if the user has edited the name but not saved it)
       *
       * @returns {string}
       */
      fullName() {
        return this.editableName.filename + this.editableName.extension;
      },
      /**
       * Whether or not to display the retry button to re-process/convert it
       *
       * @returns {boolean}
       */
      showRetryButton() {
        return this.convertingFile || this.item.status === 'conversion_failed';
      },
    },
    watch: {
      requestFileManagerClose: {
        /**
         * When we get a request to close the file manager we check if we can or we need to throw up an error
         */
        handler() {
          if (this.requestFileManagerClose && !this.canClose) {
            if (this.isUploaded) {
              this.errorState = 'Confirm file upload before closing.';
            } else if (this.fullName !== this.sourceName) {
              this.errorState = 'Save or cancel changes before closing.';
            }
          }
        },
        immediate: true,
      },
      /**
       * When the canClose computed property runs we emit it up
       */
      canClose() {
        this.$emit('canClose', {
          canClose: this.canClose,
          sourceId: this.item.id,
        });
      },
      sourceName: {
        /**
         * When we get the items name (or it changes) we break it up into an extension and filename
         */
        handler() {
          if (!this.sourceName) {
            return;
          }
          this.editableName = this.getFileNameParts();
        },
        immediate: true,
      },
    },
    methods: {
      ...mapActions(useFilesStore, [
        'selectAnotherTab',
        'renameSource',
        'removeUploadedSource',
      ]),
      ...mapActions(useFileViewerStore, ['setFileOpen']),
      /**
       * Convert the filename into the filename without the extension, and the extension.
       *
       * @returns {object}
       */
      /**
       * Downloads a single file using the category item ID
       */
      async downloadFile() {
        if (this.isDownloading) {
          return;
        }

        this.isDownloading = true;
        this.errorState = null;
        try {
          const { data: { source } } = await FileService.getSource(this.item.id, 'admin');
          const base64String = source.content.replace(/\s/g, '');
          const byteCharacters = window.atob(base64String);
          const byteNumbers = new Array(byteCharacters.length);

          for (let i = 0; i < byteCharacters.length; i += 1) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }

          const byteArray = new Uint8Array(byteNumbers);
          const blob = new window.Blob([byteArray], { type: 'application/octet-stream' });

          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, source.file_file_name);
          } else {
            const downloadLink = document.createElement('a');

            downloadLink.value = 'Download';
            downloadLink.target = '_blank';
            downloadLink.download = source.file_file_name;
            let blobURL = '';

            blobURL = window.URL.createObjectURL(blob);
            downloadLink.href = blobURL;
            this.$el.appendChild(downloadLink);
            // fire a click event on the anchor
            downloadLink.click();
            downloadLink.remove();
          }
        } catch {
          this.errorState = 'There was a problem downloading the file.';
        } finally {
          this.isDownloading = false;
        }
      },
      /**
       * Gets the name and extension of a given file
       *
       * @returns {object}
       */
      getFileNameParts() {
        const extension = this.sourceName.substring(this.sourceName.lastIndexOf('.'), this.sourceName.length) || '';
        const filename = this.sourceName.substring(0, this.sourceName.length - extension.length);

        return {
          extension,
          filename,
        };
      },
      /**
       * Handle the user clicking an cta when in the edit state
       *
       * @param {string} action
       */
      async handleEditClick(action) {
        if (action === 'open') {
          // pencil action
          this.$emit('editStateChange', this.item.id, true);
        } else {
          if (action === 'confirm') {
            // checkmark action
            if (this.sourceName !== this.fullName) {
              // Item was renamed
              this.sourceName = this.fullName;
              try {
                await FileService.setSourceName(this.item.id, this.fullName);

                this.renameSource({
                  sourceId: this.item.id,
                  name: this.fullName,
                });
              } catch {
                this.displayToast({
                  message: 'There was an error setting the source name.',
                });
              }
            }

            if (this.categoryItemMovedFrom) {
              // Item was moved
              this.categoryItemMovedFrom = '';
              FileService
                .setSourceType(this.item.id, this.categoryType)
                .catch(() => {
                  this.displayToast({
                    message: 'There was an error setting the source type.',
                  });
                });
            }

            this.isUploaded = false;
            this.errorState = null;
          }

          if (action === 'cancel') {
            // "cancel" (X) action
            if (this.categoryItemMovedFrom) {
              this.$parent.$emit('moveSourceCategory', {
                source: this.item,
                fromCategoryType: this.categoryType,
                toCategoryType: this.categoryItemMovedFrom.fromCategory,
                moveBack: true,
              });
              this.$emit('editStateChange', this.item.id, false);
            }

            if (this.isUploaded) {
              try {
                await FileService.deleteSource(this.item.id);

                this.removeUploadedSource(this.item.id);
              } catch {
                this.displayToast({
                  message: 'There was an error cancelling the upload.',
                });
              }
            }

            if (this.sourceName !== this.fullName) {
              this.editableName = this.getFileNameParts();
            }

            this.errorState = null;
          }

          if (action === 'delete') {
            // trashcan action
            try {
              await FileService.deleteSource(this.item.id);

              this.removeUploadedSource(this.item.id);
              this.selectAnotherTab(this.item.id);
            } catch {
              this.displayToast({
                message: 'There was an error deleting the source.',
              });
            }
          }

          this.$emit('editStateChange', this.item.id, false);
        }
      },
      /**
       * We dispatch to the store when files are open so we can open them again automatically and keep the "check all" checkbox in sync
       */
      handleOpenChange() {
        // cancel state change if item should not be clickable
        if (this.item.status !== 'conversion_completed') {
          return;
        }

        if (this.open) {
          this.requestTabClose = this.item.id;
        } else if (!this.getActiveFileId) {
          this.requestTabFocus = this.item.id;
        }

        this.setFileOpen({
          projectId: this.projectId,
          fileId: this.item.id,
        });
      },
      /**
       * Reprocess this file and start converting it again
       */
      async reprocessItem() {
        try {
          await FileService.reprocessSource(this.item.id);
          this.$emit('updateItem', {
            ...this.item,
            status: 'conversion_started',
          });
          this.retrying = true;
          setTimeout(() => {
            this.retrying = false;
          }, 3000);
        } catch {
          this.displayToast({
            message: 'There was an error reprocessing the source.',
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  li.list-item {
    display: block;
    height: auto;
    padding: 0;
  }

  .list-item-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    box-sizing: border-box;
    align-items: center;
    height: auto;
    padding: 16px 13px 15px;

    .el-checkbox {
      margin-right: 8px;
    }

    .edit-icon {
      margin-right: 3px;
    }
  }

  .pending-container {
    font-style: italic;
    background: var(--tf-blue-light);
    color: var(--tf-gray);

    .label {
      margin-top: 4px;
      max-width: 328px;
    }
  }

  .loader {
    fill: var(--tf-blue);
    animation: animationFrames linear 1s;
    animation-iteration-count: infinite;
    transform-origin: 50% 50%;
    margin-right: 10px;
    margin-top: 6px;
  }

  @keyframes animationFrames {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  .edit-container {
    align-items: center;
    padding: 0 20px;
    height: 53px;
    background: var(--tf-blue-light);

    &.error-container {
      border: 1px var(--tf-red) solid;
    }

    .input-wrapper {
      flex-grow: 1;
      margin-left: 10px;

      .el-input {
        width: 260px;
      }
    }
  }

  .error-message {
    font-size: 12px;
    color: var(--tf-red);
    padding: 0 20px;
  }

  .label {
    display: inline-block;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex: 1;
  }

  .label.clickable {
    cursor: pointer;
  }

  .list-item-category-labels {
    padding: 8px 13px;

    .category-label {
      margin-top: 5px;
      margin-bottom: 5px;
    }

    .category-label:not(:last-child) {
      margin-right: 5px;
    }
  }

  .app-button {
    &.is-icon-small {
      padding: 0 10px;
    }

    + .AppButton.is-icon {
      margin-left: 0;
    }
  }
</style>
