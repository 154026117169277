<template>
  <section class="auth-form">
    <AuthPageHeader title="You have been successfully signed out." />
    <p>You'll be redirected to the sign-in page shortly.</p>
  </section>
</template>

<script>
  import { signOut } from '@/services/account.js';

  export default {
    name: 'SignOut',
    async mounted() {
      try {
        await signOut();

        setTimeout(() => {
          this.$router.push({ name: 'SignInPage' });
        }, 5000);
      } catch {
        this.displayToast({
          message: 'An error occurred while signing out.',
        });
      }
    },
  };
</script>
