<template>
  <div>
    <!-- Attribute Selector -->
    <ElFormItem
      key="Attribute"
      label="Attribute:"
    >
      <ElSelect
        ref="attributeSelect"
        v-model="selectedCategoryAndAttributeIds"
        :disabled="disableDropdown"
        autocomplete
        filterable
        no-match-text="Attribute not found"
        placeholder="Select attribute"
        data-test="attribute dropdown"
      >
        <ElOptionGroup
          v-for="category in localCategories"
          :key="`category-${category.id}`"
          :label="category.name"
        >
          <template v-for="attribute in category.categorized_attributes">
            <ElOption
              v-if="!attributesHidden[attribute.id]"
              :key="`attribute-dropdown-${category.id}-${attribute.id}`"
              :value="`${category.id}-${attribute.id}`"
              :label="`${attribute.name}`"
              data-test="attribute options"
            />
          </template>
        </ElOptionGroup>
      </ElSelect>
    </ElFormItem>

    <!-- Subtype Group Selector -->
    <ElFormItem label="Subtype group:">
      <!-- Tier Group Selector -->
      <div
        id="form-tier-group"
      >
        <div>
          <ElSelect
            ref="subtypeGroupSelect"
            v-model="selectedTierGroupId"
            size="mini"
            placeholder="Select"
            :disabled="(!localTierGroups.length || disableDropdown)"
            data-test="tier group select"
            @change="updateSubtypes"
          >
            <ElOption
              label="None"
              :value="null"
            />
            <ElOption
              v-for="tierGroup in localTierGroups"
              :key="tierGroup.tier_group_id"
              :label="tierGroup.tier_group_name"
              :value="tierGroup.tier_group_id"
            />
          </ElSelect>
        </div>
        <!-- Subtype Selector -->
        <div>
          <ElSelect
            ref="subtypeIdSelect"
            v-model="selectedSubtypeId"
            placeholder="Select"
            :disabled="(!localSubtypeGroupTypes.length || disableDropdown)"
          >
            <ElOption
              v-for="subtype in localSubtypeGroupTypes"
              :key="subtype.subtype_id"
              :value="subtype.subtype_id"
              :label="subtype.subtype_name"
            />
          </ElSelect>
        </div>
      </div>
    </ElFormItem>

    <!-- Value Selector -->
    <ElFormItem
      :class="{ 'has-multiple-annotations': selectedValueAnnotations.length > 1 }"
      label="Value:"
    >
      <TfInputWithOptions
        ref="valueSelect"
        v-model="selectedValueText"
        :append-validation-type="['percentage-whole-number'].includes(validationType)"
        :auto-collapse="false"
        :disabled="disableDropdown"
        :attribute-id="selectedAttribute.id"
        :options="selectedAttribute.normalized_values"
        :width="460"
        :validation-type="validationType"
      />
    </ElFormItem>

    <!-- Plan/Class Selector -->
    <ElFormItem
      v-if="localContainers.length"
      class="container-selector"
      :label="isClassBased ? 'Class(es):' : 'Plan(s):'"
      data-test="plan class selector"
    >
      <TfMultiSelect
        ref="containerSelect"
        v-model="selectedContainerIds"
        :append-to-body="false"
        :disable-click="disableDropdown"
        :label="isClassBased ? 'name' : 'description'"
        :total-options-available="localCurrentContainers.length"
        :options="localContainers"
        empty-text="No containers available"
        popper-class="annotation-multi-select-popover multi-select-popover"
        value-key="id"
      />
    </ElFormItem>

    <ModalFooter
      :selected-container-ids="selectedContainerIds"
      :selected-product-id="selectedProductId"
      :selected-attribute-id="selectedAttribute.id"
      :selected-tier-group-id="selectedTierGroupId"
      :selected-subtype-id="selectedSubtypeId"
      data-test="modal footer"
      @save="onSave"
    />
  </div>
</template>

<script>
  import { mapState, mapActions, mapWritableState } from 'pinia';
  import { useFileViewerStore } from '@/stores/fileViewer.js';
  import { useAnnotationsStore } from '@/stores/annotations.js';
  // 3rd party
  import { captureException, addBreadcrumb } from '@sentry/vue';
  import { cloneDeep } from 'lodash';
  // utils
  import ValidationUtil from '@/utils/validation.js';
  // services
  import ProductService from '@/services/product.js';
  // components
  import ModalFooter from '@/components/AnnotationModal/Create/Footer.vue';
  import { usePdfAnnotationStore } from '@/stores/pdfAnnotation.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useProductContainersStore } from '@/stores/productContainers.js';
  import { useProductStructuresStore } from '@/stores/productStructures.js';
  import { useProductSelectionsStore } from '@/stores/productSelections.js';
  import { useProductAttributesStore } from '@/stores/productAttributes.js';

  /**
   * Annotation Modal Form for products that use non-templated values.
   *
   * @vuedoc
   * @exports FormForNonTemplate
   * @category Components
   */
  export default {
    name: 'FormForNonTemplate',
    components: {
      ModalFooter,
    },
    props: {
      missingContainers: {
        type: Boolean,
        default: false,
      },
      selectedProduct: {
        type: Object,
        default: () => ({}),
      },
      selectedProductId: {
        type: Number,
        default: null,
      },
    },
    data() {
      return {
        // modeling variables
        selectedCategoryAndAttributeIds: null,
        selectedContainerIds: [],
        selectedTierGroupId: null,
        selectedSubtypeId: null,
        selectedValueText: null,
        selectedAttribute: {},
        selectedCategory: {},
        selectedValueIds: [],
        selectedValueAnnotations: [],
        // these are updated depending on what's still available in the modal
        localCategories: [],
        fetchingCategories: false,
        localContainers: [],
        localCurrentContainers: [],
        localSubtypeGroupTypes: [],
        localTierGroups: [],
        localContainerTypeSelection: null,
        editingProductTypeId: null,
        originalAttribute: {},
        originalContainerIds: [],
      };
    },
    computed: {
      ...mapState(useProductAttributesStore, ['attributesHidden']),
      ...mapState(useProductSelectionsStore, ['activeProductId']),
      ...mapState(useProductStructuresStore, [
        'availablePlanDesignTierGroups',
        'planDesignCategories',
      ]),
      ...mapState(useProjectStore, ['currentProject']),
      ...mapState(useFileViewerStore, ['activeFileIds']),
      ...mapState(useAnnotationsStore, ['annotationsByPlanDesignValueIds']),
      ...mapState(useProductContainersStore, [
        'containerTypeSelection',
        'currentContainers',
        'isClassBased',
      ]),
      ...mapState(usePdfAnnotationStore, [
        'isSaving',
        'newAnnotationWordIds',
        'getAnnotationText',
        'getAnnotatedWordIdsAreValid',
      ]),
      ...mapWritableState(usePdfAnnotationStore, ['wordIdsError']),
      /**
       * Boolean to disable dropdowns based on annotation actions or if the containers have not fully been set up.
       *
       * @returns {boolean}
       */
      disableDropdown() {
        return this.isSaving || this.missingContainers;
      },
      /**
       * Returns the validation type for this attribute.
       *
       * @returns {boolean}
       */
      validationType() {
        return ValidationUtil.getValidationType(this.selectedAttribute.name);
      },
    },
    watch: {
      /**
       * sets local categories
       *
       * @param {string|number} newId
       * @param {string|number} oldId
       */
      selectedProductId(newId, oldId) {
        if (newId && newId !== oldId && !this.fetchingCategories) {
          this.setLocalCategories();

          this.fetchingCategories = false;
          if (this.editingProductTypeId !== this.selectedProductId) {
            // Re-set the Tier Group to 'None' to avoid having an invalid selected option
            this.selectedTierGroupId = null;
            this.selectedCategoryAndAttributeIds = null;
            this.selectedValueIds = [];
          }
        }
      },
      /**
       * Watch selectedCategoryAndAttributeIds to update local categories
       */
      selectedCategoryAndAttributeIds() {
        if (this.fetchingCategories) {
          return;
        }
        this.setLocalCategories();

        this.fetchingCategories = false;
        this.handleSelectedCategoryAndAttributeIdsChange();
      },
    },
    async created() {
      // clone all the things for the current (right side) product
      if (this.selectedProductId === this.activeProductId) {
        this.localCategories = cloneDeep(this.planDesignCategories);
        this.localContainers = cloneDeep(this.currentContainers).sort((a, b) => a.position - b.position);
        this.localCurrentContainers = cloneDeep(this.currentContainers);
        this.localTierGroups = cloneDeep(this.availablePlanDesignTierGroups);
        this.localContainerTypeSelection = this.containerTypeSelection;
      } else {
        await this.setLocalCategories();

        this.fetchingCategories = false;
      }
      this.selectedContainerIds = this.currentContainers.map((container) => container.id);
      this.selectedValueText = this.getAnnotationText;
    },
    methods: {
      ...mapActions(useAnnotationsStore, ['deleteAnnotation']),
      ...mapActions(usePdfAnnotationStore, ['setPdfAnnotationModalActionState']),
      /**
       * Set a bunch of useful things based on the selected category,
       * attribute, and the mode (edit existing vs create new).
       */
      handleSelectedCategoryAndAttributeIdsChange() {
        // This only runs from the watch on selectedCategoryAndAttributeIds
        // So this runs any time anyone also creates a new annotation because
        // they have to use the select to pick the attribute
        if (this.selectedCategoryAndAttributeIds) {
          // this should always be true
          const [categoryId, attributeId] = this.selectedCategoryAndAttributeIds.split('-').map((id) => Number(id));

          this.selectedCategory = this.localCategories.find((category) => category.id === categoryId);

          if (!this.selectedCategory) {
            return;
          }

          this.selectedAttribute = this.selectedCategory.categorized_attributes.find((attribute) => attribute.id === attributeId);
          this.originalAttribute = cloneDeep(this.selectedAttribute);

          // we were running into a scenario where on new annotation modal create saves the originalContainerIds as []
          if (this.originalContainerIds.length === 0) {
            this.originalContainerIds = this.selectedContainerIds;
          }
        } else {
          // selectedCategoryAndAttributeIds is, in fact, not true if they have selected a new product
          // in this case, we need to clear out the selected Attribute, Tier Group and Subtype group selections
          this.$set(this, 'selectedAttribute', {});
          this.selectedTierGroupId = null;
          this.updateSubtypes();
        }
      },
      /**
       * Save the value if all the required values are set.
       */
      async onSave() {
        if (this.getAnnotatedWordIdsAreValid) {
          // find any values already saved for this attribute that match the selectedContainerIds
          const originalValuesWithSameContainerIds = this.originalAttribute
            .grouped_values
            .filter(
              (groupedValue) => groupedValue.project_products_container_ids
                .filter((id) => this.selectedContainerIds.includes(id))
                .length,
            );

          // clear out error in modal if there was one, since we're good now.
          this.wordIdsError = '';

          if (originalValuesWithSameContainerIds.length) {
            // run through the already saved values
            // if any of the values have a different tier group then what we're saving we need to delete them
            const promises = originalValuesWithSameContainerIds.reduce((accumulator, value) => {
              if (value.tier_group_id !== this.selectedTierGroupId) {
                const valueAnnotations = this.annotationsByPlanDesignValueIds(value.ids);

                valueAnnotations.forEach(({ parts }) => {
                  parts.forEach(({ id }) => this.deleteAnnotation(id));
                });
                // delete the old values before we save the new one
                accumulator.push(ProductService.deletePlanDesignValue({
                  attributeId: this.selectedAttribute.id,
                  containerIds: value.project_products_container_ids,
                  productId: this.selectedProductId,
                  subtypeId: value.tier_subtype_id,
                }));
              }

              return accumulator;
            }, []);

            await Promise.all(promises);
          }

          if (this.selectedTierGroupId) {
            let containerFound = false;
            let missingContainerSubtypeId;

            // if this exists we're in a tiered group and need to make sure there is a value for the other subtypes
            this.localSubtypeGroupTypes.forEach(async (subtype) => {
              if (this.selectedSubtypeId !== subtype.subtype_id) {
                // First we check if the subtype exists already
                const foundSubtype = this.originalAttribute.grouped_values.find((value) => value.tier_subtype_id === subtype.subtype_id);

                // If it isn't found we need to create a new record for the subtype
                if (!foundSubtype) {
                  // This creates a blank value under the same tier group id as the annotation that is about to be created
                  await this.updatePlanValue({
                    containerIds: this.selectedContainerIds,
                    subtypeId: subtype.subtype_id,
                    tierGroupId: this.selectedTierGroupId,
                    value: null,
                  });
                } else {
                  // If the subtype exists, we now need to check that the project_product_container exists already
                  const foundContainer = foundSubtype.project_products_container_ids.find((id) => this.selectedContainerIds.includes(id));

                  if (foundContainer) {
                    // Container already exists, don't create a new one
                    containerFound = true;
                  } else {
                    // Container does NOT exist, hang onto the subtype_id for potentially creating a blank record
                    missingContainerSubtypeId = subtype.subtype_id;
                  }
                }
              } else {
                // If the subtype matches what we're trying to create, we need to check if the container exists already
                const foundSubtype = this.originalAttribute.grouped_values.find((value) => value.tier_subtype_id === subtype.subtype_id);

                if (foundSubtype) {
                  const foundContainer = foundSubtype.project_products_container_ids.find((id) => this.selectedContainerIds.includes(id));

                  if (foundContainer) {
                    // Container already exists, don't create a new one
                    containerFound = true;
                  } else {
                    // Container does NOT exist, hang onto the subtype_id
                    missingContainerSubtypeId = subtype.subtype_id;
                  }
                }
              }
            });
            // If we get here and no matching subtype/container combo was found then we need to create the blank record for the container
            if (!containerFound && missingContainerSubtypeId) {
              await this.updatePlanValue({
                containerIds: this.selectedContainerIds,
                subtypeId: missingContainerSubtypeId,
                tierGroupId: this.selectedTierGroupId,
                value: null,
              });
            }
          }
          if (this.newAnnotationWordIds.length > 0) {
            try {
              await this.updatePlanValue({
                annotation: {
                  word_ids: this.newAnnotationWordIds,
                  source_id: this.activeFileIds[this.currentProject.id],
                },
                containerIds: this.selectedContainerIds,
                subtypeId: this.selectedSubtypeId,
                tierGroupId: this.selectedTierGroupId,
                value: this.selectedValueText,
              });

              this.$emit('addAnnotation');
            } catch (error) {
              this.displayToast({
                message: error,
              });
            }
          }
        } else {
          this.setPdfAnnotationModalActionState({
            actionKey: 'annotationSaving',
            actionState: false,
          });
          this.wordIdsError = 'There is something wrong with the word selection for this annotation. Please try again, and if this problem persists, contact the development team.';
          addBreadcrumb({
            category: 'method',
            data: {
              wordIds: this.newAnnotationWordIds,
              sourceId: this.activeFileIds[this.currentProject.id],
              attribute: this.originalAttribute,
              text: 'invalid word ids',
            },
            level: 'debug',
          });
          captureException('invalid word ids');
        }
      },
      /**
       * Load available product design categories
       */
      async setLocalCategories() {
        this.fetchingCategories = true;

        const productDetailsPromise = ProductService
          .getProductDetails(this.selectedProduct.inforce_product.id);
        const tierGroupPromise = ProductService
          .getAvailableTierGroups(this.selectedProduct.product_type_id);

        try {
          const [product, { tier_groups }] = await Promise.all([
            productDetailsPromise,
            tierGroupPromise,
          ]);

          this.localCategories = cloneDeep(product.plan_design_categories);
          this.localCurrentContainers = cloneDeep(product.project_products_containers);
          this.localContainers = cloneDeep(product.project_products_containers).sort((a, b) => a.position - b.position);
          this.selectedContainerIds = this.localCurrentContainers.map((container) => container.id);
          this.localContainerTypeSelection = this.localCurrentContainers[0].container_type;
          this.localTierGroups = cloneDeep(tier_groups.plan_design_tier_groups);
        } catch (error) {
          this.displayToast({
            message: error,
          });
        }
      },
      /**
       * API call to PATCH the Plan Design Value. `attributeId` and `productId`
       * are part of this component so no need to pass these.
       *
       * @param {object} options
       * @param {object} options.annotation
       * @param {Array} options.containerIds
       * @param {number} options.subtypeId
       * @param {number} options.tierGroupId
       * @param {string} options.value
       * @returns {Promise}
       */
      updatePlanValue({
        annotation = null,
        containerIds,
        subtypeId,
        tierGroupId,
        value,
      }) {
        return ProductService.updatePlanValue({
          annotation,
          attributeId: this.selectedAttribute.id,
          containerIds,
          productId: this.selectedProductId,
          subtypeId,
          tierGroupId,
          value,
        });
      },
      /**
       * Update our subtypes dropdown list to match our tier group
       */
      updateSubtypes() {
        const tierGroup = this.localTierGroups.find((localTierGroup) => localTierGroup.tier_group_id === this.selectedTierGroupId);

        if (tierGroup) {
          this.localSubtypeGroupTypes = tierGroup.tier_subtypes;
        } else {
          this.localSubtypeGroupTypes = [];
          this.selectedSubtypeId = null;
        }
      },
    },
  };
</script>
