<template>
  <LfcModal
    title="Edit broker team members"
    height="550"
    :subtitle="employer ? employer.name : 'Loading…'"
    has-tabs
    :visible.sync="modalVisible"
    :show-close="false"
    @closed="cancelChanges"
  >
    <TfTabs
      v-model="activeTab"
      v-loading="!isLoaded"
      type="small"
    >
      <AppAlert
        v-if="errors.length"
        title="The following errors occurred:"
        type="danger"
      >
        <ul>
          <li
            v-for="(error, index) in errors"
            :key="`error-${index}`"
          >
            {{ error }}
          </li>
        </ul>
      </AppAlert>

      <ElTabPane
        v-if="isLoaded && !isInitialBuild"
        label="Placement event team"
        name="placement-event-team"
      >
        <TeamMemberTable
          :broker-id="brokerId"
          :team-members="placementEventTeamMembers"
          @updateTeamMembers="updatePlacementEventTeamMembers"
        />
      </ElTabPane>
      <ElTabPane
        v-if="isLoaded"
        label="Broker team"
        name="broker-team"
      >
        <TeamMemberTable
          :broker-id="brokerId"
          :team-members="brokerTeamMembers"
          @updateTeamMembers="updateBrokerTeamMembers"
        />
      </ElTabPane>
    </TfTabs>
    <template #footer>
      <AppButton
        data-test="cancel button"
        type="secondary"
        size="large"
        text="Cancel"
        @click="cancelChanges"
      />
      <AppButton
        data-test="done button"
        :is-disabled="disableDone"
        type="primary"
        icon="fa-solid fa-check"
        text="Done"
        @click="saveChanges"
      />
    </template>
  </LfcModal>
</template>

<script>
  // pinia
  import { mapWritableState, mapState, mapActions } from 'pinia';
  import { useBrokerTeamStore } from '@/stores/brokerTeam.js';
  // services
  import {
    getEmployerBrokerTeamMembers,
    addEmployerBrokerTeamMembers,
    removeEmployerBrokerTeamMembers,
  } from '@/services/employer.js';
  import ProjectService, { getProjectInfo } from '@/services/project.js';
  // components
  import TeamMemberTable from '@/components/Modal/EditBrokerUsers/TeamMemberTable/index.vue';

  /**
   * Modal for adding/editing/deleting broker users
   *
   * @vuedoc
   * @exports EditBrokerUsers
   * @category Components
   */
  export default {
    name: 'EditBrokerUsers',
    components: { TeamMemberTable },
    props: {
      projectId: {
        type: Number,
        required: true,
      },
    },
    data: () => ({
      activeTab: 'placement-event-team',
      modalVisible: false,
      errors: [],
      project: {},
      modifiedMemberIds: {
        brokerTeam: [],
        placementEventTeam: [],
      },
    }),
    computed: {
      ...mapState(useBrokerTeamStore, [
        'brokerTeamMemberIds',
        'placementEventTeamMemberIds',
        'placementEventTeamMembers',
      ]),
      ...mapWritableState(useBrokerTeamStore, [
        'brokerId',
        'brokerTeamMembers',
        'employer',
        'isInitialBuild',
        'isLoaded',
        'isLoadedFromLocation',
        'placementEventTeamMembers',
      ]),
      /**
       * Determine if the teams changed; if not, we disable the Done button.
       *
       * @returns {boolean}
       */
      disableDone() {
        const containsSameIds = (arrayA, arrayB) => arrayA.length === arrayB.length && arrayA.every((value) => arrayB.includes(value));
        const teamsAreIdentical = containsSameIds(this.brokerTeamMemberIds, this.modifiedMemberIds.brokerTeam)
          && containsSameIds(this.placementEventTeamMemberIds, this.modifiedMemberIds.placementEventTeam);
        const teamsHaveNullIds = this.modifiedMemberIds.brokerTeam.includes(null)
          || this.modifiedMemberIds.placementEventTeam.includes(null);

        return teamsAreIdentical || teamsHaveNullIds;
      },
    },
    /**
     * If data isn't available in the store already
     * (read: we're rendering the modal in the ManageRfp view),
     * We need to load it.
     */
    created() {
      this.modalVisible = true;
      if (!this.isLoaded) {
        this.loadProjectAndTeamMembers(true);
      } else {
        this.activeTab = this.isInitialBuild ? 'broker-team' : 'placement-event-team';

        // We need to initialize the modified IDs array to be identical to
        // what's in the store already, so that the Done button is disabled
        // until changes are made.
        this.setModifiedIds('placementEventTeam', this.placementEventTeamMembers);
        this.setModifiedIds('brokerTeam', this.brokerTeamMembers);
      }
    },
    /**
     * If the data was loaded in the modal, we can destroy it;
     * otherwise, leave it, because the parent page (currently
     * only ManageRfp) supplied it, continues to need it, and
     * will clean up after itself.
     */
    beforeDestroy() {
      if (this.isLoadedFromLocation === 'EditBrokerUsers') {
        this.resetBrokerTeamStore();
      }
    },
    methods: {
      ...mapActions(useBrokerTeamStore, ['resetBrokerTeamStore']),
      /**
       * Close modal and confirm changes are canceled with a notification
       */
      cancelChanges() {
        this.modalVisible = false;
        this.$emit('modalClosed');
      },
      /**
       * Load the project and set the store up with data.
       *
       * @param {boolean} setInitialTab
       */
      async loadProjectAndTeamMembers(setInitialTab = false) {
        this.isLoaded = false;

        try {
          const { project } = await getProjectInfo(this.projectId);

          this.$set(this, 'project', project);
          this.placementEventTeamMembers = project.broker_project_users;
          this.setModifiedIds('placementEventTeam', project.broker_project_users);
          this.employer = project.employer;
          this.brokerId = project.broker_id;
          this.isInitialBuild = project.type === 'Projects::InitialEmployerBuild';

          if (setInitialTab) {
            this.activeTab = this.isInitialBuild ? 'broker-team' : 'placement-event-team';
          }

          try {
            const { broker_team_members: brokerTeamMembers } = await getEmployerBrokerTeamMembers(project.employer.id);

            this.brokerTeamMembers = brokerTeamMembers;
            this.setModifiedIds('brokerTeam', brokerTeamMembers);
            this.isLoaded = true;
            this.isLoadedFromLocation = 'EditBrokerUsers';
          } catch {
            this.displayToast({
              message: 'There was an error getting the broker team members.',
            });
          }
        } catch {
          this.displayToast({
            message: 'There was an error getting the project info.',
          });
        }
      },
      /**
       * Run all necessary services to update changes made in this modal session
       */
      async saveChanges() {
        const promiseArray = [];
        const deletedBrokerTeamMemberIds = this.brokerTeamMemberIds.filter((id) => !this.modifiedMemberIds.brokerTeam.includes(id));
        const deletedPlacementEventTeamMemberIds = this.isInitialBuild
          ? []
          : this.placementEventTeamMemberIds.filter((id) => !this.modifiedMemberIds.placementEventTeam.includes(id));
        const employerId = this.employer.id;
        const newBrokerTeamMemberIds = this.modifiedMemberIds.brokerTeam.filter((id) => !this.brokerTeamMemberIds.includes(id));
        const newPlacementEventTeamMemberIds = this.isInitialBuild
          ? []
          : this.modifiedMemberIds.placementEventTeam.filter((id) => !this.placementEventTeamMemberIds.includes(id));

        // clear errors
        this.$set(this, 'errors', []);

        // users can be added with an array of userIds so we only have to hit the endpoint once.
        if (newBrokerTeamMemberIds.length) {
          promiseArray.push(
            addEmployerBrokerTeamMembers(employerId, newBrokerTeamMemberIds)
              .catch(() => {
                this.errors.push('An error occurred trying to add users to the broker team.');
              }),
          );
        }
        if (newPlacementEventTeamMemberIds.length) {
          promiseArray.push(
            ProjectService
              .addProjectBrokerUsers(this.projectId, newPlacementEventTeamMemberIds)
              .catch(() => {
                this.errors.push('An error occurred trying to add users to the placement event team.');
              }),
          );
        }

        // users have to be deleted from each time one userId at a time, so, we add a promise for each change.
        deletedPlacementEventTeamMemberIds.forEach((userId) => {
          promiseArray.push(
            ProjectService
              .removeProjectBrokerUsers(this.projectId, userId)
              .catch(({ response: { data: { message } } }) => {
                const { first_name: firstName, last_name: lastName } = this.placementEventTeamMembers.find(({ id }) => id === userId);

                this.errors.push(
                  message.includes('ETM-300')
                    ? `Cannot remove ${firstName} ${lastName}; The placement event team must include at least one user.`
                    : `An error occurred trying to remove ${firstName} ${lastName} from the placement event team.`,
                );
              }),
          );
        });
        deletedBrokerTeamMemberIds.forEach((userId) => {
          promiseArray.push(
            removeEmployerBrokerTeamMembers(employerId, userId)
              .catch(({ response: { data: { message } } }) => {
                const { full_name: fullName } = this.brokerTeamMembers.find(({ user_id: id }) => id === userId);

                this.errors.push(
                  message.includes('BTM-300')
                    ? `Cannot remove ${fullName}; The broker team must include at least one user.`
                    : `An error occurred trying to remove ${fullName} from the broker team.`,
                );
              }),
          );
        });

        await Promise.all(promiseArray);

        // then always runs, because the individual catches don't terminate,
        // so we have to check if errors has length,.
        if (!this.errors.length) {
          this.modalVisible = false;
          this.$emit('modalClosed');
          this.displayToast({
            message: 'Your request has been successfully processed.',
            type: 'success',
          });
        }
        // if we always load the current members, we'll immediately get users
        // back that we tried to delete but that the API couldn't delete.
        this.loadProjectAndTeamMembers();
      },
      /**
       * Update the arrays that track modifications
       *
       * @param {string} type
       * @param {Array} teamMembers
       */
      setModifiedIds(type, teamMembers) {
        this.$set(this.modifiedMemberIds, type, teamMembers.map(({ id, user_id: userId }) => userId || id));
      },
      /**
       * Update the placementEventTeamMembers list on emit
       *
       * @param {Array} teamMembers
       */
      updatePlacementEventTeamMembers(teamMembers) {
        this.setModifiedIds('placementEventTeam', teamMembers);
      },
      /**
       * Update the brokerTeamMembers list on emit
       *
       * @param {Array} teamMembers
       */
      updateBrokerTeamMembers(teamMembers) {
        this.setModifiedIds('brokerTeam', teamMembers);
      },
    },
  };
</script>

<style lang="scss" scoped>
  :deep(.el-alert) {
    li {
      list-style-type: disc;
      list-style-position: inside;
    }
  }
</style>
