<template>
  <NormalizedValuesTable />
</template>

<script>
  import NormalizedValuesTable from '@/components/NormalizedValues/NormalizedValuesTable.vue';

  /**
   * View for Normalized Values Management
   *
   * @vuedoc
   * @exports NormalizedValuesManagement
   * @category Views
   */
  export default {
    name: 'NormalizedValuesManagement',
    components: { NormalizedValuesTable },
  };
</script>
