<template>
  <div
    class="logo-upload"
    data-test="logo upload"
  >
    <h4>{{ name }}</h4>
    <ElUpload
      :action="action"
      :http-request="uploadLogoRequest"
      :on-preview="handlePreviewLogo"
      :on-remove="handleRemoveLogo"
      :file-list="logos"
      name="logo[]"
      list-type="picture-card"
      :class="{ 'has-logo': hasLogo }"
    >
      <i class="el-icon-plus avatar-uploader-icon" />
    </ElUpload>
  </div>
</template>

<script>
/**
 * Logo Upload Component
 *
 * @vuedoc
 * @exports LogoUpload
 * @category Components
 */
  export default {
    name: 'LogoUpload',
    props: {
      name: {
        type: String,
        default: 'Logo',
      },
      action: {
        type: String,
        required: true,
      },
      logoUrl: {
        type: String,
        default: null,
      },
    },
    data: () => ({
      hasLogo: false,
    }),
    computed: {
      /**
       * computed property for the tf-upload/large logo based off the broker object
       *
       * @returns {Array}
       */
      logos() {
        return [{
          name: this.name,
          url: this.logoUrl,
        }];
      },
    },
    created() {
      if (this.logoUrl) {
        this.hasLogo = true;
      }
    },
    methods: {
      /**
       * Open the logo in a new window
       *
       * @param {Event} event
       */
      handlePreviewLogo(event) {
        window.open(event.url);
      },
      /**
       * Remove the logo so a new one can be uploaded.
       * We do not update the record because there's risk to allowing
       * a record to not have a logo down the line (Broker, Carrier sites).
       */
      handleRemoveLogo() {
        this.hasLogo = false;
      },
      /**
       * The upload component wants to post images, so if you want to
       * do a patch call, we have to overwrite the request method.
       *
       * @param {Event} fileSelectionEvent
       */
      uploadLogoRequest(fileSelectionEvent) {
        this.$emit('updateLogo', {
          name: this.name,
          file: fileSelectionEvent.file,
        });

        this.hasLogo = true;
      },
    },
  };
</script>

<style lang="scss" scoped>
.logo-upload {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  width: 150px;
}

:deep() {
  .el-upload-list--picture-card .el-upload-list__item {
    display: flex;

    // stylelint-disable-next-line selector-max-compound-selectors
    img {
      height: auto;
      margin: auto 0;
    }
  }

  .el-upload-list__item {
    transition: none;
  }

  .el-upload-list {
    display: none;
  }

  .has-logo {
    .el-upload-list {
      display: block;
    }

    div.el-upload.el-upload--picture-card {
      display: none;
    }
  }

  .el-icon-close-tip {
    /* stylelint-disable declaration-no-important  */
    display: none !important;
    /* stylelint-enable declaration-no-important  */
  }
}
</style>
