/**
 * Mixin methods for messages
 *
 * @exports Messages
 * @category Mixins
 */

export default {
  methods: {
    /**
     * Change the project owner based on userInfo Id.
     *
     * @param {object} options
     * @param {string} options.subject
     * @param {string} options.label
     * @param {string} options.type
     * @param {string} options.value
     */
    onPropUpdate({
      subject,
      label,
      type = 'success',
      value,
    }) {
      this.$message({
        message: type === 'success'
          ? `The ${label} for "${subject}" has been changed to "${value}".`
          : `There was an error changing the ${label} for "${subject}".`,
        type,
        duration: 3000,
      });
    },
  },
};
