<template>
  <LfcApiTable
    v-bind="{
      tableData,
      tableMeta,
    }"
    @getTableData="getTableData"
  >
    <template #title>
      Invitees
    </template>
    <template #empty>
      <ol>
        <li>Use the form to enter new user credentials and select the add user button</li>
        <li>The information you entered will appear in this table</li>
        <li>When you are done entering the users you are inviting select send invites.</li>
      </ol>
    </template>
    <template #parentRow="{ rowData }">
      <LfcApiTableParentRow
        :data-test="`${rowData.email} invitee row`"
        :row-data="rowData"
      >
        <TfTableTd data-test="email">
          {{ rowData.email }}
        </TfTableTd>
        <TfTableTd data-test="roles">
          {{ rowData.roles.join(', ') }}
        </TfTableTd>
        <TfTableTd>
          <AppButton
            icon="fa-solid fa-trash-alt"
            data-test="remove invitee"
            size="icon"
            type="decline"
            :is-disabled="isSaving"
            @click="removeInvitee(rowData.email)"
          />
        </TfTableTd>
      </LfcApiTableParentRow>
    </template>
    <template #footer>
      <AppButton
        :is-disabled="(!isSaving && invitees.length === 0) || isSaving"
        size="medium"
        icon="fa-solid fa-paper-plane"
        type="primary"
        data-test="send invitees"
        text="Send invites"
        @click="sendInvites"
      />
    </template>
  </LfcApiTable>
</template>

<script>
  import {
    brokerRoles as baseRoles,
    brokerAddOnRoles as addOnRoles,
  } from '@watchtowerbenefits/lfc-components';
  import { inviteBrokerUser } from '@/services/user.js';

  export default {
    name: 'UsersInvitees',
    props: {
      invitees: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        tableData: [],
        tableMeta: {
          columns: [
            {
              format: 'string',
              label: 'Email',
              prop: 'email',
              width: 130,
            },
            {
              format: 'string',
              label: 'Permissions',
              prop: 'roles',
              width: 200,
            },
            {
              prop: 'delete',
              width: 10,
            },
          ],
          emptyValue: ' ',
        },
        isSaving: false,
        roles: {
          baseRoles,
          addOnRoles,
        },
      };
    },
    watch: {
      /**
       * Watch invitees prop and set to tableData
       */
      invitees() {
        this.getTableData();
      },
    },
    methods: {
      /**
       * Remove invitee
       *
       * @param {string} rowEmail
       */
      removeInvitee(rowEmail) {
        const updateInvitees = this.invitees.filter(({ email }) => email !== rowEmail);

        this.$emit('updateInvitees', updateInvitees);
      },
      /**
       * Get invitees prop and set to tableData
       */
      getTableData() {
        const tableData = this.invitees.map(({ email, roles }) => {
          const [name, domain] = email.split('@');

          return {
            email: email.length >= 35 ? `${name}\n@${domain}` : email,
            roles: roles.map((role) => {
              const baseRole = this.roles.baseRoles.find((base) => base.value === role);
              const addOnRole = this.roles.addOnRoles.find((addOn) => addOn.value === role);

              return addOnRole?.label || baseRole?.label || role;
            }),
          };
        });

        this.tableData = tableData;
      },
      /**
       * Send invites
       */
      async sendInvites() {
        this.isSaving = true;
        const invitePromises = [];

        // build array of invite promises
        this.invitees.forEach(({ email, roles }) => {
          const request = inviteBrokerUser({
            email_address: email,
            broker_id: this.$route.params.brokerId,
            role_name: roles,
          });

          invitePromises.push(request);
        });
        try {
          const invitedEmailAddresses = this.invitees.map(({ email }) => email);
          const sentInvitees = [];
          const errorInvitees = [];
          const invitesSent = await Promise.all(invitePromises);

          invitesSent.forEach((invite, index) => {
            if (invite.user_invite) {
              sentInvitees.push(invitedEmailAddresses[index]);
            } else {
              errorInvitees.push({
                emailAddress: invitedEmailAddresses[index],
                errorMessage: invite.message,
              });
            }
          });
          // because our toast messages are stacking on top of each other, for now
          // we will only show a success if all invites were sent w/o issues.
          if (sentInvitees.length && !errorInvitees.length) {
            const sentInviteesMessage = `Invites sent to ${sentInvitees.join(', ')}.`;

            this.displayToast({
              message: sentInviteesMessage,
              type: 'success',
            });
            this.$emit('updateInvitees', []);
          }
          // if some errored out, we will mention that some succeeded, plus the errors
          // for the failed invites.
          if (errorInvitees.length) {
            const errorInviteesMessage = `${sentInvitees.length} invite${sentInvitees.length > 1 ? 's' : ''} were successfully sent. The following email addresses could not be invited: ${errorInvitees.map(({ emailAddress, errorMessage }) => `${emailAddress}: ${errorMessage}`).join(', ')}`;

            this.displayToast({
              message: errorInviteesMessage,
              duration: 6000,
            });
          }
        } catch {
          // generic 500 errors will be handled by a generic toast.
          this.displayToast({
            message: 'There was an error sending the invitation.',
          });
        } finally {
          this.isSaving = false;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  ol {
    list-style-type: auto;
    text-align: left;
    max-width: 300px;
    margin: 0 auto;
  }

  :deep(.lfc-table-footer) {
    margin-top: 30px;
    text-align: center;
  }
</style>
