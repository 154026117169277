<template>
  <div class="product-selection-container">
    <AppButton
      id="btn-toggle-product-panel"
      type="secondary"
      icon="fa-solid fa-plus"
      size="icon"
      title="Add product"
      data-test="add product button"
      @click="togglePanelOverlay"
    />
    <div id="product-select-input">
      <ProjectProductSelector
        v-if="selectedProjectProductId"
        data-test="project product selector"
        :selected-project-product-id="Number(selectedProjectProductId)"
        @selectProduct="setActiveProduct"
      />
    </div>
  </div>
</template>

<script>
  import { mapWritableState } from 'pinia';
  import { useProjectStore } from '@/stores/project.js';
  import { useProjectProductStore } from '@/stores/projectProduct.js';
  import ProjectProductSelector from '@/components/ProjectProductSelector/index.vue';

  /**
   * The header that includes the Add Product button and Product selector.
   *
   * @vuedoc
   * @exports ProductPanelHeader
   * @category Components
   */
  export default {
    name: 'ProductPanelHeader',
    components: {
      ProjectProductSelector,
    },
    computed: {
      ...mapWritableState(useProjectProductStore, ['selectedProjectProductId']),
      ...mapWritableState(useProjectStore, ['projectPanelActive']),
    },
    methods: {
      /**
       * toggles the projectPanelActive store state value
       */
      togglePanelOverlay() {
        this.projectPanelActive = !this.projectPanelActive;
      },
      /**
       * write `selectedProjectProductId` with the project product id that was selected from the Project Product Selector.
       *
       * @param {number} value
       */
      setActiveProduct(value) {
        this.selectedProjectProductId = value;
        this.$router.replace({
          params: {
            projectProductId: value,
          },
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .product-selection-container {
    height: 45px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--tf-gray-medium);
  }

  #btn-toggle-product-panel {
    display: flex;
    justify-content: center;
    width: 45px;
    height: 45px;
    border-right: 1px solid var(--tf-gray-medium);
    cursor: pointer;
  }

  #product-select-input {
    flex: 1;
    padding: 0 20px;
  }
</style>
