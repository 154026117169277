<template>
  <DashboardApiTable
    dashboard-type="project_setup"
    title="Project setup"
  />
</template>

<script>
  import AdminNotificationsService from '@/services/notifications.js';
  import DashboardApiTable from '@/components/Dashboard/IndexApi.vue';

  /**
   * Project Table to display all the projects in the "setup" state.
   *
   * @vuedoc
   * @exports DashboardSetup
   * @category Views
   */
  export default {
    name: 'DashboardSetup',
    components: {
      DashboardApiTable,
    },
    created() {
      AdminNotificationsService.clearProjectNotifications('project_setup');
    },
  };
</script>
