<template>
  <div class="toolbar">
    <div class="toolbar-sub toolbar-sub-selection">
      <ElRadioGroup
        v-model="localAnnotationMode"
        size="small"
      >
        <ElRadioButton label="auto" />
        <ElRadioButton label="draw" />
        <ElRadioButton label="click" />
      </ElRadioGroup>
    </div>

    <div class="toolbar-sub toolbar-sub-search-toggle">
      <AppButton
        type="secondary"
        icon="fa-solid fa-search"
        size="icon"
        data-test="search toggle button"
        text="Toggle search"
        @click="togglePdfViewerToolbar('search')"
      />
    </div>

    <div class="toolbar-sub toolbar-sub-pagination">
      <AppButton
        type="secondary"
        icon="fa-solid fa-arrow-up"
        size="icon"
        data-test="page up button"
        text="Page up"
        @click="pdfViewer.scrollToPage(localPageNumber - 1)"
      />
      <ElInput
        v-model="localPageNumber"
        class="page-number"
        data-test="page number"
        size="medium"
        @keyup.enter.native="pdfViewer.scrollToPage(localPageNumber)"
      />
      <span class="add-on">/ {{ pages.length }}</span>
      <AppButton
        type="secondary"
        icon="fa-solid fa-arrow-down"
        size="icon"
        data-test="page down button"
        text="Page down"
        @click="pdfViewer.scrollToPage(localPageNumber + 1)"
      />
    </div>

    <div class="toolbar-sub toolbar-sub-magnification">
      <AppButton
        type="secondary"
        icon="fa-solid fa-minus"
        size="icon"
        data-test="zoom up button"
        text="Zoom out"
        @click="setPdfViewerZoom(localZoom - 10)"
      />
      <ElInput
        v-model="localZoom"
        class="zoom-percentage"
        size="medium"
        data-test="zoom percentage"
        @keyup.enter.native="setPdfViewerZoom(localZoom)"
      />
      <span class="add-on">%</span>
      <AppButton
        type="secondary"
        icon="fa-solid fa-plus"
        size="icon"
        data-test="zoom down button"
        text="Zoom in"
        @click="setPdfViewerZoom(localZoom + 10)"
      />
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions, mapWritableState } from 'pinia';
  import { usePdfViewerStore } from '@/stores/pdfViewer.js';
  import { usePdfAnnotationStore } from '@/stores/pdfAnnotation.js';
  /**
   * Pagination/zoom controls for PDF Viewer
   *
   * @vuedoc
   * @exports PageToolbar
   * @category Components
   */
  export default {
    name: 'PageToolbar',
    // get access to the pdfViewer's scrollTo method to avoid
    // having to use the store for changing the page
    inject: ['pdfViewer'],
    data: () => ({
      localPageNumber: 1,
      localZoom: 200,
    }),
    computed: {
      ...mapState(usePdfViewerStore, [
        'pages',
        'getPageNumber',
        'zoom',
      ]),
      ...mapWritableState(usePdfAnnotationStore, ['annotationMode']),
      /**
       * getter/setter for annotationMode
       */
      localAnnotationMode: {
        get() {
          return this.annotationMode;
        },
        set(value) {
          this.annotationMode = value;
        },
      },
    },
    watch: {
      getPageNumber: {
        /**
         * update localPageNumber if page number changes in the store
         * NOTE: because we want to use the 'enter to submit' on this field,
         * we can't use a getter/setter.
         *
         * @param {number} newPageNumberValue
         */
        handler(newPageNumberValue) {
          this.localPageNumber = newPageNumberValue;
        },
        immediate: true,
      },
      zoom: {
        /**
         * update localZoom if it changes in the store
         * NOTE: because we want to use the 'enter to submit' on this field,
         * we can't use a getter/setter.
         *
         * @param {number} newZoomValue
         */
        handler(newZoomValue) {
          this.localZoom = newZoomValue;
        },
        immediate: true,
      },
    },
    methods: {
      ...mapActions(usePdfViewerStore, [
        'togglePdfViewerToolbar',
        'setPdfViewerZoom',
      ]),
    },
  };
</script>

<style lang="scss" scoped>
  .toolbar-sub-pagination,
  .toolbar-sub-magnification {
    .el-input.el-input--medium {
      display: inline-block;
      width: 46px;
      margin-left: 15px;
      margin-right: 6px;
    }

    .add-on {
      margin-right: 15px;
      font-size: 14px;
      color: var(--tf-blue);
    }
  }
</style>
