<template>
  <ElForm
    ref="inviteForm"
    label-position="top"
    :model="value"
    :rules="rules"
  >
    <h3>Invite new user</h3>
    <ElFormItem
      prop="email"
      label="Email"
    >
      <ElInput
        v-model.trim="value.email"
        data-test="enter email"
        placeholder="Enter email"
        @input="setIsValid"
        @blur="emailHasBeenBlurred = true"
      />
    </ElFormItem>
    <ElFormItem
      label="Permissions"
      prop="baseRoles"
    >
      <ElSelect
        v-model="value.baseRoles"
        data-test="select base roles"
        @change="setIsValid"
      >
        <ElOption
          v-for="role in roles.baseRoles"
          :key="role.value"
          :data-test="`base roles ${role.label} option`"
          :label="role.label"
          :value="role.value"
        />
      </ElSelect>
    </ElFormItem>
    <ElFormItem
      prop="addOnRoles"
    >
      <ElCheckboxGroup
        v-model="value.addOnRoles"
        data-test="select add on roles"
        @input="setIsValid"
      >
        <ElCheckbox
          v-for="(role) in roles.addOnRoles"
          :key="role.value"
          :data-test="`select ${role.label} add on role`"
          :label="role.value"
        >
          {{ role.label }}
        </ElCheckbox>
      </ElCheckboxGroup>
    </ElFormItem>
    <div class="form-ctas">
      <AppButton
        data-test="clear add invitee form"
        :is-disabled="isCancelUserDisabled"
        type="primary"
        size="text"
        text="Cancel"
        @click="clearForm"
      />
      <AppButton
        data-test="add invitee"
        :is-disabled="isAddUserDisabled"
        type="primary"
        size="small"
        text="Add user"
        @click="addUser"
      />
    </div>
  </ElForm>
</template>

<script>
  import { mapState } from 'pinia';
  import { useBrokersStore } from '@/stores/brokers.js';
  import { checkInviteBrokerUser } from '@/services/user.js';
  import {
    brokerRoles as baseRoles,
    brokerAddOnRoles as addOnRoles,
  } from '@watchtowerbenefits/lfc-components';
  import { isEqual } from 'lodash';

  const defaultFormState = {
    email: '',
    baseRoles: 'placement_management',
    addOnRoles: [],
  };

  /**
   * Displays the Invite Broker Users form
   *
   * @vuedoc
   * @exports InviteUsersForm
   * @category Components
   */

  export default {
    name: 'InviteUsersForm',
    props: {
      /**
       * The list of users to be invited.
       */
      invitees: {
        type: Array,
        default: () => [],
      },
    },
    data: (vm) => ({
      value: { ...defaultFormState },
      emailHasBeenBlurred: false,
      isAddUserDisabled: true,
      roles: {
        baseRoles,
        addOnRoles,
      },
      rules: {
        email: [
          {
            required: true,
            trigger: 'blur',
            message: 'Email address is required.',
          },
          {
            validator: (rule, value, callback) => {
              vm.confirmEmail(rule, value, callback);
            },
            trigger: 'blur',
          },
        ],
      },
    }),
    computed: {
      ...mapState(useBrokersStore, ['currentBroker']),
      /**
       * If the Invite Users form is empty (or in it's default state), then we disable the cancel button
       *
       * @returns {object}
       */
      isCancelUserDisabled() {
        return isEqual(this.value, defaultFormState);
      },
    },
    methods: {
      /**
       * Add user to the invitee list
       */
      addUser() {
        const updatedInvitees = [...this.invitees];
        const roles = [
          this.value.baseRoles,
          ...this.value.addOnRoles,
        ]
          .filter((role) => role);

        updatedInvitees.push({
          email: this.value.email,
          roles,
        });
        this.$emit('updateInvitees', updatedInvitees);
        this.clearForm();
      },
      /**
       * Empty out the form and return it to it's default state
       */
      clearForm() {
        this.value = { ...defaultFormState };
        this.isAddUserDisabled = true;
        this.$refs.inviteForm.resetFields();
      },
      /**
       * Confirm email address rule
       *
       * @param {object} rule
       * @param {string} value
       * @param {Function} callback
       */
      async confirmEmail(rule, value, callback) {
        const emailDomains = this.currentBroker.email_domains;
        const validDomainsString = emailDomains.join(', ');
        const domain = value.split('@', 2)[1];

        if (!emailDomains.length) {
          // Check if email domains are configured on the brokerage
          callback(new Error('At least one email domain must be added in the email domains tab.'));
        } else if (!domain || !emailDomains.some((d) => d.toLowerCase() === domain.toLowerCase())) {
          // Check if domain is part of the whitelisted email domains
          callback(new Error(`Email domain must be ${emailDomains.length > 1 ? 'one of ' : ''}${validDomainsString}.`));
        } else if (this.invitees.some(({ email }) => email.toLowerCase() === value.toLowerCase())) {
          // Check if user already exists *or* if an invite has already been sent
          callback(new Error('This user is already on the invite list.'));
        } else {
          // initial checks are complete, now we can go bother the API
          try {
            await checkInviteBrokerUser(this.value.email.toLowerCase());
          } catch (error) {
            callback(error.response.data.message || error);
          } finally {
            callback();
          }
        }
      },
      /**
       * Ensures the email is valid, and that the user has selected at least one role before
       * allowing the user to add an invite.
       * Since this is run on the input event for the email field and triggers the form validations,
       * it's causing confirmEmail to be run which could set the input to an error state
       * before the user has even finished entering the email address. So, we prevent the error from popping up
       * by checking if the email field has been blurred yet during this component lifecycle.
       * The field will still be validated on blur which will trigger the error message if confirmEmail returns an error.
       */
      setIsValid() {
        if (this.$refs.inviteForm) {
          this.$refs.inviteForm.validate((isValid) => {
            let allFieldsAreValid = isValid;

            if (!this.emailHasBeenBlurred) {
              this.$refs.inviteForm.clearValidate();
            }
            if (
              (this.value.baseRoles === '')
              && (!this.value.addOnRoles.length)
            ) {
              allFieldsAreValid = false;
            }
            this.isAddUserDisabled = !allFieldsAreValid;
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .el-form {
    width: 40%;
    margin-right: 40px;

    .el-form-item:first-of-type {
      margin-bottom: 30px;
    }

    & :deep(.el-form-item__error) {
      top: 90%;
    }

    & :deep(.el-select) {
      display: block;
    }

    & :deep(.el-checkbox) {
      display: inline;
      padding-right: 18px;
    }

    .form-ctas {
      display: flex;
      justify-content: center;
      margin: 5px;
    }
  }
</style>
