<template>
  <tr
    :class="{
      active: isActiveRow,
      'read-only-row': readOnlyAttribute,
      'no-border': noBorder,
    }"
    @click="setActiveValueRow()"
    @focus.capture="setActiveValueRow()"
  >
    <!-- annotation marker cell; doesn't visibly render due to contents being positioned absolutely -->
    <td :class="{ 'has-faux-top-cell': fauxTopCell }">
      <AnnotationMarkerGroup
        v-if="!hiddenAttribute"
        :annotations="annotations"
        @click="showFirstAnnotation"
      />
    </td>
    <!-- Subtype cell -->
    <td>
      <template v-if="!hiddenAttribute">
        <template v-if="!valueIndex">
          <!-- IF !readonly: Show dropdown -->
          <ElSelect
            v-if="!readOnlyAttribute"
            key="Subtype select"
            v-model="localValue.tier_group_id"
            :disabled="!availablePlanDesignTierGroups.length || missingContainers"
            class="subtype-select"
            size="mini"
            placeholder="None"
            data-test="select tier group"
            @change="$emit('tierGroupChange')"
          >
            <ElOption
              label="None"
              :value="null"
            />
            <ElOption
              v-for="availableTierGroup in availablePlanDesignTierGroups"
              :key="availableTierGroup.tier_group_id"
              :label="availableTierGroup.tier_group_name"
              :value="availableTierGroup.tier_group_id"
              :data-test="`select ${availableTierGroup?.tier_group_name ?? 'option'}`"
            />
          </ElSelect>
          <!-- ELSE: Show label -->
          <span
            v-else
            class="subtype-read-only-label"
            v-text="tierGroup.name"
          />
        </template>
        <span
          v-if="value.tier_subtype_id"
          class="subtype-name"
          :class="{ 'read-only': readOnlyAttribute }"
          v-text="value.tier_subtype_name"
        />
      </template>
      <span
        v-else
        class="subtype-read-only-label"
      >
        &mdash;
      </span>
    </td>
    <!-- Value cell -->
    <td
      :class="[
        { 'has-faux-top-cell': fauxTopCell },
        'value-cell',
      ]"
    >
      <template v-if="!hiddenAttribute">
        <ElForm
          ref="value-form"
          key="Value input"
          :model="localValue"
        >
          <ElFormItem
            v-if="!readOnlyAttribute"
            prop="value"
            :rules="{
              required: true,
              message: 'Value is required.',
              trigger: 'none',
            }"
          >
            <TfInputWithOptions
              :value="localValue.value"
              :append-validation-type="['percentage-whole-number'].includes(validationType)"
              :attribute-id="attributeId"
              :disabled="missingContainers"
              :options="normalizedValues"
              :validation-type="validationType"
              width="auto"
              prop="value"
              @change="onValueChange(localValue, $event)"
            />
          </ElFormItem>
          <span
            v-else
            class="plan-design-read-only-value"
            v-text="value.value"
          />
        </ElForm>
      </template>
      <span
        v-else
        class="plan-design-read-only-value"
      >
        &mdash;
      </span>
    </td>
    <!-- Class/Plan cell -->
    <td
      :class="[
        { 'has-faux-top-cell': fauxTopCell },
        'value-container-cell',
      ]"
    >
      <template v-if="!hiddenAttribute">
        <ElForm
          v-if="containerTypeSelection"
          ref="container-form"
          key="Class/plan select"
          data-test="class/plan select"
          :model="value"
          @submit.native.prevent
        >
          <ElFormItem
            v-if="!readOnlyAttribute"
            prop="project_products_container_ids"
            :rules="{
              required: true,
              message: 'Containers are required.',
              trigger: 'none',
            }"
          >
            <TfMultiSelect
              v-if="!valueIndex && !readOnlyAttribute"
              v-model="valueContainerIds"
              :append-to-body="false"
              :disable-click="missingContainers"
              :force-disable-checkbox-click="availableContainers.length === 1 && availableContainers[0].id === valueContainerIds[0]"
              :label="isClassBased ? 'name' : 'description'"
              :options="availableContainers"
              :total-options-available="currentContainers.length"
              width="auto"
              value-key="id"
              empty-text="No containers available"
              searchable-parent="tr"
              next-tabbable-element=".ellipsis-button"
              @hide="containersHide($event)"
            />
            <span
              v-else
              class="multi-select-read-only-label"
              v-text="displayValue"
            />
          </ElFormItem>
          <span
            v-else
            class="container-read-only-label"
            v-text="displayValue"
          />
        </ElForm>
      </template>
      <template v-else>
        &mdash;
      </template>
    </td>
    <td>
      <slot
        v-if="!valueIndex"
        name="rowControls"
      />
    </td>
  </tr>
</template>

<script>
  import { getRanges } from '@watchtowerbenefits/es-utils-public';

  // pinia
  import { mapState, mapWritableState, mapActions } from 'pinia';
  import { useFilesStore } from '@/stores/files.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useFileViewerStore } from '@/stores/fileViewer.js';
  import { useAnnotationsStore } from '@/stores/annotations.js';
  import { usePdfAnnotationStore } from '@/stores/pdfAnnotation.js';
  import { useProductContainersStore } from '@/stores/productContainers.js';
  import { useProductStructuresStore } from '@/stores/productStructures.js';
  import { useProductSelectionsStore } from '@/stores/productSelections.js';

  // 3rd party
  import { xor } from 'lodash';
  // services
  import ProductService from '@/services/product.js';
  // utils
  import ValidationUtil from '@/utils/validation.js';
  // components
  import AnnotationMarkerGroup from '@/components/PlanDesign/AnnotationMarker/Group.vue';

  /**
   * Plan Design Value Row
   *
   * @vuedoc
   * @exports PlanDesignTableValueRow
   * @category Components
   */
  export default {
    name: 'PlanDesignTableValueRow',
    components: {
      AnnotationMarkerGroup,
    },
    props: {
      attributeId: {
        type: Number,
        required: true,
      },
      attributeName: {
        type: String,
        default: null,
      },
      category: {
        type: Object,
        default: () => ({}),
      },
      hiddenAttribute: {
        type: Boolean,
        default: false,
      },
      noBorder: {
        type: Boolean,
        default: false,
      },
      normalizedValues: {
        type: Array,
        default: () => [],
      },
      readOnlyAttribute: {
        type: Boolean,
        default: false,
      },
      sortedTierGroups: {
        type: Array,
        default: () => [],
      },
      tierGroup: {
        type: Object,
        default: () => ({}),
      },
      unusedContainerIds: {
        type: Array,
        default: () => [],
      },
      value: {
        type: Object,
        default: () => ({}),
      },
      valueIndex: {
        type: Number,
        default: 0,
      },
    },
    data() {
      return {
        addIds: [],
        removeIds: [],
        valueContainerIds: [],
        localValue: {},
      };
    },
    computed: {
      ...mapState(useProductStructuresStore, ['availablePlanDesignTierGroups']),
      ...mapState(useAnnotationsStore, ['annotationsByPlanDesignValueIds']),
      ...mapState(useFilesStore, ['sources']),
      ...mapWritableState(useFileViewerStore, ['goToAnnotation']),
      ...mapState(useProductContainersStore, [
        'containerTypeSelection',
        'currentContainers',
        'isClassBased',
        'missingContainers',
      ]),
      ...mapState(useProductSelectionsStore, [
        'activeAttributeId',
        'selectedProductId',
        'activeCategoryId',
        'activeValueIds',
      ]),
      ...mapWritableState(usePdfAnnotationStore, [
        'activeAnnotation',
        'annotationModalMode',
      ]),
      ...mapState(useProjectStore, [
        'currentProject',
        'readOnlyMode',
      ]),
      /**
       * Returns all annotations for this value
       *
       * @returns {Array}
       */
      annotations() {
        return this.annotationsByPlanDesignValueIds(this.value.ids);
      },
      /**
       * Returns an array of the container IDs that are either included in this value row or unused for this attribute.
       *
       * @returns {Array}
       */
      availableContainers() {
        return this.currentContainers
          .map((container) => {
            const included = this.value.project_products_container_ids.includes(container.id);
            const isUnused = this.unusedContainerIds.includes(container.id);

            return included || isUnused
              ? container
              : null;
          })
          .filter((container) => container)
          .sort((a, b) => a.position - b.position);
      },
      /**
       * Returns a mapped array of the names (or IDs for class based products) used to create this.displayValue.
       *
       * @returns {Array}
       */
      displayValues() {
        return this.availableContainers.reduce((displayValues, { id, description, name }) => {
          let displayValue;

          if (this.valueContainerIds.includes(id)) {
            displayValue = this.isClassBased ? Number(name) : description;
          }

          return displayValue ? displayValues.concat(displayValue) : displayValues;
        }, []);
      },
      /**
       * Returns a computed string based on the mixture IDs/labels of displayValues used in the template block.
       *
       * @returns {string}
       */
      displayValue() {
        let label;

        if (this.currentContainers.length === this.displayValues.length) {
          label = 'All';
        } else if (!this.displayValues.some(Number.isNaN)) {
          // this is array of only numbers so lets roll them up
          label = getRanges(this.displayValues).join(', ');
        } else if (this.displayValues.length > 1) {
          label = '(Multi)';
        } else {
          label = this.displayValues.join(', ');
        }

        return label;
      },
      /**
       * Returns a boolean based on if this is the first subtype row of a tier group.
       *
       * @returns {boolean}
       */
      fauxTopCell() {
        return !this.valueIndex && this.value.tier_subtype_id;
      },
      /**
       * Returns boolean based on if this row is active through here or from the annotations.
       *
       * @returns {boolean}
       */
      isActiveRow() {
        // I think we can use this.activeValueIds.filter(x => !this.value.ids.includes(x)) instead of xor but I'll need to test.
        return this.attributeId === this.activeAttributeId && !xor(this.activeValueIds, this.value.ids).length;
      },
      /**
       * Returns the validation type for this attribute.
       *
       * @returns {boolean}
       */
      validationType() {
        return ValidationUtil.getValidationType(this.attributeName);
      },
    },
    watch: {
      value: {
        /**
         * If an annotation is made, this component needs to set the localValue
         * the new PlanDesignValue that was created in the modal to get a correct
         * two-way flow.
         *
         * @param {object} newValue
         */
        handler(newValue) {
          this.$set(this, 'localValue', newValue);
        },
        deep: true,
        immediate: true,
      },
    },
    mounted() {
      if (this.hiddenAttribute) {
        return;
      }

      this.currentContainers.forEach((container) => {
        if (this.value.project_products_container_ids.includes(container.id)) {
          this.valueContainerIds.push(container.id);
        }
      });
    },
    methods: {
      ...mapActions(usePdfAnnotationStore, ['closeAnnotationModal']),
      ...mapActions(useProductSelectionsStore, ['setActiveIds']),
      /**
       * Show the first annotation by the userType specified (automated = TE)
       *
       * @param {boolean} automated
       */
      showFirstAnnotation(automated = false) {
        const firstAnnotationIndex = this.annotations.findIndex((annotation) => annotation.automated === automated);

        this.showAnnotation(firstAnnotationIndex);
      },
      /**
       * Show the annotation by index
       *
       * @param {number} index
       */
      showAnnotation(index = 0) {
        this.goToAnnotation = this.annotations[index];
        this.activeAnnotation = this.annotations[index];

        if (!this.isActive) {
          this.setActiveValueRow();
        }

        // if we are currently in create mode, AND in readOnly mode,
        // selecting an existing annotation through Value
        // should close the Annotation Modal
        if (this.readOnlyMode && this.annotationModalMode === 'create') {
          this.closeAnnotationModal();
        } else {
          // if there's an annotation and we're not in readOnly mode,
          // let's show it
          this.annotationModalMode = 'view';
        }
      },
      /**
       * Set active information about this attribute
       */
      setActiveValueRow() {
        if (this.activeRow || this.hiddenAttribute || this.missingContainers) {
          return;
        }

        if (
          this.activeAttributeId !== this.attributeId
          || this.activeCategoryId !== this.category.id
          || JSON.stringify(this.activeValueIds) !== JSON.stringify(this.value.ids)
        ) {
          this.setActiveIds({
            attributeId: this.attributeId,
            categoryId: this.category.id,
            productId: this.selectedProductId,
            valueIds: this.value.ids,
          });

          if (this.annotations.length) {
            this.showAnnotation();
          } else {
            this.goToAnnotation = null;
            this.closeAnnotationModal();
          }
        }
      },
      /**
       * Save a plan design value after it changes
       *
       * @param {object} value
       * @param {string} newValue
       */
      async onValueChange(value, newValue) {
        if (value.project_products_container_ids.length) {
          this.localValue.value = newValue;
          await ProductService.updatePlanValue({
            attributeId: this.attributeId,
            containerIds: value.project_products_container_ids,
            productId: this.selectedProductId,
            subtypeId: value.tier_subtype_id,
            tierGroupId: value.tier_group_id,
            value: newValue,
          });
        }
      },
      /**
       * Fires when the containers TfMultiSelect is hidden - we then figure out if containers were removed
       * and add them to a blank row or add the container ID to the current value.
       *
       * @param {object} $event
       */
      containersHide($event) {
        const newItems = $event.value.filter((id) => !$event.initialValues.includes(id));
        const removedItems = $event.initialValues.filter((id) => !$event.value.includes(id));

        if (!newItems.length && !removedItems.length) {
          return;
        }

        this.$emit('containerChange', {
          newItems,
          removedItems,
          values: $event.value,
        });
      },
      /**
       * Validate the values of this component and return the errors found in the promise.
       *
       * @returns {Promise}
       */
      validateValues() {
        return new Promise((resolve) => {
          let errorsFound = 0;

          // Test the container dropdown
          this.$refs['container-form'].validate((valid) => {
            if (!valid) {
              errorsFound += 1;
            }
          });
          // Test the value dropdown
          this.$refs['value-form'].validate((valid) => {
            if (!valid) {
              errorsFound += 1;
            }
          });
          resolve(errorsFound);
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .annotation-marker-cell {
    width: 0;
  }

  .active {
    box-shadow: inset -2px -2px 0 0 var(--tf-green), inset 3px 2px 0 0 var(--tf-green);
  }

  .table-child,
  .table-parent {
    tr {
      &:not(.no-border) {
        border-bottom: 1px var(--tf-gray-light-medium) solid;
      }
    }

    td {
      padding: 15px 5px;
      vertical-align: top;
      position: relative;

      &:first-child {
        padding: 0;
      }

      &:nth-child(2) {
        padding-left: 19px;
      }

      &:last-child {
        padding-right: 13px;
      }

      &.has-faux-top-cell {
        padding-top: 49px;
      }
    }
  }

  /* annotation maker positioning for subtype groups that are taller due to there being a dropdown and an input field */
  .has-faux-top-cell .annotation-marker-group {
    padding-top: 39px;
  }

  .el-form-item {
    margin-bottom: 0;
  }

  .multi-select-read-only-label {
    line-height: 25px;
    padding-left: 15px;
  }

  .plan-design-read-only-value {
    display: inline-block;
  }

  .read-only-row {
    cursor: pointer;
  }

  .subtype-name {
    display: block;
    margin-top: 3px;
    margin-left: 12px;

    &.read-only {
      margin-left: 0;
    }
  }

  .subtype-read-only-label + .subtype-name,
  .el-select + .subtype-name {
    margin-top: 13px;
  }

  .subtype-read-only-label {
    display: block;
    width: 144px;
  }

  .subtype-select {
    width: 144px;
  }

  .value-cell {
    width: 46%;
  }

  .value-container-cell {
    min-width: 110px;

    :deep(.empty-text) {
      display: block;
      padding: 5px;
      line-height: 16px;
    }
  }

  :deep(.el-form-item__error) {
    position: relative;
    padding-top: 4px;
  }
</style>
