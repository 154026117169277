import { defineStore } from 'pinia';
import { useFileViewerStore } from '@/stores/fileViewer.js';
import { useProjectStore } from '@/stores/project.js';

export const useFilesStore = defineStore('files', {
  state: () => ({
    sources: [],
  }),
  getters: {
    /**
     * Get a concatenated array of all VIEWABLE real source ids
     *
     * @param {object} state
     * @returns {Array}
     */
    viewableFileIds: (state) => {
      const projectStore = useProjectStore();

      return [
        // actual sources
        ...state.sources
          .filter(({ status }) => status === 'conversion_completed')
          .map(({ id }) => id),
        ...['new-rfp-responses'],
        ...projectStore.hasNoPriorCoverage ? ['npc-request-details'] : [],
      ];
    },
    /**
     * Determine if any of the sources are still being converted
     *
     * @param {object} state
     * @returns {boolean}
     */
    hasPendingSources: (state) => {
      const pendingStatuses = [
        'compressing_source',
        'conversion_pending',
        'conversion_started',
      ];

      return state.sources.some(({ status }) => pendingStatuses.includes(status));
    },
    /**
     * Generate a collection of all active (open) sources and faux-sources
     *
     * @param {object} state
     * @returns {Array}
     */
    fileTabs: (state) => {
      const fileTabs = [];
      const fileViewerStore = useFileViewerStore();
      const projectStore = useProjectStore();
      const projectOpenFileIds = fileViewerStore.openFileIds[projectStore.currentProject.id] || [];
      const createFileTab = (source) => ({
        ...source,
        label: source.name,
        name: `${source.id}`,
      });

      if (projectStore.currentProject?.id && projectOpenFileIds) {
        // real sources
        for (const source of state.sources) {
          if (projectOpenFileIds.includes(source.id)) {
            fileTabs.push(
              createFileTab(source),
            );
          }
        }

        // New RFP Responses
        if (projectOpenFileIds.includes('new-rfp-responses')) {
          fileTabs.push(
            createFileTab({
              name: 'Broker Info',
              id: 'new-rfp-responses',
            }),
          );
        }

        if (
          projectOpenFileIds.includes('npc-request-details')
          && projectStore.currentProject?.project_products.some((
            { alternative, prior_coverage: priorCoverage },
          ) => !priorCoverage && !alternative)
        ) {
          fileTabs.push(
            createFileTab({
              name: 'No prior coverage request details',
              id: 'npc-request-details',
            }),
          );
        }
      }

      return fileTabs;
    },
  },
  actions: {
    /**
     * Switch away from the currently visible source (used when activeSource is closed)
     *
     * @param {number} sourceId
     */
    selectAnotherTab(sourceId) {
      // we are closing the active source,
      // so the fileViewer needs to switch to another source
      let newActiveTab;
      const tabIndex = this.fileTabs.findIndex((tab) => tab.id === sourceId);
      const fileViewerStore = useFileViewerStore();
      const projectStore = useProjectStore();

      if (this.fileTabs[tabIndex + 1]) {
        // closing active tab, and there's one after it
        newActiveTab = `${this.fileTabs[tabIndex + 1].id}`;
      } else if (this.fileTabs[tabIndex - 1]) {
        // closing active tab, and there's a tab before it
        newActiveTab = `${this.fileTabs[tabIndex - 1].id}`;
      } else {
        newActiveTab = null;
      }
      this.activeTab = newActiveTab;

      fileViewerStore.setActiveFileId({
        projectId: projectStore.currentProject.id,
        fileId: newActiveTab,
      });
    },
    /**
     * Add a newly uploaded source to the store
     *
     * @param {object} source
     */
    addUploadedSource(source) {
      this.sources.push(source);
    },
    /**
     * Change category of source
     *
     * @param {object} payload
     * @param {number} payload.sourceId
     * @param {string} payload.toCategoryType
     * @param {string} payload.fromCategoryType
     * @param {boolean} payload.moveBack
     */
    moveSource({
      sourceId,
      toCategoryType,
      fromCategoryType,
      moveBack,
    }) {
      const source = this.sources.find(({ id }) => id === sourceId);

      if (!moveBack) {
        source.type = toCategoryType;
        source.moved = fromCategoryType;
      } else {
        source.type = source.moved;
        delete source.moved;
      }
    },
    /**
     * Remove an uploaded source by way of the cancel button
     *
     * @param {number} sourceId
     */
    removeUploadedSource(sourceId) {
      const sourceIndex = this.sources.findIndex((item) => item.id === sourceId);

      this.sources.splice(sourceIndex, 1);
    },
    /**
     * Change name of source in store
     *
     * @param {object} payload
     * @param {number} payload.sourceId
     * @param {string} payload.name
     */
    renameSource({
      sourceId,
      name,
    }) {
      const source = this.sources.find((item) => item.id === sourceId);

      source.name = name;
    },
    /**
     * Store project sources
     *
     * @param {Array} sources
     */
    setSources(sources) {
      const newSources = sources
        .map((source) => {
          /* TODO RLH - Revisit to identify if we can make a copy of the source object without breaking things
          Current code appeases no-param-reassign Linter rule, but is still passing a reference of the object */
          const updatedSource = source;

          updatedSource.id = `${source.id}`;

          return updatedSource;
        })
        .sort((a, b) => a.name.localeCompare(b.name));

      this.sources = newSources;
    },
  },
});
