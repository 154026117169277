const getEnv = (name) => {
  const env = {
    VUE_APP_ANALYTICS: import.meta.env.VUE_APP_ANALYTICS,
    VUE_APP_ANALYTICS_DISABLED: import.meta.env.VUE_APP_ANALYTICS_DISABLED,
    VUE_APP_API_URL: import.meta.env.VUE_APP_API_URL,
    VUE_APP_BROKER_URL: import.meta.env.VUE_APP_BROKER_URL,
    VUE_APP_COOKIE_NAMESPACE: import.meta.env.VUE_APP_COOKIE_NAMESPACE,
    VUE_APP_ENV: import.meta.env.VUE_APP_ENV,
    VUE_APP_FEATURE_FLAGS: import.meta.env.VUE_APP_FEATURE_FLAGS,
    VUE_APP_FEATURE_FLAGS_DISABLED: import.meta.env.VUE_APP_FEATURE_FLAGS_DISABLED,
    VUE_APP_FORCE_SSO: import.meta.env.VUE_APP_FORCE_SSO,
    VUE_APP_OAUTH_CLIENT_ID: import.meta.env.VUE_APP_OAUTH_CLIENT_ID,
    VUE_APP_SEGMENT_TOKEN: import.meta.env.VUE_APP_SEGMENT_TOKEN,
    VUE_APP_VERSION: import.meta.env.VUE_APP_VERSION,
  };

  return window?.__RUNTIME_CONFIG__?.[name] || env[name];
};
const getConfig = () => {
  const config = {
    VUE_APP_ANALYTICS: getEnv('VUE_APP_ANALYTICS'),
    VUE_APP_ANALYTICS_DISABLED: getEnv('VUE_APP_ANALYTICS_DISABLED'),
    VUE_APP_API_URL: getEnv('VUE_APP_API_URL'),
    VUE_APP_BROKER_URL: getEnv('VUE_APP_BROKER_URL'),
    VUE_APP_COOKIE_NAMESPACE: getEnv('VUE_APP_COOKIE_NAMESPACE'),
    VUE_APP_ENV: getEnv('VUE_APP_ENV'),
    VUE_APP_FEATURE_FLAGS: getEnv('VUE_APP_FEATURE_FLAGS'),
    VUE_APP_FEATURE_FLAGS_DISABLED: getEnv('VUE_APP_FEATURE_FLAGS_DISABLED'),
    VUE_APP_FORCE_SSO: getEnv('VUE_APP_FORCE_SSO'),
    VUE_APP_OAUTH_CLIENT_ID: getEnv('VUE_APP_OAUTH_CLIENT_ID'),
    VUE_APP_SEGMENT_TOKEN: getEnv('VUE_APP_SEGMENT_TOKEN'),
    VUE_APP_VERSION: getEnv('VUE_APP_VERSION'),
  };
  const analyticsDisabled = ['true', '1'].includes(config.VUE_APP_ANALYTICS_DISABLED);
  const featureFlagsDisabled = ['true', '1'].includes(config.VUE_APP_FEATURE_FLAGS_DISABLED);

  return {
    ...config,
    analyticsEnabled: (enabledStages = []) => !analyticsDisabled && enabledStages.includes(config.VUE_APP_ENV),
    featureFlagsDisabled: (disabledStages = []) => featureFlagsDisabled || disabledStages.includes(config.VUE_APP_ENV),
  };
};
const config = getConfig();

export { config };
