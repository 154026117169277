<template>
  <ConfirmTextModal
    title="Complete project"
    :subtitle="employerName"
    confirm-text="COMPLETE"
    data-test="complete project modal"
    @save="onSave"
    @modalClosed="$emit('modalClosed')"
  >
    <p>
      You will <strong>not</strong> be able to invite carriers, submit new bids, or edit this project if you set it to <strong>complete</strong>.
    </p>
  </ConfirmTextModal>
</template>

<script>
  import { setProjectState } from '@/services/project.js';
  import ConfirmTextModal from './ConfirmTextModal.vue';

  /**
   * @vuedoc
   * @exports ModalCompleteProject
   * @category Components
   */
  export default {
    name: 'ModalCompleteProject',
    components: {
      ConfirmTextModal,
    },
    props: {
      employerName: {
        type: String,
        required: true,
        default: '',
      },
      projectId: {
        type: Number,
        required: true,
        default: 0,
      },
    },
    methods: {
      /**
       * Set the project state to 'complete_rfp', trigger a message, and emit appropriately.
       */
      async onSave() {
        try {
          const response = await setProjectState(this.projectId, 'complete_rfp');

          this.displayToast({
            message: `${this.employerName} has been marked complete.`,
            type: 'success',
          });
          this.$emit('projectStateChange', response.project);
        } catch ({ data }) {
          this.displayToast({
            message: data?.message ?? `There was an error completing ${this.employerName} (${this.projectId}).`,
          });

          this.$emit('error', this.projectId);
        }
      },
    },
  };
</script>
