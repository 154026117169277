<template>
  <div
    id="invitable-carriers"
    v-loading="!isLoaded"
  >
    <div class="btn-group align-end add-carrier-button">
      <AppButton
        data-test="add carrier"
        type="primary"
        size="medium"
        icon="fa-solid fa-plus"
        text="Add new carrier"
        @click="addNewInvitableCarrierRow"
      />
    </div>
    <table
      v-if="isLoaded"
      :class="{ 'empty-table': !broker.carriers.length && !addingInvitableCarrier }"
      class="table table-parent"
    >
      <thead>
        <tr>
          <th width="150">
            Carrier name
          </th>
          <th width="150">
            Preferred carrier
          </th>
          <th>
            Offered product types
          </th>
          <th width="150" />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(newCarrier, carrierIndex) in newInvitableCarriers"
          :key="newCarrier.id"
        >
          <td>
            <ElSelect
              v-model="newCarrier.selection"
              value-key="id"
              class="carrier-select-dropdown"
              :popper-append-to-body="false"
              :popper-class="'carrier-select-popper'"
              :placement="'carrier-popper'"
              filterable
              placeholder="Select carrier"
              data-test="select carrier"
              no-match-text="No matching carriers"
            >
              <ElOption
                v-for="carrier in remainingCarriers"
                :key="carrier.id"
                :label="carrier.name"
                :data-test="`${carrier.name.toLowerCase()} carrier`"
                :value="carrier"
              />
            </ElSelect>
          </td>
          <td>
            <ElSwitch
              v-model="newCarrier.preferred"
              :disabled="!newCarrier.id"
              data-test="new carrier toggle preferred"
            />
          </td>
          <td />
          <td>
            <div class="btn-group align-end">
              <AppButton
                type="secondary"
                icon="fa-solid fa-times"
                size="icon"
                data-test="cancel cta"
                @click="deleteNewCarrierRow(carrierIndex)"
              />
              <AppButton
                :is-disabled="isSubmitDisabled(carrierIndex)"
                type="affirm"
                icon="fa-solid fa-check"
                size="icon"
                data-test="check cta"
                @click="addNewCarrier(carrierIndex)"
              />
            </div>
          </td>
        </tr>
        <tr
          v-for="carrier in broker.carriers"
          :key="carrier.id"
          :data-test="`${carrier.name.toLowerCase()} row`"
        >
          <td
            v-text="carrier.name"
          />
          <td>
            <ElSwitch
              :value="carrier.preferred"
              :data-test="`${carrier.name.toLowerCase()} toggle preferred`"
              @change="toggleCarrierPreferredStatus(carrier)"
            />
          </td>
          <td>
            <ul class="offered-products-list">
              <li
                v-for="productType in carrier.offered_product_types"
                :key="productType.id"
              >
                {{ productType.name }}
                <TfBadge
                  v-if="!productType.certified"
                  value="not certified"
                  size="small"
                />
                <TfBadge
                  v-if="!productType.available_to_broker"
                  value="not available to broker"
                  size="small"
                  status="error"
                />
              </li>
            </ul>
          </td>
          <td>
            <div class="btn-group align-end">
              <AppButton
                type="decline"
                icon="fa-solid fa-trash-alt"
                size="text-small"
                :data-test="`remove ${carrier.name.toLowerCase()}`"
                text="Remove carrier"
                @click="removeCarrier(carrier)"
              />
            </div>
          </td>
        </tr>
        <tr v-if="!broker.carriers.length">
          <td colspan="4">
            There are no carriers associated with this broker.
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  // pinia
  import { mapWritableState, mapState } from 'pinia';
  // vue
  import { useCarriersStore } from '@/stores/carriers.js';
  import { useBrokersStore } from '@/stores/brokers.js';
  import BrokerService from '@/services/broker.js';

  /**
   * List/edit/add/delete view for invitable carriers at the broker level
   *
   * @vuedoc
   * @exports EditBrokerInvitableCarriers
   * @category Views
   */
  export default {
    name: 'EditBrokerInvitableCarriers',
    props: {
      brokerId: {
        type: [Number, String],
        default: null,
      },
    },
    data() {
      return {
        addingInvitableCarrier: false,
        newInvitableCarriers: [],
        broker: {},
        isLoaded: false,
      };
    },
    computed: {
      ...mapWritableState(useBrokersStore, ['currentBrokerId']),
      ...mapState(useCarriersStore, ['getAvailableCarriers']),
      /**
       * The "invitableCarriers" from the store minus any carriers already included as "invitableCarriers"
       *
       * @returns {Array}
       */
      remainingCarriers() {
        return this.getAvailableCarriers.filter(
          (availableCarrier) => !this.broker.carriers.some((invitableCarrier) => invitableCarrier.id === availableCarrier.id),
        ).filter(
          (availableCarrier) => !this.newInvitableCarriers.map(({ selection }) => selection).includes(availableCarrier),
        );
      },
    },
    created() {
      this.currentBrokerId = this.brokerId;

      /**
       * The call to get ALL brokers does not include the broker.carriers key
       * so we need to fetch the individual broker record on created to get those
       * carrier records.
       */
      BrokerService
        .getBroker(this.brokerId)
        .then(({ broker }) => {
          this.$set(this, 'broker', broker);
          this.isLoaded = true;
        })
        .catch(() => {
          this.displayToast({
            message: 'Could not retrieve broker.',
          });
        });
    },
    methods: {
      /**
       * Add new product row
       */
      addNewInvitableCarrierRow() {
        this.newInvitableCarriers.push({
          selection: null,
          certified: false,
        });
      },
      /**
       * Cancel Adding a new invitable carrier
       *
       * @param {number} index
       */
      deleteNewCarrierRow(index) {
        this.newInvitableCarriers.splice(index, 1);
      },
      /**
       * Add the newInvitableCarrier to the invitableCarrier array
       *
       * @param {number} index
       */
      addNewCarrier(index) {
        BrokerService
          .saveNewBrokerInvitableCarrier({
            brokerId: this.brokerId,
            carrierId: this.newInvitableCarriers[index].selection.id,
            preferred: !!this.newInvitableCarriers[index].preferred,
          })
          .then(({ broker }) => {
            /**
             * Update the local broker object
             */
            this.newInvitableCarriers.splice(index, 1);
            this.$set(this, 'broker', broker);
          })
          .catch(() => {
            this.displayToast({
              message: 'There was an error saving the carrier.',
            });
          });
      },
      /**
       * Update an existing invitableCarrier's preferred status
       *
       * @param {object} carrier
       */
      toggleCarrierPreferredStatus(carrier) {
        const updatedCarrier = {
          ...carrier,
          preferred: !carrier.preferred,
        };

        BrokerService
          .updateBrokerInvitableCarrierPreferredStatus(this.brokerId, updatedCarrier)
          .then(({ broker }) => {
            /**
             * Update the local broker object
             */
            this.$set(this, 'broker', broker);
          })
          .catch(() => {
            this.displayToast({
              message: 'There was an error updating the carrier.',
            });
          });
      },
      /**
       * Remove an invitableCarrier from the invitableCarrier array
       *
       * @param {object} removedCarrier
       */
      removeCarrier(removedCarrier) {
        // eslint-disable-next-line no-alert
        const confirmation = window.confirm('Delete carrier. Are you sure?');

        if (confirmation) {
          BrokerService
            .deleteBrokerInvitableCarrier(this.brokerId, removedCarrier.id)
            .then(({ broker }) => {
              /**
               * Update the local broker object
               */
              this.$set(this, 'broker', broker);
            })
            .catch(() => {
              this.displayToast({
                message: 'There was an error deleting the carrier.',
              });
            });
        }
      },
      /**
       * Returns whether the save button is disabled
       *
       * @param {number} index
       * @returns {boolean}
       */
      isSubmitDisabled(index) {
        return this.newInvitableCarriers.length > index && !this.newInvitableCarriers[index].selection;
      },
    },
  };
</script>

<style lang="scss" scoped>
.add-carrier-button {
  margin-bottom: 20px;
}

.tf-badge {
  margin-left: 8px;
}
</style>
<style>
/* stylelint-disable declaration-no-important */
.carrier-select-popper {
  left: 0 !important;
}
/* stylelint-enable declaration-no-important */
</style>
