<template>
  <LfcApiTableParentRow
    data-test="table row"
    :row-data="rowData"
  >
    <td
      v-for="column in columns"
      :key="column.id"
      :data-test="`${column.prop} cell`"
    >
      <TableCell
        v-bind="{
          column,
          rowData,
        }"
        @changeProjectOwner="changeProductOwner"
      />
    </td>
  </LfcApiTableParentRow>
</template>

<script>
  import TableCell from '@/components/Dashboard/TableCell.vue';
  import ProductService from '@/services/product.js';
  // Misc
  import messages from '@/mixins/messages.js';

  /**
   * Child table row
   *
   * @vuedoc
   * @exports ProductRow
   * @category Components
   */
  export default {
    name: 'ProductRow',
    components: {
      TableCell,
    },
    mixins: [
      messages,
    ],
    inject: ['DashboardTable'],
    props: {
      columns: {
        type: Array,
        required: true,
      },
      rowData: {
        type: Object,
        required: true,
      },
    },
    methods: {
      /**
       * Change the project owner based on userId.
       *
       * @param {number} userId
       */
      changeProductOwner(userId) {
        ProductService
          .changeProductOwner(
            this.rowData.inforce_product_id,
            { wt_owner_id: userId },
          ).then(() => {
            let fullName;

            if (userId) {
              const { first_name: first, last_name: last } = this.DashboardTable.adminUsers.find(({ id }) => id === userId);

              fullName = `${first} ${last}`;
            } else {
              fullName = null;
            }

            this.onPropUpdate({
              subject: this.rowData.label,
              label: 'Collaborator',
              type: 'success',
              value: fullName || 'Un-assigned',
            });

            this.$emit('productPropChanged', {
              id: this.rowData.project_id, prop: 'wt_owner_id', value: userId, name: fullName, productId: this.rowData.inforce_product_id,
            });
          }).catch(() => {
            this.$emit('createMessage', { label: 'Collaborator', type: 'error' });
          });
      },
    },
  };
</script>
