<template>
  <div
    v-drag-and-drop:options="dragAndDropOptions"
    class="category"
    data-test="category"
    @dragend="handleDragEnd"
  >
    <div
      class="heading-wrapper"
      @dragenter="handleDragEnter($event)"
    >
      <!-- Heading -->
      <h3 :class="`heading-${category.label}`">
        {{ category.label }}
      </h3>
      <!-- Uploader overlay -->
      <div
        v-if="!disableUploader && !category.disableUpload"
        :class="(hovered && uploading === false) ? 'over' : ''"
        class="uploader"
        data-test="uploader"
        @dragleave="handleDragLeave"
      >
        <ElUpload
          :ref="`el-upload`"
          :show-file-list="false"
          class="uploader-inner"
          :accept="acceptableFileExtensions.join(',')"
          :data="{ type: category.type }"
          :data-category-type="category.type"
          :action="uploadAction"
          :headers="uploadHeaders"
          :before-upload="handleBeforeUpload"
          :on-progress="handleUploadProgress"
          :on-error="handleUploadError"
          :on-success="handleUploadSuccess"
          multiple
          name="files[]"
          data-test="file upload"
        />
      </div>
      <div v-if="!category.disableUpload">
        <AppButton
          v-if="!category.disableUpload"
          :key="`upload-${category.type}`"
          type="primary"
          icon="fa-regular fa-arrow-up-from-line"
          size="text-small"
          text="Upload file(s)"
          data-test="upload button"
          @click="handleUploadButtonClick(category.type)"
        />
      </div>
    </div>
    <!-- Error overlay -->
    <div
      v-if="uploadError"
      class="upload-errors"
      data-test="errors"
    >
      <div class="errors-inner">
        <div class="error-header">
          <span
            @click="handleCloseError(category)"
          >
            <AppIcon
              icon="fa-solid fa-xmark"
              class="close-error"
              data-test="close error"
            />
          </span>
          <span class="upload-error-heading">
            <AppIcon
              icon="fa-solid fa-file-xmark"
              class="file-error"
              alt="Upload error"
            />
            <strong>{{ uploadError.heading }}</strong>
          </span>
        </div>
        <p>
          {{ uploadError.message }}<br>
          <a
            href="#"
            data-test="select new file"
            @click.prevent="handleUploadButtonClick(category.type)"
          >Select a new file</a> and try again.
        </p>
      </div>
    </div>
    <div class="list-wrapper">
      <ul
        :key="`list-${category.type}`"
        :data-id="category.type"
        :class="{ 'list-disabled': category.disableUpload }"
        class="list"
        data-test="list ul"
        @dragenter="handleDragEnter($event)"
      >
        <FileManagerCategoryItem
          v-for="item in localSources"
          :key="item.id"
          :category-type="category.type"
          :edit-state="editStates[item.id]"
          :editable-names="editableNames"
          :error-states="errorStates"
          :item="item"
          :project-id="projectId"
          :open="openFileIds.includes(item.id)"
          @editStateChange="setEditState"
          @canClose="handleItemCanClose"
        />
      </ul>
      <ul data-test="uploading files">
        <li
          v-for="file in uploadingFiles"
          :key="`progress-${category.type}-${file.uid}`"
          class="file upload"
        >
          <div class="label-wrapper">
            <span class="label">{{ file.name }}</span>
            <ElProgress
              :text-inside="true"
              :stroke-width="18"
              :percentage="file.progress"
            />
            <AppButton
              type="decline"
              icon="fa-solid fa-times"
              size="icon"
              data-test="cancel upload"
              @click="handleCancelUpload(file)"
            />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import {
    reusableAcceptableUploadFileExtensions,
    reusableAcceptableUploadFileTypes,
  } from '@watchtowerbenefits/es-utils-public';

  import { mapActions } from 'pinia';
  import { useFilesStore } from '@/stores/files.js';
  import { cloneDeep, includes } from 'lodash';
  import FileManagerCategoryItem from '@/components/FileViewer/FileManager/CategoryItem.vue';

  /**
   * File Manager Categories
   *
   * @vuedoc
   * @exports FileManagerCategory
   * @category Components
   */
  export default {
    name: 'FileManagerCategory',
    components: {
      FileManagerCategoryItem,
    },
    props: {
      category: {
        type: Object,
        default: () => ({}),
      },
      disableUploader: {
        type: Boolean,
        default: () => false,
      },
      hovered: {
        type: Boolean,
        default: () => false,
      },
      onDragStart: {
        type: Function,
        default: () => ({}),
      },
      onDragEnd: {
        type: Function,
        default: () => ({}),
      },
      onDragEnter: {
        type: Function,
        default: () => ({}),
      },
      projectId: {
        type: Number,
        default: () => null,
      },
      openFileIds: {
        type: Array,
        default: () => [],
      },
      uploadAction: {
        type: String,
        default: () => '',
      },
      uploadHeaders: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        acceptableFileExtensions: reusableAcceptableUploadFileExtensions(),
        acceptableFileTypes: reusableAcceptableUploadFileTypes(),
        cannotCloseItems: [],
        dragAndDropOptions: {
          multipleDropzonesItemsDraggingEnabled: false,
          dropzoneSelector: 'ul.list:not(.list-disabled)',
          draggableSelector: 'li.edit',
          handlerSelector: '.handle',
          /**
           * This method is specific to the vue-draggable component, but it ultimately
           * calls onDragStart, which is passed from the parent component.
           *
           * @param {Event} event
           */
          onDragstart(event) {
            const itemId = event.items[0].getAttribute('data-id');
            const source = this.category.sources.find(({ id }) => id === itemId);

            this.onDragStart(source, this);
          },
        },
        editStates: {},
        editableNames: {},
        errorStates: {},
        localSources: [],
        uploading: false,
        uploadError: null,
        uploadingFiles: [],
      };
    },
    computed: {
      /**
       * Check if the user is in the middle of uploading or editing a file (and don't allow the FileManager to close)
       *
       * @returns {boolean}
       */
      canClose() {
        return !this.cannotCloseItems.length;
      },
    },
    watch: {
      'category.sources': {
        /**
         * When the category sources get updated from the parent we want to clone the sources so we can modify them locally
         * We then run though this and set a list of "editableNames" so we can check that later
         */
        handler() {
          this.localSources = cloneDeep(this.category.sources);
        },
        immediate: true,
      },
    },
    methods: {
      ...mapActions(useFilesStore, ['addUploadedSource']),
      /**
       * Called when an item emits 'canClose' - we track the items that can't be closed so we don't close the FileManager
       *
       * @param {object} options
       * @param {boolean} options.canClose
       * @param {number} options.sourceId
       */
      handleItemCanClose({ canClose, sourceId }) {
        if (!canClose && !this.cannotCloseItems.includes(sourceId)) {
          this.cannotCloseItems.push(sourceId);
        } else if (canClose && this.cannotCloseItems.includes(sourceId)) {
          const itemIndex = this.cannotCloseItems.findIndex((item) => item.id === sourceId);

          this.cannotCloseItems.splice(itemIndex, 1);
        }
      },
      /**
       * Set the hovered state of this component, this is needed so we can show/disable the drag messaging
       * This needs to be it's own method so the parent component can set the hovered status
       *
       * @param {boolean} hoveredState
       */
      setHovered(hoveredState) {
        this.$emit('hover', {
          categoryType: this.category.type,
          hoveredState,
        });
      },
      /**
       * Add an 'edit state' to a specific item
       * This needs to be it's own method so the parent component can set this when we're moving files around
       *
       * @param {number} sourceId
       * @param {string} state
       */
      setEditState(sourceId, state) {
        this.$set(this.editStates, sourceId, state);
      },
      /**
       * Do some validation on a file before we try to upload it
       *
       * @param {object} file
       * @returns {boolean}
       */
      handleBeforeUpload(file) {
        let valid = false;
        let error = {};
        const extensionArray = file.name.split('.');
        const extension = extensionArray[extensionArray.length - 1].toLowerCase();
        const acceptableFileExtensions = `${this.acceptableFileExtensions.slice(0, -1).join(', ')
        } or ${
          this.acceptableFileExtensions.slice(-1)}`;
        const errorMessage = `Uploads must be ${acceptableFileExtensions} format.`;

        if (file.size > 26214400) {
          valid = false;
          error = {
            heading: 'File too large',
            message: 'Uploads must be 25MB or less.',
          };
        } else if (file.raw && file.raw.type) {
          valid = includes(this.acceptableFileTypes, file.raw.type);
          if (!valid) {
            error = {
              heading: 'Incompatible file type',
              message: errorMessage,
            };
          }
        } else {
          valid = includes(this.acceptableFileExtensions, `.${extension}`);
          if (!valid) {
            error = {
              heading: 'Incompatible file type',
              message: errorMessage,
            };
          }
        }

        if (!valid) {
          this.$set(
            this,
            'uploadError',
            error,
          );

          return false;
        }

        this.$set(
          this,
          'uploadError',
          null,
        );

        const source = {
          type: this.category.type,
          uid: file.uid,
          name: file.name,
          uploadingFile: file,
          progress: 0,
        };
        // Probably pointless, but maybe worth tracking for debugging…is the file with this uid already found in the category's files array?
        const fileFound = this.localSources.find((existingFile) => existingFile === file.uid);

        this.uploading = true;
        if (!fileFound) {
          this.uploadingFiles.push(source);
        }

        this.setHovered(false);

        return true;
      },
      /**
       * Cancel an upload that is in progress
       *
       * @param {number} fileUid
       */
      handleCancelUpload(fileUid) {
        const file = this.uploadingFiles.find((uploadingFile) => fileUid === uploadingFile.uid);
        const fileIndex = this.uploadingFiles.findIndex((uploadingFile) => fileUid === uploadingFile.uid);
        const ref = this.$refs['el-upload'];

        ref.abort(file);
        this.uploadingFiles.splice(fileIndex, 1);
        // is everything for this sourceType done uploading? this impacts visual treatment of the category
        if (this.uploadingFiles.length === 0) {
          this.uploading = false;
        }
      },
      /**
       * Close the upload error message
       */
      handleCloseError() {
        this.$set(
          this,
          'uploadError',
          null,
        );
      },
      /**
       * When the user starts dragging we change the hover status and call the onDragEnter callback we got as a prop
       */
      handleDragEnter() {
        this.setHovered(true);
        this.onDragEnter(this);
      },
      /**
       * When the user drags outside of this component we set the hover status to false
       */
      handleDragLeave() {
        this.setHovered(false);
      },
      /**
       * When the user ends their drag we need to call the onDragEnd prop we got from the parent
       */
      handleDragEnd() {
        this.onDragEnd(this.category);
        this.setHovered(false);
      },
      /**
       * If the user clicks the upload button we have to invoke a click on the element uploader
       */
      handleUploadButtonClick() {
        this.uploadErrorImage = null;
        this.$refs['el-upload']
          .$el.querySelector('.el-upload')
          .click();
      },
      /**
       * Set the upload error
       */
      handleUploadError() {
        this.$set(
          this,
          'uploadError',
          {
            heading: 'Unknown error occurred',
            message: 'Please contact administrator if this persists',
          },
        );
      },
      /**
       * Update the upload progress on an uploading file
       *
       * @param {Event} event
       * @param {object} file
       */
      handleUploadProgress(event, file) {
        const index = this.uploadingFiles.findIndex(({ uid }) => uid === file.uid);

        if (index > -1) {
          this.uploadingFiles[index].percentage = file.percentage;
        }
      },
      /**
       * Handle a successful upload
       *
       * @param {Event} event
       * @param {object} file
       */
      handleUploadSuccess(event, file) {
        const uploadingFileIndex = this.uploadingFiles.indexOf(file.uid);
        const source = event.successfully_uploaded_sources[0];
        const extension = source.name.substring(source.name.lastIndexOf('.'), source.name.length) || '';
        const filename = source.name.substring(0, source.name.length - extension.length);

        // add file to source category instead of reloading the API call
        this.localSources.push(source);
        // push it into edit mode
        this.$set(this.editableNames, source.id, { filename, extension });
        this.$set(this.editStates, source.id, true);
        // remove file that was just finished from the sourceType's internal file tracking
        this.uploadingFiles.splice(uploadingFileIndex, 1);
        this.addUploadedSource(source);
        // is everything for this sourceType done uploading? this impacts visual treatment of the SourceType
        if (this.uploadingFiles.length === 0) {
          this.uploading = false;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .list-wrapper {
    position: relative;
  }

  .heading-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 10px 16px;
    background: var(--tf-gray-light);
    border-bottom: 1px solid var(--tf-gray-medium);
    padding-right: 20px;
  }

  h3 {
    font-size: 14px;
    margin-bottom: 0;

    &.heading-0 {
      margin-top: 0;
    }
  }

  li {
    display: flex;
    justify-content: space-between;
    padding: 6px 16px;
    line-height: 19px;
    height: 19px;
    border-bottom: 1px solid var(--tf-gray-medium);
  }

  .el-checkbox {
    margin-right: 10px;
    position: relative;
    top: -2px;
  }

  .label-wrapper {
    flex-grow: 2;
    display: flex;
    max-width: 100%;
  }

  .label {
    display: inline-block;
    font-size: 14px;
    max-width: 306px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex-grow: 2;
  }

  li.upload {
    .label {
      max-width: 220px;
      margin-right: 10px;
    }
  }

  .uploader,
  .errors {
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    padding: 0;
    margin: 0;
    position: absolute;
    z-index: 12;
    pointer-events: none;
    min-height: 68px;
  }

  .uploader {
    opacity: 0;

    &.over {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .upload-errors {
    border: 1px dashed var(--tf-red);
    height: auto;

    .upload-error-heading {
      display: flex;
      align-items: center;
    }

    .errors-inner {
      position: relative;
      background: rgba(255, 236, 233, .75);
      padding: 10px 15px 10px 30px;
      color: var(--tf-red);

      a {
        font-weight: normal;
        pointer-events: auto;
        color: var(--tf-red);
        text-decoration: underline;
      }

      p {
        font-size: 14px;
        margin: 0;
        line-height: 16px;
        pointer-events: none;
      }

      .close-error {
        position: absolute;
        right: 12px;
        top: 20px;
        pointer-events: auto;
        cursor: pointer;
      }
    }
  }

  .error-header {
    display: flex;
    color: var(--tf-red);
    vertical-align: middle;
    margin-bottom: 10px;

    i {
      margin-right: 10px;
    }

    strong {
      text-transform: uppercase;
      display: block;
    }
  }

  .uploader-inner {
    width: 100%;
    height: 100%;
    background: rgba(239, 243, 253, .75);
  }

  .category {
    :deep() {
      .el-input-group__append {
        background: var(--tf-gray-light-medium);
        font-size: 1rem;
        color: var(--tf-blue);
      }

      .el-progress {
        display: flex;
        width: 138px;
      }

      .el-progress-bar__innerText {
        display: none;
      }

      .el-upload.el-upload--text {
        width: 100%;
        height: 100%;
      }
    }
  }
</style>
