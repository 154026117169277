<template>
  <li
    :class="[
      { 'class-based': isClassBased },
      `${readOnlyMode || currentContainers.length === 1 ? 'non-sortable-row' : 'sortable-row'}`,
      'container-row',
    ]"
    :data-id="container.id"
  >
    <button
      v-if="!readOnlyMode && currentContainers.length > 1"
      class="move-button"
      tabindex="-1"
    >
      <img
        :src="dragIcon"
        alt="Drag icon"
      >
    </button>
    <div
      :class="[
        { 'is-editing': ['editing', 'saving'].includes(container.state) },
        'container-form-wrapper',
      ]"
    >
      <span
        v-if="isClassBased"
        class="container-label"
        v-text="container.name"
      />
      <!-- Class-Based Container -->
      <ClassBasedContainer
        v-if="isClassBased && ['editing', 'saving'].includes(container.state)"
        :container="container"
      />
      <!-- Plan-Based Container -->
      <PlanBasedContainer
        v-else-if="!isClassBased"
        :container="container"
      />
      <!-- Read only description -->
      <span
        v-if="container.state === 'reviewing'"
        data-test="plan description"
        class="read-only-description"
        v-text="container.description"
      />
      <!-- Container Actions (edit/delete popover) -->
      <ContainerActions
        v-if="currentContainers.length"
        :container="container"
      />
    </div>
  </li>
</template>

<script>
  import { mapState } from 'pinia';
  import dragIcon from '@/assets/drag-handle.svg';
  import { useProjectStore } from '@/stores/project.js';
  import { useProductContainersStore } from '@/stores/productContainers.js';
  import ClassBasedContainer from './ClassBasedContainer.vue';
  import PlanBasedContainer from './PlanBasedContainer.vue';
  import ContainerActions from './ContainerActions.vue';

  /**
   * Product Container
   *
   * @vuedoc
   * @exports ProductContainer
   * @category Components
   */
  export default {
    name: 'ProductContainer',
    components: {
      ClassBasedContainer,
      ContainerActions,
      PlanBasedContainer,
    },
    props: {
      container: {
        type: Object,
        required: true,
        default: () => {},
      },
    },
    data() {
      return {
        dragIcon,
      };
    },
    computed: {
      ...mapState(useProjectStore, ['readOnlyMode']),
      ...mapState(useProductContainersStore, [
        'currentContainers',
        'isClassBased',
      ]),
    },
  };
</script>

<style lang="scss" scoped>
li {
  position: relative;
  border-bottom: 1px solid var(--tf-gray-light-medium);

  &:not(.non-sortable-row) {
    padding-left: 21px;
    cursor: move;
  }
}

.container-form-wrapper {
  display: flex;
  padding: 15px 12px 15px 20px;
  justify-content: space-between;
  align-items: center;

  .class-based & {
    padding-left: 0;
  }
}

.container-label {
  width: 59px;
}

.el-textarea {
  min-width: 380px;

  .el-textarea__inner {
    resize: none;
    height: 44px;
    line-height: 18px;
  }
}

.move-button {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 21px;
  padding: 0;
  border: 0;
  background: #f7f7f7;
  cursor: move;

  svg {
    fill: var(--tf-gray);
  }
}

.read-only-description {
  display: flex;
  flex: 1;
  align-items: center;
  font-size: 14px;
  text-align: left;

  .class-based & {
    min-height: 63px;
  }
}

:deep() {
  .el-form {
    display: flex;
    margin-right: 15px;
    align-items: center;
  }

  .el-form-item {
    margin-bottom: 0;
  }

  .el-form-item__error {
    position: relative;
    text-align: left;
  }
}
</style>
