<template>
  <div
    v-loading="!(adminUsersIsLoaded && filtersLoaded)"
    class="dashboard-table-tab"
    data-test="dashboard table"
  >
    <h3
      v-if="showTitle"
      v-text="title"
    />
    <template v-if="adminUsersIsLoaded && filtersLoaded">
      <LfcApiTable
        v-if="!errorMessage"
        v-bind="{
          tableData,
          tableMeta,
          apiMeta,
          childKey: 'project_products',
          findIndexBy: 'project_id',
        }"
        :show-filter-button="showFilterButton"
        :current-page.sync="currentPage"
        :sorting.sync="sorting"
        :filters.sync="filters"
        @getTableData="getTableData"
      >
        <template #filters>
          <DashboardFilters
            :show-filter-button="showFilterButton"
            :filters.sync="filters"
          />
        </template>
        <template #parentRow="{ rowData }">
          <ProjectRow
            v-bind="{
              columns: tableMeta.columns,
              rowData,
              dashboardType,
              childKey: 'project_products',
            }"
            @createMessage="onPropUpdate({
              subject: rowData.employer_name,
              label: $event.label,
              type: $event.type,
              value: $event.value,
            })"
            @projectPropChanged="onProjectPropChanged(rowData.project_id, $event)"
            @projectCanBeAuditedChanged="onProjectPropChanged(rowData.project_id, 'can_be_audited', $event)"
            @openCloseProjectModal="toggleModal('closeProject', true, rowData)"
            @openCompleteProjectModal="toggleModal('completeProject', true, rowData)"
            @openResetModal="toggleModal('resetProject', true, rowData)"
            @openEditBrokerUsers="toggleModal('editBrokerUsers', true, rowData)"
          />
        </template>
        <template #childTable="{ childData, childColumns }">
          <table
            class="table table-child"
            data-test="opportunity row"
          >
            <thead>
              <tr>
                <th
                  v-for="column in childColumns"
                  :key="column.label"
                  :width="column.width"
                  v-text="column.label"
                />
              </tr>
            </thead>
            <tbody>
              <ProductRow
                v-for="(child, childIndex) in childData"
                :key="`${childIndex}-${childData.label}`"
                v-bind="{
                  columns: childColumns,
                  rowData: child,
                  dashboardType,
                }"
                @createMessage="onPropUpdate({
                  subject: childData.label,
                  label: 'collaborator',
                  type: $event.type,
                  value: $event.value,
                })"
                @productPropChanged="onProductPropChanged($event)"
              />
            </tbody>
          </table>
        </template>
        <template #empty>
          There don't appear to be any "{{ title }}" projects.
        </template>
      </LfcApiTable>
      <AppAlert
        v-else-if="errorMessage"
        :closable="false"
        :title="errorMessage"
        type="danger"
      />
    </template>

    <ModalResetProject
      v-if="modal.resetProject.visible"
      :employer-name="modal.resetProject.data.employer_name"
      :project-id="modal.resetProject.data.project_id"
      :project-state="modal.resetProject.data.project_state"
      @modalClosed="toggleModal('resetProject')"
      @error="toggleModal('resetProject')"
      @projectReset="onProjectReset"
    />

    <ModalBrokerUsers
      v-if="modal.editBrokerUsers.visible"
      :project-id="modal.editBrokerUsers.data.project_id"
      @modalClosed="toggleModal('editBrokerUsers')"
      @error="toggleModal('editBrokerUsers')"
    />

    <ModalCloseProject
      v-if="modal.closeProject.visible"
      :employer-name="modal.closeProject.data.employer_name"
      :project-id="modal.closeProject.data.project_id"
      :project-state="modal.closeProject.data.project_state"
      @modalClosed="toggleModal('closeProject')"
      @error="toggleModal('closeProject')"
      @projectStateChange="onProjectStateChange('closeProject', $event)"
    />

    <ModalCompleteProject
      v-if="modal.completeProject.visible"
      :employer-name="modal.completeProject.data.employer_name"
      :project-id="modal.completeProject.data.project_id"
      :project-state="modal.completeProject.data.project_state"
      @modalClosed="toggleModal('completeProject')"
      @error="toggleModal('completeProject')"
      @projectStateChange="onProjectStateChange('completeProject', $event)"
    />
  </div>
</template>

<script>
  import { mapState } from 'pinia';
  import { useAdminsStore } from '@/stores/admins.js';
  // Services
  import { getDashboardProjects, getDashboardFilterOptions } from '@/services/project.js';
  // Misc
  import messages from '@/mixins/messages.js';
  import { config } from '@/utils/config.js';
  // Components
  import ModalCloseProject from '@/components/Modal/CloseProject.vue';
  import ModalCompleteProject from '@/components/Modal/CompleteProject.vue';
  import ModalResetProject from '@/components/Modal/ResetProject.vue';
  import ModalBrokerUsers from '@/components/Modal/EditBrokerUsers/index.vue';
  import ProjectRow from '@/components/Dashboard/ProjectRow.vue';
  import ProductRow from '@/components/Dashboard/ProductRow.vue';
  import DashboardFilters from '@/components/Dashboard/Filters.vue';

  /**
   * Dashboard table to display all our projects in various states
   * Uses LfcApiTable which relies on the backend to do pagination, sorting, and filtering
   * of the data.
   *
   * @vuedoc
   * @exports DashboardTable
   * @category Components
   */
  export default {
    name: 'DashboardApiTable',
    components: {
      ModalCloseProject,
      ModalCompleteProject,
      ModalResetProject,
      ModalBrokerUsers,
      ProjectRow,
      ProductRow,
      DashboardFilters,
    },
    mixins: [
      messages,
    ],
    provide() {
      return {
        DashboardTable: this,
      };
    },
    props: {
      dashboardType: {
        type: String,
        required: true,
      },
      showTitle: {
        type: Boolean,
        default: false,
      },
      showFilterButton: {
        type: Boolean,
        default: false,
      },
      title: {
        type: String,
        default: '',
      },
    },
    data: (vm) => ({
      currentPage: 1,
      sorting: [],
      filters: [],
      lastQuery: null,
      filtersLoaded: false,
      isLoaded: false,
      errorMessage: null,
      modal: {
        closeProject: {
          visible: false,
          data: {},
        },
        completeProject: {
          visible: false,
          data: {},
        },
        resetProject: {
          visible: false,
          data: {},
        },
        editBrokerUsers: {
          visible: false,
          data: {},
        },
      },
      /**
       * tableData holds the current page of data
       */
      tableData: [],
      /**
       * apiMeta contains the meta_data from the api response including
       * things like total_record_count. This property holds information
       * about the current page of data. This is different from tableMeta
       * which contains meta data about the table structure, not the data itself
       */
      apiMeta: {},
      /**
       * The meta data object that describes the table structure (columns, etc)
       */
      tableMeta: {
        columns: [
          {
            label: 'Employer',
            prop: 'employer_name',
            sortable: true,
            filterable: true,
          },
          {
            label: 'Broker',
            prop: 'broker_name',
            sortable: true,
            filterable: true,
          },
          {
            format: ['my_projects', 'project_setup', 'my_collaborations'].includes(vm.dashboardType)
              ? 'ownerSelect'
              : null,
            label: 'Owner',
            prop: 'wt_owner_name',
            sortable: true,
            filterable: true,
          },
          {
            label: 'Project type',
            prop: 'project_type',
            sortable: true,
            filterable: true,
          },
          {
            format: 'ctas',
            label: null,
            prop: 'ctas',
            width: 130,
          },
        ],
        childColumns: [
          {
            label: 'Project ID',
            prop: 'project_id',
            width: '16%',
          },
          {
            label: 'Product(s)',
            prop: 'label',
            width: '21%',
          },
          {
            label: 'In-force carrier(s)',
            prop: 'inforce_carrier_name',
            width: '21%',
          },
          {
            label: 'Collaborator',
            prop: 'wt_owner_name',
            format: ['my_projects', 'project_setup', 'my_collaborations'].includes(vm.dashboardType)
              ? 'ownerSelect'
              : null,
            width: '21%',
          },
          {
            label: 'Product state',
            prop: 'inforce_product_state',
            width: '21%',
          },
        ],
        emptyValue: '&mdash;',
      },
      /**
       * Table filter config
       * label: string
       * type: multiselect, checkboxes, etc
       * props: array
       * options: array of arrays of label/value objects
       *
       * @returns {Array}
       */
      tableFilters: [
        {
          label: 'Employer',
          type: 'multiselect',
          props: 'employer_name',
          value: [],
        }, {
          label: 'Broker',
          type: 'multiselect',
          props: 'broker_name',
          value: [],
        }, {
          label: 'Owner',
          type: 'multiselect',
          props: 'wt_owner_name',
          value: [],
          options: [
            {
              label: 'n/a',
              value: '_NULL_',
            },
          ],
        }, {
          label: 'Project type',
          type: 'checkboxes',
          value: [],
          props: 'project_type',
          options: [
            {
              label: 'Initial employer build',
              value: 'Projects::InitialEmployerBuild',
            }, {
              label: 'Policy audit',
              value: 'Projects::PolicyAudit',
            }, {
              label: 'Renewal placement event',
              value: 'Projects::RenewalPlacementEvent',
            }, {
              label: 'RFP placement event',
              value: 'Projects::RfpPlacementEvent',
            },
          ],
        },
      ],
    }),
    computed: {
      ...mapState(useAdminsStore, ['adminUsers']),
      ...mapState(useAdminsStore, {
        adminUsersIsLoaded: 'isLoaded',
      }),
    },
    watch: {
      filters: {
        /**
         * Update query string when filters are applied
         */
        handler() {
          if (!this.filtersLoaded) {
            return;
          }

          this.updateQueryString();
        },
        deep: true,
      },
      /**
       * Update query string when sorting changes
       */
      sorting() {
        this.updateQueryString();
      },
      /**
       * Update query string when currentPage changes.
       */
      currentPage() {
        this.updateQueryString();
      },
    },
    async created() {
      try {
        if (this.$route.query.filters) {
          const parsedQueries = JSON.parse(this.$route.query.filters);
          const appliedFilters = this.tableFilters.map((filter) => ({
            ...filter,
            value: parsedQueries[filter.props] || filter.value,
          }));

          this.tableFilters = appliedFilters;
        }
        if (this.$route.query.sorting) {
          this.sorting.push(this.$route.query.sorting.split(','));
        }
        if (this.$route.query.page) {
          this.currentPage = Number(this.$route.query.page);
        }
      } catch {
        this.$router.replace({
          query: {},
        });
      }

      await this.getFilterOptions();
    },
    methods: {
      /**
       * Get filter options from the server. This will use a new endpoint to get a
       * list of unique values
       */
      async getFilterOptions() {
        try {
          // Get the data from the API which will come back as a object with array values
          const data = await getDashboardFilterOptions(this.dashboardType);
          // Map the options to objects with label and value properties as most UI controls require these
          const options = Object.fromEntries(
            Object.keys(data).map((key) => (
              [key, data[key]
                ?.filter((valueExists) => valueExists)
                .map((filterOption) => ({
                  label: filterOption,
                  value: filterOption,
                }))]
            )),
          );
          // Map the filter options to the local filters
          const localFilters = this.tableFilters.map((filter) => {
            const localFilterOptions = [];

            if (filter.options) {
              filter.options.forEach((opt) => {
                localFilterOptions.push(opt);
              });
              // dedupe local options from api ones
              options[filter.props]?.forEach((opt) => {
                const filterExists = localFilterOptions.findIndex(({ value }) => value === opt.value) > -1;

                if (!filterExists) {
                  localFilterOptions.push(opt);
                }
              });
            } else {
              options[filter.props]?.forEach((opt) => {
                localFilterOptions.push(opt);
              });
            }

            const localValue = {
              ...filter,
              options: localFilterOptions,
            };

            // Locate and substitute values from query string with those from options
            if (localValue.value?.length) {
              localValue.value = localValue.value.map((val) => localValue.options.find(({ value }) => value === val));
            }

            return localValue;
          });

          this.$set(this, 'filters', localFilters);
        } catch (error) {
          this.errorMessage = `Error loading project filters: ${error}`;
        } finally {
          this.filtersLoaded = true;
        }
      },
      /**
       * Get filter query string
       *
       * @returns {string}
       */
      getFilterQueryString() {
        const queries = {};

        this.filters.forEach(({ props, value }) => {
          if (value.length) {
            queries[props] = [
              ...new Set(
                value.map((val) => (
                  val.value || value
                )).flat(),
              ),
            ];
          }
        });

        return queries;
      },
      /**
       * Update query string when filters or sorting changes
       */
      updateQueryString() {
        const queryString = {
          query: {},
        };
        const filterQueries = this.getFilterQueryString();

        if (Object.keys(filterQueries).length) {
          queryString.query.filters = JSON.stringify(filterQueries);
        }

        if (this.sorting.length) {
          queryString.query.sorting = this.sorting;
        }

        if (this.currentPage) {
          queryString.query.page = this.currentPage;
        }
        this.$router.push(queryString);
      },
      /**
       * Get the table data from the api.
       *
       * @param {object} root
       * @param {number} root.pageSelected
       * @param {number} root.pageLimit
       * @param {object} root.sorting
       * @param {object} root.filters
       */
      async getTableData({
        pageSelected = 1,
        pageLimit = 50,
        sorting,
        filters = this.lastQuery,
      }) {
        try {
          const { _meta_data: meta, project_dashboard_view: data } = await getDashboardProjects(this.dashboardType, {
            pageSelected,
            pageLimit,
            sorting,
            filters,
          });

          this.apiMeta = {
            ...this.apiMeta,
            ...meta,
          };
          // Do some string replacement to make values more human readable
          const tableData = data.map((row) => ({
            ...row,
            project_type: row.project_type?.replace('Projects::', '').replace(/([A-Z])/g, ' $1'),
            project_products: row.project_products.map((pp) => ({
              ...pp,
              project_id: row.project_id,
              inforce_product_state: pp.inforce_product_state.replace(/_/g, ' '),
            })),
            brokerUrl: ['live', 'completed'].includes(this.dashboardType)
              ? `${config.VUE_APP_BROKER_URL}/projects/${row.project_id}`
              : null,
          }));

          this.$set(this, 'tableData', tableData);
        } catch (error) {
          this.errorMessage = `Error loading projects: ${error}`;
        }

        // Save the last filter query in case we need to update the table after a record is updated
        // This way we don't need to recalculate the filter argument for the request since the
        // LfcApiTable component actually takes care of that translation
        this.$set(this, 'lastQuery', filters);
      },
      /**
       * Finds the row index on the table based on the ID that is passed.
       *
       * @param {number} id
       * @returns {number}
       */
      findIndex(id) {
        return this.tableData.findIndex((row) => row.project_id === id);
      },
      /**
       * Finds the row index on the table based on the index that is passed, then finds the product index based in the product ID that is passed.
       *
       * @param {number} index
       * @param {number} productId
       * @returns {number}
       */
      projectIndex(index, productId) {
        return this.tableData[index].project_products.findIndex(({ inforce_product_id }) => inforce_product_id === productId);
      },
      /**
       * When the user updates a record, update the tableData
       *
       * @param {number} projectId
       * @param {object} event
       */
      onProjectPropChanged(projectId, event) {
        const { name, prop, value } = event;
        const projectProp = prop === 'wt_owner_name' ? 'wt_owner_id' : prop;
        const tableIndex = this.findIndex(projectId);

        this.$set(this.tableData[tableIndex], [projectProp], value);
        this.$set(this.tableData[tableIndex], 'wt_owner_name', name);
      },
      /**
       * When the user updates a collaborator, update the tableData's collaborator
       *
       * @param {object} event
       */
      onProductPropChanged(event) {
        const {
          id, prop, value, name, productId,
        } = event;
        const projectProp = prop === 'wt_owner_name' ? 'wt_owner_id' : prop;
        const tableIndex = this.findIndex(id);
        const projectIndex = this.projectIndex(tableIndex, productId);

        this.$set(this.tableData[tableIndex].project_products[projectIndex], [projectProp], value);
        this.$set(this.tableData[tableIndex].project_products[projectIndex], 'wt_owner_name', name);
      },
      /**
       * When a project has been reset, refresh the table data
       */
      onProjectReset() {
        this.getTableData({});
        this.toggleModal('resetProject');
      },
      /**
       * When a project state has changed (currently only completed or closed),
       * refresh the table data
       *
       * @param {string} modalName
       */
      onProjectStateChange(modalName) {
        this.getTableData({});
        this.toggleModal(modalName);
      },
      /**
       * Toggles the appropriate modal based on parameters.
       *
       * @param {string} name
       * @param {boolean} visible
       * @param {object} projectData
       */
      toggleModal(name, visible = false, projectData = {}) {
        this.$set(this.modal[name], 'data', projectData);
        this.modal[name].visible = visible;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .dashboard-table-tab {
    padding: 0 35px 20px;

    :deep(.table-container) {
      align-items: flex-start;
    }
  }

  h3 {
    margin: {
      bottom: 20px;
    }
    font: {
      size: 16px;
      weight: 600;
    }
  }
</style>
