<template>
  <div
    class="file-manager"
    data-test="file manager"
  >
    <FileManagerHeader />
    <div class="file-list">
      <AppAlert
        v-if="hasPendingSources"
        title="One or more of your files are processing and will be available in 5-10 minutes. You can close the file manager and check back later."
        data-test="pending sources alert"
        type="warning"
      />

      <div class="select-all">
        <div
          class="label-wrapper"
          data-test="select all"
          @click="onAllSelectedChange"
        >
          <ElCheckbox
            :value="allSelected"
            class="offset"
            @change="onAllSelectedChange"
          />
          <span class="label clickable">Select all</span>
        </div>
      </div>
      <FileManagerCategory
        v-for="category in categories"
        :key="category.type"
        ref="category-component"
        :hovered="uploadHoveredCategory === category.type"
        :on-drag-end="onDragEnd"
        :on-drag-enter="onDragEnter"
        :on-drag-start="onDragStart"
        :category="category"
        :disable-uploader="disableUploader"
        :project-id="currentProject.id"
        :open-file-ids="projectOpenFileIds"
        :upload-action="uploadDetails.action"
        :upload-headers="uploadDetails.headers"
        @moveSourceCategory="moveSourceCategory"
        @hover="setUploadHoveredCategory"
      />
    </div>
  </div>
</template>

<script>
  import { isStopLossProduct } from '@watchtowerbenefits/es-utils-public';
  import { mapState, mapActions, mapWritableState } from 'pinia';
  import { useFilesStore } from '@/stores/files.js';
  import { useFileViewerStore } from '@/stores/fileViewer.js';
  import { useProjectStore } from '@/stores/project.js';
  // services
  import FileService from '@/services/file.js';
  import { npcDetails } from '@/utils/featureFlags.js';
  // components
  import FileManagerHeader from '@/components/FileViewer/FileManager/Header.vue';
  import FileManagerCategory from '@/components/FileViewer/FileManager/Category.vue';
  import { useProjectProductStore } from '@/stores/projectProduct.js';

  /**
   * Collapsible/Viewable Panel to Manage viewable PDF files
   *
   * @vuedoc
   * @exports FileManager
   * @category Components
   */
  export default {
    name: 'FileManager',
    components: {
      FileManagerCategory,
      FileManagerHeader,
    },
    data() {
      return {
        allSelected: false,
        disableUploader: false,
        draggedSource: null,
        draggedSourceFromCategoryComponent: null,
        draggedSourceToCategoryComponent: null,
        sourcesIntervalId: null,
        uploadHoveredCategory: null,
      };
    },
    computed: {
      ...mapState(useFilesStore, [
        'sources',
        'hasPendingSources',
        'viewableFileIds',
      ]),
      ...mapState(useFileViewerStore, [
        'getAllFilesOpen',
        'openFileIds',
      ]),
      ...mapWritableState(useFileViewerStore, [
        'requestFileManagerClose',
        'requestTabFocus',
      ]),
      ...mapState(useProjectProductStore, [
        'activeProjectProducts',
      ]),
      ...mapState(useProjectStore, [
        'currentProject',
        'hasNoPriorCoverage',
        'documentId',
      ]),
      /**
       * shorthand access to the openFileIds for this project
       *
       * @returns {Array}
       */
      projectOpenFileIds() {
        return this.openFileIds[this.currentProject.id];
      },
      /**
       * shorthand access to whether or not all files are open
       *
       * @returns {boolean}
       */
      projectAllFilesOpen() {
        return this.getAllFilesOpen(this.currentProject.id);
      },
      /**
       * Cache upload details
       *
       * @returns {object}
       */
      uploadDetails() {
        return FileService.getSourceUploadDetails(this.documentId);
      },
      /**
       * Determines if Project has a Stop Loss product
       *
       * @returns {boolean}
       */
      hasStopLoss() {
        return this.activeProjectProducts.some((activeProduct) => isStopLossProduct(activeProduct));
      },
      /**
       * generates a categorized set of sources from the sources object
       *
       * @returns {Array}
       */
      categories() {
        const nonContractualFiles = [];

        if (this.$ld.checkFlags(npcDetails) && this.hasNoPriorCoverage) {
          nonContractualFiles.push({
            name: 'No prior coverage request details',
            type: 'NPCRequestDetails',
            id: 'npc-request-details',
            status: 'conversion_completed',
            disableModify: true,
          });
        }

        nonContractualFiles.push({
          name: 'Broker info',
          type: 'NewRfpResponses',
          id: 'new-rfp-responses',
          status: 'conversion_completed',
          disableModify: true,
        });

        const stopLossCategories = [
          {
            label: 'Stop loss policy / certificates',
            type: 'StopLoss::PolicySource',
          },
          {
            label: 'Stop loss rates and enrollment',
            type: 'StopLoss::RateSource',
          },
          {
            label: 'Stop loss experience',
            type: 'StopLoss::ExperienceSource',
          },
        ];
        const categoryOrder = [
          {
            label: 'Non-contractual',
            // this is not a real source type, but we're homogenizing the data
            type: 'NonContractualFile',
            sources: nonContractualFiles,
            disableUpload: true,
          },
          {
            label: 'Census',
            type: 'CensusSource',
          },
          ...this.hasStopLoss ? stopLossCategories : [],
          {
            label: 'Non-medical policy / certificates',
            type: 'PolicySource',
          },
          {
            label: 'Non-medical rates and enrollment',
            type: 'RateSource',
          },
          {
            label: 'Non-medical experience',
            type: 'ExperienceSource',
          },
        ];
        const allCategories = categoryOrder.map(({
          label, type, sources, disableUpload = false,
        }) => ({
          label,
          type,
          sources: sources || this.filterSource(type),
          disableUpload,
        }));
        const hasSources = allCategories.filter(({ sources }) => sources.length > 0);
        const noSources = allCategories.filter(({ sources }) => sources.length === 0);

        return [...hasSources, ...noSources];
      },
    },
    watch: {
      /**
       * update allSelected if the all file ids array doesn't/does match the current selection
       *
       * @param {boolean} newValue
       */
      projectAllFilesOpen(newValue) {
        if (newValue !== this.allSelected) {
          this.allSelected = newValue;
        }
      },
      /**
       * We watch this pinia state and when the parent component wants to close the file manager
       *
       * @param {boolean} requestFileManagerClose
       */
      requestFileManagerClose(requestFileManagerClose) {
        // We want to see if any categories have files in an edit state and we
        // won't close the file manager if they do
        const categoryComponents = this.$refs['category-component'];

        if (!requestFileManagerClose || !categoryComponents) {
          return;
        }

        const canClose = categoryComponents.every((categoryComponent) => categoryComponent.canClose);

        // this is a hack, but we were resetting the state of
        // requestFileManagerClose too fast and the child components
        // couldn't react to it
        // TODO try out nextTick
        setTimeout(() => {
          this.requestFileManagerClose = false;
          if (canClose) {
            this.$emit('approveClose');
          }
        }, 100);
      },
      /**
       * Check if there are still pending sources in our current store, and if there are, reload the source list.
       * If we didn't have pending sources and now we do, start the interval.
       * If we're done now (no more pending sources), cancel the interval.
       *
       * @param {boolean} newValue
       */
      hasPendingSources(newValue) {
        if (newValue) {
          this.createReloadSourcesInterval();
        } else {
          this.destroyReloadSourcesInterval();
        }
      },
    },
    /**
     * Initialize file manager
     */
    created() {
      // set AllSelected to true if the fileIds list matches the openFileIds array for this project the file manager
      this.allSelected = this.projectAllFilesOpen;

      // if we have pending sources right out the gate (without user uploads created while file manager is open), start the interval now.
      if (this.hasPendingSources) {
        this.createReloadSourcesInterval();
      }
    },
    /*
     * Cancel out interval if we close the file manager
     */
    beforeDestroy() {
      this.destroyReloadSourcesInterval();
    },
    methods: {
      ...mapActions(useFilesStore, ['moveSource', 'setSources']),
      ...mapActions(useFileViewerStore, ['setAllFilesOpen', 'setActiveFileId']),
      /**
       * Create setInterval if we have unprocessed sources
       */
      createReloadSourcesInterval() {
        if (!this.sourcesIntervalId) {
          this.sourcesIntervalId = setInterval(() => {
            this.reloadSources();
          }, 5000);
        }
      },
      /**
       * Destroy reload setInterval if it is set
       */
      destroyReloadSourcesInterval() {
        if (this.sourcesIntervalId) {
          clearInterval(this.sourcesIntervalId);
        }
      },
      /**
       * Toggle all selected
       */
      onAllSelectedChange() {
        this.allSelected = !this.allSelected;
        this.setAllFilesOpen({
          projectId: this.currentProject.id,
          value: this.allSelected,
        });
        if (!this.allSelected) {
          // everything was unselected, so clear out activeFileId
          this.setActiveFileId({
            projectId: this.currentProject.id,
            fileId: null,
          });
        } else {
          // everything was selected, so, just to make it usable,
          // set the first file to active
          // eslint-disable-next-line prefer-destructuring
          this.requestTabFocus = this.viewableFileIds[0];
        }
      },
      /**
       * Controlling what category is hovered at this level, only one category
       * can be hovered at a time so it made sense to control it in this
       * component (We track if a category is hovered so we know if we should
       * show the el-upload component for dragging in files)
       *
       * @param {object} options
       * @param {string} options.categoryType
       * @param {boolean} options.hoveredState
       */
      setUploadHoveredCategory({ categoryType, hoveredState }) {
        this.uploadHoveredCategory = hoveredState
          ? categoryType
          : null;
      },
      /**
       * We pass this prop down to run on drag start and we save the source that
       * is being dragged and the category it's being dragged from
       *
       * @param {object} source
       * @param {object} categoryComponent
       */
      onDragStart(source, categoryComponent) {
        this.draggedSource = source;
        this.draggedSourceFromCategoryComponent = categoryComponent;
        this.setDisableUploader(true);
      },
      /**
       * We pass this method to the category component as a prop to be called
       * on drag end. If the user is dragging a file to change it's category
       * we call moveSourceCategory
       *
       * @param {object} category
       */
      onDragEnd(category) {
        if (!this.draggedSource) {
          return;
        }

        this.moveSourceCategory({
          source: this.draggedSource,
          toCategoryType: this.draggedSourceToCategoryComponent.category.type,
          fromCategoryType: category.type,
        });
        this.setDisableUploader(false);
        this.uploadHoveredCategory = null;
      },
      /**
       * We pass this method to the category component as a prop to be called
       * on drag enter. We need to save this component so we can reference it
       * on drag end.
       *
       * @param {object} categoryComponent
       */
      onDragEnter(categoryComponent) {
        this.draggedSourceToCategoryComponent = categoryComponent;
      },
      /**
       * The user is trying to move a file from one category to another
       *
       * @param {object} options
       * @param {object} options.source
       * @param {string} options.toCategoryType
       * @param {string} options.fromCategoryType
       * @param {boolean} options.moveBack
       */
      moveSourceCategory({
        source,
        toCategoryType,
        fromCategoryType,
        moveBack = false,
      }) {
        if (!moveBack) {
          // we need to set the edit states on this component because we want
          // the file to still be in an edit state
          this.draggedSourceToCategoryComponent.setEditState(source.id, true);
        } else {
          // we're done
          this.draggedSourceFromCategoryComponent.setEditState(source.id, false);
        }

        this.moveSource({
          sourceId: source.id,
          toCategoryType,
          fromCategoryType,
          moveBack,
        });
        this.draggedSource = null;
      },
      /**
       * We run this via a setInterval to check if the processing status of each
       * file has changed
       */
      async reloadSources() {
        try {
          const { sources } = await FileService.getDocumentSources(this.documentId);

          this.setSources(sources);
        } catch {
          this.displayToast({
            message: 'There was an error getting the list of documents.',
          });
        }
      },
      /**
       * Set disableUploader so we don't show the "drag files to upload" message
       * when changing categories
       *
       * @param {boolean} value
       */
      setDisableUploader(value) {
        this.disableUploader = value;
      },
      /**
       * filters sources by type
       *
       * @param {string} sourceType
       * @returns {Array}
       */
      filterSource(sourceType) {
        return this.sources.filter(({ type }) => type === sourceType);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .file-manager {
    position: absolute;
    z-index: 24;
    background: var(--tf-base-light);
    height: auto;
    width: 420px;
    top: 46px;
    left: 0;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, .04), 0 2px 4px 0 rgba(0, 0, 0, .12), inset -1px 0 0 0 var(--tf-gray-light-medium);

    :deep() {
      .el-alert.el-alert--warning {
        margin: 20px 48px;
        padding: 20px 15px;
        max-width: 322px;
        background-color: #fffeec;
        border: 1px #b9b343 solid;
        font-size: 12px;
        color: #b9b343;
      }

      .el-alert__content {
        padding: 0;
      }
      /* cSpell:disable */
      .el-alert__closebtn.el-icon-close {
        color: #b9b343;
        right: 9px;
        top: 9px;
      }
    }
    /* cSpell:enable */
  }

  .file-list {
    max-height: calc(100vh - 171px);
    overflow: scroll;
  }

  .el-checkbox {
    margin-right: 5px;
    position: relative;
    top: 2px;
  }

  .label-wrapper {
    flex-grow: 2;
    display: flex;
    max-width: 100%;
  }

  .label.clickable {
    cursor: pointer;
  }

  .label {
    display: inline-block;
    font-size: 14px;
    max-width: 306px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex-grow: 2;
  }

  .select-all {
    display: flex;
    height: 24px;
    background: var(--tf-gray-light-medium);
    border-bottom: 1px solid var(--tf-gray-medium);
    padding: 12px 16px 10px;
  }

  .el-checkbox.offset {
    margin-right: 8px;
  }
</style>
