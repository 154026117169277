<template>
  <div class="dashboard">
    <div class="tabs-background">
      <h1>Project dashboard</h1>
    </div>

    <TfTabs
      :value="$route.name"
      :data-test="`${$route.name} tab`"
      @tab-click="onTabClick"
    >
      <ElTabPane
        v-for="({ label, name }) in dashboards"
        :key="label"
        v-bind="{ label, name }"
        :lazy="true"
      >
        <template
          v-if="name === 'DashboardSetup'"
          #label
        >
          Project setup
          <sup v-if="projectSetupNotifications">
            <TfBadge
              :value="projectSetupNotifications"
              status="error"
              is-counter
            />
          </sup>
        </template>
      </ElTabPane>
    </TfTabs>
    <RouterView />
  </div>
</template>

<script>
  import AdminNotificationsService from '@/services/notifications.js';

  export default {
    name: 'ProjectDashboard',
    data: () => ({
      dashboards: [
        {
          label: 'Your projects',
          name: 'DashboardOwner',
        },
        {
          label: 'Project setup',
          name: 'DashboardSetup',
        },
        {
          label: 'Live projects',
          name: 'DashboardLive',
        },
        {
          label: 'Completed projects',
          name: 'DashboardCompleted',
        },
        {
          label: 'Closed projects',
          name: 'DashboardClosed',
        },
      ],
      projectSetupNotifications: 0,
      qaNotifications: 0,
    }),
    created() {
      AdminNotificationsService
        .getProjectNotifications()
        .then((data) => {
          this.projectSetupNotifications = data.project_setup_notifications;
          this.qaNotifications = data.qa_notifications;
        })
        .catch(() => {
          this.displayToast({
            message: 'There was an error getting the project notifications.',
          });
        });
    },
    methods: {
      /**
       * Evaluates the name to determine the correct route params to push.
       *
       * @param {object} root
       * @param {string} root.name
       */
      onTabClick({ name }) {
        if (name === 'DashboardSetup' && this.projectSetupNotifications) {
          this.projectSetupNotifications = 0;
        }

        this.$router.push({ name });
      },
    },
  };
</script>

<style lang="scss" scoped>
.tabs-background {
  padding: 20px 35px;
  min-height: 100px;
  background: var(--tf-gray-light);
  border-bottom: 1px var(--tf-gray-medium) solid;
}

.el-tabs {
  position: relative;
  top: -44px;
  padding-left: 32px;
}

:deep(.el-alert) {
  margin-top: 25px;
}

:deep(.el-loading-parent--relative) {
  position: relative;
  min-height: 100px;
}
</style>
