<template>
  <AppButton
    :is-disabled="isDisabled"
    :is-loading="isDeleting"
    :class="[
      { 'is-deleting ': isDeleting },
    ]"
    type="decline"
    icon="fa-solid fa-trash-alt"
    size="text"
    data-test="delete alternative"
    text="Delete product"
    @click.native="onClickDelete"
  />
</template>

<script>
  import ProductService from '@/services/product.js';
  import { mapActions, mapState } from 'pinia';
  import { usePdfAnnotationStore } from '@/stores/pdfAnnotation.js';
  import { useProjectProductStore } from '@/stores/projectProduct.js';
  /**
   * Delete Button for Products
   *
   * @vuedoc
   * @exports ProductBtnDelete
   * @category Components
   */
  export default {
    name: 'ProductBtnDelete',
    props: {
      isDisabled: {
        type: Boolean,
        default: false,
      },
      projectProduct: {
        type: Object,
        required: true,
        default: () => ({}),
      },
    },
    data() {
      return {
        isDeleting: false,
      };
    },
    computed: {
      ...mapState(usePdfAnnotationStore, ['annotationModalMode']),
    },
    methods: {
      ...mapActions(usePdfAnnotationStore, ['closeAnnotationModal']),
      ...mapActions(useProjectProductStore, ['removeActiveProjectProduct']),
      /**
       * Calls the API to delete the product, remove from the store, and close the annotation if open.
       */
      onClickDelete() {
        const payload = { projectProductId: this.projectProduct.id };

        if (this.projectProduct.alternative) {
          payload.alternativeType = this.projectProduct.product_type_id;
        }

        this.isDeleting = true;

        if (this.annotationModalMode !== 'closed') {
          this.closeAnnotationModal();
        }

        ProductService
          .deleteProduct(this.projectProduct.inforce_product.id)
          .then(() => {
            this.removeActiveProjectProduct(payload);
          })
          .catch(() => {
            this.isDeleting = false;
            this.displayToast({
              message: 'Cannot delete this product. Please contact support for help.',
            });
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .app-button {
    height: 35px;
  }
</style>
