<template>
  <div class="product-container">
    <ProductForm
      v-model="localForm"
      data-test="alt product container"
      :is-editing.sync="isEditing"
      :product="product"
      @onClickCancel="onClickCancel"
    />
    <div
      v-if="isEditing"
      class="product-actions"
    >
      <BtnDelete :project-product="product" />
    </div>
  </div>
</template>

<script>
  import BtnDelete from '@/components/Product/BtnDelete.vue';
  import ProductForm from '@/components/Product/ProductForm.vue';
  /**
   * Individual Product Alternative Layout
   *
   * @vuedoc
   * @exports AlternativeProductLayout
   * @category Components
   */
  export default {
    name: 'AlternativeProductLayout',
    components: {
      BtnDelete,
      ProductForm,
    },
    props: {
      product: {
        type: Object,
        default: () => ({
          inforce_product: {
            carrier_id: null,
          },
          product_type_id: null,
          plan_type: null,
          prior_coverage: true,
        }),
      },
    },
    data() {
      return {
        isEditing: false,
        localForm: {},
      };
    },
    mounted() {
      this.updateLocalForm(this.product);
    },
    methods: {
      /**
       * Toggle isEditing variable and reset localForm data.
       */
      onClickCancel() {
        this.updateLocalForm(this.product);
        this.isEditing = false;
      },
      /**
       * Update the local form object based on the product that is passed.
       *
       * @param {object} product
       */
      updateLocalForm(product) {
        this.localForm = {
          carrier: product.inforce_product.carrier_id,
          label: product.label,
          priorCoverage: product.prior_coverage,
          productType: product.product_type_id,
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .product {
    &-form {
      width: 100%;

      &:not(.editing-form) {
        align-items: center;
      }
    }

    &-actions {
      width: 100%;
      display: flex;
      box-sizing: border-box;
      background: var(--tf-gray-light);
      padding: 0;
    }
  }

  :deep(p.form-disclaimer) {
    margin-bottom: 0;
    padding-bottom: 0;
  }
</style>
