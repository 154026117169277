import MathUtil from '@/utils/math.js';
import { find } from 'lodash';

/**
 * Utility file for working with images
 *
 * @exports Containers
 * @category Utils
 */
export default {
  /**
   * "rollup" a bunch of ranges
   *
   * @param {Array} containerIds - The project row
   * @param {Array} containers - The project row
   * @param {boolean} usesClasses - The project row
   * @returns {Array}
   */
  getContainersDisplayValue(containerIds, containers, usesClasses) {
    if (containerIds.length === containers.length) {
      return 'All';
    }
    const labels = [];

    containerIds.forEach((containerId) => {
      const container = find(containers, ({ id }) => Number(id) === Number(containerId));

      if (container) {
        const label = usesClasses ? container.name : container.description;

        labels.push(label);
      }
    });
    if (!labels.some(Number.isNaN)) {
      return MathUtil.getRanges(labels).join(', ');
    }

    return labels.join(', ');
  },
};
