import Vue, { reactive } from 'vue';
import { defineStore } from 'pinia';
import { useFilesStore } from '@/stores/files.js';
import { useProjectStore } from '@/stores/project.js';

export const useFileViewerStore = defineStore('fileViewer', {
  state: () => ({
    activeFileIds: {},
    goToAnnotation: null,
    openFileIds: {},
    requestFileManagerClose: false,
    requestTabClose: false,
    requestTabFocus: false,
    closedInfoTableIds: {},
  }),
  persist: {
    key: 'admin-fileViewer',
    paths: ['openFileIds', 'activeFileIds', 'closedInfoTableIds'],
    serializer: {
      serialize: (value) => JSON.stringify(value),
      deserialize: (value) => reactive(JSON.parse(value)),
    },
  },
  getters: {
    getAllFilesOpen: (state) => (projectId) => {
      const filesStore = useFilesStore();

      return filesStore.viewableFileIds?.length === state.openFileIds[projectId]?.length;
    },
    getActiveFileId: (state) => {
      const projectStore = useProjectStore();

      return state.activeFileIds[projectStore.currentProject?.id] ?? null;
    },
    getClosedInfoTableIds: (state) => {
      const projectStore = useProjectStore();

      return state.closedInfoTableIds[projectStore.currentProject?.id] ?? [];
    },
  },
  actions: {
    /**
     * Set or toggle all files open/closed
     *
     * @param {object} payload
     * @param {number} payload.projectId
     * @param {boolean} payload.value
     */
    setAllFilesOpen({ projectId, value }) {
      const filesStore = useFilesStore();
      const fileIds = value ? [...filesStore.viewableFileIds] : [];

      Vue.set(this.openFileIds, projectId, fileIds);
    },

    /**
     * Set or toggle a file open/closed
     *
     * @param {object} payload
     * @param {number} payload.projectId
     * @param {number} payload.fileId
     * @param {boolean} payload.value
     */
    setFileOpen({ projectId, fileId, value }) {
      // if no value was passed, we use this to toggle
      const hasValue = value || !this.openFileIds[projectId].includes(fileId);

      if (hasValue) {
        this.openFileIds[projectId].push(fileId);
      } else {
        const sourceIndex = this.openFileIds[projectId].indexOf(fileId);

        this.openFileIds[projectId].splice(sourceIndex, 1);
      }
    },

    /**
     * Set or toggle an infoTable open/closed
     *
     * @param {object} payload
     * @param {number} payload.projectId
     * @param {number} payload.productTypeId
     * @param {boolean} payload.value
     */
    setInfoTableIdClosed({ projectId, productTypeId, value }) {
      if (!this.closedInfoTableIds[projectId]) {
        Vue.set(this.closedInfoTableIds, projectId, []);
      }

      if (value) {
        this.closedInfoTableIds[projectId].push(productTypeId);
      } else {
        const sourceIndex = this.closedInfoTableIds[projectId].indexOf(productTypeId);

        this.closedInfoTableIds[projectId].splice(sourceIndex, 1);
      }
    },
    /**
     * Create blank openFileIds array for project
     *
     * @param {number} projectId
     */
    createOpenFilesForProjectId(projectId) {
      Vue.set(this.openFileIds, projectId, []);
    },
    /**
     * Set active file ID, per project
     *
     * @param {object} payload
     * @param {number} payload.projectId
     * @param {number} payload.fileId
     */
    setActiveFileId({ projectId, fileId }) {
      Vue.set(this.activeFileIds, projectId, fileId);
    },
  },
});
