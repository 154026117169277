import { defineStore } from 'pinia';

export const useProjectStore = defineStore('project', {
  state: () => ({
    currentProject: null,
    projectPanelActive: true,
  }),
  getters: {
    /**
     * We need a getter for the projectID so we can access it in another getter
     *
     * @param {object} state
     * @returns {number}
     */
    projectId: (state) => (state.currentProject?.id ?? null),
    /**
     * Evaluate the current projects's project products for any non-alternatives that have no prior coverage.
     *
     * @param {object} state
     * @returns {number}
     */
    hasNoPriorCoverage: (state) => state.currentProject?.project_products.some(({ alternative, prior_coverage: priorCoverage }) => !priorCoverage && !alternative),
    /**
     * Evaluate the current projects's projects read only mode.
     *
     * @param {object} state
     * @returns {boolean}
     */
    readOnlyMode: (state) => {
      const readOnlyModes = [
        'rfp_setup_completed',
        'rfp_closed',
        'rfp_sent_to_market',
        'rfp_modified',
        'rfp_modified_info',
        'rfp_modified_invites',
        'rfp_completed',
      ];

      return state.currentProject ? readOnlyModes.includes(state.currentProject.state) : true;
    },
    /**
     * Returns current projects inforce document id.
     *
     * @param {object} state
     * @returns {string}
     */
    documentId: (state) => (state.currentProject?.inforce_document.id ?? null),
  },
});
