<template>
  <div
    :class="[
      'annotation-marker-group',
      { 'multiple-annotation-types': annotationsByType.user.length && annotationsByType.automated.length },
    ]"
  >
    <AnnotationMarker
      v-if="annotationsByType.user.length"
      :automated="false"
      :count="annotationsByType.user.length"
      @click="$emit('click', false)"
    />
    <AnnotationMarker
      v-if="annotationsByType.automated.length"
      :automated="true"
      :count="annotationsByType.automated.length"
      @click="$emit('click', true)"
    />
  </div>
</template>

<script>
// components
  import AnnotationMarker from '@/components/PlanDesign/AnnotationMarker/Marker.vue';

  /**
   * Annotation indicator grouping
   *
   * @vuedoc
   * @exports PlanDesignTableAnnotationMarkerGroup
   * @category Components
   */
  export default {
    name: 'PlanDesignTableAnnotationMarkerGroup',
    components: {
      AnnotationMarker,
    },
    props: {
      annotations: {
        type: Array,
        default: () => [],
      },
    },
    computed: {
      /**
       * Split annotations into two sets
       *
       * @returns {object}
       */
      annotationsByType() {
        return {
          automated: this.annotations
            .filter((annotation) => annotation.automated),
          user: this.annotations
            .filter((annotation) => !annotation.automated),
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .annotation-marker {
    display: block;
    margin-top: 3px;

    &:first-of-type {
      margin-top: 13px;
    }

    &-group {
      position: absolute;
      left: -23px;
      height: 52px;
      top: 1px;
      // offset single icons
      margin-top: 11px;

      &.multiple-annotation-types {
        // don't offset if there are multiple annotation types stacked
        margin-top: 0;
      }

      // read only rows have less height, shift the whole group up
      .read-only-row & {
        top: -5px;
      }

      // TODO: use this/find a project that uses this
      &.has-faux-top-cell {
        top: 62px;
      }
    }
  }
</style>
