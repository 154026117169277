<template>
  <div>
    <h5 :class="{ 'is-hidden-plan': isHidden }">
      {{ planDescription }}
      <AppButton
        v-if="!isEditing && !isHidden"
        key="toggle-edit"
        :is-disabled="isHidden"
        type="secondary"
        class="btn-edit"
        icon="fa-solid fa-pencil"
        size="icon"
        @click="isEditing = true"
      />
    </h5>
    <PlanDesignValue
      v-for="(planDesignValue, planDesignValueIndex) in planDesignValues"
      :key="planDesignValue.tier_subtype_name || 'no-subtype'"
      :attribute="attribute"
      :attribute-index="attributeIndex"
      :category-index="categoryIndex"
      :copy-enabled="plan.copyEnabled"
      :is-editing="isEditing"
      :is-hidden="isHidden"
      :plan-id="plan.id"
      :plan-index="planIndex"
      :plan-design-value="planDesignValue"
      :plan-design-value-index="planDesignValueIndex"
      :subtypes="subtypes"
      data-test="plan design value"
    />
  </div>
</template>

<script>
  import { mapState } from 'pinia';
  import PlanDesignValue from '@/components/PlanDesign/FromTemplate/Value.vue';
  import { useProjectProductStore } from '@/stores/projectProduct.js';
  import { useProductContainersStore } from '@/stores/productContainers.js';
  import { useProductStructuresStore } from '@/stores/productStructures.js';

  /**
   * Plan Design Plan From Template
   *
   * @vuedoc
   * @exports PlanDesignPlan
   * @category Components
   */
  export default {
    name: 'PlanDesignPlan',
    components: {
      PlanDesignValue,
    },
    props: {
      attribute: {
        type: Object,
        default: () => ({}),
        required: true,
      },
      attributeIndex: {
        type: Number,
        default: 0,
        required: true,
      },
      categoryIndex: {
        type: Number,
        default: 0,
        required: true,
      },
      isHidden: {
        type: Boolean,
        default: false,
      },
      plan: {
        type: Object,
        default: () => ({}),
        required: true,
      },
      planIndex: {
        type: Number,
        default: 0,
        required: true,
      },
    },
    data() {
      return {
        isEditing: false,
      };
    },
    computed: {
      ...mapState(useProductStructuresStore, ['availablePlanDesignTierGroups']),
      ...mapState(useProjectProductStore, ['validPlanDesign']),
      ...mapState(useProductContainersStore, ['currentContainers']),
      /**
       * Returns the plan description from the current containers in store based on what the plan ID is.
       *
       * @returns {string}
       */
      planDescription() {
        return this.currentContainers.find((container) => container.id === this.plan.id).description;
      },
      /**
       * Returns an array of plan values.
       * IF the attribute has no values (isHidden): return the an object based on the first subtype item.
       * ELSE IF copy is enabled: return only the first value from plan.values
       * ELSE: return all plan values
       *
       * @returns {Array}
       */
      planDesignValues() {
        if (this.isHidden) {
          if (this.plan.associatedTierGroupId) {
            return [this.subtypes.map((subtype) => ({
              ids: [],
              project_products_container_ids: [],
              tier_group_id: this.plan.associatedTierGroupId,
              tier_subtype_id: subtype.subtype_id,
              tier_subtype_name: subtype.subtype_name,
              value: '',
            }))[0]];
          }

          return [{
            ids: [],
            project_products_container_ids: [],
            tier_group_id: null,
            tier_subtype_id: null,
            tier_subtype_name: null,
            value: '',
          }];
        }

        if (this.plan.copyEnabled) {
          /**
           * "copyEnabled" seems to, for all intents and purposes, act like a rollup of values.
           * However, if we want to show the correct annotation markers on the rolled up row,
           * we need all the valueIds…and not just the ids inside the first value.
           * As such, this workaround grabs all value ids and creates a new "value" that is,
           * well, kind of a fake value, since it's a combination of element 1 and the aggregate
           * of the value ids.
           *
           * Since it sounds a lot like the Dental/non-dental (template based) work is going to be redone,
           * I'm personally okay with this workaround, as it's at least structurally sound.
           */
          const allIds = this.plan.values.reduce((array, value) => array.concat(value.ids), []);

          return [{
            ...this.plan.values[0],
            ids: allIds,
          }];
        }

        return this.plan.values;
      },
      /**
       * Returns an array of subtypes.
       * IF there is an associated tier group to this plan type: return the subtypes for that tier group.
       * ELSE: return an empty array.
       *
       * @returns {Array}
       */
      subtypes() {
        return this.plan.associatedTierGroupId
          ? this.availablePlanDesignTierGroups.find((tierGroup) => tierGroup.tier_group_id === this.plan.associatedTierGroupId).tier_subtypes
          : [];
      },
    },
    watch: {
      /**
       * sets editing to true if hidden is false
       */
      isHidden() {
        if (!this.isHidden) {
          this.isEditing = true;
        }
      },
      /**
       * sets editing to false if plan design is valid
       */
      validPlanDesign() {
        if (this.validPlanDesign) {
          this.isEditing = false;
        }
      },
    },
    mounted() {
      this.isEditing = this.validPlanDesign === false;
    },
  };
</script>

<style lang="scss" scoped>
  h5 {
    display: flex;
    justify-content: space-between;
    margin: 0;
    padding: 5px 0;
    font: {
      size: 14px;
      weight: 600;
    }

    &.is-hidden-plan {
      color: var(--tf-gray-medium);
    }
  }

  .app-button.btn-edit {
    margin-left: 5px;
  }
</style>
