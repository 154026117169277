<template>
  <div class="team-members">
    <TfTable
      v-loading="!isLoaded"
      :table-data="tableData"
      :table-meta="tableMeta"
    >
      <template #title>
        Assigned broker user{{ tableData.length > 1 ? 's' : '' }}
      </template>
      <template #ctaHeader>
        <AppButton
          type="primary"
          icon="fa-solid fa-pencil"
          size="text"
          class="edit-team-btn"
          text="Edit broker team"
          @click="showEditBrokerTeamModal = true"
        />
      </template>
    </TfTable>
    <ModalBrokerUsers
      v-if="showEditBrokerTeamModal"
      :project-id="Number($route.params.projectId)"
      @modalClosed="showEditBrokerTeamModal = false"
    />
  </div>
</template>

<script>
  import { mapState } from 'pinia';
  import { useBrokerTeamStore } from '@/stores/brokerTeam.js';
  import ModalBrokerUsers from '@/components/Modal/EditBrokerUsers/index.vue';
  /**
   * Renders a read-only list of broker team members.
   *
   * @vuedoc
   * @exports TeamMemberTable
   * @category Components
   */
  export default {
    name: 'TeamMemberTable',
    components: {
      ModalBrokerUsers,
    },
    data: () => ({
      showEditBrokerTeamModal: false,
      tableMeta: {
        columns: [
          {
            prop: 'fullName',
            label: 'Full name',
          },
          {
            prop: 'type',
            label: 'Assignment type',
          },
        ],
      },
    }),
    computed: {
      ...mapState(useBrokerTeamStore, [
        'brokerTeamMembers',
        'isLoaded',
        'placementEventTeamMembers',
      ]),
      /**
       * Returns an array of the projects users with a single added property
       * that "polyfills" the two different style API responses.
       * Note that a single user can exist in both sets of data, so therefore,
       * IDs are prefixed with something so that we can use the (new) id as
       * keys in the table.
       *
       * @returns {Array}
       */
      tableData() {
        const formattedBrokerTeamMembers = this.brokerTeamMembers
          .map(({ full_name: fullName, user_id: id }) => ({
            fullName,
            id: `BT-${id}`,
            type: 'Broker team',
          }));
        const formattedPlacementEventTeamMembers = this.placementEventTeamMembers
          .map(({ first_name: firstName, id, last_name: lastName }) => ({
            // this response does not have a full_name prop on the users so we have to build it.
            fullName: `${firstName} ${lastName}`,
            id: `PET-${id}`,
            type: 'Placement event team',
          }));

        return [
          ...formattedBrokerTeamMembers,
          ...formattedPlacementEventTeamMembers,
        ];
      },
    },
  };
</script>

<style lang="scss" scoped>
  .team-members {
    margin-top: 36px;
  }
</style>
