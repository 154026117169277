import Vue from 'vue';
import { defineStore } from 'pinia';
import { createPlans } from '@/utils/piniaHelpers.js';
import { useProductStructuresStore } from '@/stores/productStructures.js';
import { useProductContainersStore } from '@/stores/productContainers.js';

export const useProductAttributesStore = defineStore('productAttributes', {
  state: () => ({
    attributesHidden: {},
    lastAttributeUpdated: null,
    rateAttributes: [],
    totalPlanDesignAttributes: null,
    isSettingCategoriesAndAttributes: false,
    planDesignErrorCount: null,
    rateErrorCount: null,
    validatingPlanDesignAttributes: {},
    validatingRates: {},
  }),
  actions: {
    /**
     * Creates plan attributes, sorts and returns them if usePlanTemplates
     * or creates categories and sets store values to them and returns blank array
     *
     * @param {object} payload
     * @param {Array} payload.containers
     * @param {object} payload.product
     * @param {Array} payload.tierGroups
     * @param {boolean} payload.updateStore
     * @param {boolean} payload.usePlanTemplates
     * @returns {object|Array}
     */
    setCategoriesAndAttributes({
      containers,
      product,
      tierGroups,
      updateStore = true,
      usePlanTemplates = false,
    }) {
      this.isSettingCategoriesAndAttributes = true;
      const attributesHidden = {};
      const categories = [];
      let totalPlanDesignAttributes = 0;

      this.validatingPlanDesignAttributes = {};

      const createAttributes = (attributes) => attributes.map((attribute) => {
        const clonedAttribute = { ...attribute };

        // IF Dental Product: map plans according to the current containers.
        if (usePlanTemplates) {
          clonedAttribute.plans = createPlans({
            containers,
            groupedValues: attribute.grouped_values,
            tierGroups,
          });
        } else {
          totalPlanDesignAttributes += 1;
          // Set if attribute to hidden if there are no values.
          // This is used for non-templated products, which are using a computed property to determine if the attribute should be hidden.
          attributesHidden[attribute.id] = !attribute.grouped_values.length;
        }

        this.validatingPlanDesignAttributes[attribute.id] = false;

        // reverse-alphabetically-naturally-sort the normalized values until we add positions to the back-end.
        clonedAttribute.normalized_values = clonedAttribute.normalized_values
          .slice()
          .sort(
            (a, b) => a.normalized_value.localeCompare(
              b.normalized_value,
              'en',
              {
                numeric: true,
                sensitivity: 'base',
              },
            ),
          )
          .reverse();

        return clonedAttribute;
      });

      product.plan_design_categories.forEach((category) => {
        categories.push({
          categorized_attributes: createAttributes(category.categorized_attributes),
          category_order: category.category_order,
          id: category.id,
          name: category.name,
        });
      });

      if (updateStore) {
        const productStructuresStore = useProductStructuresStore();

        this.attributesHidden = attributesHidden;
        this.totalPlanDesignAttributes = totalPlanDesignAttributes;
        productStructuresStore.planDesignCategories = categories;
        this.rateAttributes = product.rate_attributes;
        this.validatingRates = Object.fromEntries(this.rateAttributes.map(({ id }) => [id, false]));
      } else {
        this.isSettingCategoriesAndAttributes = false;

        return categories;
      }

      this.isSettingCategoriesAndAttributes = false;

      return [];
    },
    /**
     * Creates plan called clonedAttribute and sets it in productStructure store if createPlansFromActiveStoreProduct
     * or sets sets store values then creates and sets categories in productStructuresStore
     *
     * @param {object} payload
     * @param {object} payload.updatedAttribute
     * @param {number} payload.attributeIndex
     * @param {number} payload.categoryIndex
     * @param {boolean} payload.createPlansFromActiveStoreProduct
     */
    updatePlanAttribute({
      updatedAttribute,
      attributeIndex,
      categoryIndex,
      createPlansFromActiveStoreProduct,
    }) {
      const productStructuresStore = useProductStructuresStore();

      if (createPlansFromActiveStoreProduct) {
        const productContainersStore = useProductContainersStore();
        const clonedAttribute = {
          ...updatedAttribute,
          plans: createPlans({
            containers: productContainersStore.currentContainers,
            groupedValues: updatedAttribute.grouped_values,
            tierGroups: productStructuresStore.availablePlanDesignTierGroups,
          }),
        };

        Vue.set(
          productStructuresStore.planDesignCategories[categoryIndex].categorized_attributes,
          attributeIndex,
          clonedAttribute,
        );

        return;
      }

      this.lastAttributeUpdated = updatedAttribute;

      let attributeFound = false;

      for (const category of productStructuresStore.planDesignCategories) {
        if (attributeFound) {
          break;
        }
        for (const [key, value] of Object.entries(Object.entries(category.categorized_attributes))) {
          const attribute = value[1];

          if (attribute.id === updatedAttribute.id) {
            attributeFound = true;
            Vue.set(category.categorized_attributes, key, updatedAttribute);
            break;
          }
        }
      }
    },
    /**
     * Sets groupedValues in store rateAttributes based on attributeId.
     *
     * @param {object} payload
     * @param {number} payload.attributeId
     * @param {Array} payload.groupedValues
     */
    updateRateAttributeValues({ attributeId, groupedValues }) {
      /**
       * Update the grouped_values of rateAttributes in the store
       * Called when the user changes a rate value
       */
      this.rateAttributes.forEach((rateAttr, index) => {
        if (rateAttr.id === attributeId) {
          Vue.set(this.rateAttributes[index], 'grouped_values', groupedValues);
        }
      });
    },
  },
});
