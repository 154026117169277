<template>
  <ElForm
    ref="value-form"
    :class="[
      { 'is-hidden-plan': isHidden },
      { active: isActive },
    ]"
    :model="localForm"
    label-position="left"
    label-width="120px"
    data-test="value form"
    @click.native="setActiveValue()"
    @focus.capture="setActiveValue()"
  >
    <AnnotationMarkerGroup
      v-if="hasAnnotation"
      :annotations="planDesignValueAnnotations"
      data-test="show first annotation"
      @click="showFirstAnnotation"
    />
    <ElFormItem
      prop="value"
      :label="valueLabel"
      :rules="{
        message: `Value is required.`,
        trigger: 'none',
        required: !isHidden,
      }"
    >
      <TfInputWithOptions
        v-if="isEditing"
        v-model="localForm.value"
        :attribute-id="attribute.id"
        :disabled="copyingSubtype || isHidden"
        :options="attribute.normalized_values"
        :saving="copyingSubtype"
        :suffix-clickable="suffixIcon"
        width="auto"
        prop="value"
        :validation-type="validationType"
        data-test="plan value input with options"
        @change="onValueChange"
        @suffixClick="onSuffixClick"
      />
      <p v-else>
        {{ localForm.value || '&mdash;' }}
      </p>
    </ElFormItem>
  </ElForm>
</template>

<script>
  import { mapState, mapWritableState, mapActions } from 'pinia';
  import { useProjectStore } from '@/stores/project.js';
  import { useFileViewerStore } from '@/stores/fileViewer.js';
  import { useAnnotationsStore } from '@/stores/annotations.js';
  import { useProductAttributesStore } from '@/stores/productAttributes.js';
  // services
  import ProductService from '@/services/product.js';
  // utils
  import ValidationUtil from '@/utils/validation.js';
  // components
  import AnnotationMarkerGroup from '@/components/PlanDesign/AnnotationMarker/Group.vue';
  import { usePdfAnnotationStore } from '@/stores/pdfAnnotation.js';
  import { useProductStructuresStore } from '@/stores/productStructures.js';
  import { useProductSelectionsStore } from '@/stores/productSelections.js';

  /**
   * Plan Design Value From Template
   *
   * @vuedoc
   * @exports PlanBasedValue
   * @category Components
   */
  export default {
    name: 'PlanBasedValue',
    components: {
      AnnotationMarkerGroup,
    },
    props: {
      attribute: {
        type: Object,
        default: () => ({}),
        required: true,
      },
      attributeIndex: {
        type: Number,
        default: 0,
        required: true,
      },
      categoryIndex: {
        type: Number,
        default: 0,
        required: true,
      },
      copyEnabled: {
        type: Boolean,
        default: false,
      },
      isEditing: {
        type: Boolean,
        default: false,
      },
      isHidden: {
        type: Boolean,
        default: false,
      },
      planDesignValue: {
        type: Object,
        required: true,
      },
      planDesignValueIndex: {
        type: Number,
        default: 0,
      },
      planId: {
        type: Number,
        default: 0,
        required: true,
      },
      planIndex: {
        type: Number,
        default: 0,
        required: true,
      },
      subtypes: {
        type: Array,
        default: () => ([]),
      },
    },
    data() {
      return {
        copyingSubtype: false,
        externalChange: false,
        localForm: {
          value: this.planDesignValue.value,
        },
      };
    },
    computed: {
      ...mapWritableState(useFileViewerStore, ['goToAnnotation']),
      ...mapWritableState(usePdfAnnotationStore, ['activeAnnotation', 'annotationModalMode']),
      ...mapWritableState(useProductAttributesStore, [
        'planDesignErrorCount',
      ]),
      ...mapState(useAnnotationsStore, ['annotationsByPlanDesignValueIds']),
      ...mapState(useProductStructuresStore, ['planDesignCategories', 'validatePlanTemplate']),
      ...mapState(useProjectStore, ['readOnlyMode']),
      ...mapState(useProductSelectionsStore, [
        'activeValueIds',
        'selectedProductId',
      ]),
      /**
       * Returns all annotations for this value
       * planDesignValue.plan_design_values is a new property added by DanDrust
       * which is pairs of value/container ids. This is used here to specify which value id is selected
       * from the rolled up (by the backend) ids property in planDesignValue.
       *
       * @returns {Array}
       */
      planDesignValueAnnotations() {
        if (!this.planDesignValue.plan_design_values) {
          return [];
        }

        const valueIds = this.planDesignValue.plan_design_values.filter(
          (value) => this.planDesignValue.project_products_container_ids.includes(value.project_products_container_id),
        ).map(({ id }) => id);

        return this.annotationsByPlanDesignValueIds(valueIds);
      },
      /**
       * Check if this value id/these value ids has annotations or not
       *
       * @returns {boolean}
       */
      hasAnnotation() {
        return !!this.planDesignValueAnnotations.length;
      },
      /**
       * Returns a boolean to see if this value is active
       * If copy is enabled, we need to check both IDs since this value only takes into consideration the first subtype.
       *
       * @returns {boolean}
       */
      isActive() {
        return this.copyEnabled
          ? this.attribute.plans[this.planIndex].values.find((value) => value.ids.filter((id) => this.activeValueIds.includes(id)).length > 0)
          : this.planDesignValue.ids.filter((id) => this.activeValueIds.includes(id)).length > 0;
      },
      /**
       * Returns the validation type for this attribute.
       *
       * @returns {boolean}
       */
      validationType() {
        return ValidationUtil.getValidationType(this.attribute.name);
      },
      /**
       * Returns a label for the input.
       * IF Copy is enabled: return a joined string based on all subtypes.
       * ELSE IF: Copy is not enabled && there is a subtype ID: return the subtype name.
       * ELSE: return 'None' string.
       *
       * @returns {string}
       */
      valueLabel() {
        let valueLabel = 'None';

        if (this.copyEnabled) {
          valueLabel = this.subtypes
            .map((subtypeObj) => subtypeObj.subtype_name)
            .join('/');
        } else if (!this.copyEnabled && this.planDesignValue.tier_subtype_id) {
          valueLabel = this.subtypes.find(
            (subtypeObj) => subtypeObj.subtype_id === this.planDesignValue.tier_subtype_id,
          ).subtype_name;
        }

        if (valueLabel.toLowerCase() === 'in-network/out-of-network') {
          valueLabel = 'IN/OON';
        }

        return valueLabel;
      },
      /**
       * Returns a icon string for the input.
       * IF there is a tier group ID && Copy is enabled: return `+`
       * IF there is a tier group ID, Copy is not enabled, && planDesignValueIndex isn't 0: return `-`
       *
       * @returns {string}
       */
      suffixIcon() {
        let icon;

        if (this.planDesignValue.tier_group_id) {
          if (this.copyEnabled) {
            icon = 'plus';
          }

          if (this.subtypes.length && this.planDesignValueIndex) {
            icon = 'minus';
          }
        }

        return icon;
      },
    },
    watch: {
      /**
       * Reset the validation form if toggling the attribute.
       */
      isHidden() {
        this.$refs['value-form'].resetFields();
      },
      planDesignValue: {
        /**
         * When annotating a value from the annotation modal, we need to watch to see if
         * the value has changed. This will trigger this.onValueChange() so we need to switch this.externalChange on.
         *
         * @param {object} newValue
         */
        handler(newValue) {
          if (this.localForm.value !== newValue.value) {
            this.externalChange = true;
            this.localForm.value = newValue.value;
          }
        },
        deep: true,
      },
      async validatePlanTemplate(validating) {
        if (validating) {
          const errorsFound = await this.validateValues();

          this.planDesignErrorCount = this.planDesignErrorCount !== null
            ? this.planDesignErrorCount + errorsFound
            : errorsFound;
        }
      },
    },
    methods: {
      ...mapActions(useProductStructuresStore, ['setPlanCopyEnabledState']),
      ...mapActions(usePdfAnnotationStore, ['closeAnnotationModal']),
      ...mapActions(useProductSelectionsStore, [
        'setActiveIds',
        'clearAnnotationData',
      ]),
      /**
       * Show the first annotation by the userType specified (automated = TE)
       *
       * @param {boolean} automated
       */
      showFirstAnnotation(automated = false) {
        const firstAnnotationIndex = this.planDesignValueAnnotations.findIndex((annotation) => {
          const idMatch = annotation.plan_design_values.some(
            ({ project_products_container_id: id }) => this.planDesignValue.project_products_container_ids.includes(id),
          );

          return annotation.automated === automated && idMatch;
        });

        this.showAnnotation(firstAnnotationIndex);
      },
      /**
       * Show the annotation by index
       *
       * @param {number} index
       */
      showAnnotation(index = 0) {
        this.goToAnnotation = this.planDesignValueAnnotations[index];
        this.activeAnnotation = this.planDesignValueAnnotations[index];

        if (!this.isActive) {
          this.setActiveValue();
        }

        // if we are currently in create mode, AND in readOnly mode,
        // selecting an existing annotation through Value
        // should close the Annotation Modal
        if (this.readOnlyMode && this.annotationModalMode === 'create') {
          this.closeAnnotationModal();
        } else {
          // if there's an annotation and we're not in readOnly mode,
          // let's show it
          this.annotationModalMode = 'view';
        }
      },
      /**
       * Set active information about this attribute
       */
      setActiveValue() {
        if (this.isHidden || this.activeValueIds.join('') === this.planDesignValue.ids.join('')) {
          return;
        }

        // Clear out the old values before starting
        if (this.activeValueIds.length) {
          this.clearAnnotationData();
        }

        this.setActiveIds({
          attributeId: this.attribute.id,
          categoryId: this.planDesignCategories[this.categoryIndex].id,
          productId: this.selectedProductId,
          valueIds: this.planDesignValue.ids,
        });

        if (!this.planDesignValueAnnotations.length) {
          this.goToAnnotation = null;
          this.closeAnnotationModal();
        }
      },
      /**
       * Method used when a user clicks the +/- in the input.
       * Validation should be cleared on click.
       * A copyEnabled mutation or delete/update plan value should be called based on copyEnabled state.
       */
      async onSuffixClick() {
        this.$refs['value-form'].resetFields();

        // IF copy is enabled: change `copyEnabled` key in the store plan object to false.
        if (this.copyEnabled) {
          this.setPlanCopyEnabledState({
            attributeIndex: this.attributeIndex,
            categoryIndex: this.categoryIndex,
            copyEnabled: false,
            planIndex: this.planIndex,
          });

          return;
        }

        // ELSE: Make an API call to DELETE the plan value (w/o removing from the store).
        // After the value is deleted, make an API call to PATCH to whatever the first plan value is.
        const subtypeId = this.planDesignValue.tier_subtype_id;

        this.copyingSubtype = true;

        this.setPlanCopyEnabledState({
          attributeIndex: this.attributeIndex,
          categoryIndex: this.categoryIndex,
          copyEnabled: true,
          planIndex: this.planIndex,
        });
        await this.updatePlanValue({
          copySubtype: true,
          subtypeId,
          value: this.attribute.plans[this.planIndex].values[0].value,
          valueIndex: 1,
        });
      },
      /**
       * Save a plan design value (or values if copy is enabled) after it changes.
       * There are external components that can change localForm.value so we want to make sure that those changes are caught and re-patched.
       *
       * @param {string} value
       */
      async onValueChange(value) {
        if (this.externalChange) {
          this.externalChange = false;

          return;
        }
        // IF copy enabled: go through each subtype ID and make an API call to PATCH the value.
        if (this.copyEnabled) {
          this.subtypes.forEach(async (subtype, subtypeIndex) => {
            await this.updatePlanValue({
              subtypeId: subtype.subtype_id,
              valueIndex: subtypeIndex,
              value,
            });
          });

          return;
        }
        // ELSE: PATCH with the subtype ID of the value
        await this.updatePlanValue({
          subtypeId: this.planDesignValue.tier_subtype_id,
          value,
        });
      },
      /**
       * API call to PATCH the value with the appropriate subtype ID and value.
       * Plan Designs from template require the store to be updated.
       *
       * @param {object} options
       * @param {boolean} options.copySubtype
       * @param {number} options.valueIndex
       * @param {number} options.subtypeId
       * @param {string} options.value
       */
      updatePlanValue({
        copySubtype = false,
        valueIndex = this.planDesignValueIndex,
        subtypeId,
        value,
      }) {
        try {
          ProductService.updatePlanValue({
            attributeId: this.attribute.id,
            attributeIndex: this.attributeIndex,
            categoryIndex: this.categoryIndex,
            containerIds: [this.planId],
            planIndex: this.planIndex,
            valueIndex,
            productId: this.selectedProductId,
            subtypeId,
            tierGroupId: this.planDesignValue.tier_group_id,
            updatePlanAttribute: false,
            updatePlanValue: !this.isHidden,
            value,
          });

          // Change `copyEnabled` key in the store plan object to true if copySubtype is true.
          if (copySubtype) {
            this.setPlanCopyEnabledState({
              attributeIndex: this.attributeIndex,
              categoryIndex: this.categoryIndex,
              copyEnabled: true,
              planIndex: this.planIndex,
            });
            this.copyingSubtype = false;
          }
        } catch {
          this.displayToast({
            message: 'There was an error updating the plan value.',
          });
        }
      },
      validateValues() {
        return new Promise((resolve) => {
          let errorsFound = 0;

          this.$refs['value-form'].validate((valid) => {
            if (!valid) {
              errorsFound += 1;
            }
          });
          resolve(errorsFound);
        });
      },
    },
  };

</script>

<style lang="scss" scoped>
  .el-form {
    padding: 5px;
    position: relative;
  }

  .annotation-marker {
    width: 14px;
    height: 14px;
    position: absolute;
    left: -26px;
    top: 12px;
    background: var(--tf-yellow);
    cursor: pointer;
  }

  .active {
    box-shadow: inset -2px -2px 0 0 var(--tf-green), inset 3px 2px 0 0 var(--tf-green);
  }

  :deep(.el-form-item) {
    margin-bottom: 0;

    .el-form-item__error {
      position: relative;
      padding-top: 4px;
      margin-bottom: 5px;
    }

    .el-form-item__label {
      padding-top: 3px;
      line-height: 21px;

      .is-hidden-plan & {
        color: var(--tf-gray-medium);
      }

      .is-required & {
        &:before {
          display: none;
        }
      }
    }
  }

  p {
    padding: 5px 16px;

    .is-hidden-plan & {
      color: var(--tf-gray-medium);
    }
  }

  .annotation-marker-group {
    margin-top: 0;
  }
</style>
