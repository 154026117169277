<template>
  <ConfirmTextModal
    :require-reason="requireReasonForClosingProject"
    :subtitle="employerName"
    confirm-text="CLOSE"
    data-test="close project modal"
    title="Close project"
    @save="onSave"
    @modalClosed="$emit('modalClosed')"
  >
    <p>
      Once you close this project, you will no longer see this&nbsp;RFP.
    </p>
    <template
      v-if="requireReasonForClosingProject"
      #disclaimer
    >
      Type the reason for closing this project.
    </template>
  </ConfirmTextModal>
</template>

<script>
  import { setProjectState } from '@/services/project.js';
  import { requireReasonForClosingProject } from '@/utils/featureFlags.js';

  /**
   * @vuedoc
   * @exports ModalCloseProject
   * @category Components
   */
  export default {
    name: 'ModalCloseProject',
    components: {
      ConfirmTextModal: () => import('@/components/Modal/ConfirmTextModal.vue'),
    },
    props: {
      employerName: {
        type: String,
        required: true,
      },
      projectId: {
        type: Number,
        required: true,
      },
    },
    computed: {
      /**
       * Evaluate the "requireReasonForClosingProject" feature flag.
       *
       * @returns {boolean}
       * @deprecated
       */
      requireReasonForClosingProject() {
        return this.$ld.checkFlags(requireReasonForClosingProject);
      },
    },
    methods: {
      /**
       * Set the project state to 'close_rfp', trigger a message, and emit appropriately.
       *
       * @param {string} value
       */
      async onSave(value) {
        try {
          const { project } = await setProjectState(
            this.projectId,
            'close_rfp',
            { closed_reason: value },
          );

          this.displayToast({
            message: `${this.employerName} has been marked closed.`,
            type: 'success',
          });
          this.$emit('projectStateChange', project);
        } catch (response) {
          this.displayToast({
            message: response?.data?.message
              ?? `There was an error closing ${this.employerName} (${this.projectId}).`,
            type: 'error',
          });

          this.$emit('error', this.projectId);
        }
      },
    },
  };
</script>
