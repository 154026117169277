import { defineStore } from 'pinia';

import { sortObjects } from '@watchtowerbenefits/es-utils-public';

import { getUsers } from '@/services/user.js';

export const useAdminsStore = defineStore('admins', {
  state: () => ({
    isLoaded: false,
    adminUsers: [],
  }),
  actions: {
    /**
     * Get, format and set the admin users
     */
    async loadAdminUsers() {
      try {
        const { users } = await getUsers();
        const sortedUsers = sortObjects(
          users.map((user) => ({
            ...user,
            fullName: `${user.first_name} ${user.last_name}`,
          })),
          'fullName',
        );

        this.adminUsers = sortedUsers;
      } catch {
        // We should eventually catch and handle any errors here
      } finally {
        this.isLoaded = true;
      }
    },
  },
});
