import axios from 'axios';
import { signOut } from '@/services/account.js';
import { useAuthStore } from '@/stores/auth.js';
import router from '@/router.js';
import { useLaunchDarklyStore } from '@/stores/launchDarkly.js';

import { parseLaunchDarklyConfiguration } from '@/utils/launchDarkly.js';

/**
 * Axios Interceptor File
 *
 * @exports Interceptor
 * @category Services
 */
export default {
  /**
   * activates the axios interceptor
   */
  activate() {
    axios.interceptors.request.use((config) => {
      const updatedConfig = config;

      updatedConfig.metadata = { startTime: new Date() };

      return updatedConfig;
    }, (error) => Promise.reject(error));

    axios.interceptors.response.use((response) => {
      // We let the healthCheck call handle this itself, since it runs before the store is initialized.
      // regex looks for any url that contains "core-api" and doesn't contain "health_check"
      if (response.config.url.match(/.*core-api((?!health_check).)*$/)) {
        try {
          const parsedConfig = parseLaunchDarklyConfiguration(response);
          const launchDarklyStore = useLaunchDarklyStore();

          launchDarklyStore.config = parsedConfig;
        } catch {
          // No launch darkly configuration header found
        }
      }

      /* TODO RLH - Revisit to identify if we can make a copy of the updatedResponse without breaking things
      Current code appeases no-param-reassign Linter rule, but is still passing a reference */
      const updatedResponse = response;

      updatedResponse.config.metadata.endTime = new Date();
      updatedResponse.duration = response.config.metadata.endTime - response.config.metadata.startTime;

      return updatedResponse;
    }, (error = {}) => {
      const { response } = error;
      const isNotAuthorized = !response || [401, 403].includes(response.status);
      // we can remove this if we change the sign in error code
      const isSignIn = response && !response.config.url.includes('v1/admin/sign_in');
      const authStore = useAuthStore();

      // we should also be checking that this came from our api server (and not a third party api
      if (
        authStore.confirmed
        && isNotAuthorized
        && isSignIn
      ) {
        this.inactiveSignOut();
      }

      return Promise.reject(error);
    });
  },
  /**
   * Method that adds the LogRocket SessionURL to the headers each time an
   * Axios Request is made
   *
   * @param {string} sessionURL
   */
  addLogRocketURLToHeaders(sessionURL) {
    axios.interceptors.request.use((config) => ({
      ...config,
      headers: {
        ...config.headers,
        'X-LogRocket-URL': sessionURL,
      },
    }));
  },
  /**
   * Call the sign out service and then redirect the user to the sign in page
   * This gets passed down to the WatchtowerBenefits plugin so it can call it too
   */
  inactiveSignOut() {
    signOut(true)
      .then(() => {
        router.push({
          name: 'SignInPage',
          query: {
            redirect: window.location.pathname,
          },
        });
      })
      .catch(() => {
        this.displayToast({
          message: 'An error occurred while signing out.',
        });
      });
  },
};
