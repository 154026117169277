<template>
  <footer>
    <!-- Cancel/Save buttons -->
    <div
      class="btn-group"
    >
      <AppButton
        :is-disabled="isSaving"
        type="secondary"
        size="medium"
        icon="fa-solid fa-times"
        text="Cancel"
        @click="onCancel"
      />
      <AppButton
        :is-disabled="saveIsDisabled"
        :loading="isSaving"
        type="primary"
        size="medium"
        icon="fa-solid fa-check"
        text="Save"
        @click="onSave"
      />
    </div>
  </footer>
</template>

<script>
  import { usePdfAnnotationStore } from '@/stores/pdfAnnotation.js';
  import { mapActions, mapState } from 'pinia';

  /**
   * Annotation Modal Create Footer
   *
   * @vuedoc
   * @exports AnnotationModalFooter
   * @category Components
   */
  export default {
    name: 'AnnotationModalFooter',
    props: {
      selectedAttributeId: {
        type: Number,
        default: null,
      },
      selectedContainerIds: {
        type: Array,
        default: () => ([]),
      },
      selectedProductId: {
        type: Number,
        default: null,
      },
      selectedSubtypeId: {
        type: Number,
        default: null,
      },
      selectedSubtypeIds: {
        type: Array,
        default: () => [],
      },
      selectedTierGroupId: {
        type: Number,
        default: null,
      },
    },
    computed: {
      ...mapState(usePdfAnnotationStore, ['isSaving']),
      /**
       * Determine if the button should be disabled
       *
       * @returns {boolean}
       */
      saveIsDisabled() {
        const subTypeIsNotSelected = !this.selectedSubtypeId && !this.selectedSubtypeIds.length;

        return !this.selectedProductId // product is not selected
          || !this.selectedAttributeId // attribute is not selected
          || !this.selectedContainerIds.length // containers are not selected
          || (this.selectedTierGroupId && subTypeIsNotSelected); // tier group is selected but subtype is not
      },
    },
    methods: {
      ...mapActions(usePdfAnnotationStore, [
        'closeAnnotationModal',
        'setPdfAnnotationModalActionState',
      ]),
      /**
       * Close the modal by way of canceling.
       */
      onCancel() {
        this.closeAnnotationModal();
      },
      /**
       * Update modal to enter save state and emit save up to parent.
       */
      onSave() {
        this.setPdfAnnotationModalActionState({
          actionKey: 'isSaving',
          actionState: true,
        });
        this.$emit('save');
      },
    },
  };
</script>
