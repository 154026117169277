<template>
  <LfcApiTableParentRow
    data-test="table row"
    :row-data="rowData"
    :child-key="childKey"
  >
    <td
      v-for="column in columns"
      :key="column.id"
      :data-test="cellDataAttribute(column.prop)"
    >
      <div
        v-if="column.format === 'ctas'"
        class="btn-group align-end"
      >
        <RouterLink
          v-if="dashboardType !== 'completed'"
          :to="{
            name: 'ProjectSetup',
            params: { projectId: rowData[findByKey] },
          }"
        >
          <AppButton
            data-test="open product view"
            :text="btnText"
            type="primary"
            size="medium"
          />
        </RouterLink>
        <TfPopover
          v-model="showPopover"
          placement="bottom-end"
          popper-class="project-settings-popover"
          :offset="20"
          :append-to-body="false"
          class="project-settings"
          data-test="to project settings"
          width="200"
        >
          <!-- TODO LC-563 -->
          <AppButton
            slot="reference"
            :class="{ 'is-active': showPopover }"
            icon="fa-solid fa-cog"
            size="icon"
            data-test="open project actions menu button"
          />
          <ul>
            <li v-if="auditable">
              <AppButton
                data-test="policy audit button"
                size="text"
                type="primary"
                text="Start Policy Audit"
                :is-disabled="isCreatingPolicyAudit"
                @click="startPolicyAudit"
              />
            </li>
            <li v-if="showResetBtn">
              <AppButton
                data-test="reset project button"
                size="text"
                type="primary"
                text="Reset project"
                @click="emitAndClosePopover('openResetModal')"
              />
            </li>
            <li v-if="rowData.can_be_completed">
              <AppButton
                data-test="complete project button"
                size="text"
                type="primary"
                text="Complete project"
                @click="emitAndClosePopover('openCompleteProjectModal')"
              />
            </li>
            <li v-if="rowData.can_be_closed">
              <AppButton
                data-test="close project button"
                size="text"
                type="primary"
                text="Close project"
                @click="emitAndClosePopover('openCloseProjectModal')"
              />
            </li>
            <li>
              <AppButton
                data-test="edit broker users button"
                size="text"
                type="primary"
                text="Edit broker users"
                @click="emitAndClosePopover('openEditBrokerUsers')"
              />
            </li>
            <li>
              <RouterLink
                :to="{
                  name: 'ManageRFP',
                  params: { projectId: rowData[findByKey] },
                }"
              >
                <AppButton
                  size="text"
                  type="primary"
                  text="Manage RFP"
                />
              </RouterLink>
            </li>
          </ul>
        </TfPopover>
        <AppButton
          v-if="rowData.brokerUrl"
          :href="rowData.brokerUrl"
          icon="fa-solid fa-external-link"
          tag="a"
          size="icon"
          target="_blank"
          rel="noopener noreferrer"
          type="primary"
        />
      </div>
      <TableCell
        v-else
        v-bind="{
          column,
          rowData,
        }"
        @changeProjectOwner="changeProjectOwner"
        @dateSelected="dateSelected"
      />
    </td>
  </LfcApiTableParentRow>
</template>

<script>
  import { formatSimpleDate, trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';

  import ProjectService, {
    startPolicyAudit as startPolicyAuditApi,
  } from '@/services/project.js';
  import { mapState } from 'pinia';
  import { useUserInfoStore } from '@/stores/userInfo.js';
  // rff:policyAuditFlag
  import { policyAuditFlag } from '@/utils/featureFlags.js';
  import TableCell from '@/components/Dashboard/TableCell.vue';

  /**
   * Dashboard table Uses TfTable to display all our projects in various states
   *
   * @vuedoc
   * @exports ProjectRow
   * @category Components
   */
  export default {
    name: 'ProjectRow',
    components: {
      TableCell,
    },
    props: {
      findByKey: {
        type: String,
        default: 'project_id',
      },
      columns: {
        type: Array,
        required: true,
      },
      rowData: {
        type: Object,
        required: true,
      },
      dashboardType: {
        type: String,
        default: '',
      },
      childKey: {
        type: String,
        default: 'children',
      },
    },
    data: () => ({
      loaded: false,
      isActive: false,
      showPopover: false,
      isCreatingPolicyAudit: false,
    }),
    computed: {
      ...mapState(useUserInfoStore, ['broker', 'email']),
      /**
       * Button text
       *
       * @returns {string}
       */
      btnText() {
        let btnText = 'View';

        if (['my_collaborations', 'project_setup', 'my_projects'].includes(this.dashboardType)) {
          btnText = 'Edit';
        }

        return btnText;
      },
      /**
       * Move this logic
       *
       * @returns {boolean}
       */
      showResetBtn() {
        const isSameOrganization = this.broker?.name === this.rowData.broker_name;
        const isResettable = [
          'rfp_setup_completed',
          'rfp_closed',
          'rfp_sent_to_market',
          'rfp_modified',
          'rfp_modified_info',
          'rfp_modified_invites',
          'rfp_completed',
          'implementation',
          'policy_audit',
          'project_closed',
        ].includes(this.rowData.project_state);

        return isSameOrganization && isResettable;
      },
      /**
       * Evaluate the the can_be_audited prop and policyAuditFlag.
       *
       * @returns {boolean}
       */
      auditable() {
        return this.rowData.can_be_audited && this.policyAuditFlag;
      },
      /**
       * Evaluate the "policyAuditFlag" feature flag.
       *
       * @returns {boolean}
       */
      policyAuditFlag() {
        return this.$ld.checkFlags(policyAuditFlag);
      },
    },
    methods: {
      /**
       * Emit the event to open the modal, but close the popover at the same time.
       *
       * @param {string} event
       */
      emitAndClosePopover(event) {
        this.closePopover();
        this.$emit(event);
      },
      /**
       * Close the popover.
       *
       */
      closePopover() {
        this.showPopover = false;
      },
      /**
       * Start policy audit- hit API, close popover, and display message.
       *
       */
      async startPolicyAudit() {
        let message = 'Policy audit project has successfully been created.';
        let type = 'success';

        this.isCreatingPolicyAudit = true;

        try {
          await startPolicyAuditApi(this.rowData[this.findByKey]);

          trackSegmentEvent(
            'clicked start policy audit',
            {
              user_email: this.email,
              project_id: this.rowData[this.findByKey],
            },
          );
          this.$emit('projectCanBeAuditedChanged', false);
        } catch (error) {
          message = error.response.data.message ? error.response.data.message : 'Failed to create policy audit';
          type = 'error';
        } finally {
          this.isCreatingPolicyAudit = false;
          this.closePopover();
          this.$message({
            showClose: true,
            duration: 3000,
            message,
            type,
          });
        }
      },
      /**
       * Change the project owner based on userInfo Id.
       *
       * @param {number} userId
       */
      changeProjectOwner(userId) {
        ProjectService
          .setProjectData(this.rowData[this.findByKey], { wt_owner_id: userId })
          .then(({ project }) => {
            this.$emit('createMessage', { label: 'Owner', value: project.wt_owner_name || 'Un-assigned' });
            this.$emit('projectPropChanged', { prop: 'wt_owner_id', value: project.wt_owner_id, name: project.wt_owner_name });
          }).catch(() => {
            this.$emit('createMessage', { label: 'Owner', type: 'error' });
          });
      },
      /**
       * sets the date in project when date is selected
       *
       * @param {string} prop
       * @param {Date} date
       */
      dateSelected(prop, date) {
        const { label } = this.columns.find(({ prop: columnProp }) => columnProp === prop);

        ProjectService
          .setProjectData(this.rowData[this.findByKey], { [prop]: date })
          .then(({ project }) => {
            const newDate = project[prop];

            this.$emit('createMessage', { label, value: formatSimpleDate(newDate) });
            this.$emit('projectPropChanged', { prop, value: newDate });
          }).catch(() => {
            this.$emit('createMessage', { label, type: 'error' });
          });
      },
      /**
       * Based on the property passed in, returns a string with the name of the data attribute in question.
       *
       * @param {string} property
       * @returns {string}
       */
      cellDataAttribute(property) {
        let dataAttr;
        const splitProperty = property.split('_');

        switch (property) {
        case 'project_type':
          dataAttr = 'type';
          break;
        case 'wt_owner_name':
          dataAttr = 'owner';
          break;
        default:
          dataAttr = splitProperty.filter((str) => str !== 'name').join(' ');
          break;
        }

        return `${dataAttr} cell`;
      },
    },
  };
</script>

<style lang="scss" scoped>
  a:not(:first-child),
  .project-settings {
    margin-left: 14px;
  }

  li {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  :deep(.project-settings-popover) {
    &:focus {
      outline: none;
    }
  }

  .datepicker-container {
    margin-top: 0;
  }

  :deep(.vdp-datepicker) {
    input {
      width: 100px;
      border-color: transparent;
      transition: border-color .2s ease-out;

      &:hover {
        border-color: var(--tf-gray-medium);
        cursor: pointer;
      }

      &:focus {
        border-color: var(--tf-blue);
      }
    }
  }
</style>
