<template>
  <span class="container-append-slot">
    <TfProgressIcon
      v-if="showIcon"
      :progress-state="productValidationState"
      :data-test="`product validation state ${productValidationState}`"
    />
  </span>
</template>

<script>
  import { mapState } from 'pinia';
  import { useProjectProductStore } from '@/stores/projectProduct.js';
  import getProductState from '@/utils/productState.js';

  /**
   * The Validation slot that determines the appended progress indicator
   * for tf-progress-icon based on the products current state
   *
   * @vuedoc
   * @exports ValidationSlot
   * @category Components
   */
  export default {
    name: 'ValidationSlot',
    props: {
      showIcon: {
        type: Boolean,
        default: true,
      },
      projectProductId: {
        type: Number,
        default: null,
      },
    },
    computed: {
      ...mapState(useProjectProductStore, ['activeProjectProducts']),
      /**
       * Returns blank if no project product ID is found, otherwise
       * returns `not-started`, `half`, or `full` based on the product's state property
       * This string is used as a class name for tf-progress-icon
       *
       * @returns {string}
       */
      productValidationState() {
        if (!this.projectProductId) {
          return '';
        }

        const { inforce_product: { state } } = this.activeProjectProducts.find(({ id }) => id === this.projectProductId);
        const { current: parsedState } = getProductState(state);

        // QA 1, QA 2, or editing plan design should have half orange progress circle
        // TODO: editing plan design is only a band-aid for now until BE functionality is built out further
        if (parsedState.match(/qa|editing/i)) {
          return 'half';
        }

        // Complete should have full green checkmark progress circle
        if (parsedState.match(/complete/i)) {
          return 'full';
        }

        // Anything else will have an empty, grey progress circle
        return 'not-started';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .container-append-slot {
    display: flex;
    align-items: center;
    width: 16px;
    margin-left: 5px;
  }
</style>
