<template>
  <div class="manage-rfp-header">
    <h1>Manage RFP/Project</h1>
    <div class="flex">
      <div>
        <div class="fieldset">
          <AppIcon
            class="icon"
            icon="fa-light fa-building"
            size="xl"
          />
          <div>
            <h4>
              <span>Employer group:</span>
              {{ employerName }}
            </h4>
            <ManageRfpEditItem
              v-model="localEmployeeCount"
              data-test="ee count"
              label="EE count"
              @save="onEECountSave"
            />
          </div>
        </div>
        <div class="fieldset">
          <AppIcon
            class="icon"
            icon="fa-light fa-file-alt"
            size="xl"
          />
          <ManageRfpEditItem
            data-test="project number"
            label="Project number"
          >
            <RouterLink
              :to="{
                name: 'ProjectSetup',
                params: { projectId },
              }"
            >
              <AppButton
                :text="`${projectId}`"
                data-test="project number button"
                size="text"
                type="primary"
              />
            </RouterLink>
          </ManageRfpEditItem>
        </div>
      </div>
      <div>
        <div class="fieldset">
          <AppIcon
            class="icon"
            icon="fa-regular fa-calendar-days"
            size="xl"
          />
          <div>
            <ManageRfpEditItem
              v-model="localEffectiveDate"
              data-test-effective-date
              input-type="date"
              label="Effective date"
              @save="onProjectPropSave(
                'effective_date',
                'Effective date',
                $event,
              )"
            />
            <ManageRfpEditItem
              v-model="localReadyDate"
              data-test-ready-date
              input-type="date"
              label="Ready date"
              @save="onProjectPropSave(
                'staging_due_date',
                'Ready date',
                $event,
              )"
            />
            <ManageRfpEditItem
              v-model="localQaDate"
              data-test-qa-date
              input-type="date"
              label="QA due date"
              @save="onProjectPropSave(
                'qa_due_date',
                'QA due date',
                $event,
              )"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { formatSimpleDate } from '@watchtowerbenefits/es-utils-public';

  // Components
  import ManageRfpEditItem from '@/components/ManageRfp/EditItem.vue';
  // Services
  import { updateEmployerInfo } from '@/services/employer.js';
  import ProjectService from '@/services/project.js';
  // Misc
  import messages from '@/mixins/messages.js';

  /**
   * Header for Manage RFP Page
   *
   * @vuedoc
   * @exports ManageRfpHeader
   * @category Components
   */
  export default {
    name: 'ManageRfpHeader',
    components: {
      ManageRfpEditItem,
    },
    mixins: [
      messages,
    ],
    props: {
      effectiveDate: {
        type: String,
        default: '',
      },
      employeeCount: {
        type: Number,
        default: null,
      },
      employerId: {
        type: Number,
        default: null,
      },
      employerName: {
        type: String,
        default: '',
      },
      projectId: {
        type: Number,
        default: null,
      },
      readyDate: {
        type: String,
        default: '',
      },
      qaDate: {
        type: String,
        default: '',
      },
    },
    computed: {
      /**
       * Returns and emits updates for the employeeCount prop
       * get: copy the "employeeCount" prop.
       * set: $emit "input" with the "value"
       *
       * @returns {number}
       */
      localEmployeeCount: {
        get() {
          return this.employeeCount;
        },
        set(value) {
          this.$emit('input', value);
        },
      },
      /**
       * Returns and emits updates for the effectiveDate prop
       * get: copy the "effectiveDate" prop.
       * set: $emit "input" with the "value"
       *
       * @returns {string}
       */
      localEffectiveDate: {
        get() {
          return this.effectiveDate;
        },
        set(value) {
          this.$emit('input', value);
        },
      },
      /**
       * Returns and emits updates for the readyDate prop
       * get: copy the "readyDate" prop.
       * set: $emit "input" with the "value"
       *
       * @returns {string}
       */
      localReadyDate: {
        get() {
          return this.readyDate;
        },
        set(value) {
          this.$emit('input', value);
        },
      },
      /**
       * Returns and emits updates for the qaDate prop
       * get: copy the "qaDate" prop.
       * set: $emit "input" with the "value"
       *
       * @returns {string}
       */
      localQaDate: {
        get() {
          return this.qaDate;
        },
        set(value) {
          this.$emit('input', value);
        },
      },
    },
    methods: {
      /**
       * Call API to update employer's EECount (total_lives) and then emit up
       *
       * @param {number} eeCount
       */
      async onEECountSave(eeCount) {
        const label = 'EE Count';

        try {
          const { employer } = await updateEmployerInfo(
            this.employerId,
            { total_lives: eeCount },
          );

          this.createMessage({
            label,
            value: eeCount,
          });
          this.$emit('updateProp', {
            prop: 'employer',
            value: employer,
          });
        } catch {
          this.createMessage({ label, type: 'error' });
        }
      },
      /**
       * Call API to update projects effective date and then emit up
       *
       * @param {string} prop
       * @param {string} label
       * @param {string} value
       */
      async onProjectPropSave(prop, label, value) {
        try {
          const { project } = await ProjectService
            .updateProjectInfo(this.projectId, { [prop]: value });

          this.createMessage({
            label,
            value: formatSimpleDate(value),
          });
          this.$emit('updateProp', {
            prop,
            value: project[prop],
          });
        } catch {
          this.createMessage({ label, type: 'error' });
        }
      },
      /**
       * Trigger 'onPropUpdate' based on parameters.
       *
       * @param {object} options
       * @param {string} options.label
       * @param {string} options.type
       * @param {string} options.value
       */
      createMessage({ label, type = 'success', value }) {
        this.onPropUpdate({
          subject: this.employerName,
          label,
          type,
          value,
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .edit-rfp-item {
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .fa-icon {
    width: 22px;
    height: 25px;
    margin-right: 10px;
  }

  .fieldset {
    display: flex;
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }

    .icon {
      margin-top: 10px;
      margin-right: 10px;
    }
  }

  .flex {
    display: flex;
    max-width: 1200px;
    margin: auto;

    > div {
      flex: 1;
    }
  }

  .manage-rfp-header {
    padding: 25px;
    background: var(--tf-gray-light);
    border-bottom: 1px solid var(--tf-gray-light-medium);

    &,
    :deep(.app-button.is-text) {
      font-size: 16px;
    }
  }

  h1 {
    padding-bottom: 21px;
    max-width: 1200px;
    margin: auto;
  }

  h4 {
    margin-bottom: 0;

    span {
      text-transform: uppercase;
    }
  }
</style>
