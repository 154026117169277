<template>
  <header>
    <h2>{{ employer.name }}</h2>
    <div class="header-info-container">
      <div class="fieldset">
        <p>Total eligible lives: {{ employer.total_lives }}</p>
        <p>Sic code: {{ employer.sic_code }}</p>
        <p>Tax ID: {{ employer.tax_id }}</p>
      </div>
      <div class="fieldset">
        <p>
          Broker client since: {{ employer.client_acquisition_year }}
        </p>
        <p>
          <strong>Finalized {{ formatSimpleDate(employer.created_at, 'MM/DD/YYYY') }}</strong>
        </p>
      </div>
    </div>

    <div class="fieldset">
      <div class="fieldset-title">
        <p>Employer contact info</p>
      </div>
      <div class="fieldset-content">
        <p>{{ employer.address1 }}</p>
        <p v-if="employer.address2">
          {{ employer.address2 }}
        </p>
        <p>{{ employer.city }}, {{ employer.state }} {{ employer.zipcode }}</p>
        <a
          :href="employerUrl"
          target="_blank"
          rel="noopener noreferrer"
        >{{ employer.website }}</a>
      </div>
    </div>
    <div class="fieldset">
      <div class="fieldset-title">
        <p>Benefit system</p>
      </div>
      <div class="fieldset-content">
        <p>{{ employer.benefit_administrator }}</p>
      </div>
    </div>
    <div
      v-if="employer.finalized_by"
      class="fieldset"
    >
      <div class="fieldset-title">
        <p>Finalized by</p>
      </div>
      <div class="fieldset-content">
        <p>
          {{ employer.finalized_by.first_name }} {{ employer.finalized_by.last_name }}<br>
          {{ employer.finalized_by.email }}<br>
        </p>
      </div>
    </div>
  </header>
</template>

<script>
  import { formatSimpleDate } from '@watchtowerbenefits/es-utils-public';

  /**
   * Header for Client Setup Info
   *
   * @vuedoc
   * @exports SetupHeader
   * @category Components
   */
  export default {
    name: 'SetupHeader',
    props: {
      employer: {
        type: Object,
        required: true,
      },
    },
    computed: {
      /**
       * Evaluate the client record website and return a url with "http://" if necessary.
       *
       * @returns {string}
       */
      employerUrl() {
        let url;
        const testPattern = /^(?:http[s]*:)*\/\//i;

        if (this.employer.website && this.employer.website.toLowerCase() !== 'not provided') {
          url = testPattern.test(this.employer.website)
            ? this.employer.website
            : `http://${this.employer.website}`;
        }

        return url;
      },
    },
    methods: {
      formatSimpleDate,
    },
  };
</script>

<style lang="scss" scoped>
  header {
    margin-bottom: 55px;
  }

  h2 {
    margin-bottom: 5px;
  }

  p {
    margin: 0;
  }

  strong {
    color: var(--tf-gray);
    font-weight: 100;
  }

  .header-info-container {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--tf-gray-medium);
    padding-bottom: 20px;

    .fieldset {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      border-bottom: none;
      padding: 0;

      &:last-of-type {
        text-align: right;
      }
    }
  }

  .fieldset {
    border-bottom: 1px solid var(--tf-gray-medium);
    padding: 13px 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .fieldset-title,
    .fieldset-content {
      width: 100%;
    }
  }
</style>
