import Vue from 'vue';
import { defineStore } from 'pinia';

export const useProductStructuresStore = defineStore('productStructures', {
  state: () => ({
    availablePlans: [],
    availablePlanDesignTierGroups: [],
    availableRateTierGroups: [],
    planDesignCategories: [],
    validatePlanTemplate: false,
  }),
  actions: {
    /**
     * Returns number of validation errors.
     *
     * @returns {Promise} number
     */
    validatePlanDesignValues() {
      return new Promise((resolve) => {
        let errors = 0;

        this.planDesignCategories.forEach((category) => {
          category.categorized_attributes.forEach((attribute) => {
            if (attribute.plans.some((plan) => plan.values.length)) {
              attribute.plans.forEach((plan) => {
                if (attribute.plans.some(({ values }) => values.length)) {
                  const emptyValues = plan.values.filter((value) => !value.value).length;

                  if (emptyValues) {
                    errors += plan.copyEnabled
                      ? emptyValues - 1
                      : emptyValues;
                  }
                }
              });
            }
          });
        });

        resolve(errors);
      });
    },
    /**
     * Sets copyEnabled in nested plan.
     *
     * @param {object} payload
     * @param {number} payload.attributeIndex
     * @param {number} payload.categoryIndex
     * @param {boolean} payload.copyEnabled
     * @param {number} payload.planIndex
     */
    setPlanCopyEnabledState({
      attributeIndex,
      categoryIndex,
      copyEnabled,
      planIndex,
    }) {
      Vue.set(
        this.planDesignCategories[categoryIndex].categorized_attributes[attributeIndex].plans[planIndex],
        'copyEnabled',
        copyEnabled,
      );
    },
    /**
     * adds plan design values in categories.
     *
     * @param {object} payload
     * @param {number} payload.attributeIndex
     * @param {number} payload.categoryIndex
     * @param {string} payload.planDesignValue
     * @param {number} payload.planIndex
     * @param {number} payload.tierGroupId
     */
    addPlanDesignValues({
      attributeIndex,
      categoryIndex,
      planDesignValue,
      planIndex,
      tierGroupId,
    }) {
      const { values } = this
        .planDesignCategories[categoryIndex]
        .categorized_attributes[attributeIndex]
        .plans[planIndex];

      values.push(planDesignValue);

      if (planDesignValue.tier_subtype_id) {
        const subtypes = this.availablePlanDesignTierGroups.find(
          (tierGroup) => tierGroup.tier_group_id === tierGroupId,
        ).tier_subtypes;

        if (subtypes.length > 1) {
          values.sort((a, b) => {
            const positionA = subtypes.find(
              (subtype) => subtype.subtype_id === a.tier_subtype_id,
            ).subtype_position;
            const positionB = subtypes.find(
              (subtype) => subtype.subtype_id === b.tier_subtype_id,
            ).subtype_position;

            return positionA - positionB;
          });
        }
      }
    },
    /**
     * Sets plan design values in categories.
     *
     * @param {object} payload
     * @param {number} payload.attributeIndex
     * @param {number} payload.categoryIndex
     * @param {string} payload.planDesignValue
     * @param {number} payload.planIndex
     * @param {number} payload.valueIndex
     */
    setPlanDesignValues({
      attributeIndex,
      categoryIndex,
      planDesignValue,
      planIndex,
      valueIndex,
    }) {
      /* eslint-disable indent */
      this
        .planDesignCategories[categoryIndex]
        .categorized_attributes[attributeIndex]
        .plans[planIndex]
        .values[valueIndex] = {
        ...this.planDesignCategories[categoryIndex]
          .categorized_attributes[attributeIndex]
          .plans[planIndex]
          .values[valueIndex],
        ...planDesignValue,
      };
      /* eslint-enable indent */
    },
    /**
     * Deletes plan design value.
     *
     * @param {object} payload
     * @param {boolean} payload.allValues
     * @param {number} payload.attributeIndex
     * @param {number} payload.categoryIndex
     * @param {number} payload.planIndex
     * @param {number} payload.valueIndex
     */
    deletePlanDesignValueFromStructure({
      allValues = false,
      attributeIndex,
      categoryIndex,
      planIndex,
      valueIndex,
    }) {
      if (allValues) {
        Vue.set(
          this
            .planDesignCategories[categoryIndex]
            .categorized_attributes[attributeIndex]
            .plans[planIndex],
          'values',
          [],
        );

        return;
      }

      this
        .planDesignCategories[categoryIndex]
        .categorized_attributes[attributeIndex]
        .plans[planIndex]
        .values
        .splice(valueIndex, 1);
    },
  },
});
