<template>
  <div v-if="isProjectLoaded">
    <ManageRfpHeader
      :effective-date="project.effective_date"
      :employee-count="project.employer.total_lives"
      :employer-id="project.employer.id"
      :employer-name="project.employer.name"
      :project-id="project.id"
      :qa-date="project.qa_due_date"
      :ready-date="project.staging_due_date"
      @updateProp="onUpdateProp"
    />
    <div class="manage-rfp-main">
      <PremiumsCompensation
        :project-products="project.project_products"
      />

      <div class="manage-rfp-users">
        <TeamMemberTable />

        <TfTable
          :table-data="tableData"
          :table-meta="tableMeta"
        >
          <template #title>
            ThreeFlow user{{ tableData.length > 1 ? 's' : '' }}
          </template>
          <template #parentRow="{ rowData }">
            <ProjectCellData
              v-bind="{
                adminUsers,
                columns: tableMeta.columns,
                rowData: rowData ?? {},
                dashboardType: 'rfp',
              }"
              @createMessage="onPropUpdate({
                subject: rowData.employer.name,
                label: $event.label,
                type: $event.type,
                value: $event.value,
              })"
            />
          </template>
        </TfTable>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapState, mapWritableState } from 'pinia';
  import { useAdminsStore } from '@/stores/admins.js';
  import { useBrokerTeamStore } from '@/stores/brokerTeam.js';

  // Components
  import TeamMemberTable from '@/components/ManageRfp/TeamMemberTable.vue';
  import ManageRfpHeader from '@/components/ManageRfp/Header.vue';
  import PremiumsCompensation from '@/components/ManageRfp/PremiumsCompensation/index.vue';
  import ProjectCellData from '@/components/Dashboard/ProjectCellData.vue';
  // Services
  import { getEmployerBrokerTeamMembers } from '@/services/employer.js';
  import { getProjectInfo } from '@/services/project.js';
  // Misc
  import messages from '@/mixins/messages.js';

  /**
   * Manage RFP Details.
   *
   * @vuedoc
   * @exports ManageRfp
   * @category Views
   */
  export default {
    name: 'ManageRFP',
    components: {
      TeamMemberTable,
      ManageRfpHeader,
      PremiumsCompensation,
      ProjectCellData,
    },
    mixins: [
      messages,
    ],
    props: {
      projectId: {
        type: [Number, String],
        default: null,
      },
    },
    data: () => ({
      isProjectLoaded: false,
      project: null,
      tableData: [],
      tableMeta: {
        columns: [{
          format: 'ownerSelect',
          label: 'Full name',
          prop: 'wt_owner_name',
        }],
      },
    }),
    computed: {
      ...mapWritableState(useBrokerTeamStore, [
        'brokerId',
        'brokerTeamMembers',
        'employer',
        'isInitialBuild',
        'isLoaded',
        'isLoadedFromLocation',
        'placementEventTeamMembers',
      ]),
      ...mapState(useAdminsStore, ['adminUsers']),
    },
    /**
     * On create, lets grab the project level info.
     *
     * NOTE: Broker team data can be pulled down and loaded
     * in two places; ManageRfp and the EditBrokerUsers modal itself.
     * This duplication of loading logic will probably be addressed ASAP.
     *
     * We are managing TWO is(Project)Loaded states because we're dealing with
     * the following:
     * - ManageRfp itself only needs Project.
     * - Anything rendering a team member table (tabbed in the modal,
     * unified in the ManageRfp view) will need access to Project AND
     * EmployerBrokerTeamMembers.
     *
     * As such, the isProjectLoaded in ManageRfp becomes `true` when
     * ProjectInfo is available, and isProjectLoaded for BrokerTeam isn't
     * set to `true` until the second API call finishes.
     */
    async created() {
      try {
        const { project } = await getProjectInfo(this.projectId);

        this.project = project;
        this.isProjectLoaded = true;
        this.tableData.push(project);
        this.placementEventTeamMembers = project.broker_project_users;
        this.brokerId = project.broker_id;
        this.employer = project.employer;
        this.isInitialBuild = project.type === 'Projects::InitialEmployerBuild';

        try {
          const { broker_team_members: brokerTeamMembers } = await getEmployerBrokerTeamMembers(project.employer.id);

          this.brokerTeamMembers = brokerTeamMembers;
          this.isLoaded = true;
          this.isLoadedFromLocation = 'ManageRfp';
        } catch {
          this.displayToast({
            message: 'There was an error getting the broker team members.',
          });
        }
      } catch {
        this.displayToast({
          message: 'There was an error getting the project info.',
        });
      }
    },
    /**
     * Before destroy, empty out the broker team store.
     */
    beforeDestroy() {
      this.resetBrokerTeamStore();
    },
    methods: {
      ...mapActions(useBrokerTeamStore, ['resetBrokerTeamStore']),
      /**
       * Update Project prop based on the prop and value passed
       *
       * @param {object} $event
       */
      onUpdateProp($event) {
        this.project[$event.prop] = $event.value;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .manage-rfp-main {
    padding: 65px 25px 25px;
    display: flex;
    max-width: 1200px;
    margin: auto;

    > * {
      flex: 1;
      padding-right: 20px;
    }
  }

  h3 {
    margin: 0 0 14px 20px;
  }

  :deep(.table) {
    margin-bottom: 20px;
  }

  .app-button.edit-team-btn {
    margin: 0 0 20px 20px;
  }

  .manage-rfp-users {
    position: relative;
  }
</style>
