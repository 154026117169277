import axios from 'axios';
import { getAxiosConfig } from '@watchtowerbenefits/es-utils-public';
import { config } from '@/utils/config.js';

const apiUrl = config.VUE_APP_API_URL;
const cookieNameSpace = config.VUE_APP_COOKIE_NAMESPACE;

/**
 * All carrier related ajax calls
 *
 * @exports Carrier
 * @category Services
 */
/**
 * Get all the different carriers from the DB
 *
 * @returns {Promise}
 */
export function getCarriers() {
  return new Promise((resolve, reject) => {
    const axiosConfig = getAxiosConfig(cookieNameSpace);
    const url = `${apiUrl}/v1/admin/carriers`;

    axios
      .get(url, axiosConfig)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export default {
  /**
   * Get the info for a specific carrier
   *
   * @param {number} carrierId
   * @returns {Promise}
   */
  getCarrier(carrierId) {
    return new Promise((resolve, reject) => {
      const axiosConfig = getAxiosConfig(cookieNameSpace);
      const url = `${apiUrl}/v1/admin/carriers/${carrierId}`;

      axios
        .get(url, axiosConfig)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Get states list
   *
   * @returns {Promise}
   */
  getStatesList() {
    return new Promise((resolve, reject) => {
      const axiosConfig = getAxiosConfig(cookieNameSpace);
      const url = `${apiUrl}/v1/admin/states`;

      axios
        .get(url, axiosConfig)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};
