<template>
  <div
    class="annotation-marker-row"
  >
    <component
      :is="wrapInTooltip ? 'TfTooltip' : 'div'"
      :options="wrapInTooltip ? { placement: 'right' } : null"
      :class="tooltipWrapperClass"
    >
      <div
        v-for="(annotationMarker, index) of annotationMarkers"
        :key="`${pageId}-annotation-${index}`"
        :data-marker-id="`${pageId}-annotation-marker-${annotationMarker.annotation.id}`"
        :style="annotationStyles({ ...annotationMarker, width: 12, height: 8 })"
        :class="[
          'annotation-marker',
          { automated: annotationMarker.annotation.automated },
        ]"
        @mouseover="pdfViewer.handleMarkerMouseOver(annotationMarker.annotation)"
      />
      <div
        v-if="wrapInTooltip"
        slot="reference"
        class="annotation-marker annotation-marker-number"
        :style="annotationStyles({ ...annotationMarkers[0], width: 12, height: 8 })"
        @mouseover="flyoutVisible = true"
      >
        {{ annotationMarkers.length }}
      </div>
    </component>
  </div>
</template>

<script>
  /**
   * A row container for annotation markers
   *
   * @vuedoc
   * @exports AnnotationMarkerRow
   * @category Components
   */
  export default {
    name: 'AnnotationMarkerRow',
    inject: ['pdfViewer'],
    props: {
      annotationMarkers: {
        type: Array,
        required: true,
      },
      pageId: {
        type: String,
        required: true,
      },
    },
    data: () => ({
      flyoutVisible: false,
    }),
    computed: {
      /**
       * Shorthand for deciding if this should be wrapped in a tooltip
       *
       * @returns {boolean}
       */
      wrapInTooltip() {
        return this.annotationMarkers.length > 2;
      },
      /**
       * Tooltip wrapper styles
       * We need to use a class here so that inline styles don't get overridden by the tooltip styles
       *
       * @returns {string}
       */
      tooltipWrapperClass() {
        if (this.wrapInTooltip && this.annotationMarkers[0].transform === 90) {
          return 'tf-tooltip-wrapper rotate-90';
        }
        if (this.wrapInTooltip && this.annotationMarkers[0].transform === 270) {
          return 'tf-tooltip-wrapper rotate-270';
        }

        return this.wrapInTooltip ? 'tf-tooltip-wrapper' : '';
      },
    },
    methods: {
      /**
       * Position annotations on rotated pages
       *
       * @param {object} positionable
       * @returns {string}
       */
      annotationStyles(positionable) {
        /**
         * Rotated annotation 90 degrees
         */
        if (positionable.transform === 90) {
          return {
            left: `${Math.round(positionable.left * this.pdfViewer.getScale)}px`,
            bottom: '0px',
            width: `${Math.round(positionable.width * this.pdfViewer.getScale)}px`,
            height: `${Math.round(positionable.height * this.pdfViewer.getScale)}px`,
            transform: 'rotate(270deg)',
          };
        }
        /**
         * Rotated annotation 270 degrees
         */
        if (positionable.transform === 270 || positionable.transform === 180) {
          return {
            right: `${Math.round(positionable.right * this.pdfViewer.getScale)}px`,
            top: '0px',
            width: `${Math.round(positionable.width * this.pdfViewer.getScale)}px`,
            height: `${Math.round(positionable.height * this.pdfViewer.getScale)}px`,
            transform: 'rotate(90deg)',
          };
        }

        /**
         * Non-rotated annotation or word
         */
        return {
          left: `${Math.round(positionable.left * this.pdfViewer.getScale)}px`,
          top: `${Math.round(positionable.top * this.pdfViewer.getScale)}px`,
          width: `${Math.round(positionable.width * this.pdfViewer.getScale)}px`,
          height: `${Math.round(positionable.height * this.pdfViewer.getScale)}px`,
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  .annotation-marker-row {
    display: block;
    transform-origin: center;

    .tf-tooltip-wrapper {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  // rotated pages need to have the tooltip span rotated back
  :deep(.tf-tooltip-wrapper.rotate-90) > span {
    display: block;
    height: 100%;
    transform: rotate(270deg);
    transform-origin: top left;
  }

  :deep(.tf-tooltip-wrapper.rotate-270) > span {
    display: block;
    height: 100%;
    transform-origin: top right;
    transform: rotate(90deg) translateX(100%);
  }

  .annotation-marker {
    position: absolute;
    background: var(--tf-yellow);
    cursor: pointer;
    border: 1px var(--tf-gray) solid;
    transform-origin: center;

    &.automated {
      background: #80c7fb;
    }

    &:hover {
      background: var(--tf-green);
    }

    .tf-tooltip & {
      position: static;
      border-bottom: 0;

      &:last-of-type {
        border-bottom: 1px var(--tf-gray) solid;
      }
    }
  }

  .annotation-marker-number {
    color: var(--tf-base-light);
    background: var(--tf-gray-dark);
    position: absolute;
    font-weight: bold;
    text-align: center;
    line-height: 8px;
    font-size: 12px;
  }
</style>
