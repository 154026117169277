<template>
  <div class="category-container">
    <h3>{{ category.name }}</h3>
    <PlanDesignAttribute
      v-for="(attribute, attributeIndex) in categoryAttributes"
      :key="attribute.name"
      :attribute="attribute"
      :attribute-index="attributeIndex"
      :category-index="categoryIndex"
      data-test="plan design attribute"
    />
  </div>
</template>

<script>
  import PlanDesignAttribute from '@/components/PlanDesign/FromTemplate/Attribute.vue';

  /**
   * Plan Design Category From Template
   *
   * @vuedoc
   * @exports PlanDesignCategory
   * @category Components
   */
  export default {
    name: 'PlanDesignCategory',
    components: {
      PlanDesignAttribute,
    },
    props: {
      category: {
        type: Object,
        default: () => ({}),
        required: true,
      },
      categoryIndex: {
        type: Number,
        default: 0,
        required: true,
      },
    },
    computed: {
      /**
       * Strip out "rate guarantee" attributes
       *
       * @returns {Array} category attributes array
       */
      categoryAttributes() {
        return this.category.categorized_attributes.filter((attr) => attr.name.toLowerCase() !== 'rate guarantee');
      },
    },
  };
</script>

<style lang="scss" scoped>
  h3 {
    margin: 0;
    padding: 12px 25px;
    font: {
      size: 14px;
      weight: 700;
    }
    text-transform: uppercase;
    border-bottom: 1px solid var(--tf-gray-medium);
  }
</style>
