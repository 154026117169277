import axios from 'axios';
import { getAxiosConfig } from '@watchtowerbenefits/es-utils-public';
import { useProjectProductStore } from '@/stores/projectProduct.js';
import { config } from '@/utils/config.js';

const apiUrl = config.VUE_APP_API_URL;
const cookieNameSpace = config.VUE_APP_COOKIE_NAMESPACE;

/**
 * Product Validation API calls
 *
 * @exports Validation
 * @category Services
 */

/**
 * Validates the plan design attributes of a product
 *
 * @param {string|number} productId
 * @returns {Promise}
 */
export async function validatePlanDesign(productId) {
  const axiosConfig = getAxiosConfig(cookieNameSpace);
  const url = `${apiUrl}/v1/admin/products/${productId}/validate_plan_design`;
  const projectProductStore = useProjectProductStore();

  try {
    const { data } = await axios
      .patch(url, {}, axiosConfig);

    projectProductStore.updateProjectProductsValidation({
      projectProduct: data.product.project_product,
    });

    return data;
  } catch (e) {
    projectProductStore.updateProjectProductsValidation({
      productId,
      validPlanDesign: false,
    });

    return e;
  }
}

/**
 * Validates rate structure of a product
 *
 * @param {string|number} productId
 * @returns {Promise}
 */
export async function validateRates(productId) {
  const axiosConfig = getAxiosConfig(cookieNameSpace);
  const url = `${apiUrl}/v1/admin/products/${productId}/validate_rate_structure`;
  const projectProductStore = useProjectProductStore();

  try {
    const { data } = await axios
      .patch(url, {}, axiosConfig);

    projectProductStore.updateProjectProductsValidation({
      projectProduct: data.product.project_product,
    });

    return data;
  } catch (e) {
    projectProductStore.updateProjectProductsValidation({
      productId,
      validRateStructure: false,
    });

    return e;
  }
}
