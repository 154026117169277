<template>
  <TfTableParentRow
    @mouseover.native="isHovering = true"
    @mouseleave.native="isHovering = false"
  >
    <TfTableTd>
      <div class="inline-form-field-wrapper">
        <TfInlineFormField
          v-bind="{
            isEditing,
            isHovering,
            isSaving: false,
            showBtnActions: true,
            showBtnDelete: false,
            showBtnEdit: false,
            showExtension: false,
            prop: 'userFullName',
            value: userFullName,
          }"
          @edit="onEdit"
          @cancel="onCancel"
          @save="onSave"
        >
          <ElSelect
            ref="valueSelect"
            v-model="userId"
            :popper-append-to-body="false"
            popper-class="team-member-select-popper"
            :filterable="true"
            class="team-member-select"
            placeholder="Select broker users"
            data-test="select broker users select"
            no-data-text="No available users."
            no-match-text="No matching users."
          >
            <ElOption
              v-for="user in nonTeamBrokerUsers"
              :key="user.id"
              :label="user.fullName"
              :value="user.id"
            />
          </ElSelect>
        </TfInlineFormField>
        <AppButton
          v-if="!isNew && !isEditing && userCanBeDeleted"
          data-test-delete-button
          data-test="delete button"
          type="decline"
          icon="fa-solid fa-trash-alt"
          size="icon"
          @click="onDelete"
        />
      </div>
    </TfTableTd>
  </TfTableParentRow>
</template>

<script>
  /**
   * TeamMemberTable Row
   *
   * @vuedoc
   * @exports TeamMemberTableRow
   * @category Components
   */
  export default {
    name: 'TeamMemberTableRow',
    props: {
      brokerUsers: {
        type: Array,
        default: () => [],
      },
      canBeDeleted: {
        type: Boolean,
        default: false,
      },
      rowData: {
        type: Object,
        required: true,
      },
      teamMemberIds: {
        type: Array,
        default: () => [],
      },
    },
    data: () => ({
      isEditing: false,
      isHovering: false,
      isNew: false,
      isDeletable: false,
      userId: null,
    }),
    computed: {
      /**
       * We want a list of broker users without the people already on the team.
       * However, we need to also include the current userId's entry, or
       * the ElSelect will open and not match with the user.
       *
       * @returns {Array}
       */
      nonTeamBrokerUsers() {
        return this.brokerUsers.filter(
          ({ id }) => this.rowData.id === id || !this.teamMemberIds.includes(id),
        );
      },
      /**
       * Track fullName derived from the userId select value,
       * so that the value updates and the done button can
       * be unlocked
       *
       * @returns {string}
       */
      userFullName() {
        const user = this.brokerUsers.find(
          ({ id }) => id === this.userId,
        );

        return user ? user.fullName : '';
      },
      /**
       *
       * On hover, check if more than one teamMemberIds exists
       * and canBeDeleted is true
       *
       * @returns {boolean}
       */
      userCanBeDeleted() {
        return !!(this.teamMemberIds.length > 1 && this.canBeDeleted && this.isHovering);
      },
    },
    /**
     * If no id is set, we're dealing with a new row
     * that we'll handle differently.
     */
    created() {
      if (!this.rowData.id) {
        this.isEditing = true;
        this.isNew = true;
      } else {
        this.userId = this.rowData.id;
      }
    },
    methods: {
      /**
       * Handle cancel action
       */
      onCancel() {
        if (this.isNew) {
          this.$nextTick(() => {
            this.$emit('onNewRowCancel', this.rowData.index);
          });
        } else {
          // reset ID before we clear out isEditing mode.
          this.userId = this.rowData.id;
          // Delaying to the next tick because there's a weird …race condition?
          // that occurs where the faux-input gets the click that triggered the
          // cancel as well, effectively undoing the cancel by going straight
          // back into edit mode.
          this.$nextTick(() => {
            this.isEditing = false;
          });
        }
      },
      /**
       * Handle deletion
       */
      onDelete() {
        this.$emit('onRowDelete', this.rowData.index);
      },
      /**
       * Toggle isEditing so focus on the textarea inside the select on the next tick.
       */
      onEdit() {
        this.isEditing = true;
        this.$nextTick(() => {
          this.$refs.valueSelect.$el.querySelector('input').focus();
        });
      },
      /**
       * Handle saving
       */
      onSave() {
        this.$emit('onRowSave', {
          index: this.rowData.index,
          userId: this.userId,
        });
        this.isNew = false;
        this.isEditing = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .inline-form-field-wrapper {
    display: flex;
    position: relative;
  }

  .team-member-select {
    position: relative;
    flex-grow: 1;
  }

  :deep() {
    .faux-input {
      max-width: 257px;
    }

    .el-input.el-input--medium .el-input__inner {
      padding: 0 30px 0 10px;
    }
  }

  .app-button.fa-trash-alt {
    margin-left: 20px;
  }
</style>

<style lang="scss">
  /* stylelint-disable declaration-no-important */
  .team-member-select-popper {
    position: absolute !important;
    max-height: 180px;
    max-width: 100%;
    overflow: hidden;
    left: 0 !important;
    top: 24px !important;

    .el-scrollbar {
      max-height: inherit;
    }
  }
  /* stylelint-enable declaration-no-important */
</style>
