/**
 * Creates a reader friendly version of the current and next states along with a transition endpoint
 *
 * @param {string} state
 * @returns {object}
 */
const getProductState = (state) => {
  switch (state) {
    case 'not_started':
    case 'pending_validation':
      return {
        current: 'Pending validation',
        next: 'QA 1',
        transition: 'start_qa',
      };
    case 'in_progress':
      return {
        current: 'Setup',
        next: 'QA 1',
        transition: 'start_qa',
      };
    case 'qa_started':
      return {
        current: 'QA 1',
        next: 'QA 2',
        transition: 'start_qa2',
      };
    case 'qa2_started':
      return {
        current: 'QA 2',
        next: 'Complete',
        transition: 'move_to_completed',
      };
    case 'completed':
      return {
        current: 'Complete',
        next: '',
        transition: '',
      };
    // TODO: this is a temporary "quick-fix" for AD-1090 to be replaced once BE functionality is built
    case 'editing_plan_design':
      return {
        current: 'Editing plan design',
        next: 'Complete',
        transition: 'move_to_completed',
      };
    default:
      return {
        current: state.replace(/_/g, ' '),
        next: '',
        transition: '',
      };
  }
};

export default getProductState;
