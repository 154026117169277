import Vue, { reactive } from 'vue';
import { defineStore } from 'pinia';
import { usePdfSearchStore } from '@/stores/pdfSearch.js';
import { useFileViewerStore } from '@/stores/fileViewer.js';
import { useProjectStore } from '@/stores/project.js';
import { usePdfAnnotationStore } from '@/stores/pdfAnnotation.js';

export const usePdfViewerStore = defineStore('pdfViewer', {
  state: () => ({
    mouseX: 0,
    mouseY: 0,
    pages: [],
    pagePositions: {},
    searchToolbarVisible: false,
    pageToolbarVisible: true,
    words: [],
    zoom: 200,
  }),
  persist: {
    key: 'admin-pdfViewer',
    paths: ['searchToolbarVisible', 'pagePositions', 'pageToolbarVisible', 'zoom'],
    serializer: {
      serialize: (value) => JSON.stringify(value),
      deserialize: (value) => reactive(JSON.parse(value)),
    },
  },
  getters: {
    /**
     * get a page number representation of the current source's scroll position
     *
     * @param {object} state
     * @returns {number}
     */
    getPageNumber: (state) => {
      const fileViewerStore = useFileViewerStore();
      const projectStore = useProjectStore();
      const currentFileId = fileViewerStore.activeFileIds[projectStore.projectId];
      const position = state.pagePositions[currentFileId] || 0;
      let height = 0;
      let currentPage = position === 0 ? 1 : 0;

      for (let pageIndex = 0; height < position; pageIndex += 1) {
        if (!state.pages[pageIndex]) {
          break;
        }
        height += (state.pages[pageIndex].pageRotation > 0
          ? state.pages[pageIndex].width
          : state.pages[pageIndex].height)
          * state.getScale;
        currentPage += 1;
      }

      return currentPage;
    },
    /**
     * Get a scale multiplier based on the current zoom;
     *
     * @param {object} state
     * @returns {number}
     */
    getScale: (state) => state.zoom / 100,
    getWordClass: () => (wordId) => {
      const pdfSearchStore = usePdfSearchStore();
      const pdfAnnotationStore = usePdfAnnotationStore();

      return {
        word: true,
        'search-highlight': pdfSearchStore.searchWordIds.includes(wordId),
        'search-highlight-active': pdfSearchStore.getSearchResultWordIds.includes(wordId),
        'hover-highlight': pdfAnnotationStore.hoverWordIds.includes(wordId),
        'temporary-highlight': pdfAnnotationStore.newAnnotationWordIds.includes(wordId),
        'inactive-highlight': pdfAnnotationStore.getAnnotatedWordIds.includes(wordId),
        'active-highlight': pdfAnnotationStore.getActiveAnnotationWordIds.includes(wordId),
        automated: pdfAnnotationStore.getAutomationAnnotatedWordIds.includes(wordId),
      };
    },
  },
  actions: {
    /**
     * delete all annotation for a specific container
     *
     * @param {Array} rawPages
     */
    setPagesAndWords(rawPages = []) {
      const pages = rawPages.map((page) => {
        const words = page.words.map((word) => {
          const {
            bottom,
            height,
            id,
            left,
            text,
            width,
            rotation,
          } = word;
          const top = page.height - bottom - height;

          return {
            height,
            id,
            left,
            text,
            top,
            width,
            rotation,
          };
        });
        let rotatedWords = words;
        let pageRotation = 0;

        /**
         * Resequence words on pages rotated 90 or 270 degrees
         * Note: 180 degree rotated pages do not need to be resequenced
         */
        if (words.filter(({ rotation }) => rotation === 90).length > words.length / 2) {
          pageRotation = 90;
          /**
           * Pages rotated 90 degrees will have word 1 at the bottom left corner of the document
           */
          rotatedWords = words.map((word) => {
            let count = 0;

            // Count each word on the page that is above or to the left of the current word
            words.forEach(({ top, left }) => {
              if (word.left > left) {
                count += 1;
              } else if (word.left === left && word.top < top) {
                count += 1;
              }
            });

            // Resequence the word id
            return {
              ...word,
              id: `${word.id.split('-')[0]}-w${count}`,
            };
          });
        } else if (words.filter(({ rotation }) => rotation === 270).length > words.length / 2) {
          pageRotation = 270;
          /**
           * Pages rotated 270 degrees will have word 1 at the top right corner of the document
           */
          rotatedWords = words.map((word) => {
            let count = 0;

            // Count each word on the page that is below or to the right of the current word
            words.forEach(({ top, left }) => {
              if (word.left < left) {
                count += 1;
              } else if (word.left === left && word.top > top) {
                count += 1;
              }
            });

            // Resequence the word id
            return {
              ...word,
              id: `${word.id.split('-')[0]}-w${count}`,
            };
          });
        } else if (words.filter(({ rotation }) => rotation === 180).length > words.length / 2) {
          pageRotation = 180;
        }

        return {
          ...page,
          pageRotation,
          words: rotatedWords,
        };
      });
      const words = pages.reduce((accumulatedWords, page) => accumulatedWords.concat(page.words), []);

      this.pages = Object.freeze(pages);
      this.words = Object.freeze(words);
    },
    /**
     * Store current mouse coordinates
     *
     * @param {object} coordinates
     */
    setPdfViewerMouseCoordinates(coordinates) {
      this.mouseX = coordinates.mouseX;
      this.mouseY = coordinates.mouseY;
    },
    /**
     * Store current scroll position for a source
     *
     * @param {object} obj
     * @param {number} obj.sourceId
     * @param {number} obj.scrollTop
     */
    setPdfViewerPagePosition({ sourceId, scrollTop }) {
      Vue.set(this.pagePositions, sourceId, scrollTop);
    },
    /**
     * Toggle open/closed controls for a toolbar in the PDF viewer
     *
     * @param {string} name
     */
    togglePdfViewerToolbar(name) {
      this[`${name}ToolbarVisible`] = !this[`${name}ToolbarVisible`];
    },
    /**
     * Set zoom percentage
     *
     * @param {number} zoom
     */
    setPdfViewerZoom(zoom) {
      let newZoom = Math.ceil(zoom / 10) * 10;

      if (newZoom > 800) {
        newZoom = 800;
      }

      if (newZoom < 50) {
        newZoom = 50;
      }

      this.zoom = newZoom;
    },
  },
});
