import { defineStore } from 'pinia';
import { useFileViewerStore } from '@/stores/fileViewer.js';
import { useAnnotationsStore } from '@/stores/annotations.js';
import { usePdfViewerStore } from '@/stores/pdfViewer.js';

export const usePdfAnnotationStore = defineStore('pdfAnnotation', {
  state: () => ({
    activeAnnotation: null,
    annotationModalMode: 'closed',
    annotationMode: 'auto',
    isDeleting: false,
    isSaving: false,
    newAnnotationWordIds: [],
    hoverWordIds: [],
    startWordId: null,
    wordIdsError: '',
  }),
  getters: {
    /**
     * Get wordIds of the active annotation.
     *
     * @param {object} state
     * @returns {Array}
     */
    getActiveAnnotationWordIds: (state) => (state.activeAnnotation ? state.activeAnnotation.word_ids : []),
    /**
     * Get all annotated wordIds.
     *
     * @param {object} state
     * @returns {Array}
     */
    getAnnotatedWordIds: (state) => state.getCurrentSourceAnnotations.reduce(
      (acc, annotation) => acc.concat(annotation.word_ids),
      [],
    ),
    /**
     * Get all annotations, sorted by page.
     *
     * @param {object} state
     * @returns {Array}
     */
    getAnnotationsPerPage: (state) => {
      const annotationsPerPage = {};

      state.getCurrentSourceAnnotations.forEach((annotation) => {
        const pageIds = [...new Set(annotation.word_ids.map((wordId) => wordId.split('-')[0]))];

        pageIds.forEach((pageId) => {
          // annotation can exist on two pages
          if (!Object.keys(annotationsPerPage).includes(pageId)) {
            annotationsPerPage[pageId] = [];
          }
          annotationsPerPage[pageId].push(annotation);
        });
      });

      return annotationsPerPage;
    },
    /**
     * Get text of the current annotation selection.
     *
     * @param {object} state
     * @returns {string}
     */
    getAnnotationText: (state) => {
      const pdfViewerStore = usePdfViewerStore();

      return pdfViewerStore.words
        .filter((word) => state.newAnnotationWordIds.includes(word.id))
        .sort(({ id: a }, { id: b }) => {
          // Get the page and word values from the ids (e.g. p12-w30)
          let [, pageA, wordA] = /p(\d+)-w(\d+)/g.exec(a) || [];
          let [, pageB, wordB] = /p(\d+)-w(\d+)/g.exec(b) || [];

          // Map the page and word values into Numbers so they are compared numerically
          // i.e. 1, 2, 3, 10, 11, etc rather than 1, 10, 11, 2, 3, etc.
          [pageA, pageB, wordA, wordB] = [pageA, pageB, wordA, wordB].map(Number);

          // First check if the pages are different
          if (pageA > pageB) {
            return 1;
          }
          // If the pages are the same, compare the word positions
          if (pageA === pageB) {
            return wordA > wordB ? 1 : -1;
          }

          return -1;
        })
        .map((word) => word.text)
        .join(' ')
        .trim();
    },
    /**
     * Get only the annotations created by TacticEngine.
     *
     * @param {object} state
     * @returns {Array}
     */
    getAutomationAnnotatedWordIds: (state) => state.getCurrentSourceAnnotations
      .filter((annotation) => annotation.automated)
      .reduce((acc, annotation) => acc.concat(annotation.word_ids), []),
    /**
     * Get all annotations on the current source.
     *
     * @returns {Array}
     */
    getCurrentSourceAnnotations: () => {
      const fileViewerStore = useFileViewerStore();
      const annotationsStore = useAnnotationsStore();

      return annotationsStore.annotationsBySourceId(fileViewerStore.getActiveFileId);
    },
    /**
     * Verify that the selected new word ids are actually valid against the sources.
     *
     * @param {object} state
     * @returns {boolean}
     */
    getAnnotatedWordIdsAreValid(state) {
      const pdfViewerStore = usePdfViewerStore();
      const allWordIds = pdfViewerStore.words.map((word) => word.id);

      return state.newAnnotationWordIds.filter((newAnnotationWord) => !allWordIds.includes(newAnnotationWord)).length === 0;
    },
  },
  actions: {
    /**
     * Close annotation modal
     */
    closeAnnotationModal() {
      this.annotationModalMode = 'closed';
      this.activeAnnotation = null;
      this.newAnnotationWordIds = [];
      this.hoverWordIds = [];
    },
    /**
     * store the actively hovered word id, and set an array that includes the wordIds
     * between the parameter and the word id of the current selection, if one was started.
     *
     * @param {string} wordId
     */
    setHoverWordIds(wordId) {
      const hoverWordIds = this.getWordRange(
        this.startWordId,
        wordId,
      );

      this.hoverWordIds = hoverWordIds;
    },
    /**
     * Track the active state of the annotation modal
     *
     * @param {object} obj
     * @param {string} obj.actionKey - 'isSaving', 'isDeleting'
     * @param {boolean} obj.actionState
     */
    setPdfAnnotationModalActionState({ actionKey, actionState }) {
      this[actionKey] = actionState;
    },
    /**
     * Get an array of all the word ids between two ids, including those two ids.
     *
     * @param {string} startWordId
     * @param {string} endWordId
     * @returns {Array}
     */
    getWordRange(startWordId, endWordId) {
      const pdfViewerStore = usePdfViewerStore();
      const wordIds = [];
      const [startPage, startId] = startWordId.substr(1).split('-w').map((n) => Number(n));
      const [endPage, endId] = endWordId.substr(1).split('-w').map((n) => Number(n));
      const { pages } = pdfViewerStore;

      if (endPage === startPage) {
        // All good, everything's on one page.
        if (startId < endId) {
          for (let w = startId; w <= endId; w += 1) {
            wordIds.push(`p${startPage}-w${w}`);
          }
        } else {
          // Fine, just start at the end.
          for (let w = endId; w <= startId; w += 1) {
            wordIds.push(`p${startPage}-w${w}`);
          }
        }
      } else if (endPage > startPage) {
        const startPageWords = pages[startPage - 1].words;
        const startPageLastId = Number(startPageWords[startPageWords.length - 1].id.split('-w')[1]);

        // Add all the words from the start page from the selected word through the last word on the page.
        for (let w = startId; w <= startPageLastId; w += 1) {
          wordIds.push(`p${startPage}-w${w}`);
        }
        // Add all the words from the end page from zero through from the end-word.
        for (let w = 0; w <= endId; w += 1) {
          wordIds.push(`p${endPage}-w${w}`);
        }
      } else {
        const startPageWords = pages[startPage - 1].words;
        const startPageFirstId = Number(startPageWords[0].id.split('-w')[1]);
        const endPageWords = pages[endPage - 1].words;
        const endPageLastId = Number(endPageWords[endPageWords.length - 1].id.split('-w')[1]);

        // Add all the words from the first word start page through the start word.
        for (let w = startPageFirstId; w <= startId; w += 1) {
          wordIds.push(`p${startPage}-w${w}`);
        }
        // Add all the words from the endPage from the endWord through the last word on the page.
        for (let w = endId; w <= endPageLastId; w += 1) {
          wordIds.push(`p${endPage}-w${w}`);
        }
      }

      return wordIds;
    },
  },
});
