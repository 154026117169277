<template>
  <div class="edit-rfp-item">
    {{ label }}:&nbsp;
    <slot>
      <template v-if="!editing">
        {{ reviewValue }}
      </template>
      <ElForm
        v-else
        @submit.native.prevent="onSave"
      >
        <datepicker
          v-if="inputType === 'date'"
          :value="datepickerValue"
          :inline="false"
          format="MM/dd/yy"
          placeholder="Please enter a date"
          @selected="inputValue = $event"
        />
        <ElInput
          v-else
          v-model="inputValue"
          @keydown.native="onKeydown"
        />
      </ElForm>
      <div class="btn-group">
        <AppButton
          v-if="!editing"
          data-test="edit button"
          type="primary"
          icon="fa-solid fa-pencil"
          size="icon-small"
          @click="editing = true"
        />
        <template v-else>
          <AppButton
            data-test="cancel button"
            icon="fa-solid fa-times"
            size="icon"
            type="secondary"
            @click="onCancel"
          />
          <AppButton
            data-test="save button"
            icon="fa-solid fa-check"
            size="icon"
            type="affirm"
            @click="onSave"
          />
        </template>
      </div>
    </slot>
  </div>
</template>

<script>
  import { formatSimpleDate } from '@watchtowerbenefits/es-utils-public';

  import ValidationUtil from '@/utils/validation.js';

  /**
   * Component for Editing EE Count and Effective Date
   *
   * @vuedoc
   * @exports ManageRfpEditItem
   * @category Components
   */
  export default {
    name: 'ManageRfpEditItem',
    props: {
      label: {
        type: String,
        required: true,
      },
      inputType: {
        type: String,
        default: 'string',
      },
      value: {
        type: [Number, String],
        default: '',
      },
    },
    data: (vm) => ({
      editing: false,
      inputValue: vm.value,
      originalValue: vm.value,
    }),
    computed: {
      /**
       * Format the date if inputType === date and there's a value.
       *
       * @returns {string}
       */
      datepickerValue() {
        let datepickerValue;

        if (this.inputType === 'date') {
          datepickerValue = this.inputValue
            ? formatSimpleDate(this.inputValue, 'MM/DD/YY')
            : null;
        }

        return datepickerValue;
      },
      /**
       * Evaluates inputType to return the readable value of the inputValue, datepickerValue, or '--'.
       *
       * @returns {string}
       */
      reviewValue() {
        let reviewValue = this.inputValue;

        if (this.inputType === 'date') {
          reviewValue = this.datepickerValue;
        }

        return reviewValue || '--';
      },
    },
    methods: {
      /**
       * Revert the value and change 'editing' to false.
       */
      onCancel() {
        this.inputValue = this.originalValue;
        this.editing = false;
      },
      /**
       * On Keydown of the ElInput we check if the input type is "number" and restrict the input to numbers
       *
       * @param {Event} event
       */
      onKeydown(event) {
        if (this.inputType === 'number') {
          ValidationUtil.isNumberKey(event);
        }
      },
      /**
       * Change 'editing' to false and emit it up.
       */
      onSave() {
        this.editing = false;
        this.$emit('save', this.inputValue);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .edit-rfp-item {
    display: flex;
    align-items: center;
    min-height: 26px;
  }

  .btn-group {
    margin-left: 14px;
  }
</style>
