<template>
  <span
    :data-test="`${value?.toLowerCase() ?? 'generic'} category label`"
    class="category-label"
    :class="`is-${size} is-${type}`"
    v-text="value"
  />
</template>

<script>
  /**
   * "CategoryLabel" is a component used for displaying labels for categories in the File Manager menu within the File Viewer.
   *
   * @vuedoc
   * @exports CategoryLabel
   * @category components
   */
  export default {
    name: 'CategoryLabel',
    props: {
      /**
       * String used to determine the various padding and font styles.
       */
      size: {
        type: String,
        default: 'large',
        validator: (value) => ['small', 'medium', 'large'].includes(value),
      },
      /**
       * String used to determine the type of Category Label and style accordingly.
       */
      type: {
        type: String,
        required: true,
        validator: (value) => ['carrier', 'document-category', 'missing-document-category'].includes(value),
      },
      /**
       * String or number used as text for the badge.
       */
      value: {
        type: [String, Number],
        required: true,
      },
    },
  };
</script>

<style scoped lang="scss">
  span {
    display: inline-block;
    font: {
      size: 12px;
      weight: 700;
    }
    border: 1px solid;
    border-radius: 5px;
    text-align: center;

    &.is {
      // Sizes
      &-small {
        padding: 2px 5px;
        font: {
          size: 10px;
          weight: 600;
        }
        line-height: 12px;
        letter-spacing: inherit;
      }

      &-medium {
        padding: 3px 5px;
      }

      &-large {
        padding: 3px 16px;
      }

      // Types
      &-carrier {
        color: var(--tf-blue-medium);
        background: var(--tf-blue-light);
        border-color: var(--tf-blue-medium);
      }

      &-document-category {
        color: var(--tf-blue);
        background: var(--tf-blue-light);
        border-color: var(--tf-blue);
      }

      &-missing-document-category {
        color: var(--tf-orange);
        background: var(--tf-orange-light);
        border-color: var(--tf-orange);
      }
    }
  }
</style>
