<template>
  <div
    class="pdf-viewer"
    :class="{
      'modal-open': annotationModalMode !== 'closed',
      'active-search': searchTerm && searchTerm.length > 0 && searchToolbarVisible,
    }"
  >
    <AnnotationModal v-if="annotationModalMode !== 'closed'" />

    <PageToolbar v-if="pages.length && pageToolbarVisible" />

    <SearchToolbar
      v-if="pages.length && searchToolbarVisible"
      :offset="pageToolbarVisible"
    />

    <div class="pdf-viewer-inner">
      <div
        v-if="error"
        class="error-wrapper"
      >
        <AppAlert
          show-icon
          :title="`PDF rendering error. ${error}`"
          :closable="false"
          type="danger"
        />
      </div>
      <div
        v-else
        ref="page-container"
        v-loading="showLoader"
        element-loading-text="Loading page data…"
        class="page-container"
        data-test="page container"
        @mousedown="handlePageMouseDown"
        @mousemove="handlePageMouseMove"
        @scroll.passive="handleScroll"
      >
        <div
          ref="drawBox"
          :style="drawBoxStyle"
          class="draw-box"
        />
        <div
          v-if="pages.length"
          :style="`${pages.length ? `width: ${pages[0].width * getScale}px` : ''}`"
          class="pages"
        >
          <div
            ref="page-scroller"
            class="scroller"
          >
            <page
              v-for="page of pages"
              :key="page.id"
              :annotations="getAnnotationsPerPage[page.id]"
              :is-active="activePageIds.includes(page.id)"
              :page="page"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions, mapWritableState } from 'pinia';
  // 3rd party
  import { captureException, addBreadcrumb } from '@sentry/vue';
  import { isEqual } from 'lodash';
  // utils
  import annotationsUtils from '@/utils/annotations.js';
  // services
  import FileService from '@/services/file.js';
  // components
  import AnnotationModal from '@/components/AnnotationModal/index.vue';
  import PageToolbar from '@/components/FileViewer/PdfViewer/PageToolbar.vue';
  import SearchToolbar from '@/components/FileViewer/PdfViewer/SearchToolbar.vue';
  import Page from '@/components/FileViewer/PdfViewer/Page.vue';
  // stores
  import { usePdfSearchStore } from '@/stores/pdfSearch.js';
  import { useFileViewerStore } from '@/stores/fileViewer.js';
  import { usePdfViewerStore } from '@/stores/pdfViewer.js';
  import { useAnnotationsStore } from '@/stores/annotations.js';
  import { usePdfAnnotationStore } from '@/stores/pdfAnnotation.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useProductSelectionsStore } from '@/stores/productSelections.js';

  /**
   * PDF rendering for FileViewer
   *
   * @vuedoc
   * @exports PDFViewer
   * @category Components
   */
  export default {
    name: 'PdfViewer',
    components: {
      AnnotationModal,
      Page,
      PageToolbar,
      SearchToolbar,
    },
    provide() {
      return {
        pdfViewer: this,
      };
    },
    data: () => ({
      drawBoxCoordinates: null,
      currentSelection: null,
      error: false,
      isAnnotating: false,
      annotationType: '',
      pageImages: {},
    }),
    computed: {
      ...mapState(usePdfSearchStore, [
        'searchResultIndex',
        'searchResults',
        'searchTerm',
      ]),
      ...mapState(useFileViewerStore, [
        'getActiveFileId',
        'activeFileIds',
      ]),
      ...mapState(usePdfViewerStore, [
        'pagePositions',
        'pages',
        'pageToolbarVisible',
        'searchToolbarVisible',
        'zoom',
        'getPageNumber',
        'getScale',
      ]),
      ...mapState(usePdfAnnotationStore, [
        'annotationMode',
        'getAnnotationsPerPage',
      ]),
      ...mapWritableState(usePdfAnnotationStore, [
        'activeAnnotation',
        'annotationModalMode',
        'startWordId',
        'newAnnotationWordIds',
      ]),
      ...mapState(useProductSelectionsStore, ['selectedProductId']),
      ...mapState(useProjectStore, ['currentProject', 'readOnlyMode']),
      ...mapWritableState(useFileViewerStore, ['goToAnnotation']),
      /**
       * The current page id, along with the previous and next one.
       *
       * @returns {Array}
       */
      activePageIds() {
        const p = this.getPageNumber;

        return [`p${p - 1}`, `p${p}`, `p${p + 1}`];
      },
      /**
       * Helper that if in 'draw box' mode positions and defines the box.
       *
       * @returns {string}
       */
      drawBoxStyle() {
        if (this.isAnnotating && this.annotationType === 'draw') {
          return `left: ${this.drawBoxCoordinates.left}px;
        top: ${this.drawBoxCoordinates.top}px;
        width: ${this.drawBoxCoordinates.width}px;
        height: ${this.drawBoxCoordinates.height}px;`;
        }

        return null;
      },
      /**
       * Determine whether or not we need to show a loader.
       *
       * @returns {boolean}
       */
      showLoader() {
        return !this.pages.length;
      },
    },
    watch: {
      /**
       * If we switch sources, we disable any active annotation mode stuff
       * and empty out assets/annotations
       */
      activeFileIds: {
        /**
         * If we're already heading to another annotation, just avoid race conditions
         * and the annotation modal's destroy mucking with things by not closing it
         */
        handler() {
          if (!this.readOnlyMode && (!this.goToAnnotation || !this.goToAnnotation.id)) {
            this.resetAnnotationMode();
          }

          if (this.getActiveFileId) {
            this.loadAssetsAndAnnotations();
          }

          this.error = null;
        },
        deep: true,
      },
      activePageIds: {
        /**
         * Watch active page changes so we can track better S3 bucket image loading issues
         */
        handler() {
          this.pages
            .filter((page) => this.activePageIds.includes(page.id))
            .forEach(({ source_url: sourceUrl }) => this.preloadPageImage(sourceUrl));
        },
        deep: true,
        immediate: true,
      },
      /**
       * Reset annotations in progress if annotation mode changes
       */
      annotationMode() {
        this.resetAnnotationMode();
      },
      goToAnnotation: {
        /**
         * Listen for a 'goToAnnotation' directive from the store and, well, go to it
         */
        handler() {
          this.handleGoToAnnotation();
        },
        deep: true,
        immediate: true,
      },
      searchResults: {
        /**
         * Scroll to the first result if the results change
         *
         * @param {object} oldResults
         * @param {object} newResults
         */
        handler(oldResults, newResults) {
          if (!isEqual(oldResults, newResults)) {
            if (this.searchResults.length > 0) {
              const result = this.searchResults[0][0];
              const [pageId] = result.id.split('-');
              const pageNumber = Number(pageId.substr(1));
              const top = (result.top * this.getScale) - 300;

              this.scrollToPage(pageNumber, top);
            }
          }
        },
        deep: true,
      },
      /**
       * When the loader goes away we should check if the user has a previously saved position to scroll
       * them to (if there isn't a 'goToAnnotation')
       */
      showLoader() {
        if (this.showLoader) {
          return;
        }

        /**
         * If the search field is visible and contains a search term, stored in this.localSearchTerm,
         * when the user switches file tabs we want to clear the existing search and rerun it on the
         * newly selected document. This is done in here, in showLoader, rather than when activeFileIds
         * changes as we need to wait for the document to load before running the search.
         */
        this.initializeSearch(this.searchTerm);
        if (!this.goToAnnotation || !this.goToAnnotation.ids) {
          const scrollPosition = this.pagePositions[this.getActiveFileId];

          this.$nextTick(() => {
            /**
             * If the user is navigating around quickly there's a possibility that this assignment to scrollTop
             * will fire at a time when the page-container is not visible which throws an error. This simply
             * checks to make sure it exists to mitigate that error being thrown.
             */
            if (this.$refs['page-container']) {
              this.$refs['page-container'].scrollTop = scrollPosition;
            }
          });
        }
      },
      /**
       * Jump to next/previous result
       */
      searchResultIndex() {
        if (Number.isInteger(this.searchResultIndex) && this.searchResults[this.searchResultIndex]) {
          const result = this.searchResults[this.searchResultIndex][0];
          const [pageId] = result.id.split('-');
          const pageNumber = Number(pageId.substr(1));
          const top = (result.top * this.getScale) - 500;

          this.scrollToPage(pageNumber, top);
        }
      },
      zoom: {
        /**
         * Monitor zoom percentage and reset/update things if changed
         */
        handler() {
          setTimeout(() => { this.scrollToPage(this.getPageNumber); }, 600);
          this.isAnnotating = false;
          this.annotationType = '';
          this.drawBoxCoordinates = null;
        },
        immediate: true,
      },
    },
    created() {
      document.addEventListener('keydown', this.handleKeyDown);

      if (this.getActiveFileId) {
        this.loadAssetsAndAnnotations();
      }
    },
    /**
     * On destroy we want to remove the keydown listener and close the annotation modal
     * to avoid race condition issues when the page is loaded again.
     */
    beforeDestroy() {
      document.removeEventListener('keydown', this.handleKeyDown);
      this.closeAnnotationModal();
    },
    methods: {
      ...mapActions(usePdfSearchStore, ['initializeSearch']),
      ...mapActions(usePdfViewerStore, [
        'setPagesAndWords',
        'setPdfViewerMouseCoordinates',
        'setPdfViewerPagePosition',
        'togglePdfViewerToolbar',
        'setPdfViewerZoom',
      ]),
      ...mapActions(usePdfAnnotationStore, [
        'closeAnnotationModal',
        'setHoverWordIds',
        'getWordRange',
      ]),
      ...mapActions(useProductSelectionsStore, ['setActiveIds']),
      ...mapActions(useAnnotationsStore, ['setAnnotations']),
      /**
       * Sentry error logging
       *
       * @param {object} obj
       * @param {string} obj.text
       * @param {object} obj.payload
       */
      logSentryError({ text, payload }) {
        addBreadcrumb({
          category: 'method',
          data: {
            ...payload,
            text,
          },
          level: 'debug',
        });
        captureException(text);
      },
      /**
       * Start a new annotation based on WordClick mode, based on an emit from the Page component
       *
       * @param {string} wordId
       */
      startWordClickAnnotation(wordId) {
        this.startWordId = wordId;
        this.isAnnotating = true;
        this.annotationType = 'click';
      },
      /**
       * Open annotation modal when page wordClicking is finished
       *
       * @param {object} obj
       * @param {Event} obj.$event
       * @param {string} obj.wordId
       */
      finishWordClickAnnotation({ $event, wordId }) {
        this.$nextTick(() => {
          const wordIds = this.getWordRange(this.startWordId, wordId);

          this.isAnnotating = false;
          this.openAnnotationModal($event, wordIds);
        });
      },
      /**
       * Scroll the pdfViewer to the goToAnnotation
       */
      handleGoToAnnotation() {
        if (!this.pages.length) {
          // If this page isn't loaded yet, lets try again in a few
          setTimeout(() => { this.handleGoToAnnotation(); }, 500);

          return;
        }

        if (this.goToAnnotation && typeof this.goToAnnotation === 'object') {
          // typecast for string to string comparison
          if (`${this.getActiveFileId}` === `${this.goToAnnotation.source_id}`) {
            const annotation = this.goToAnnotation;

            this.activeAnnotation = annotation;
            this.goToAnnotation = null;

            setTimeout(() => {
              this.scrollToAnnotation(annotation);
            }, 1000);
          } else {
            setTimeout(() => { this.handleGoToAnnotation(); }, 500);
          }
        }
      },
      /**
       * Reset annotation mode (drawBox or wordSelection) if they're active
       */
      resetAnnotationMode() {
        this.isAnnotating = false;
        this.annotationType = '';
        this.drawBoxCoordinates = null;
        this.closeAnnotationModal();
      },
      /**
       * Scroll to the first word of an annotation
       *
       * @param {object} annotation - The annotation to scroll to
       */
      scrollToAnnotation(annotation) {
        const [firstWordId] = annotation.word_ids;
        const pageNumber = Number(firstWordId.split('-')[0].substr(1));

        if (!this.pages.length) {
          setTimeout(() => { this.scrollToAnnotation(annotation); }, 600);

          return;
        }

        const page = this.pages[pageNumber - 1];
        const result = page.words.find((word) => word.id === firstWordId);

        if (!result) {
          this.logSentryError({
            text: 'annotation describes word that does not exist (scrollToAnnotation)',
            payload: {
              pageId: page.id,
              annotation,
              wordIdsOnPage: page.words,
            },
          });

          return;
        }

        const top = (result.top * this.getScale) - 300;

        this.scrollToPage(pageNumber, top);
      },
      /**
       * Set active ids when an annotation marker is hovered
       *
       * @param {object} annotation - Annotation Object
       */
      handleMarkerMouseOver(annotation) {
        this.activeAnnotation = annotation;

        const firstValue = annotation.plan_design_values[0];
        const valueIds = annotation.plan_design_values.map((planDesignValue) => planDesignValue.plan_design_value_id);

        this.setActiveIds({
          attributeId: firstValue.plan_design_attribute_id,
          categoryId: firstValue.category_id,
          productId: firstValue.product_id,
          valueIds,
        });
        // annotation marker hover always opens the annotation view
        // component, even in read only mode
        this.annotationModalMode = 'view';
      },
      /**
       * Open annotation modal and set the annotation words
       *
       * @param {event} $event - Click event
       * @param {Array} wordIds - word ids
       */
      openAnnotationModal($event, wordIds) {
        this.setActiveIds({
          attributeId: null,
          categoryId: null,
          productId: this.selectedProductId,
          valueIds: [],
        });
        this.setPdfViewerMouseCoordinates({
          mouseX: $event.clientX,
          mouseY: $event.clientY,
        });
        this.newAnnotationWordIds = wordIds;
        this.annotationModalMode = 'create';
      },
      /**
       * load all annotations for the active source and store in the Store
       */
      async loadAssetsAndAnnotations() {
        this.clearImageLoaders();
        this.setPagesAndWords();
        // Hang on to the fileId that we're loading so we can abort if the user loads another, different file
        const loadingFileId = this.getActiveFileId;

        /**
         * This needs to be refactored, imo. The nested promises are ugly
         */
        try {
          const { assets } = await FileService.getSourceAssets(this.getActiveFileId);

          // refactored per comment above to try catch's but this is still ugly and needed this getActiveFileId check
          if (!this.getActiveFileId) return;
          try {
            const { plan_design_annotations: annotations } = await FileService.getSourceAnnotations(this.getActiveFileId);
            const unrolledAnnotations = annotationsUtils.unrollAnnotations(
              annotations,
              this.getActiveFileId,
            );

            // User has requested to load a different doc. Abort!
            if (loadingFileId !== this.getActiveFileId) {
              return;
            }

            this.setAnnotations(unrolledAnnotations);

            if (assets.source_json) {
              this.error = null;
              const sourceUrl = assets.source_json.source_url;

              try {
                const { data, duration, headers } = await FileService.getPageMarkup(sourceUrl);
                // analytics
                const analyticsPayLoad = {
                  sourceURL: assets.source_json.source_url.split('?')[0],
                  sourceId: this.getActiveFileId,
                  duration,
                  contentLength: headers['content-length'],
                  project: this.currentProject.id,
                  pages: data.pages.length,
                };

                window.analytics && window.analytics.track(
                  'Download Source JSON File',
                  analyticsPayLoad,
                );

                // sort because the ordering is sometimes/often/? wrong
                const pages = [];

                assets.source_pages.sort(
                  (a, b) => a.page_number - b.page_number,
                );
                // start loading images
                for (const [index, page] of data.pages.entries()) {
                  const newPage = {
                    source_url: assets.source_pages[index].source_url,
                    ...page,
                  };

                  // User has requested to load a different doc. Abort!
                  if (loadingFileId !== this.getActiveFileId) {
                    return;
                  }

                  this.$set(this.pageImages, newPage.source_url, false);
                  pages.push(newPage);
                }

                // User has requested to load a different doc. Abort!
                if (loadingFileId !== this.getActiveFileId) {
                  return;
                }

                this.setPagesAndWords(pages);
              } catch {
                this.error = 'File not found.';
              }
            } else {
              this.error = 'Unknown error.';
            }
          } catch {
            this.displayToast({
              message: 'There was an error getting the source annotations.',
            });
          }
        } catch {
          this.displayToast({
            message: 'There was an error getting the source assets.',
          });
        }
      },
      /**
       * Remove all src attributes and onLoad handlers from any images that haven't finished loading
       */
      clearImageLoaders() {
        this.$set(this, 'pageImages', []);
      },
      /**
       * Preload image and update pageImages when it's done
       *
       * @param {string} sourceUrl
       */
      async preloadPageImage(sourceUrl) {
        if (!this.pageImages[sourceUrl]) {
          // NOTE 07/22/2021 RI: We're allowing the use of Fetch here because the Admin site is Chromium only.
          // this way, we can capture errors on the preloading, which is harder with new Image() / .onload.
          try {
            await fetch(sourceUrl, { mode: 'no-cors' });

            this.$set(this.pageImages, sourceUrl, true);
          } catch (error) {
            this.logSentryError({
              text: 'could not load PDF image',
              payload: {
                sourceUrl,
                error,
              },
            });
          }
        }
      },
      /**
       * Handle the scrolling inside the page-container
       */
      handleScroll() {
        if (this.showLoader) {
          return;
        }
        /**
         * Prevent console error in the case where this method is called after all tabs have been closed.
         * There is a rare, edge-case race-condition that /can/ occur if the user closes tabs very quickly.
         * If this method call gets queued up before all the tabs have unloaded but is executed after they have
         * closed then $refs['page-container'] could be null.
         * This is difficult to replicate and only appears to happen when the user closes all tabs quickly while
         * also moving the mouse around quickly.
         * DHB - 12/2021
         */
        if (this.$refs['page-container']) {
          this.setPdfViewerPagePosition({
            sourceId: this.getActiveFileId,
            scrollTop: this.$refs['page-container'].scrollTop,
          });
        }
      },
      /**
       * Scroll to a specific page, offset by the value off modifier (+100 because of the top bar)
       *
       * @param {number} pageNumber
       * @param {number} modifier
       */
      scrollToPage(pageNumber = 1, modifier = 0) {
        let height = 0;

        for (let pageIndex = 1; pageIndex < pageNumber; pageIndex += 1) {
          if (!this.pages[pageIndex]) {
            break;
          }
          height += (this.pages[pageIndex].pageRotation > 0 ? this.pages[pageIndex].width : this.pages[pageIndex].height) * this.getScale;
        }

        if (this.$refs['page-container']) {
          this.$refs['page-container'].scrollTo(0, height + modifier + 90);
        }
      },
      /**
       * increase or decrease user-editable zoom after button click
       *
       * @param {string} direction
       */
      handleZoomKey(direction) {
        const newZoom = (Math.ceil(this.zoom / 10) * 10)
          + (direction === 'decrease' ? -10 : 10);

        this.setPdfViewerZoom(newZoom);
      },
      /**
       * Handler for all pdfViewer keydown events (generally ones that need to prevent the default behavior)
       *
       * @param {Event} $event
       */
      handleKeyDown($event) {
        const targetTagName = $event.target.tagName.toLowerCase();
        // if we're not focused on an input, escape should cancel annotation
        const escapeWhileOutsideInput = !(['input', 'textarea'].includes(targetTagName)) && this.annotationModalMode !== 'closed';
        const { platform } = window.navigator;

        switch ($event.key) {
        case 'f':
          if ((($event.metaKey && platform.includes('Mac'))
            || ($event.ctrlKey && platform.includes('Win')))) {
            $event.preventDefault();
            this.togglePdfViewerToolbar('search');
          }
          break;
        case 'Escape':
          // if we are drawing a drawBox or are in the middle of creating a clickWord chain, it should cancel annotation mode
          if (escapeWhileOutsideInput || this.isAnnotating) {
            this.resetAnnotationMode();
          }
          break;
        default:
        }
      },
      /**
       * Initiate or finish 'box draw' mode
       *
       * @param {Event} $event
       */
      handlePageMouseDown($event) {
        if ($event.button === 0 && this.annotationMode !== 'click') {
          if (!this.isAnnotating) {
            const classAttribute = $event.target.getAttribute('class');
            // 1. in draw mode the spans on the words are always possible targets too
            const drawBoxDivIsTarget = classAttribute.includes('draw-box');
            // 2: pdf-background gets all the clicks because pointer events are disabled on the image
            const wordsDivIsTarget = classAttribute === 'words';
            // 3: when an annotation is on the page, the annotation marker-row should be an available target
            const annotationMarkerRowIsTarget = classAttribute === 'annotation-marker-row';
            // 4: when a box is being drawn, it'll always be under the cursor so it's
            // the only possible click target during drawBox mode
            const validDrawModeOnlyTarget = this.annotationMode === 'draw'
              && $event.target.tagName.toLowerCase() === 'div'
              && classAttribute === 'word';

            if (validDrawModeOnlyTarget || wordsDivIsTarget || drawBoxDivIsTarget || annotationMarkerRowIsTarget) {
              const x = $event.clientX + this.$refs['page-container'].scrollLeft;
              const y = $event.clientY - 102 + this.$refs['page-container'].scrollTop;

              // we are starting an annotation using drawBox mode
              this.isAnnotating = true;
              this.annotationType = 'draw';
              this.drawBoxCoordinates = {
                startX: x,
                startY: y,
                left: x,
                top: y,
                width: 0,
                height: 0,
                hoverX: x,
                hoverY: y,
              };
            }
          } else if (this.annotationType === 'draw') {
            // We are finishing an annotation using drawBox mode
            // Collision detection...which words are inside the selection area?
            const wordIds = this.findWordIdsInDrawBox();

            if (wordIds.length) {
              this.$nextTick(() => {
                this.openAnnotationModal($event, wordIds);
              });
            } else {
              this.displayToast({
                message: 'No words were found inside the area you selected.',
              });
            }

            this.isAnnotating = false;
            this.annotationType = '';
            this.drawBoxCoordinates = null;
          }
        }
      },
      /**
       * if DrawBox mode is active, draw box to match cursor coordinates
       *
       * @param {Event} $event
       */
      handlePageMouseMove($event) {
        /**
         * Prevent console error in the case where this method is called after all tabs have been closed.
         * There is a rare, edge-case race-condition that /can/ occur if the user closes tabs very quickly.
         * If this method call gets queued up before all the tabs have unloaded but is executed after they have
         * closed then $refs['page-container'] could be null.
         * This is difficult to replicate and only appears to happen when the user closes all tabs quickly while
         * also moving the mouse around quickly.
         * DHB - 12/2021
         */
        if (!this.$refs['page-container']) {
          return;
        }

        const x = $event.clientX + this.$refs['page-container'].scrollLeft;
        const y = $event.clientY - 102 + this.$refs['page-container'].scrollTop;

        if (this.isAnnotating && this.annotationType === 'draw') {
          this.drawBoxCoordinates.hoverX = x;
          this.drawBoxCoordinates.hoverY = y;

          if (x >= this.drawBoxCoordinates.startX) {
            this.drawBoxCoordinates.width = x - this.drawBoxCoordinates.startX;
            this.drawBoxCoordinates.left = this.drawBoxCoordinates.startX;
          } else {
            this.drawBoxCoordinates.width = this.drawBoxCoordinates.startX - x;
            this.drawBoxCoordinates.left = x;
          }

          if (y >= this.drawBoxCoordinates.startY) {
            this.drawBoxCoordinates.height = y - this.drawBoxCoordinates.startY;
            this.drawBoxCoordinates.top = this.drawBoxCoordinates.startY;
          } else {
            this.drawBoxCoordinates.height = this.drawBoxCoordinates.startY - y;
            this.drawBoxCoordinates.top = y;
          }
        }
      },
      /**
       * Perform collision detection between the drawBox and words that are visible in the DOM right now
       *
       * @returns {Array}
       */
      findWordIdsInDrawBox() {
        const drawBox = this.$refs.drawBox.getBoundingClientRect();

        // getElementsByClassName returns a htmlCollection,
        // not a real array, so, we need to recast.
        return [...document.getElementsByClassName('word')]
          .filter((wordElement) => {
            const word = wordElement.getBoundingClientRect();

            return !(
              ((drawBox.top + drawBox.height) < (word.top))
              || (drawBox.top > (word.top + word.height))
              || ((drawBox.left + drawBox.width) < word.left)
              || (drawBox.left > (word.left + word.width))
            );
          })
          .map((wordElement) => wordElement.dataset.wordId);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .pdf-viewer,
  .pdf-viewer-inner {
    position: relative;
    width: 100%;
    height: calc(100vh - 102px);
    overflow: hidden;
  }

  // Toolbars
  .toolbar {
    display: flex;
    position: absolute;
    align-items: center;
    top: 0;
    right: 0;
    width: auto;
    height: 46px;
    z-index: 12;
    padding: 0 5px;
    background: var(--tf-base-light);
    border-top: 1px var(--tf-gray-medium) solid;
    border-bottom: 1px var(--tf-gray-medium) solid;
    box-shadow: -2px 2px 5px 0 rgba(0, 0, 0, .25), inset -1px 0 0 0 var(--tf-gray-light-medium);

    :deep() & {
      &.toolbar-search {
        z-index: 13;
      }

      &.offset {
        top: 47px;
      }

      .toolbar-sub {
        display: flex;
        align-items: center;
        height: 46px;
        padding: 0 15px;
        border-right: 1px var(--tf-gray-medium) solid;

        &.toolbar-sub-magnification,
        &.toolbar-sub-search {
          border-right: 0;
        }
      }
    }
  }

  .page-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 15px;
    bottom: 0;
    z-index: 2;
    overflow: scroll;
    user-select: none;
  }

  .pages {
    transform-origin: top left;
    cursor: crosshair;
    margin-bottom: 128px;
    border-right: 1px var(--tf-gray-medium) solid;
  }

  .scroller {
    height: 100%;
  }

  .el-select {
    width: 100%;
  }

  .draw-box {
    z-index: 10000;
    background: rgba(0, 185, 128, .5);
    border: 1px solid var(--tf-green);
    position: absolute;
    cursor: crosshair;
  }

  .error-wrapper {
    margin: 20px;
  }
</style>
