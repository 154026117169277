<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17 15"
    :class="[{ 'is-automated': automated }, 'annotation-marker']"
    @click="$emit('click')"
  >
    <path
      v-if="count > 0"
      d="M0 0h15v13H0V0z"
      fill-rule="evenodd"
      clip-rule="evenodd"
    />
    <template v-if="count > 1">
      <path
        d="M1.99 1.99H17.5v13.56H1.99z"
      />
      <path
        d="M17 2.49v12.56H2.49V2.49H17m1-1H1.49v14.56H18V1.49z"
        fill="#fff"
      />
    </template>
  </svg>
</template>

<script>
/**
 * Indicator for annotations
 *
 * @vuedoc
 * @exports PlanDesignTableAnnotationMarker
 * @category Components
 */
  export default {
    name: 'PlanDesignTableAnnotationMarker',
    props: {
      automated: {
        type: Boolean,
        default: false,
      },
      count: {
        type: Number,
        default: 0,
      },
    },
  };
</script>

<style lang="scss" scoped>
  path {
    fill: #f8e71c;

    .is-automated & {
      fill: #80c7fb;
    }

    // strange workaround because vue classes aren't rendering on paths
    &:nth-of-type(3) {
      fill: #fff;
    }
  }

  .annotation-marker {
    width: 17px;
    height: 15px;
    cursor: pointer;
  }
</style>
