<template>
  <div
    v-loading="!isLoaded"
    class="tactic-panel-contents"
    data-test="tactic engine panel"
  >
    <JobSetupsTable v-if="isActiveTabDocument" />

    <DocumentType v-if="isActiveTabDocument" />

    <AppAlert
      v-if="errors.length"
      class="setup-error"
      type="danger"
    >
      <ul>
        <li
          v-for="(error, index) in errors"
          :key="`error-${index}`"
        >
          {{ error }}
        </li>
      </ul>
    </AppAlert>

    <div
      v-if="isActiveTabDocument"
      class="run-tactic btn-group align-end"
    >
      <AppButton
        data-test="cancel button"
        type="primary"
        size="text"
        text="Cancel"
        @click="$emit('togglePanel')"
      />
      <AppButton
        data-test="run tactic button"
        :is-disabled="runTacticDisabled"
        type="primary"
        text="Run tactic"
        @click="runTactic"
      />
    </div>

    <JobTable
      v-if="isActiveTabDocument"
      class="job-table"
      @jobsCanceled="onJobsCanceled"
    />

    <p class="confluence-link">
      <a
        href="https://watchtower.atlassian.net/wiki/spaces/UWOps/pages/1159561248/Tactic+Engine+eligiblity"
        target="_blank"
        rel="noopener noreferrer"
      >See all current rules</a>
    </p>
  </div>
</template>

<script>
  import {
    mapActions,
    mapState,
    mapWritableState,
  } from 'pinia';
  import FileService from '@/services/file.js';
  import ProductService from '@/services/product.js';
  import {
    getSearchJobSetups,
    getSourceSearchJobs,
    runSearchJob,
  } from '@/services/tacticEngine.js';
  import annotationsUtils from '@/utils/annotations.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useProjectProductStore } from '@/stores/projectProduct.js';
  import { useFileViewerStore } from '@/stores/fileViewer.js';
  import { useAnnotationsStore } from '@/stores/annotations.js';
  import { useTacticEngineStore } from '@/stores/tacticEngine.js';
  import { usePdfAnnotationStore } from '@/stores/pdfAnnotation.js';
  import { useProductAttributesStore } from '@/stores/productAttributes.js';

  import JobSetupsTable from './JobSetupsTable.vue';
  import JobTable from './JobTable.vue';
  import DocumentType from './DocumentType.vue';

  export default {
    name: 'TacticPanel',
    components: {
      JobSetupsTable,
      JobTable,
      DocumentType,
    },
    data() {
      return {
        errors: [],
        isLoaded: false,
        pollTimer: null,
      };
    },
    computed: {
      ...mapState(useProjectProductStore, [
        'selectedProjectProduct',
        'usesPlanTemplates',
      ]),
      ...mapState(useProjectStore, ['currentProject']),
      ...mapState(useTacticEngineStore, [
        'jobSetups',
        'jobs',
      ]),
      ...mapState(useFileViewerStore, ['getActiveFileId']),
      ...mapState(usePdfAnnotationStore, ['getCurrentSourceAnnotations']),
      ...mapWritableState(useTacticEngineStore, [
        'jobSetupDocumentType',
      ]),
      /**
       * Document is open (not the Broker Info tab)
       *
       * @returns {boolean}
       */
      isActiveTabDocument() {
        return this.getActiveFileId && this.getActiveFileId !== 'new-rfp-responses';
      },
      /**
       * Disable the Run Tactic button if there are no job setups without jobs
       * or if the document type has not been selected
       *
       * @returns {boolean}
       */
      runTacticDisabled() {
        // Look thru the setups
        for (const index in this.jobSetups) {
          // See if there's a job that matches this setup. If there isn't then the job can be run
          // and the button should NOT be disabled
          if (this.jobSetups[index]) {
            if (!this.jobs.some(
              ({ product_id: productId, project_products_container_id: containerId }) => productId === this.jobSetups[index].product_id
                && containerId === this.jobSetups[index].project_products_container_id,
            )) {
              return !this.jobSetupDocumentType;
            }
          }
        }
        // If there are no setups without run jobs, disable the button if
        // they haven't created any setups or they haven't selected a document type
        if (!this.jobSetups.length || !this.jobSetupDocumentType) {
          return true;
        }

        // If there is at least one setup, they've selected a document type and
        // there are no setups that don't have already run jobs, disable the Run Tactic button
        return true;
      },
    },
    watch: {
      getActiveFileId: {
        /**
         * Watch the actively selected document and load the appropriate setups and jobs
         * Unless they've closed all documents in which case just close the panel
         */
        handler() {
          if (!this.getActiveFileId) {
            this.$emit('togglePanel');
          } else if (this.getActiveFileId === 'new-rfp-responses') {
            this.errors = ['Select a document to enable the Tactic Engine setup panel.'];
          } else {
            this.isLoaded = false;
            this.errors = [];

            Promise.all([
              this.getSetups(),
              this.getJobs(),
            ]).then(() => {
              this.isLoaded = true;
            })
              .catch(() => {
                this.displayToast({
                  message: 'There was an error getting the search job data.',
                });
              });
          }
        },
        immediate: true,
      },
    },
    beforeDestroy() {
      // clean up the store as we don't need this stuff anymore
      // there's no reason to hang onto it after the panel is closed
      this.resetTacticEngineStore();
      clearTimeout(this.pollTimer);
    },
    methods: {
      ...mapActions(useProductAttributesStore, [
        'setCategoriesAndAttributes',
      ]),
      ...mapActions(useTacticEngineStore, [
        'resetTacticEngineStore',
        'setJobs',
        'setJobSetups',
      ]),
      ...mapActions(useAnnotationsStore, [
        'setAnnotations',
        'deleteSourceAnnotations',
      ]),
      /**
       * Fetch search jobs
       *
       * @returns {Promise}
       */
      getJobs() {
        return getSourceSearchJobs(this.getActiveFileId)
          .then(({ search_jobs: searchJobs }) => {
            this.setJobs(searchJobs);
          }).catch(() => {
            this.displayToast({
              message: 'There was an error retrieving the search jobs.',
            });
          });
      },
      /**
       * Get the job setups from the API
       *
       * @returns {Promise}
       */
      getSetups() {
        return getSearchJobSetups(this.getActiveFileId)
          .then((response) => {
            /**
             * It's possible that the response from the API will be null
             * This means there are no search job setups
             */
            const searchJobSetup = response ? response.search_job_setup : response;

            if (searchJobSetup) {
              /**
               * There is only one selected document type returned from the API
               * We want to make sure that, if it's defined, that it matches one of the available
               * document types in the search job setups
               */
              const docTypeIsValid = searchJobSetup.search_job_setup_entries.every(
                ({ search_template_document_types: docTypes }) => docTypes.includes(searchJobSetup.document_type),
              );

              if (searchJobSetup.search_job_setup_entries.length && docTypeIsValid) {
                this.jobSetupDocumentType = searchJobSetup.document_type;
              }
              this.setJobSetups(searchJobSetup.search_job_setup_entries);
            } else {
              this.jobSetupDocumentType = null;
              this.setJobSetups([]);
            }
          })
          .catch(() => {
            this.displayToast({
              message: 'There was an error retrieving the search job setups.',
            });
          });
      },
      /**
       * Run TacticEngine on the current source
       */
      runTactic() {
        this.isLoaded = false;

        runSearchJob(this.getActiveFileId)
          .then(({ search_job_setup: searchJobSetup }) => {
            this.setJobs(this.jobs.concat(
              searchJobSetup.search_job_setup_entries.map(({ search_job: searchJob }) => searchJob),
            ));
            this.errors = [];
            this.isLoaded = true;
            this.pollJobs();
          })
          .catch(({ data }) => {
            this.isLoaded = true;

            this.errors.push(data.message);
          });
      },
      /**
       * Get jobs as long as any of them are in a not completed state
       */
      pollJobs() {
        if (this.jobs.some(({ state }) => state !== 'completed')) {
          this.getJobs()
            .then(() => {
              this.pollTimer = setTimeout(this.pollJobs, 3000);
            })
            .catch(() => {
              this.displayToast({
                message: 'There was an error getting the search jobs.',
              });
            });
        } else {
          this.updateAnnotationsAndData();
        }
      },
      /**
       * Delete source annotations in the store and update annotations from the API server, just in case.
       */
      onJobsCanceled() {
        this.deleteSourceAnnotations(this.getActiveFileId);
        this.updateAnnotationsAndData();
      },
      /**
       * Update annotations after a job finishes running, or if we undo a job.
       */
      updateAnnotationsAndData() {
        // the fact that pollJobs is running at all means that there
        // were unfinished jobs and now there are none. Therefore,
        // we can/need to reload the annotations, etc.
        // TODO: this should be DRYd up. RI 12/06/21
        FileService
          .getSourceAnnotations(this.getActiveFileId)
          .then(({ plan_design_annotations: annotations }) => {
            const unrolledAnnotations = annotationsUtils.unrollAnnotations(annotations, this.getActiveFileId);
            const productDetailsPromise = ProductService
              .getProductDetails(this.selectedProjectProduct.inforce_product.id)
              .then((product) => product)
              .catch(() => {
                this.displayToast({
                  message: 'There was an issue loading product details.',
                });
              });
            const tierGroupPromise = ProductService
              .getAvailableTierGroups(this.selectedProjectProduct.product_type_id)
              .then((data) => data.tier_groups.plan_design_tier_groups)
              .catch(() => {
                this.displayToast({
                  message: 'There was an issue loading tier group information.',
                });
              });

            // annotations can be set without resolving the other promises
            this.setAnnotations(unrolledAnnotations);

            Promise.all([
              productDetailsPromise,
              tierGroupPromise,
            ])
              .then(([product, tierGroups]) => {
                this.setCategoriesAndAttributes({
                  product,
                  containers: product.project_products_containers,
                  tierGroups,
                  usePlanTemplates: this.usesPlanTemplates,
                  updateStore: true,
                });
              })
              .catch(() => {
                this.displayToast({
                  message: 'There was an issue loading product details and/or tier group information.',
                });
              });
          })
          .catch(() => {
            this.displayToast({
              message: 'There was an issue loading annotations for this source.',
            });
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
h4 {
  font-size: 16px;
}

.job-table {
  margin: 36px 0;
  padding: 36px 0;
  border-top: 1px solid var(--tf-gray-light-medium);
}

.tactic-panel-contents {
  padding: 22px 17px 60px;
  text-align: left;
}

.run-tactic {
  margin-top: 20px;
}

.confluence-link {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 20px 20px;
  margin: 0;
  background-color: #fff;
}
</style>
