<template>
  <LfcModal
    title="Update password"
    :visible="passwordChangeRequired"
    :show-close="false"
    :close-on-press-escape="false"
  >
    <AppResetPasswordForm
      :password.sync="password"
      :is-valid-password.sync="isValidPassword"
    >
      <template #ctaSubmit>
        <AppButton
          :is-disabled="!isValidPassword"
          :is-loading="isUpdating"
          data-test="update password"
          native-type="submit"
          type="primary"
          is-block
          text="Update password"
          @click="updatePassword"
        />
      </template>
    </AppResetPasswordForm>
  </LfcModal>
</template>

<script>
  import { useAuthStore } from '@/stores/auth.js';
  import { mapWritableState } from 'pinia';
  import { changePassword } from '@/services/account.js';

  export default {
    name: 'UpdatePassword',
    data: () => ({
      changePassword,
      email: '',
      isUpdating: false,
      password: '',
      isValidPassword: false,
    }),
    computed: {
      ...mapWritableState(useAuthStore, ['passwordChangeRequired']),
    },
    methods: {
      /**
       * updates user password
       */
      async updatePassword() {
        this.isUpdating = true;

        try {
          await this.changePassword(this.password);

          this.passwordChangeRequired = false;
          this.$message({
            showClose: true,
            message: 'Success! Your password has been updated.',
            type: 'success',
          });
        } catch ({ response: { data: { message } } }) {
          this.$message({
            duration: 10000,
            message,
            showClose: true,
            type: 'error',
          });
          this.isUpdating = false;
        }
      },
    },
  };
</script>
