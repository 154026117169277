<template>
  <div class="project-setup">
    <AppAlert
      v-if="currentProject?.merged_into && showMergedProjectAlert"
      type="warning"
      closable
      class="merged-project-alert"
      @close="showMergedProjectAlert = false"
    >
      <p>
        This project is closed because it has been merged with another project,
        <a
          :href="$router.resolve({
            name: 'ProjectSetup',
            params: { projectId: currentProject.merged_into },
          }).href"
        >
          click here
        </a>
        to view the merged project.
      </p>
    </AppAlert>
    <FileViewer
      :project-id="projectId"
    />
    <TfPanel2
      :animate="false"
      :closable="false"
      :status-bar="true"
      :open="isPanelOpen('product-setup')"
      :fixed-position="false"
      max-width="40%"
      min-width="500px"
      position="right"
      data-test="toggle product setup"
      @togglePanel="setPanel('product-setup')"
    >
      <ProductPanel />
    </TfPanel2>

    <TfPanel2
      v-if="isPanelOpen('tactic-engine')"
      title="Tactic engine setup"
      class="tactic-setup-panel"
      :animate="false"
      :open="isPanelOpen('tactic-engine')"
      max-width="510px"
      min-width="510px"
      position="right"
      data-test="toggle tactic engine"
      @togglePanel="setPanel('tactic-engine')"
    >
      <TacticPanel
        @togglePanel="setPanel('tactic-engine')"
      />
    </TfPanel2>
  </div>
</template>

<script>
  import {
    mapActions,
    mapState,
    mapWritableState,
  } from 'pinia';
  import { usePanelsStore } from '@/stores/panels.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useProjectProductStore } from '@/stores/projectProduct.js';
  import { getProjectInfo } from '@/services/project.js';
  import { usePdfAnnotationStore } from '@/stores/pdfAnnotation.js';
  import FileViewer from '@/components/FileViewer/index.vue';
  import ProductPanel from '@/components/ProductPanel/index.vue';
  import TacticPanel from '@/components/TacticPanel/index.vue';

  export default {
    components: {
      FileViewer,
      ProductPanel,
      TacticPanel,
    },
    props: {
      projectId: {
        type: [Number, String],
        default: null,
      },
      projectProductId: {
        type: [Number, String],
        default: null,
      },
    },
    data: () => ({
      showMergedProjectAlert: true,
    }),
    computed: {
      ...mapState(usePanelsStore, ['isPanelOpen']),
      ...mapWritableState(useProjectProductStore, ['selectedProjectProductId']),
      ...mapWritableState(useProjectStore, ['currentProject']),
      ...mapState(useProjectProductStore, ['activeProjectProducts']),
    },
    watch: {
      activeProjectProducts: {
        /**
         * if we have a projectProductId via the router, then we want to confirm that id exists in our active products (this is an edge case).
         *
         * @param {Array} activeProjectProducts
         */
        handler(activeProjectProducts) {
          if (activeProjectProducts.length && this.projectProductId) {
            const activeProduct = activeProjectProducts.find((product) => Number(this.projectProductId) === product.id);

            if (!activeProduct) {
              // if this product doesn't exist we'll just send them to the first product
              this.selectedProjectProductId = activeProjectProducts[0].id;
              this.$router.replace({
                params: {
                  projectProductId: activeProjectProducts[0].id,
                },
              });
            }
          }
        },
        immediate: true,
      },
    },
    async created() {
      this.registerPanel({
        name: 'product-setup',
        startOpen: true,
      });
      this.registerPanel({
        name: 'tactic-engine',
        startOpen: false,
      });
      if (!this.currentProject) {
        try {
          const { project } = await getProjectInfo(this.projectId);

          this.currentProject = project;
        } catch {
          this.displayToast({
            message: 'There was an error getting the project info.',
          });
        }
      }
    },
    beforeDestroy() {
      this.resetPanelsStore();
      this.closeAnnotationModal();
      this.currentProject = null;
      this.setActiveProjectProducts({});
      this.selectedProjectProductId = null;
    },
    methods: {
      ...mapActions(usePanelsStore, ['registerPanel', 'setPanel', 'resetPanelsStore']),
      ...mapActions(usePdfAnnotationStore, ['closeAnnotationModal']),
      ...mapActions(useProjectProductStore, ['setActiveProjectProducts']),
    },
  };
</script>

<style lang="scss" scoped>
.project-setup {
  position: absolute;
  top: 57px;
  bottom: 0;
  display: flex;
  width: 100%;

  :deep(.panel.open) {
    overflow: hidden;
  }
}

.merged-project-alert {
  /* stylelint-disable-next-line */
  position: fixed !important;
  top: 57px;
  left: 50%;
  translate: -50%;
  z-index: 1000;
}
</style>
