<template>
  <div
    v-if="broker"
    class="broker-content management-content"
  >
    <ManagementHeader
      :breadcrumb="{ name: 'BrokerManagement' }"
      breadcrumb-text="Back to brokers"
      data-test="display broker name"
    >
      Broker info &ndash; {{ broker.name }}
    </ManagementHeader>

    <TfTabs
      :value="tabValue"
      @tab-click="onTabClick"
    >
      <ElTabPane
        v-for="({ label, name }) in computedPanes"
        :key="label"
        :label="label"
        :name="name"
        :data-test="`to ${label} pane`"
      />
    </TfTabs>
    <RouterView
      v-if="!brokerId || broker.id"
    />
  </div>
</template>

<script>
  // pinia
  import { mapWritableState, mapState } from 'pinia';
  import { useBrokersStore } from '@/stores/brokers.js';
  // components
  import ManagementHeader from '@/components/Management/Header.vue';

  /**
   * Edit brokerage view
   *
   * @vuedoc
   * @exports ManageBroker
   * @category Views
   */
  export default {
    name: 'ManageBroker',
    components: {
      ManagementHeader,
    },
    props: {
      brokerId: {
        type: [Number, String],
        default: null,
      },
    },
    data() {
      return {
        statesList: [],
      };
    },
    computed: {
      ...mapState(useBrokersStore, ['currentBroker', 'brokers']),
      ...mapWritableState(useBrokersStore, ['currentBrokerId']),
      broker: {
        get() {
          return this.brokers.find((broker) => +broker.id === +this.brokerId);
        },
      },
      /**
       * Returns the panes array with the hierarchy pane added if the feature flag is enabled.
       *
       * @returns {Array}
       */
      computedPanes() {
        const panes = [
          {
            label: 'Users',
            name: 'EditBrokerUsers',
          },
        ];

        panes.push(
          {
            label: 'Invite users',
            name: 'BrokerInviteUsers',
          },
          {
            label: 'Pending invitations',
            name: 'BrokerUserInvites',
          },
          {
            label: 'Invitable carriers',
            name: 'EditBrokerInvitableCarriers',
          },
          {
            label: 'Email domains',
            name: 'EditBrokerDomains',
          },
        );

        panes.push(
          {
            label: 'Hierarchy',
            name: 'EditBrokerHierarchy',
          },
        );

        panes.push(
          {
            label: 'Settings',
            name: 'EditBrokerSettings',
          },
        );

        return panes;
      },
      /**
       * Name
       *
       * @returns {string}
       */
      tabValue() {
        // this view goes in the same tab as the users page
        if (this.$route.name === 'EditBrokerUser') {
          return 'EditBrokerUsers';
        }

        return this.$route.name;
      },
    },
    /**
     * On create we want to hit the api and grab the current broker (if there is a broker id)
     */
    created() {
      if (this.brokerId) {
        this.currentBrokerId = this.brokerId;
      }
    },
    methods: {
      /**
       * Evaluates the name to determine the correct route params to push.
       *
       * @param {object} root
       * @param {string} root.name
       */
      onTabClick({ name }) {
        if (name !== this.tabValue) {
          this.$router.push({ name });
        }
      },
    },
  };
</script>

<style lang="scss">
  .broker-content .el-tabs__content {
    margin-top: 40px;
  }
</style>
