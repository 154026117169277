<template>
  <ElFormItem
    v-if="isEditing"
    :class="{ 'is-error': isDuplicate }"
  >
    <TfInputWithOptions
      v-model.trim="label"
      data-test-input
      :allow-enter="false"
      :disabled="disabled"
      :placeholder="placeholder"
      option-key="productLabel"
      prop="label"
      width="auto"
      data-test="Enter alternative label"
      @keydownEnter="$emit('onKeydownEnter')"
    />
    <p class="form-disclaimer">
      <span
        :class="{ 'has-error': isDuplicate }"
        v-text="'Unique characters: 31'"
      />
      <span
        :class="{ 'has-error': containsSpecial }"
        v-text="'Special characters :, \, /, ?, *, [, or ] will be removed in tabs appearing in the Excel export.'"
      />
    </p>
  </ElFormItem>
  <AppButton
    v-else-if="enableProductLabelLink"
    :text="product.label"
    type="primary"
    size="text"
    class="readonly-text"
    @click.native="$emit('click')"
  />
  <span
    v-else
    class="readonly-text"
    v-text="product.label"
  />
</template>

<script>
  import { mapState } from 'pinia';
  import { useProjectProductStore } from '@/stores/projectProduct.js';
  /**
   * Product label input and readonly text.
   *
   * @vuedoc
   * @exports ProductLabel
   * @category Components
   */
  export default {
    name: 'ProductLabel',
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
      enableProductLabelLink: {
        type: Boolean,
        default: false,
      },
      isEditing: {
        type: Boolean,
        default: false,
      },
      placeholder: {
        type: String,
        default: 'Enter base product label (optional)',
      },
      product: {
        type: Object,
        default: () => ({
          id: null,
          label: '',
        }),
      },
      required: {
        type: Boolean,
        default: false,
      },
      value: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        uniqueIdCount: 31,
      };
    },
    computed: {
      ...mapState(useProjectProductStore, ['activeProjectProducts']),
      /**
       * Evaluates if the label contains any special characters.
       *
       * @returns {boolean}
       */
      containsSpecial() {
        const label = this.label.substring(0, this.uniqueIdCount).toLowerCase();
        const specialCharacters = /[:?*[\]/\\]/g;

        return label.match(specialCharacters) && label.match(specialCharacters).length > 0;
      },
      /**
       * Evaluates if the label is a duplicate of active product labels.
       *
       * @returns {boolean}
       */
      isDuplicate() {
        return this.label.length
          ? this.uniqueLabels.includes(this.label.substring(0, this.uniqueIdCount).toLowerCase())
          : false;
      },
      /**
       * Returns the v-model prop and emits the value on @input.
       *
       * @returns {string}
       */
      label: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        },
      },
      /**
       * Filters the active products for product labels that have been truncated to the uniqueIdCount or the length of the label model.
       *
       * @returns {Array}
       */
      uniqueLabels() {
        return this.activeProjectProducts
          .filter((product) => product.id !== this.product.id)
          .map((product) => product.label.substring(0, this.uniqueIdCount).toLowerCase());
      },
    },
    watch: {
      /**
       * The parent component should be notified when `isDuplicate` changes.
       */
      isDuplicate() {
        // Aaron notes: I tried to use `this.$refs.productLabel.isDuplicate` but
        // it wasn't working with new products for some reason.
        // I might come back to this later if I have some time though.
        this.$emit('isDuplicate', this.isDuplicate);
      },
    },
  };
</script>

<style lang="scss" scoped>
  p.form-disclaimer {
    margin: 5px 0 10px;
    font-size: 12px;
  }
</style>
