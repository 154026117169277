import axios from 'axios';
import { getAxiosConfig } from '@watchtowerbenefits/es-utils-public';
import { config } from '@/utils/config.js';

const apiUrl = `${config.VUE_APP_API_URL}/v1/admin`;

/**
 * Smart Parser/Annotation API calls
 *
 * @exports NormalizedValues
 * @category Services
 */
export default {
  /**
   * Get normalized values from the API
   *
   * @param {object} payload
   * @param {number} payload.page
   * @param {number} payload.count
   *
   * @returns {Promise}
   */
  getNormalizedValues({ page = 1, count = 100 }) {
    return new Promise((resolve, reject) => {
      const url = `${apiUrl}/normalized_values?current_page=${page}&per_page=${count}`;
      const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);

      axios
        .get(url, axiosConfig)
        .then((response) => {
          resolve(response.data.normalized_values_data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * Create a new normalized value
   *
   * @param {object} obj
   * @param {number} obj.productTypeId
   * @param {number} obj.planDesignAttributeId
   * @param {string} obj.value
   *
   * @returns {Promise}
   */
  createNormalizedValue({
    productTypeId,
    planDesignAttributeId,
    value,
  }) {
    return new Promise((resolve, reject) => {
      const url = `${apiUrl}/normalized_values/`;
      const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);
      const payload = {
        product_type_id: productTypeId,
        plan_design_attribute_id: planDesignAttributeId,
        value,
      };

      axios
        .post(url, payload, axiosConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e.response.data);
        });
    });
  },
  /**
   * Update existing normalized value
   *
   * @param {object} obj
   * @param {number} obj.id
   * @param {string} obj.value
   *
   * @returns {Promise}
   */
  updateNormalizedValue({ id, value }) {
    return new Promise((resolve, reject) => {
      const url = `${apiUrl}/normalized_values/${id}`;
      const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);
      const payload = {
        value,
      };

      axios
        .patch(url, payload, axiosConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e.response.data);
        });
    });
  },
  /**
   * Delete a normalized value
   *
   * @param {number} valueId
   * @returns {Promise}
   */
  deleteNormalizedValue(valueId) {
    return new Promise((resolve, reject) => {
      const url = `${apiUrl}/normalized_values/${valueId}`;
      const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);

      axios
        .delete(url, axiosConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * Get attributes for a given product type id
   *
   * @param {number} productTypeId
   * @returns {Promise}
   */
  getProductTypeAttributes(productTypeId) {
    return new Promise((resolve, reject) => {
      const url = `${apiUrl}/normalized_values/available_plan_design_attributes/${productTypeId}`;
      const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);

      axios
        .get(url, axiosConfig)
        .then((response) => {
          resolve(response.data.available_plan_design_attributes);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
