<template>
  <tbody>
    <template v-for="(containerGroup, containerGroupIndex) in tierGroup.containerGroups">
      <template v-for="(value, valueIndex) in containerGroup">
        <ValueRow
          :key="`${value.tier_subtype_name}-${value.tier_subtype_id}-${containerGroupIndex}-${valueIndex}-${value.project_products_container_ids.join('_')}`"
          ref="value-row"
          :attribute-id="attributeId"
          :attribute-name="attributeName"
          :category="category"
          :no-border="containerGroup.length > 1 && !valueIndex"
          :normalized-values="normalizedValues"
          :read-only-attribute="readOnlyAttribute"
          :sorted-tier-groups="sortedTierGroups"
          :tier-group="tierGroup"
          :unused-container-ids="unusedContainerIds"
          :value="value"
          :value-index="valueIndex"
          :data-test="`${value?.tier_subtype_name?.toLowerCase() ?? 'subtype'} row`"
          @containerChange="onContainerChange({
            containerGroup,
            containers: $event,
            value,
          })"
          @tierGroupChange="onTierGroupChange({
            containerGroup,
            containerGroupIndex,
            tierGroupId: value.tier_group_id,
          })"
        >
          <!-- Edit && Add/Delete Row controls -->
          <template #rowControls>
            <!-- IF !readonly: Show the Ellipsis w/ popover menu -->
            <ElPopover
              v-if="!readOnlyAttribute"
              v-model="value.popoverVisible"
              placement="left"
              :offset="-40"
              :visible-arrow="false"
              :append-to-body="false"
              popper-class="attribute-more-popover"
              width="122"
              trigger="click"
            >
              <!-- TODO LC-563 -->
              <AppButton
                slot="reference"
                class="ellipsis-button"
                type="secondary"
                icon="fa-solid fa-ellipsis-v"
                data-test="more button"
                size="icon"
                text="More"
              />
              <AppButton
                class="close-more-button"
                type="secondary"
                icon="fa-solid fa-times"
                size="icon"
                text="Close"
                @click="value.popoverVisible = !value.popoverVisible"
              />
              <div class="button-group">
                <AppButton
                  type="secondary"
                  icon="fa-solid fa-plus"
                  size="text-small"
                  data-test="add row"
                  text="Add row"
                  @click="$emit('addRow', { rowInfo: value })"
                />
                <AppButton
                  :is-disabled="sortedTierGroups.length === 1 && tierGroup.containerGroups.length === 1"
                  type="secondary"
                  icon="fa-solid fa-trash-alt"
                  size="text-small"
                  data-test="delete row"
                  text="Delete row"
                  @click="$emit('deleteRow', {
                    containerGroup,
                    containerGroupIndex,
                    tierGroupIndex,
                  })"
                />
              </div>
            </ElPopover>
            <!-- ELSE: Show edit icon -->
            <AppButton
              v-else
              type="secondary"
              icon="fa-solid fa-pencil"
              size="icon"
              text="Edit"
              @click="$emit('edit')"
            />
          </template>
        </ValueRow>
      </template>
    </template>
  </tbody>
</template>

<script>
  import { mapState, mapWritableState } from 'pinia';
  import { useProductContainersStore } from '@/stores/productContainers.js';
  import { useProductAttributesStore } from '@/stores/productAttributes.js';
  import ValueRow from '@/components/PlanDesign/Table/ValueRow.vue';

  /**
   * Plan Design Tier Group
   *
   * @vuedoc
   * @exports PlanDesignTableTierGroup
   * @category Components
   */
  export default {
    name: 'PlanDesignTableTierGroup',
    components: {
      ValueRow,
    },
    props: {
      attributeId: {
        type: Number,
        default: 0,
      },
      attributeName: {
        type: String,
        default: null,
      },
      category: {
        type: Object,
        default: () => ({}),
      },
      normalizedValues: {
        type: Array,
        default: () => [],
      },
      readOnlyAttribute: {
        type: Boolean,
        default: false,
      },
      sortedTierGroups: {
        type: Array,
        default: () => [],
      },
      unusedContainerIds: {
        type: Array,
        default: () => [],
      },
      tierGroup: {
        type: Object,
        required: true,
        default: () => {},
      },
      tierGroupIndex: {
        type: Number,
        default: 0,
      },
    },
    computed: {
      ...mapState(useProductContainersStore, ['currentContainers']),
      ...mapState(useProductAttributesStore, [
        'attributesHidden',
      ]),
      ...mapWritableState(useProductAttributesStore, [
        'planDesignErrorCount',
        'validatingPlanDesignAttributes',
      ]),
    },
    watch: {
      async validatingPlanDesignAttributes() {
        if (this.validatingPlanDesignAttributes[this.attributeId]) {
          await this.validatePlanDesignValues();
          this.validatingPlanDesignAttributes[this.attributeId] = false;
        }
      },
    },
    mounted() {
      // On mount I remove the tabindex from the ellipsisButton
      // TODO: When we upgrade element we should be able to put a tabindex on the popover
      // but right now the popover is putting a tabindex of 0 on the ellipsisButton
      const ellipsisButton = this.$el.querySelector('.ellipsis-button');

      if (ellipsisButton) {
        ellipsisButton.setAttribute('tabindex', '-1');
      }
    },
    methods: {
      /**
       * Emits `containerChange` up the the Plan Design Table component.
       * aaron notes : I'd like to do this directly in the template but for some reason, it was sending JS errors. I'll look into this later if I come back to this.
       *
       * @param {object} options
       * @param {Array} options.containerGroup
       * @param {Array} options.containers
       * @param {object} options.value
       */
      onContainerChange({ containerGroup, containers, value }) {
        this.$emit('containerChange', {
          containerGroup,
          containers,
          tierGroup: this.tierGroup,
          value,
        });
      },
      /**
       * Emits `tierGroupChange` up the the Plan Design Table component.
       * aaron notes : I'd like to do this directly in the template but for some reason, it was sending JS errors. I'll look into this later if I come back to this.
       *
       * @param {object} options
       * @param {Array} options.containerGroup
       * @param {number} options.containerGroupIndex
       * @param {number} options.tierGroupId
       */
      onTierGroupChange({
        containerGroup,
        containerGroupIndex,
        tierGroupId,
      }) {
        this.$emit('tierGroupChange', {
          containerGroup,
          containerGroupIndex,
          tierGroup: this.tierGroup,
          tierGroupId,
          tierGroupIndex: this.tierGroupIndex,
        });
      },
      /**
       * Triggered when the product panel has hit the Validate Plan button.
       * This goes through each of the tier group value rows and validates the forms. Once all of the value rows have been complete, it emits up to the Product Panel.
       */
      async validatePlanDesignValues() {
        let errorsFound = 0;

        if (!this.attributesHidden[this.attributeId] && this.$refs['value-row']) {
          for await (const containerRow of this.$refs['value-row']) {
            // 1) Validate each value-row
            // 2) Add the validation errors from each container for row
            try {
              const validationErrors = await containerRow.validateValues();

              errorsFound += validationErrors;
            } catch {
              this.displayToast({
                message: 'There was an error validating the plan design values.',
              });
            }
          }
        }

        this.planDesignErrorCount = this.planDesignErrorCount !== null
          ? this.planDesignErrorCount + errorsFound
          : errorsFound;
      },
    },
  };
</script>

<style scoped lang="scss">
  /* stylelint-disable declaration-no-important  */
  :deep(.el-popover.attribute-more-popover) {
    top: 38px !important;
    right: 6px !important;
  }

  .close-more-button.is-icon {
    position: absolute;
    right: -1px;
    top: -23px;
    padding: 1px 3px;
    z-index: 1;
    background-color: var(--tf-base-light) !important;
    border-radius: 4px 4px 0 0;
    border: {
      left: 1px solid var(--tf-blue);
      right: 1px solid var(--tf-blue);
      top: 1px solid var(--tf-blue);
    }
  }
  /* stylelint-enable declaration-no-important */
</style>
