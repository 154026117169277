<template>
  <div class="client-setup-info">
    <div class="content">
      <template v-if="isLoaded">
        <SetupHeader
          :employer="brokerProjectInfo.employer"
        />

        <SetupTable
          v-for="product in brokerProjectInfo.products"
          :key="product.product_id"
          :product="product"
          :closed="getClosedInfoTableIds.includes(product.product_type_id)"
        />
      </template>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'pinia';
  import { useFileViewerStore } from '@/stores/fileViewer.js';
  import ProjectService from '@/services/project.js';
  import { useProjectStore } from '@/stores/project.js';
  import SetupHeader from './SetupHeader.vue';
  import SetupTable from './SetupTable.vue';

  /**
   * ClientSetupInfo base component
   *
   * @vuedoc
   * @exports ClientSetupInfo
   * @category Components
   */
  export default {
    name: 'ClientSetupInfo',
    components: {
      SetupHeader,
      SetupTable,
    },
    data: () => ({
      isLoaded: false,
      brokerProjectInfo: {},
    }),
    computed: {
      ...mapState(useProjectStore, ['currentProject']),
      ...mapState(useFileViewerStore, ['getClosedInfoTableIds']),
    },
    async created() {
      try {
        const { broker_project_info: brokerProjectInfo } = await ProjectService
          .getInitialClientSetupInfo(this.currentProject.id);

        this.isLoaded = true;
        this.$set(this, 'brokerProjectInfo', brokerProjectInfo);
      } catch {
        this.displayToast({
          message: 'There was an error retrieving client setup info.',
        });
      }
    },
  };
</script>

<style lang="scss" scoped>
  .client-setup-info {
    box-sizing: border-box;
    // expose scrollbars
    width: calc(100% - 15px);
    padding: 20px 35px;
    overflow: scroll;
  }

  .content {
    margin: 0 auto;
    max-width: 1200px;
  }
</style>
