<template>
  <div>
    <TfTable
      v-bind="{
        tableData,
        tableMeta,
      }"
      :key="tableData.length"
    >
      <template #parentRow="{ rowData }">
        <TeamMemberTableRow
          data-test="team member row"
          :row-data="rowData"
          :can-be-deleted="canBeDeleted"
          :broker-users="brokerUsers"
          :team-member-ids="teamMemberIds"
          @onNewRowCancel="onNewRowCancel"
          @onRowSave="onRowSave"
          @onRowDelete="onRowDelete"
        />
      </template>
      <template
        v-if="error"
        #error
      >
        {{ error }}
      </template>
      <template #empty>
        <div>No users of this type added.</div>
      </template>
    </TfTable>
    <div class="controls">
      <AppButton
        data-test="add team member select"
        :is-disabled="isAdding"
        type="primary"
        size="text"
        text="Add team member"
        @click="addTeamMember"
      />
      <p>
        Need to register a broker user? Go to
        <RouterLink :to="{ name: 'EditBroker', params: { brokerId } }">
          broker management.
        </RouterLink>
      </p>
    </div>
  </div>
</template>

<script>
  // services
  import { getBrokerUsers } from '@/services/broker.js';
  // components
  import TeamMemberTableRow from '@/components/Modal/EditBrokerUsers/TeamMemberTable/Row.vue';

  /**
   * Repeated block with link for adding additional broker users
   *
   * @vuedoc
   * @exports TeamMemberTable
   * @category Components
   */
  export default {
    name: 'TeamMemberTable',
    components: {
      TeamMemberTableRow,
    },
    props: {
      teamMembers: {
        type: Array,
        required: true,
      },
      brokerId: {
        type: Number,
        required: true,
      },
    },
    data: () => ({
      brokerUsers: [],
      error: null,
      isAdding: false,
      tableData: [],
      tableMeta: {
        columns: [
          {
            prop: 'fullName',
            label: 'Name',
          },
        ],
      },
    }),
    computed: {
      /**
       * Should the delete button be shown on the item(s) in the table
       * Rules:
       * 1. Delete should be disabled if there's only one row, because one user
       * is required on each team
       * 2. Delete should be disabled if there's two rows, IF we're adding a row,
       * because that means there's no valid team member on the row if the
       * non-adding row is not finished.
       *
       * @returns {boolean}
       */
      canBeDeleted() {
        return this.tableData.length > (this.isAdding ? 2 : 1);
      },
      /**
       * Keep a flattened set of just the current team's ids
       * to filter them out of the broker list
       *
       * @returns {Array}
       */
      teamMemberIds() {
        return this.tableData.map(({ id }) => id);
      },
    },
    /**
     * Load all Broker Users for this carrier up front;
     * Clone and homogenize the user data so that we can
     * have only what we need, and can render a full name
     * for each different data set.
     */
    async created() {
      this.tableData = this.teamMembers.map(({
        first_name: firstName,
        full_name: fullName,
        id,
        last_name: lastName,
        user_id: userId,
      }, index) => ({
        fullName: fullName || `${firstName} ${lastName}`,
        id: userId || id,
        index,
      }));

      try {
        const { users_data: { users } } = await getBrokerUsers({
          brokerId: this.brokerId,
          page: 1,
          count: 10000,
          includeAncestors: true,
        });

        this.brokerUsers = [...users]
          .sort((a, b) => a.last_name.localeCompare(b.last_name))
          .reduce(
            (acc, {
              active,
              first_name: firstName,
              last_name: lastName,
              id,
            }) => (active
              ? acc.concat({
                fullName: `${firstName} ${lastName}`,
                id,
              })
              : acc),
            [],
          );
      } catch {
        this.displayToast({
          message: 'An error occurred loading the broker users list.',
        });
      }
    },
    methods: {
      /**
       * Add a new blank row in the team data.
       */
      addTeamMember() {
        this.tableData.push({
          fullName: '',
          id: null,
          index: this.tableData.length,
        });

        this.isAdding = true;
        this.emitTeamMembersUpdate();
      },
      /**
       * Delete a row by index
       *
       * @param {number} index
       */
      deleteRow(index) {
        this.$set(this, 'tableData', this.tableData.filter(({ index: rowIndex }) => index !== rowIndex));
      },
      /**
       * If new row is canceled, delete the blank row and unset isAdding.
       *
       * @param {number} index
       */
      onNewRowCancel(index) {
        this.deleteRow(index);
        this.isAdding = false;
        this.emitTeamMembersUpdate();
      },
      /**
       * Delete the row if the user tried to delete it.
       *
       * @param {number} index
       */
      onRowDelete(index) {
        this.deleteRow(index);
        this.emitTeamMembersUpdate();
      },
      /**
       * Update the row if the ID was edited.
       *
       * @param {object} payload
       * @param {number} payload.index
       * @param {number} payload.userId
       */
      onRowSave({ index, userId }) {
        const existingRow = this.tableData[index];
        const newMember = this.brokerUsers.find(
          (user) => user.id === userId,
        );

        // this is a new user, so we can turn isAdding off after this.
        if (!existingRow.id) {
          this.isAdding = false;
        }

        this.$set(existingRow, 'fullName', newMember.fullName);
        this.$set(existingRow, 'id', newMember.id);
        this.emitTeamMembersUpdate();
      },
      /**
       * Pass the updated array back to the parent for processing
       */
      emitTeamMembersUpdate() {
        this.$emit('updateTeamMembers', this.tableData);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .controls {
    margin-top: 26px;
    height: 120px;

    p {
      margin-top: 34px;
    }
  }
</style>
