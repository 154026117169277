<template>
  <div class="btn-group">
    <AppButton
      v-if="!container.newId && container.description && container.state !== 'saving'"
      :is-disabled="container.state === 'deleting' || !container.description"
      icon="fa-solid fa-times"
      size="icon"
      type="secondary"
      data-test="cancel button"
      @click="$emit('cancelSave')"
    />
    <AppButton
      :is-disabled="!localContainer.description || localContainer.description === container.description || disabledSaveBtn"
      :is-loading="container.state === 'saving'"
      icon="fa-solid fa-check"
      size="icon"
      type="affirm"
      data-test="save button"
      @click="saveDescription"
    />
  </div>
</template>

<script>
  import { mapActions, mapState } from 'pinia';
  import { useProductContainersStore } from '@/stores/productContainers.js';
  import { useProductSelectionsStore } from '@/stores/productSelections.js';

  /**
   * Container Description Actions
   *
   * @vuedoc
   * @exports ContainerDescriptionActions
   * @category Components
   */
  export default {
    name: 'ContainerDescriptionActions',
    props: {
      disabledSaveBtn: {
        type: Boolean,
        default: false,
      },
      container: {
        type: Object,
        required: true,
        default: () => {},
      },
      localContainer: {
        type: Object,
        required: true,
        default: () => {},
      },
    },
    computed: {
      ...mapState(useProductSelectionsStore, ['selectedProductId']),
    },
    methods: {
      ...mapActions(useProductContainersStore, ['updateProductContainer']),
      /**
       * When a description changes we validate it for calling `updateProductContainer` with the updated description.
       */
      saveDescription() {
        if (!this.localContainer.description) {
          return;
        }

        this.updateProductContainer({
          container: this.container,
          data: { description: this.localContainer.description },
          projectProductId: this.selectedProductId,
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
.btn-group {
  margin: 2px 0 0 14px;
}
</style>
