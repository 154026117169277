<template>
  <DashboardApiTable
    dashboard-type="completed"
    title="Completed projects"
  />
</template>

<script>
  import DashboardApiTable from '@/components/Dashboard/IndexApi.vue';

  /**
   * Project Table to display all the products owned by in the "completed" state.
   *
   * @vuedoc
   * @exports DashboardCompleted
   * @category Views
   */
  export default {
    name: 'DashboardCompleted',
    components: {
      DashboardApiTable,
    },
  };
</script>
