<template>
  <div class="footer">
    <AppButton
      v-if="currentProductStateIsIncomplete"
      data-test="update product state button"
      :is-disabled="!(validRateStructure && validPlanDesign)"
      :text="nextProductState"
      type="affirm"
      icon="fa-solid fa-chevron-right"
      size="medium"
      @click="$emit('advanceProductState')"
    />
    <h4 v-else>
      Completed
    </h4>
  </div>
</template>

<script>
  /**
   * Product Panel Footer
   *
   * @vuedoc
   * @exports ProductPanelFooter
   * @category Components
   */
  export default {
    name: 'ProductPanelFooter',
    inject: ['adminShortcut'],
    props: {
      productState: {
        type: Object,
        required: true,
      },
      validRateStructure: {
        type: Boolean,
        default: false,
      },
      validPlanDesign: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      /**
       * Returns true if the current product state is not complete
       *
       * @returns {boolean}
       */
      currentProductStateIsIncomplete() {
        return this.productState?.current.toLowerCase() !== 'complete';
      },
      /**
       * Returns the next product state
       *
       * @returns {string}
       */
      nextProductState() {
        // B&B bypass code: remove first if statement when removing
        if (this.adminShortcut) {
          return 'Complete product';
        }

        return this.productState?.next.toLowerCase() !== 'complete'
          ? `Send product to ${this.productState.next}`
          : 'Complete product';
      },
      /**
       * Disable validate button
       *
       * @returns {string}
       */
      disableProductStateButton() {
        return this.adminShortcut
          ? false
          : !(this.validRateStructure && this.validPlanDesign);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: fixed;
    bottom: 0;
    width: 40%;
    height: 60px;
    min-width: 500px;
    padding-right: 17px;
    box-sizing: border-box;
    background: var(--tf-base-light);
    border-top: 1px solid var(--tf-gray-light-medium);
    z-index: 1;
  }
</style>
