<template>
  <ElForm :model="localContainer">
    <ElFormItem
      key="Plan type select"
      prop="container_type_id"
      class="plan-type-container"
    >
      <!-- Dental products require the container to be in an `editing` state in order to edit plan types. -->
      <span
        v-if="usesPlanTemplates && container.state === 'reviewing'"
        v-text="container.container_type_name"
      />
      <ElSelect
        v-else
        v-model="localContainer.container_type_id"
        :disabled="['deleting', 'saving'].includes(container.state)"
        :placeholder="usesPlanTemplates ? 'Plan type' : 'Select'"
        data-test="plan type select"
        @change="onPlanChange"
      >
        <ElOption
          v-for="plan in availablePlans"
          :key="plan.id"
          :data-test="`plan type ${plan.name.toLowerCase()}`"
          :label="plan.name"
          :value="plan.id"
        />
      </ElSelect>
    </ElFormItem>
    <template v-if="['editing', 'saving'].includes(container.state)">
      <ElFormItem
        key="Plan label"
        :rules="[
          { validator: validate, trigger: 'change' },
        ]"
        class="description-input"
        prop="description"
      >
        <ElInput
          v-model="localContainer.description"
          :disabled="!localContainer.container_type_id || ['deleting', 'saving'].includes(container.state)"
          :maxlength="32"
          :placeholder="usesPlanTemplates ? 'Plan label (required)' : 'Enter plan label'"
          data-test="plan label"
          type="text"
        />
        <p class="form-disclaimer">
          <span
            v-if="container.state === 'editing'"
            :class="[
              { 'has-error': localContainer.description && localContainer.description.length === 32 },
            ]"
            v-text="'Character limit: 32'"
          />
          <span
            v-if="duplicateContainerName"
            class="has-error"
            v-text="'Plan labels must be unique.'"
          />
        </p>
      </ElFormItem>
      <ContainerDescriptionActions
        :container="container"
        :disabled-save-btn="duplicateContainerName"
        :local-container="localContainer"
        data-test="container description actions"
        @cancelSave="cancelSave"
      />
    </template>
  </ElForm>
</template>

<script>
  import { mapState, mapActions, mapWritableState } from 'pinia';
  import ValidationUtil from '@/utils/validation.js';
  import { useProjectProductStore } from '@/stores/projectProduct.js';
  import { useProductContainersStore } from '@/stores/productContainers.js';
  import { useProductStructuresStore } from '@/stores/productStructures.js';
  import { useProductSelectionsStore } from '@/stores/productSelections.js';
  import ContainerDescriptionActions from './ContainerDescriptionActions.vue';

  /**
   * Plan-based Container
   *
   * @vuedoc
   * @exports PlanBasedContainer
   * @category Components
   */
  export default {
    name: 'PlanBasedContainer',
    components: { ContainerDescriptionActions },
    props: {
      container: {
        type: Object,
        required: true,
        default: () => {},
      },
    },
    data() {
      return {
        localContainer: {
          description: this.container.description,
          container_type_id: this.container.container_type_id, // This needs to be localized since changing model changes the form model.
        },
        popoverVisible: false,
      };
    },
    computed: {
      ...mapState(useProjectProductStore, [
        'usesPlanTemplates',
        'selectedProjectProduct',
      ]),
      ...mapState(useProductContainersStore, ['currentContainers']),
      ...mapWritableState(useProductContainersStore, [
        'isProductContainersLoaded',
      ]),
      ...mapState(useProductStructuresStore, ['availablePlans']),
      ...mapState(useProductSelectionsStore, ['selectedProductId']),
      /**
       * Verify that we aren't reusing any container names that are already used in this (project) product
       *
       * @returns {boolean}
       */
      duplicateContainerName() {
        const container = {
          ...this.container,
          description: this.localContainer.description,
        };

        return this.localContainer.description
          && ValidationUtil.checkDuplicateContainerNames(container, this.currentContainers);
      },
    },
    methods: {
      ...mapActions(useProductContainersStore, [
        'addProductContainer',
        'deleteProductContainer',
        'updateProductContainer',
        'setContainerState',
        'syncProductDetails',
      ]),
      /**
       * resets local containers and store if save is cancelled
       */
      cancelSave() {
        // Set the localContainer.description back to the original description
        if (this.localContainer.description !== this.container.description) {
          this.localContainer.description = this.container.description;
        }
        this.setContainerState({
          container: this.container,
          containerState: 'reviewing',
        });
      },
      /**
       * API call to change the description or plan label. newId declares that this in the store, but not the DB so we need to call `addProductContainer` instead of `updateProductContainer`.
       *
       * @param {number} value
       */
      async onPlanChange(value) {
        if (this.container.newId) {
          this.isProductContainersLoaded = false;

          try {
            const container = await this.addProductContainer({
              addToStore: false,
              containerTypeId: value,
              position: this.container.position,
              productId: this.selectedProductId,
            });

            this.syncProductDetails({
              newId: container.id,
            });
          } catch {
            this.displayToast({
              message: 'There was an error adding the container.',
            });
          }

          return;
        }

        if (this.usesPlanTemplates) {
          const originalPosition = this.container.position;

          try {
            const addedContainer = await this.addProductContainer({
              containerTypeId: value,
              productId: this.selectedProductId,
            });

            await this.deleteProductContainer({
              container: this.container,
              projectProductId: this.selectedProductId,
            });
            this.isProductContainersLoaded = false;

            await this.updateProductContainer({
              container: addedContainer,
              data: { position: originalPosition },
              projectProductId: this.selectedProductId,
            });
            this.syncProductDetails();
          } catch {
            this.displayToast({
              message: 'There was an error updating the container.',
            });
          }

          return;
        }

        this.updateProductContainer({
          container: this.container,
          data: { project_products_container_type_id: value },
          newId: this.container.newId,
          selectedProductId: this.selectedProductId,
        });
      },
      /**
       * Validate the container description.
       *
       * @param {object} rule
       * @param {string} value
       * @param {Function} callback
       */
      validate(rule, value, callback) {
        if (!value) {
          callback(new Error('Plan label is required.'));
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .el-form {
    .has-error &,
    .is-editing & {
      align-items: flex-start;
    }
  }

  .plan-type-container {
    width: 115px;
    margin-right: 10px;
    text-align: left;
  }

  .description-input {
    width: 218px;
  }

  /* stylelint-disable declaration-no-important  */
  :deep(.el-popover.attribute-more-popover) {
    top: 40px !important;

    .has-error & {
      top: 45px !important;
    }
  }

  :deep(.el-input__inner) {
    height: 30px;
    line-height: 30px;
  }
  /* stylelint-enable declaration-no-important  */
</style>
