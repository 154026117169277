<template>
  <ConfirmTextModal
    title="Reset project"
    :subtitle="employerName"
    confirm-text="RESET"
    data-test="reset project modal"
    @save="onSave"
    @modalClosed="$emit('modalClosed')"
  >
    <p>By resetting this project, all proposals will be deleted and the project will revert to pre-invitation status.</p>
  </ConfirmTextModal>
</template>

<script>
  import ProjectService from '@/services/project.js';
  import ConfirmTextModal from './ConfirmTextModal.vue';

  /**
   * @vuedoc
   * @exports ModalResetProject
   * @category Components
   */
  export default {
    name: 'ModalResetProject',
    components: {
      ConfirmTextModal,
    },
    props: {
      employerName: {
        type: String,
        required: true,
        default: '',
      },
      projectId: {
        type: Number,
        required: true,
        default: 0,
      },
      projectState: {
        type: String,
        required: true,
        default: '',
      },
    },
    methods: {
      /**
       * On reset we throw up the loading screen
       * We then hit the service call to actually reset this project
       */
      async onSave() {
        try {
          await ProjectService.resetProject(this.projectId);
          const remove = ['rfp_completed', 'completed', 'project_closed'].includes(this.projectState);
          const message = `${this.employerName} (${this.projectId}) has been reset ${remove ? ' and has been moved to the live projects dashboard table' : ''}.`;

          this.displayToast({
            message,
            type: 'success',
          });

          this.$emit('projectReset', {
            id: this.projectId,
            remove,
          });
        } catch ({ data }) {
          this.displayToast({
            message: data?.message ?? `There was an error resetting ${this.employerName} (${this.projectId}).`,
          });

          this.$emit('error', this.projectId);
        }
      },
    },
  };
</script>
