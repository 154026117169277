<template>
  <EditDomains
    :email-domains="currentBroker.email_domains"
    @save="saveDomains"
    @delete="deleteDomain"
  />
</template>

<script>
  /**
   * "Manage domain names associated with a broker" view
   *
   * @vuedoc
   * @exports BrokerDomains
   * @category Views
   */
  import BrokerService from '@/services/broker.js';
  import { mapWritableState, mapState, mapActions } from 'pinia';
  import { useBrokersStore } from '@/stores/brokers.js';
  import EditDomains from '@/components/Management/EditDomains.vue';

  export default {
    name: 'BrokerDomains',
    components: { EditDomains },
    props: {
      brokerId: {
        type: [Number, String],
        default: null,
      },
    },
    data() {
      return {
        addingNewDomain: false,
        newDomain: null,
      };
    },
    computed: {
      ...mapState(useBrokersStore, ['currentBroker']),
      ...mapWritableState(useBrokersStore, ['currentBrokerId']),
    },
    created() {
      this.currentBrokerId = this.brokerId;
    },
    methods: {
      ...mapActions(useBrokersStore, ['updateBroker']),
      /**
       * Add a domain to the broker email_domains array
       *
       * @param {Array} domains
       */
      saveDomains(domains) {
        const updatedBroker = {
          ...this.currentBroker,
          email_domains: domains,
        };

        this.saveBroker(updatedBroker);
      },
      /**
       * Remove an already saved email domain
       *
       * @param {number} index
       */
      deleteDomain(index) {
        const emailDomains = [...this.currentBroker.email_domains];

        emailDomains.splice(index, 1);

        const updatedBroker = {
          ...this.currentBroker,
          email_domains: emailDomains,
        };

        this.saveBroker(updatedBroker);
      },
      /**
       * Save the updated broker record and update the store
       *
       * @param {object} value
       */
      saveBroker(value) {
        BrokerService
          .saveBrokerInfo(this.brokerId, value)
          .then(() => {
            this.updateBroker(value);
          })
          .catch(() => {
            this.displayToast({
              message: 'There was an error saving the broker.',
            });
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
.add-domain-button {
  margin-bottom: 20px;
}
</style>
