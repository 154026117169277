<template>
  <div class="attribute-container">
    <h4
      :class="[
        { 'is-hidden-attribute': isHidden },
        'attribute-name',
      ]"
    >
      <AppButton
        key="toggle-attribute"
        type="secondary"
        tabindex="-1"
        :class="[{ 'is-hidden-attribute': isHidden }, 'fa-solid hide-button']"
        :icon="!isHidden ? 'fa-eye' : 'fa-eye-slash'"
        :is-loading="togglingAttribute"
        :text="isHidden ? 'Enable' : 'Disable'"
        data-test="toggle eye"
        size="icon"
        @click="toggleValues({ containers: currentContainers })"
      />
      {{ attribute.name }}
    </h4>
    <PlanDesignPlan
      v-for="(plan, planIndex) in attribute.plans"
      :key="plan.description"
      :attribute="attribute"
      :attribute-index="attributeIndex"
      :category-index="categoryIndex"
      :is-hidden="isHidden"
      :plan="plan"
      :plan-index="planIndex"
    />
  </div>
</template>

<script>
  import { mapState, mapActions, mapWritableState } from 'pinia';
  import { useAnnotationsStore } from '@/stores/annotations.js';
  import { useProductAttributesStore } from '@/stores/productAttributes.js';
  import { useProductContainersStore } from '@/stores/productContainers.js';
  import { useProductStructuresStore } from '@/stores/productStructures.js';
  import { useProductSelectionsStore } from '@/stores/productSelections.js';

  // components
  import PlanDesignPlan from '@/components/PlanDesign/FromTemplate/Plan.vue';
  // services
  import ProductService from '@/services/product.js';

  /**
   * Plan Design Attribute From Template
   *
   * @vuedoc
   * @exports PlanDesignAttribute
   * @category Components
   */
  export default {
    name: 'PlanDesignAttribute',
    components: {
      PlanDesignPlan,
    },
    inject: ['adminShortcut'],
    props: {
      attribute: {
        type: Object,
        default: () => ({}),
        required: true,
      },
      attributeIndex: {
        type: Number,
        default: 0,
        required: true,
      },
      categoryIndex: {
        type: Number,
        default: 0,
        required: true,
      },
    },
    data() {
      return {
        togglingAttribute: false,
      };
    },
    computed: {
      ...mapWritableState(useProductAttributesStore, [
        'planDesignErrorCount',
      ]),
      ...mapState(useProductStructuresStore, ['availablePlanDesignTierGroups']),
      ...mapState(useAnnotationsStore, ['annotationsByPlanDesignValueIds']),
      ...mapState(useProductContainersStore, ['currentContainers']),
      ...mapState(useProductSelectionsStore, ['selectedProductId']),
      /**
       * Returns a boolean testing if all of the plans for this attribute have values.
       *
       * @returns {boolean}
       */
      isHidden() {
        return this.attribute.plans.some((plan) => !plan.values.length);
      },
    },
    mounted() {
      // This tests for any plans that have been added after an attribute has been hidden.
      // We get a bit of performance gain by testing first for isHidden first,
      // Then testing for values that are returned from a template.
      if (this.isHidden && this.attribute.plans.filter((plan) => plan.values.length).length) {
        // We only want to pass the containers w/o values in `toggleValues`
        this.toggleValues({
          containers: this.attribute.plans.filter((plan) => !plan.values.length),
        });
      }
    },
    created() {
      // B&B bypass: will use toggleValues to delete plan design values
      if (this.adminShortcut && !this.isHidden) {
        this.toggleValues({ containers: this.currentContainers });
      }
    },
    methods: {
      ...mapActions(useProductStructuresStore, [
        'setPlanCopyEnabledState',
        'deletePlanDesignValueFromStructure',
      ]),
      ...mapActions(useAnnotationsStore, ['deleteAnnotation']),
      /**
       * When a user clicks the eye ball icon we need to make an API called based on if the attribute is hidden.
       * toggleValues also gets the mouseEvent as a property from the click event on AppButton so it needs be an array for any containers passed to it.
       *
       * @param {object} options
       * @param {Array} options.containers
       */
      async toggleValues({ containers }) {
        this.planDesignErrorCount = 0;
        const getSubtypes = (tierGroupId) => (tierGroupId
          ? this.availablePlanDesignTierGroups.find(
            (tierGroup) => tierGroup.tier_group_id === tierGroupId,
          ).tier_subtypes
          : []);
        const deleteInStore = () => {
          this.attribute.plans.forEach((plan, planIndex) => {
            const subtypes = getSubtypes(plan.associatedTierGroupId);

            plan.values.forEach((value, valueIndex) => {
              this.deletePlanDesignValueFromStructure({
                allValues: true,
                attributeIndex: this.attributeIndex,
                categoryIndex: this.categoryIndex,
                planIndex,
              });
              // we remove any associated annotations from the store
              const valueAnnotations = this.annotationsByPlanDesignValueIds(value.ids);

              valueAnnotations.forEach(({ parts }) => {
                parts.forEach(({ id }) => this.deleteAnnotation(id));
              });
              // Change the copyEnabled IF:
              // 1) Hiding a plan type that originally has no tier group but has an associated Tier Group with multiple subtypes. Ex: Hiding a PPO where the templated value has no subtype
              // 2) Hiding a plan that has multiple values/subtypes and valueIndex is the last of these.  Ex: Hiding a PPO where the templated value has subtypes and this is the last in the loop.
              const noneToSubtypes = !value.tier_group_id && subtypes.length > 1;
              const lastValue = valueIndex === plan.values.length - 1;

              if (noneToSubtypes || lastValue) {
                this.setPlanCopyEnabledState({
                  attributeIndex: this.attributeIndex,
                  categoryIndex: this.categoryIndex,
                  copyEnabled: true,
                  planIndex,
                });
              }
              this.togglingAttribute = false;
            });
          });
        };

        this.togglingAttribute = true;

        // IF isHidden: Iterate through all containers and updatePlanValue for each of the subtypes.
        if (this.isHidden) {
          containers.forEach(async (container, containerIdx) => {
            const subtypes = getSubtypes(container.associatedTierGroupId);
            const containerIds = [container.id];
            const isLastContainer = containerIdx === containers.length - 1;
            const planIndex = this.attribute.plans.findIndex((plan) => plan.id === container.id);

            if (!subtypes.length) {
              try {
                await this.updatePlanValue({
                  containerIds,
                  planIndex,
                  updatePlanAttribute: true,
                });
                if (isLastContainer) {
                  this.togglingAttribute = false;
                }
              } catch {
                this.displayToast({
                  message: 'There was an error updating the plan value.',
                });
              }
            } else {
              subtypes.forEach(async (subtype, subtypeIdx) => {
                const isLast = isLastContainer && subtypeIdx === subtypes.length - 1;

                try {
                  await this.updatePlanValue({
                    containerIds,
                    planIndex,
                    subtypeId: subtype.subtype_id,
                    tierGroupId: container.associatedTierGroupId,
                    updatePlanAttribute: true,
                  });

                  if (isLast) {
                    this.togglingAttribute = false;
                  }
                } catch {
                  this.displayToast({
                    message: 'There was an error updating the subtype plan value.',
                  });
                }
              });
            }
          });

          return;
        }

        // ELSE: Run deletePlanDesignValue for all of the containers.
        try {
          await ProductService.deletePlanDesignValue({
            attributeId: this.attribute.id,
            containerIds: this.currentContainers.map((container) => container.id),
            productId: this.selectedProductId,
          });
          deleteInStore();
        } catch {
          this.displayToast({
            message: 'There was an error deleting the plan design value.',
          });
        }
      },
      /**
       * API call to PATCH the Plan Design Value. `attributeId` and `productId` are part of this component so no need to pass these.
       *
       * @param {object} options
       * @param {Array} options.containerIds
       * @param {number} options.planIndex
       * @param {number} options.subtypeId
       * @param {number} options.tierGroupId
       * @param {boolean} options.updatePlanAttribute
       * @param {string} options.value
       * @returns {Promise}
       */
      updatePlanValue({
        containerIds,
        planIndex,
        subtypeId = null,
        tierGroupId = null,
        updatePlanAttribute = false,
        value = '',
      }) {
        return ProductService.updatePlanValue({
          attributeId: this.attribute.id,
          attributeIndex: this.attributeIndex,
          categoryIndex: this.categoryIndex,
          createPlansFromActiveStoreProduct: true,
          containerIds,
          planIndex,
          productId: this.selectedProductId,
          subtypeId,
          tierGroupId,
          updatePlanAttribute,
          value,
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .attribute-container {
    padding: 0 25px 10px;
    border-bottom: 1px solid var(--tf-gray-medium);
  }

  .category-container {
    padding: 0 25px;
  }

  h4 {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 8px 0;
    font: {
      size: 14px;
      weight: 600;
    }
    color: var(--tf-gray-dark);

    &.is-hidden-attribute {
      color: var(--tf-gray-medium);
    }
  }

  :deep(.app-button.has-icon) {
    padding: 3px 10px 10px 0;

    i {
      font-style: normal;
    }

    &.is-hidden-attribute i {
      color: var(--tf-gray-medium);
    }
  }
</style>
