/**
 * Utility file for unrolling annotations
 *
 * @exports Annotations
 * @category Utils
 */
export default {
  /**
   * "unroll" an annotation series
   *
   * @param {Array} annotations
   * @param {string} sourceId
   * @returns {Array}
   */
  unrollAnnotations(annotations, sourceId) {
    const unrolledAnnotations = [];

    annotations.forEach((annotation) => {
      // clone, and add source_id as this is missing from the response
      const baseAnnotation = {
        ...annotation,
      };

      baseAnnotation.source_id = `${sourceId}`;

      const planDesignValues = [...baseAnnotation.plan_design_values];

      delete baseAnnotation.ids;

      annotation.ids.forEach((id, index) => {
        unrolledAnnotations.push({
          ...baseAnnotation,
          plan_design_values: [{ ...planDesignValues[index] }],
          id,
        });
      });
    });

    return unrolledAnnotations;
  },
};
