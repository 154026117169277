<template>
  <div
    class="annotation-modal"
    :style="dynamicStyles"
  >
    <header ref="handle">
      <AppButton
        id="btn-close-annotation-modal"
        type="secondary"
        icon="fa-solid fa-times"
        size="icon"
        data-test="close btn"
        @click="closeAnnotationModal"
      />
    </header>
    <CreateAnnotation v-if="annotationModalMode === 'create'" />
    <ViewAnnotation v-else />
  </div>
</template>

<script>
  import { mapState, mapActions } from 'pinia';
  // components
  import CreateAnnotation from '@/components/AnnotationModal/Create/index.vue';
  import ViewAnnotation from '@/components/AnnotationModal/View/index.vue';
  import { usePdfViewerStore } from '@/stores/pdfViewer.js';
  import { usePdfAnnotationStore } from '@/stores/pdfAnnotation.js';

  /**
   * Draggable Modal to annotation pdf documents
   *
   * @vuedoc
   * @exports AnnotationModal
   * @category Components
   */
  export default {
    name: 'AnnotationModal',
    components: {
      CreateAnnotation,
      ViewAnnotation,
    },
    data() {
      return {
        // mouse variables
        left: 50,
        top: 50,
        deltaX: 0,
        deltaY: 0,
        isMouseDown: false,
      };
    },
    computed: {
      ...mapState(usePdfViewerStore, ['mouseX', 'mouseY']),
      ...mapState(usePdfAnnotationStore, ['annotationModalMode']),
      /**
       * Returns a style string based on the positioning of the modal.
       *
       * @returns {string}
       */
      dynamicStyles() {
        return `left: ${this.left}px; top: ${this.top}px`;
      },
    },
    created() {
      document.body.addEventListener('mousedown', this.handleMouseDown);
      document.body.addEventListener('mouseup', this.handleMouseUp);
      document.body.addEventListener('mousemove', this.handleMouseMove);
      const productPanelDomElement = document.querySelector('.product-panel');
      const productPanelWidth = productPanelDomElement?.offsetWidth || 500;
      const availableDocumentWidth = window.innerWidth - productPanelWidth;
      const modalWidth = 500;
      const modalHeight = 572;

      if (this.annotationModalMode === 'create') {
        if (this.mouseX + modalWidth > availableDocumentWidth) {
          this.left = this.mouseX - modalWidth;
        } else {
          this.left = this.mouseX;
        }

        if ((this.mouseY + modalHeight) > window.innerHeight) {
          this.top = window.innerHeight - modalHeight - 100;
        } else {
          this.top = this.mouseY - 100;
        }

        this.deltaX = 0;
        this.deltaY = 100;
      } else {
        if (300 + modalWidth > availableDocumentWidth) {
          this.left = 150;
        } else {
          this.left = 300;
        }

        this.top = 200;
        this.deltaX = 0;
        this.deltaY = 100;
      }
    },
    /**
     * Destroy registered listeners
     */
    beforeDestroy() {
      document.body.removeEventListener('mousedown', this.handleMouseDown);
      document.body.removeEventListener('mouseup', this.handleMouseUp);
      document.body.removeEventListener('mousemove', this.handleMouseMove);
    },
    methods: {
      ...mapActions(usePdfAnnotationStore, ['closeAnnotationModal']),
      /**
       * Handle mousedown event - Start dragging (if the header was
       * the click target)
       *
       * @param {event} $event - Mouse event
       */
      handleMouseDown($event) {
        if ($event.target.tagName.toLowerCase() === 'header') {
          this.isMouseDown = true;
          this.deltaX = $event.clientX - this.$el.offsetLeft;
          this.deltaY = $event.clientY - this.$el.offsetTop;
        }
      },
      /**
       * Handle mousemove event - If 'drag in' mode is active
       * or mouse is down, update modal position
       *
       * @param {event} $event - MouseDown event
       */
      handleMouseMove($event) {
        if (this.isMouseDown) {
          this.left = $event.clientX - this.deltaX;
          this.top = $event.clientY - this.deltaY;
        }
      },
      /**
       * Handle mouseup event
       */
      handleMouseUp() {
        this.isMouseDown = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: move;
  background: var(--tf-gray-light);
  padding: 6px 10px;
}

.annotation-modal {
  position: absolute;
  left: 50px;
  top: 50px;
  width: 500px;
  background: var(--tf-base-light);
  z-index: 11;
  box-shadow: 0 1px 7px 4px rgba(0, 0, 0, .25);
  user-select: auto;

  :deep(footer) {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #eee;
    height: 54px;
    padding: 0 20px;

    .btn-group {
      margin-left: auto;
    }
  }
}

main {
  max-height: 70vh;
  padding-top: 15px;
  overflow: auto;

  .el-form-item {
    margin-bottom: 15px;

    &.has-multiple-annotations {
      margin-bottom: 3px;
    }
  }
}

#btn-close-annotation-modal {
  margin-left: auto;
}

#alert-container-missing-containers {
  padding: 0 20px 20px;
}

:deep {
  .el-form-item {
    padding: 0 20px;
  }

  .el-select {
    width: 100%;

    &.select-project-product {
      margin: 0;
    }
  }

  #form-tier-group {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

    > div {
      flex: 1;

      &:first-child {
        margin-right: 20px;
      }
    }
  }
}
</style>
