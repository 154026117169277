<template>
  <div class="rate-entry-container">
    <RateEntryGroup
      v-for="rate in rateAttributes"
      ref="rate-entry-group"
      :key="`rate-${rate.id}`"
      :rate-attribute="rate"
      @resetValidation="resetValidation"
      @updateRateAttributeValues="updateRateAttributeValues"
    />
  </div>
</template>

<script>
  import { mapActions, mapState, mapWritableState } from 'pinia';
  import RateEntryGroup from '@/components/ProductPanel/RateEntryGroup.vue';
  // Services
  import { useProjectProductStore } from '@/stores/projectProduct.js';
  import { useProductSelectionsStore } from '@/stores/productSelections.js';
  import { useProductAttributesStore } from '@/stores/productAttributes.js';

  /**
   * Product Panel RateEntry
   *
   * @vuedoc
   * @exports ProductPanelRateEntry
   * @category Components
   */
  export default {
    name: 'ProductPanelRateEntry',
    components: {
      RateEntryGroup,
    },
    computed: {
      ...mapWritableState(useProductAttributesStore, [
        'validatingRates',
        'rateErrorCount',
      ]),
      ...mapState(useProductSelectionsStore, ['selectedProductId']),
      ...mapState(useProductAttributesStore, ['rateAttributes']),
    },
    watch: {
      async validatingRates() {
        if (this.rateAttributes.some(({ id }) => this.validatingRates[id])) {
          await this.validateRates();
        }
      },
    },
    methods: {
      ...mapActions(useProjectProductStore, ['updateProjectProductsValidation']),
      ...mapActions(useProductAttributesStore, ['updateRateAttributeValues']),
      /**
       * Reset validation state
       */
      resetValidation() {
        this.rateErrorCount = 0;

        this.updateProjectProductsValidation({
          productId: this.selectedProductId,
          validRateStructure: false,
        });
      },
      /**
       * Validate the rates
       */
      async validateRates() {
        let errorsFound = 0;

        for await (const rateEntryGroup of this.$refs['rate-entry-group']) {
          // 1) Validate each value-row
          // 2) Add the validation errors from each container for row
          try {
            const validationErrors = await rateEntryGroup.validateRates();

            errorsFound += validationErrors;
          } catch {
            this.displayToast({
              message: 'There was an error validating the plan design values.',
            });
          }
        }

        if (errorsFound) {
          this.rateErrorCount = this.rateErrorCount !== null
            ? this.rateErrorCount + errorsFound
            : errorsFound;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .rate-entry-container {
    padding-bottom: 30px;
  }
</style>
