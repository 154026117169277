<template>
  <div
    :class="[
      { 'missing-plans': missingContainers },
      'plan-design-entry',
    ]"
  >
    <AppAlert
      v-if="missingContainers"
      show-icon
      type="warning"
      title="You must finish setting up your plan types to load a plan design."
      :closable="false"
    />
    <template v-else>
      <category
        v-for="(category, categoryIndex) in planDesignCategories"
        :key="category.name"
        :category="category"
        :category-index="categoryIndex"
        data-test="category"
      />
    </template>
  </div>
</template>

<script>
  import { mapState } from 'pinia';
  import Category from '@/components/PlanDesign/FromTemplate/Category.vue';
  import { useProductContainersStore } from '@/stores/productContainers.js';
  import { useProductStructuresStore } from '@/stores/productStructures.js';

  /**
   * Plan Design Entry From Template
   *
   * @vuedoc
   * @exports PlanDesignEntryFromTemplate
   * @category Components
   */
  export default {
    name: 'PlanDesignEntryFromTemplate',
    components: {
      Category,
    },
    computed: {
      ...mapState(useProductStructuresStore, ['planDesignCategories']),
      ...mapState(useProductContainersStore, ['missingContainers']),
    },
  };
</script>

<style lang="scss" scoped>
  .plan-design-entry {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: left;

    &.missing-plans {
      justify-content: center;
      align-items: center;
    }
  }

  .el-alert {
    width: 330px;
  }
</style>
