<template>
  <div
    v-if="currentProject && !isSettingCategoriesAndAttributes"
    class="product-panel"
    data-test="product panel"
  >
    <!-- Product Selection -->
    <ProductPanelHeader />
    <!-- Selected Product Container -->
    <div
      v-loading="!selectedProductId && activeProjectProducts.length || !isProductContainersLoaded"
      element-loading-text="Loading plan design..."
    >
      <div
        v-if="!activeProjectProducts.length"
        class="no-container-selected"
      >
        Add a product to begin staging this project.
      </div>
      <ProductContainers
        v-if="activeProjectProducts.length"
      />
    </div>
    <!-- Product Overlay container -->
    <ProductOverlay
      v-if="projectPanelActive"
      ref="product-overlay"
    />
    <!-- Product details container -->
    <template v-if="!projectPanelActive && selectedProjectProductId && isProductContainersLoaded">
      <ProductDetails
        v-bind="{
          productState,
        }"
      />
      <ProductPanelFooter
        v-bind="{
          productState,
          validRateStructure,
          validPlanDesign,
        }"
        @advanceProductState="advanceProductState"
      />
    </template>
  </div>
</template>

<script>
  import { computed } from 'vue';
  import { mapActions, mapState, mapWritableState } from 'pinia';
  // Components
  import ProductOverlay from '@/components/ProductPanel/ProductOverlay.vue';
  import ProductDetails from '@/components/ProductPanel/ProductDetails.vue';
  import ProductPanelHeader from '@/components/ProductPanel/Header.vue';
  import ProductPanelFooter from '@/components/ProductPanel/Footer.vue';
  // Services
  import ProductService, {
    getProductTypes,
  } from '@/services/product.js';
  import { validatePlanDesign, validateRates } from '@/services/validation.js';
  // Utils
  import getProductState from '@/utils/productState.js';
  import ProductContainers from '@/components/ProductContainers/index.vue';
  import { useAnnotationsStore } from '@/stores/annotations.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useProjectProductStore } from '@/stores/projectProduct.js';
  import { useProductContainersStore } from '@/stores/productContainers.js';
  import { useProductStructuresStore } from '@/stores/productStructures.js';
  import { useProductSelectionsStore } from '@/stores/productSelections.js';
  import { useProductAttributesStore } from '@/stores/productAttributes.js';
  import { adminUiRenewalShortcutSupport } from '@/utils/featureFlags.js';

  /**
   * The Product panel over the pdf viewer
   *
   * @vuedoc
   * @exports ProductPanel
   * @category Components
   */
  export default {
    name: 'ProductPanel',
    components: {
      ProductContainers,
      ProductOverlay,
      ProductPanelHeader,
      ProductDetails,
      ProductPanelFooter,
    },
    provide() {
      return {
        adminShortcut: computed(() => this.adminShortcut),
      };
    },
    data() {
      return {
        containerSelection: null,
      };
    },
    computed: {
      ...mapWritableState(useProductAttributesStore, [
        'planDesignErrorCount',
        'rateErrorCount',
      ]),
      ...mapWritableState(useProductStructuresStore, [
        'availablePlanDesignTierGroups',
        'availableRateTierGroups',
        'availablePlans',
        'planDesignCategories',
      ]),
      ...mapWritableState(useProductContainersStore, [
        'containerTypeSelection',
        'isProductContainersLoaded',
      ]),
      ...mapState(useProjectProductStore, [
        'selectedProjectProductId',
        'selectedProjectProduct',
        'validPlanDesign',
        'validRateStructure',
        'activeProjectProducts',
      ]),
      ...mapState(useProjectStore, [
        'currentProject',
        'projectPanelActive',
      ]),
      ...mapState(useProductSelectionsStore, [
        'availableProductTypes',
        'selectedProductId',
      ]),
      ...mapState(useProductAttributesStore, [
        'attributesHidden',
        'totalPlanDesignAttributes',
        'isSettingCategoriesAndAttributes',
      ]),
      /**
       * Evaluate the "adminUiRenewalShortcutSupport" feature flag.
       *
       * @deprecated rff:adminUiRenewalShortcutSupport
       * @returns {boolean}
       */
      ffAdminUiRenewalShortcutSupportEnabled() {
        return this.$ld.checkFlags(adminUiRenewalShortcutSupport);
      },
      /**
       * Checks if ff adminUiRenewalShortcutSupport in on
       * currentProductStates and currentProject.allow_null_values
       *
       * @returns {boolean}
       */
      adminShortcut() {
        const invalidProductStates = [
          'complete',
          'editing plan design',
        ];

        return this.ffAdminUiRenewalShortcutSupportEnabled
          && this.currentProject.allow_null_products
          && !invalidProductStates.includes(this.productState.current.toLowerCase());
      },
      /**
       * gets the length of all attributes hidden
       *
       * @returns {number}
       */
      attributesHiddenLength() {
        return Object.values(this.attributesHidden).filter((attribute) => attribute).length;
      },
      /**
       * check if product can be advanced to the next status
       *
       * @returns {boolean}
       */
      canAdvanceProductState() {
        return this.validRateStructure && this.validPlanDesign;
      },
      /**
       * Returns an object representing the current and next statuses for a given product
       *
       * @returns {object}
       */
      productState() {
        return this.selectedProjectProduct
          ? getProductState(this.selectedProjectProduct.inforce_product.state)
          : '';
      },
    },
    watch: {
      /**
       * Watch for changes to the currentProject and update active products accordingly
       */
      currentProject() {
        this.setupActiveProductsCheck();
      },
      availableProductTypes: {
        /**
         * Watch for changes to the availableProductTypes and update active products accordingly
         */
        handler() {
          this.setupActiveProductsCheck();
        },
        deep: true,
      },
      /**
       * Reset the errors when changing products
       */
      selectedProductId() {
        this.planDesignErrorCount = 0;
        this.rateErrorCount = 0;
      },
      /**
       * If deleting the active product, there's a timing issue where there will be no selectedProjectProduct so we don't want to return anything.
       */
      async selectedProjectProduct() {
        if (!this.selectedProjectProduct) {
          return;
        }

        try {
          await this.syncProductDetails();
        } catch {
          this.displayToast({
            message: 'There was an error syncing the product details.',
          });
        }
      },
      /**
       * update our local variable if the store value changes
       */
      containerTypeSelection() {
        this.containerSelection = this.containerTypeSelection;
      },
    },
    async created() {
      // we need to hit the server to get all product types
      try {
        const data = await getProductTypes();

        this.setAvailableProductTypes(data.product_types);
      } catch {
        this.displayToast({
          message: 'There was an error getting the product types.',
        });
      }
    },
    destroyed() {
      this.containerTypeSelection = null;
    },
    methods: {
      ...mapActions(useProjectProductStore, [
        'updateProjectProductsValidation',
        'setActiveProjectProducts',
      ]),
      ...mapActions(useProductContainersStore, [
        'setCurrentContainers',
        'syncProductDetails',
      ]),
      ...mapActions(useProductSelectionsStore, [
        'setAvailableProductTypes',
        'setActiveProjectProduct',
        'resetProductInfo',
      ]),
      ...mapActions(useAnnotationsStore, ['setAnnotations']),
      /**
       * Determines which endpoint to hit based off product's current status
       */
      async advanceProductState() {
        if (this.adminShortcut) {
          try {
            await validatePlanDesign(this.selectedProductId);
            await validateRates(this.selectedProductId);

            if (this.validPlanDesign && this.validRateStructure) {
              const product = await ProductService.updateProductState(this.selectedProjectProduct.inforce_product.id, 'move_to_completed');

              this.updateProjectProductsValidation({ projectProduct: product.project_product });
            } else {
              this.displayToast({
                message: 'There was an error validating product state.',
              });
            }
          } catch {
            this.displayToast({
              message: 'There was an error updating the product state.',
            });
          }

          return;
        }
        if (!this.canAdvanceProductState) {
          return;
        }

        ProductService
          .updateProductState(this.selectedProjectProduct.inforce_product.id, this.productState.transition)
          .then((product) => {
            this.updateProjectProductsValidation({ projectProduct: product.project_product });
          })
          .catch(() => {
            this.displayToast({
              message: 'There was an error updating the product state.',
            });
          });
      },
      /**
       * sets up active products if there are available product types and there is a current project
       */
      setupActiveProductsCheck() {
        if (this.availableProductTypes.length && this.currentProject?.project_products) {
          this.setActiveProjectProducts({ products: this.currentProject.project_products, selectedProjectProduct: this.$route.params.projectProductId });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  $footer-height: 60px;

  .product-panel {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: calc(100% - #{$footer-height});
    flex: none;
  }

  .no-container-selected {
    color: var(--tf-gray);
    margin-top: 23px;
    text-align: center;
  }

  .el-loading-mask {
    z-index: 10;
  }
</style>

<style lang="scss">
  .tf-file-tabs {
    flex-shrink: 1;
    min-width: 0;
  }

  .project-setup .panel.open {
    flex-shrink: 0;
  }
</style>
