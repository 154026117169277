import axios from 'axios';
import { getAxiosConfig } from '@watchtowerbenefits/es-utils-public';
import { config } from '@/utils/config.js';

const apiUrl = `${config.VUE_APP_API_URL}/v1/admin`;
const cookieNameSpace = config.VUE_APP_COOKIE_NAMESPACE;

/**
 * All employer related ajax calls
 *
 * @exports Employer
 * @category Services
 */

/**
 * Get list of broker team members on the employer level
 *
 * @param {number} employerId
 * @returns {object}
 */
export async function getEmployerBrokerTeamMembers(employerId) {
  const axiosConfig = getAxiosConfig(cookieNameSpace);
  const url = `${apiUrl}/employers/${employerId}/broker_team_members`;

  try {
    const { data } = await axios
      .get(url, axiosConfig);

    return data;
  } catch (error) {
    return error;
  }
}
/**
 * Add broker users to broker team
 *
 * @param {number} employerId
 * @param {Array} userIds
 * @returns {Array}
 */
export async function addEmployerBrokerTeamMembers(employerId, userIds) {
  const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);
  const url = `${apiUrl}/employers/${employerId}/broker_team_members`;
  const payload = {
    user_ids: userIds,
  };

  try {
    const { data } = axios
      .patch(url, payload, axiosConfig);

    return data.broker_team_members;
  } catch (error) {
    return error;
  }
}
/**
 * Removes a broker user from a given a given project
 *
 * @param {number} employerId
 * @param {number} userId
 * @returns {Promise}
 */
export async function removeEmployerBrokerTeamMembers(employerId, userId) {
  const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);
  const url = `${apiUrl}/employers/${employerId}/broker_team_members`;

  axiosConfig.data = {
    user_id: userId,
  };

  try {
    await axios
      .delete(url, axiosConfig);

    return Promise.resolve();
  } catch (error) {
    return Promise.reject(error);
  }
}
/**
 * Save updated employer info
 *
 * @param {number} employerId
 * @param {object} payload
 * @returns {object}
 */
export async function updateEmployerInfo(employerId, payload) {
  const axiosConfig = getAxiosConfig(cookieNameSpace);
  const url = `${apiUrl}/employers/${employerId}`;

  try {
    const { data } = await axios
      .patch(url, payload, axiosConfig);

    return data;
  } catch (error) {
    return error;
  }
}
