import axios from 'axios';
import { getAxiosConfig } from '@watchtowerbenefits/es-utils-public';
import { config } from '@/utils/config.js';

const apiUrl = `${config.VUE_APP_API_URL}/v1/admin`;

/**
 * Get the TE search jobs for a source
 *
 * @param {number} sourceId
 * @returns {Promise}
 */
export function getSourceSearchJobs(sourceId) {
  return new Promise((resolve, reject) => {
    const url = `${apiUrl}/sources/${sourceId}/search_jobs`;
    const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);

    axios
      .get(url, axiosConfig)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

/**
 * Get the TE search jobs for a source
 *
 * @param {number} sourceId
 * @returns {Promise}
 */
export function getSearchJobSetups(sourceId) {
  return new Promise((resolve, reject) => {
    const url = `${apiUrl}/tactic_engine/sources/${sourceId}/search_job_setups`;
    const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);

    axios
      .get(url, axiosConfig)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

/**
 * Create a new search job setup
 *
 * @param {number} sourceId
 * @param {number} carrierId
 * @param {number} productType
 * @param {number} containerId
 */
export function createSearchJobSetup(sourceId, carrierId, productType, containerId) {
  return new Promise((resolve, reject) => {
    const url = `${apiUrl}/tactic_engine/sources/${sourceId}/search_job_setups`;
    const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);
    const payload = {
      carrier_id: carrierId,
      product_type_id: productType,
      project_products_container_id: containerId,
    };

    axios
      .post(url, payload, axiosConfig)
      .then((response) => resolve(response.data))
      .catch((e) => reject(e.response));
  });
}

/**
 * Delete a search job setup
 *
 * @param {number} sourceId
 * @param {number} jobSetupId
 * @returns {Promise}
 */
export function deleteSearchJobSetup(sourceId, jobSetupId) {
  return new Promise((resolve, reject) => {
    const queryString = `?search_job_setup_entry_ids[]=${jobSetupId}`;
    const url = `${apiUrl}/tactic_engine/sources/${sourceId}/search_job_setups${queryString}`;
    const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);

    axios
      .delete(url, axiosConfig)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

/**
 * Delete a search job
 *
 * @param {number} jobId
 * @returns {Promise}
 */
export function deleteSearchJob(jobId) {
  return new Promise((resolve, reject) => {
    const url = `${apiUrl}/smart_parser/search_jobs/${jobId}`;
    const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);

    axios
      .delete(url, axiosConfig)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

/**
 * Create a new search job setup
 *
 * @param {number} sourceId
 * @param {string} documentType
 */
export function saveSearchJobDocumentType(sourceId, documentType) {
  return new Promise((resolve, reject) => {
    const url = `${apiUrl}/tactic_engine/sources/${sourceId}/search_job_setups`;
    const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);
    const payload = {
      document_type: documentType,
    };

    axios
      .patch(url, payload, axiosConfig)
      .then((response) => resolve(response.data))
      .catch((e) => reject(e.response));
  });
}

/**
 * Run the Tactic Engine on a source
 *
 * @param {number} sourceId
 * @returns {Promise}
 */
export function runSearchJob(sourceId) {
  return new Promise((resolve, reject) => {
    const url = `${apiUrl}/tactic_engine/sources/${sourceId}/search_job_setups/run_search_jobs`;
    const axiosConfig = getAxiosConfig(config.VUE_APP_COOKIE_NAMESPACE);

    axios
      .post(url, null, axiosConfig)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}
