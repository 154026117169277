<template>
  <table
    class="table table-parent"
  >
    <thead v-if="!projectProduct.alternative">
      <tr>
        <th v-text="projectProduct.product_type_name" />
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>
          <label
            v-if="projectProduct.hasAlternatives"
            class="product-label"
            v-text="projectProduct.label"
          />
          <div class="flex">
            <div>
              <ElCheckbox
                v-model="compensationEligible"
                name="compensation-eligible"
                label="Compensation eligible"
                @change="emitUpdate"
              />

              <ElCheckbox
                v-model="compensationReceived"
                name="compensation-received"
                label="Compensation received"
                @change="emitUpdate"
              />
            </div>
            <div>
              <ElInput
                v-model="premium"
                name="premium"
                placeholder="Enter premium"
                @blur="onPremiumBlur"
                @change="emitUpdate"
                @focus="onPremiumFocus"
                @keydown.native="isNumberKey"
              >
                <template #prepend>
                  $
                </template>
              </ElInput>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  import { formatNumber } from '@watchtowerbenefits/es-utils-public';
  import ValidationUtil from '@/utils/validation.js';

  /**
   * Premiums and Compensation - Project Product
   *
   * @vuedoc
   * @exports ProjectProduct
   * @category Components
   */
  export default {
    name: 'ProjectProduct',
    props: {
      projectProduct: {
        type: Object,
        default: null,
      },
    },
    data: (vm) => ({
      premium: vm.formatPremium(vm.projectProduct.estimated_annual_premium),
      isNumberKey: ValidationUtil.isNumberKey,
      compensationEligible: vm.projectProduct.compensation_eligible,
      compensationReceived: vm.projectProduct.compensation_received,
    }),
    methods: {
      /**
       * On every change we need to emit up to the parent
       * We also add the 'dirty' property so parent only saves project products that have changes made
       */
      emitUpdate() {
        this.$emit('update', {
          id: this.projectProduct.id,
          estimated_annual_premium: this.removePremiumFormatting(this.premium),
          compensation_eligible: this.compensationEligible,
          compensation_received: this.compensationReceived,
          dirty: true,
        });
      },
      /**
       * On focus we strip out comma formatting so the user can edit without them getting in the way
       *
       * @param {Event} event
       */
      onPremiumFocus(event) {
        this.premium = this.removePremiumFormatting(event.target.value);
      },
      /**
       * On blur we format the value with commas so the user can see they entered the correct value
       *
       * @param {Event} event
       */
      onPremiumBlur(event) {
        // use a stripped version of the value to check
        const premium = this.removePremiumFormatting(event.target.value);

        if (Number.isInteger(Number(premium))) {
          this.premium = this.formatPremium(premium);
        }
      },
      /**
       * Add commas to a value
       *
       * @param {number} premium
       * @returns {string}
       */
      formatPremium(premium) {
        return premium !== null ? formatNumber({ amount: premium, decimalCountParam: 0 }) : null;
      },
      /**
       * Strip commas from a premium
       *
       * @param {number} premium
       * @returns {string}
       */
      removePremiumFormatting(premium) {
        return String(premium).replace(/,/g, '');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .table-parent {
    margin-bottom: 20px;

    th {
      font-weight: 600;
    }

    &:not(:last-of-type) > tbody > tr {
      border-bottom: 0;
    }
  }

  .flex {
    display: flex;

    > * {
      flex: 1;

      &:first-child {
        padding-right: 10px;
      }
    }
  }

  .product-label {
    display: flex;
    margin-bottom: 17px;
  }

  .el-input {
    max-width: 196px;
  }

  .el-checkbox {
    display: flex;

    + .el-checkbox {
      margin-left: 0;
      margin-top: 17px;
    }
  }

  :deep(.el-checkbox__input) {
    padding-top: 1px;
  }
</style>
