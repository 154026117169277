<template>
  <div class="el-form-item">
    <div
      v-for="label in containerLabels"
      :key="label"
    >
      <span v-text="isClassBased ? 'Class' : 'Plan'" /> {{ label }}: (
      <span
        v-for="(annotation, index) in annotationsByContainerLabel[label]"
        :key="annotation.key"
      >
        <span
          :class="[
            'annotation-link',
            { clickable: annotation.key !== activeAnnotationKey },
          ]"
          @click="clickAnnotation(annotation)"
        >
          {{ index + 1 }}
        </span>
        <template v-if="index + 1 !== annotationsByContainerLabel[label].length">, </template>
      </span>
      )
    </div>
  </div>
</template>

<script>
  export default {
    name: 'AnnotationLinks',
    props: {
      activeAnnotationKey: {
        type: String,
        required: true,
      },
      annotationsByContainerLabel: {
        type: Object,
        required: true,
      },
      isClassBased: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      /**
       * All the container label combinations that have annotations
       *
       * @returns {Array}
       */
      containerLabels() {
        return Object.keys(this.annotationsByContainerLabel);
      },
    },
    methods: {
      /**
       * Emit annotation change to annotation view component
       *
       * @param {object} annotation
       */
      clickAnnotation(annotation) {
        if (annotation.key === this.activeAnnotationKey) {
          return;
        }
        this.$emit('clickAnnotation', annotation);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .annotation-link {
    &.clickable {
      color: var(--tf-blue);
      cursor: pointer;
    }
  }
</style>
