import { defineStore } from 'pinia';
import { usePdfViewerStore } from '@/stores/pdfViewer.js';

export const usePdfSearchStore = defineStore('pdfSearch', {
  state: () => ({
    searchError: '',
    searchResults: [],
    searchResultIndex: 0,
    searchWordIds: [],
    searchTerm: '',
  }),
  getters: {
    /**
     * Get the wordIds of the current search result
     *
     * @param {object} state
     * @returns {Array}
     */
    getSearchResultWordIds: (state) => (
      state.searchResults[state.searchResultIndex]?.length
        ? state.searchResults[state.searchResultIndex].map((word) => word.id)
        : []
    ),
  },
  actions: {
    /**
     * Run a search
     *
     * @param {string} term
     */
    initializeSearch(term = null) {
      const pdfViewerStore = usePdfViewerStore();

      this.searchTerm = term;
      this.searchResultIndex = 0;

      if (term) {
        const searchWords = term.split(' ');
        let searchWordIds = [];
        const results = [];
        // find all instances of the first search word
        //  collect the indices of the matching words
        const matchingFirstWords = pdfViewerStore.words.reduce((wordAccumulator, word, index) => {
          if (searchWords.length > 1) {
            if (
              word
                .text
                .toLowerCase()
                .endsWith(searchWords[0].toLowerCase())
            ) {
              wordAccumulator.push(index);
            }
          } else if (
            word
              .text
              .toLowerCase()
              .indexOf(searchWords[0].toLowerCase()) > -1) {
            wordAccumulator.push(index);
          }

          return wordAccumulator;
        }, []);

        // for each of the matched "first words" from the search string:
        matchingFirstWords.forEach((firstWordIndex) => {
          // can I also match the rest of the search phrase
          const nextWordSet = pdfViewerStore.words
            .slice(firstWordIndex + 1, (firstWordIndex + searchWords.length))
            .map((w) => w.text.toLowerCase());

          // nextWordSet is now an array of n-number of strings, where n
          // is the remaining number of search words.  eg. ["of", "coverages"]
          // match the next set of words to the remaining search string
          // as exactly as possible.
          if (
            nextWordSet
              .join(' ')
              .indexOf(searchWords.slice(1).join(' ')) > -1) {
            const newResult = pdfViewerStore.words.slice(
              firstWordIndex,
              firstWordIndex + searchWords.length,
            );
            const newIds = newResult.map((word) => word.id);

            results.push(newResult);
            searchWordIds = searchWordIds.concat(newIds);
          }
        });

        this.searchWordIds = searchWordIds;
        this.searchResults = results;

        if (results.length === 0) {
          this.searchError = true;
        } else {
          this.searchError = false;
        }
      } else {
        // clear/reset
        this.searchWordIds = [];
        this.searchResults = [];
        this.searchError = false;
      }
    },
    /**
     * Set the active search result index
     *
     * @param {number} index
     */
    setSearchResultIndex(index) {
      let newIndex = index;
      const lastIndex = this.searchResults.length - 1;

      if (newIndex < 0) {
        newIndex = lastIndex;
      } else if (newIndex > lastIndex) {
        newIndex = 0;
      }

      this.searchResultIndex = newIndex;
    },
  },
});
