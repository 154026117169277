import axios from 'axios';
import { getAxiosConfig } from '@watchtowerbenefits/es-utils-public';
import { config } from '@/utils/config.js';

const apiUrl = config.VUE_APP_API_URL;
const cookieNameSpace = config.VUE_APP_COOKIE_NAMESPACE;

/**
 * Notification API calls
 *
 * @exports Notifications
 * @category Services
 */
export default {
  /**
   * Gets all unread qa and project setup notifications
   *
   * @returns {Promise}
   */
  getProjectNotifications() {
    const axiosConfig = getAxiosConfig(cookieNameSpace);
    const qaUrl = `${apiUrl}/v1/admin/notifications/unread_qa`;
    const qaPromise = axios.get(qaUrl, axiosConfig);
    const setupUrl = `${apiUrl}/v1/admin/notifications/unread_project_setup`;
    const setupPromise = axios.get(setupUrl, axiosConfig);

    return new Promise((resolve, reject) => {
      Promise.all([qaPromise, setupPromise])
        .then((values) => {
          const data = {
            qa_notifications: values[0].data.qa_notifications,
            project_setup_notifications: values[1].data.project_setup_notifications,
          };

          resolve(data);
        }).catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * clears notifications based on project type
   *
   * @param {string} projectType
   * @returns {Promise}
   */
  clearProjectNotifications(projectType) {
    const axiosConfig = getAxiosConfig(cookieNameSpace);
    const url = `${apiUrl}/v1/admin/notifications/mark_${projectType}_as_read`;

    return new Promise((resolve, reject) => {
      axios
        .patch(url, {}, axiosConfig)
        .then((data) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
