<template>
  <header>
    <h2>File manager</h2>
    <span
      :class="['packet-button-container', { 'generating-packet': generatingInforcePacket }]"
    >
      <template v-if="currentProject.inforce_packet_url">
        <AppButton
          type="primary"
          class="download-button"
          icon="fa-regular fa-arrow-down-to-line"
          size="text-small"
          text="Download inforce packet"
          data-test="data-download-inforce-packet-btn"
          @click.native="downloadExperiencePackage"
        />
      </template>
      <span
        v-else-if="inforcePacketGenerated"
        class="packet-generated-text"
        data-packet-generated-text
      >
        <AppIcon icon="fa-solid fa-clock" />
        Your packet is being generated and will be available shortly upon page refresh.
      </span>
      <span
        v-else-if="inforcePacketFailed"
        class="inforce-packet-failed"
        data-inforce-packet-failed-span
      >
        Inforce packet failed
        <AppButton
          :is-disabled="generatingInforcePacket"
          type="primary"
          class="download-button"
          icon="fa-regular fa-arrow-down-to-line"
          size="text-small"
          text="Retry"
          data-test="retry generate inforce packet"
          @click.native="generateInforcePacket"
        />
      </span>
      <AppButton
        v-else
        :is-disabled="generatingInforcePacket"
        type="primary"
        class="download-button"
        icon="fa-regular fa-arrow-down-to-line"
        size="text-small"
        text="Generate inforce packet"
        data-test="generate inforce packet"
        @click.native="generateInforcePacket"
      />
    </span>
  </header>
</template>

<script>

  import { mapState } from 'pinia';
  import ProjectService from '@/services/project.js';
  import { useProjectStore } from '@/stores/project.js';

  /**
   * Header For the File Manager - Allows Downloading/Generating Inforce Packet
   *
   * @vuedoc
   * @exports FileManagerHeader
   * @category Components
   */
  export default {
    name: 'FileManagerHeader',
    data() {
      return {
        generatingInforcePacket: false,
        inforcePacketFailed: false,
        inforcePacketGenerated: false,
      };
    },
    computed: {
      ...mapState(useProjectStore, ['currentProject']),
    },
    methods: {
      /**
       * Download experience file (does not generate file)
       */
      downloadExperiencePackage() {
        window.open(this.currentProject.inforce_packet_url, '_self');
      },
      /**
       * Call the API to generate an inforce packet
       */
      async generateInforcePacket() {
        this.generatingInforcePacket = true;
        this.inforcePacketFailed = false;

        try {
          await ProjectService.generateInforcePacket(this.currentProject.id);

          this.inforcePacketGenerated = true;
          this.generatingInforcePacket = false;
        } catch {
          this.generatingInforcePacket = false;
          this.inforcePacketFailed = true;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
header {
  display: flex;
  justify-content: space-between;
  height: 64px;
  padding: 0 20px;
  border-bottom: 1px var(--tf-gray-medium) solid;

  > * {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .packet-button-container {
    text-align: right;
    position: relative;
    max-width: 70%;
  }

  .packet-generated-text {
    color: var(--tf-gray);
    font-style: italic;
    width: 244px;
    align-items: center;
    display: flex;
    font-size: 12px;
  }

  :deep() {
    .el-loading-mask {
      width: 21px;
      left: auto;
      top: 22px;
    }

    .circular {
      width: 21px;
      height: 21px;
    }
  }
}

h2 {
  font-size: 16px;
  margin: 0;
}

.inforce-packet-failed {
  display: flex;
  align-self: center;
  color: var(--tf-danger);

  button {
    margin-left: 10px;
  }
}
</style>
