import {
  tfGet,
  tfPost,
  tfPut,
} from '@/utils/apiClient.js';
import { config } from '@/utils/config.js';
import { useAuthStore } from '@/stores/auth.js';
import { useUserInfoStore } from '@/stores/userInfo.js';

import { deleteCookie, setCookie } from '@watchtowerbenefits/es-utils-public';

const cookieNameSpace = config.VUE_APP_COOKIE_NAMESPACE;

/**
 * All account related ajax calls
 *
 * @exports Account
 * @category Services
 */

/**
 * Log the user out by: update the Pinia Store, and remove cookies/local storage items
 *
 * @param {boolean} inActive - If we show the inactive message after the user is signed out
 * @returns {Promise}
 */
export function signOut(inActive = false) {
  const authStore = useAuthStore();
  const userInfoStore = useUserInfoStore();

  authStore.setAuthStatus(false);
  userInfoStore.$reset();
  authStore.inactive = true;
  window.localStorage.setItem('auth', false);
  deleteCookie(`${cookieNameSpace}-user-id`);
  deleteCookie(`${cookieNameSpace}-auth-token`);

  if (!inActive) {
    return tfPost('sign_out', {});
  }

  return new Promise((resolve) => {
    resolve();
  });
}

/**
 * Get the user info.
 *
 * @returns {Promise}
 */
export function getUserInfo() {
  return tfGet(`${config.VUE_APP_API_URL}/v1/users/me`);
}

/**
 * Change the user's password
 *
 * @param {string} password
 * @returns {Promise}
 */
export function changePassword(password) {
  return tfPut('reset_password', { password });
}

/**
 * Setting all the appropriate info for logging in a user: localstorage/cookies and pinia states
 *
 * @param {object} data - Data to send over to api
 */
export function setSignedIn(data) {
  const authStore = useAuthStore();
  const userInfoStore = useUserInfoStore();

  authStore.setAuthStatus(true);
  userInfoStore.setUserInfo(data.user);
  window.localStorage.setItem('auth', true);
  window.localStorage.setItem('confirmed', data.user.confirmed);

  if (data.user.auth_token) {
    /* Set Cookie of Bearer Token and UserID */
    const expirationTime = new Date(data.user.auth_token_expires_at);

    setCookie(`${cookieNameSpace}-user-id`, data.user.id, expirationTime);
    setCookie(`${cookieNameSpace}-auth-token`, data.user.auth_token, expirationTime);
  }
}

/**
 * Sign the user in.
 *
 * @param {object} payload
 * @returns {object}
 */
export function signIn(payload = {}) {
  return tfPost('sign_in', payload);
}

/**
 * Sign the user in using a Google Auth code
 *
 * @param {string} code
 * @returns {object}
 */
export async function getGoogleOAuthSession(code) {
  return tfPost(`${config.VUE_APP_API_URL}/v1/omniauth/google/callback`, { code });
}
