import { defineStore } from 'pinia';
import Vue from 'vue';
import { getCarriers } from '@/services/carrier.js';
import { sortObjects } from '@watchtowerbenefits/es-utils-public';

export const useCarriersStore = defineStore('carriers', {
  state: () => ({
    carriers: [],
  }),
  getters: {
    /**
     * Filters out the "No Prior Coverage" carrier (id 49) and then filters out any carriers that are disabled/unavailable
     *
     * @returns {Array} - An array of carriers that are available
     */
    getAvailableCarriers() {
      return this.carriers
        .filter(({ id }) => id !== 49)
        .filter((carrier) => !carrier.disabled);
    },
  },
  actions: {
    /**
     * Grab all the carriers and save them to the store
     */
    async loadCarriers() {
      try {
        const { carriers } = await getCarriers();

        Vue.set(this, 'carriers', sortObjects(carriers));
      } catch {
        // We should eventually catch and handle any errors here
      }
    },
  },
});
