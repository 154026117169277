import Vue from 'vue';
import VueRouter from 'vue-router';
import { routes } from '@/routes.js';

Vue.use(VueRouter);

/**
 * To get around the NavigationDuplicated error, following the suggestions from https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
 */

const originalPush = VueRouter.prototype.push;

VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }

  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // resolve err
      return err;
    }

    // rethrow error
    return Promise.reject(err);
  });
};

const router = new VueRouter({
  mode: 'history',
  routes,
  /**
   * handles scroll position on route changes
   *
   * @param {object} to - Route object
   * @param {object} from - Route object
   * @param {object} savedPosition
   * @returns {object} saved or new position
   */
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    return { x: 0, y: 0 };
  },
});

export default router;
