<template>
  <main>
    <InviteUsersForm
      :invitees="invitees"
      @updateInvitees="invitees = $event"
    />
    <UsersInvitees
      class="invitees"
      :invitees="invitees"
      @updateInvitees="invitees = $event"
    />
  </main>
</template>

<script>
  import UsersInvitees from '@/components/Management/BrokerInviteUsers/Invitees.vue';
  import InviteUsersForm from '@/components/Management/BrokerInviteUsers/InviteUsersForm.vue';

  /**
   * View broker invite users
   *
   * @vuedoc
   * @exports BrokerInviteUsers
   * @category Views
   */

  export default {
    name: 'BrokerInviteUsers',
    components: {
      InviteUsersForm,
      UsersInvitees,
    },
    data: () => ({
      invitees: [],
    }),
    created() {
      this.updateInvitees();
    },
    methods: {
      /**
       * Update invitees
       *
       * @param {Array} update
       */
      updateInvitees(update) {
        this.invitees = update;
      },
    },
  };
</script>

<style lang="scss" scoped>
  main {
    display: flex;
    margin: 0 30px;
    // The below code is temporary/behind a feature flag and will be removed in subsequent PR
    .invitees {
      width: 65%;
    }
  }
</style>
