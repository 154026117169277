import axios from 'axios';
import { getAxiosConfig } from '@watchtowerbenefits/es-utils-public';
import { config } from '@/utils/config.js';

const apiUrl = config.VUE_APP_API_URL;
const cookieNameSpace = config.VUE_APP_COOKIE_NAMESPACE;

/**
 * Broker API calls
 *
 * @exports Broker
 * @category Services
 */

/**
 * Get all the invites for a specific broker
 *
 * @param {number} brokerId
 * @returns {Promise}
 */
export async function getBrokerUserInvites({ brokerId, page = 1, count = 1000 }) {
  const axiosConfig = getAxiosConfig(cookieNameSpace);
  const url = `${apiUrl}/v1/admin/brokers/${brokerId}/user_invites?current_page=${page}&per_page=${count}`;

  try {
    const { data } = await axios
      .get(url, axiosConfig);

    return data;
  } catch (error) {
    return error;
  }
}

/**
 * Get all the users for a specific broker
 *
 * @param {object} payload
 * @param {number} payload.brokerId
 * @param {number} payload.page
 * @param {number} payload.count
 * @param {boolean} payload.includeAncestors
 * @returns {Promise}
 */
export async function getBrokerUsers({
  brokerId,
  page = 1,
  count = 1000,
  includeAncestors = false,
}) {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${apiUrl}/v1/admin/brokers/${brokerId}/users?current_page=${page}&per_page=${count}&include_ancestors=${includeAncestors}`,
        getAxiosConfig(cookieNameSpace),
      )
      .then(({ data }) => resolve(data))
      .catch((e) => reject(e));
  });
}

/**
 * Get all the different brokers from the DB
 *
 * @returns {Promise}
 */
export function getBrokers() {
  return new Promise((resolve, reject) => {
    const axiosConfig = getAxiosConfig(cookieNameSpace);
    const url = `${apiUrl}/v1/admin/brokers`;

    axios
      .get(url, axiosConfig)
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}

export default {
  /**
   * Add a new broker
   *
   * @param {object} data - Data to create the new broker
   * @returns {Promise}
   */
  addNewBroker(data) {
    return new Promise((resolve, reject) => {
      const axiosConfig = getAxiosConfig(cookieNameSpace);
      const url = `${apiUrl}/v1/admin/brokers`;

      axios
        .post(url, data, axiosConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * Get the info for a specific broker
   *
   * @param {number} brokerId
   * @returns {Promise}
   */
  getBroker(brokerId) {
    return new Promise((resolve, reject) => {
      const axiosConfig = getAxiosConfig(cookieNameSpace);
      const url = `${apiUrl}/v1/admin/brokers/${brokerId}`;

      axios
        .get(url, axiosConfig)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * Update Broker Info
   *
   * @param {number} brokerId
   * @param {object} data - Data to update on the broker user
   * @returns {Promise}
   */
  saveBrokerInfo(brokerId, data) {
    return new Promise((resolve, reject) => {
      const axiosConfig = getAxiosConfig(cookieNameSpace);
      const url = `${apiUrl}/v1/admin/brokers/${brokerId}`;

      axios
        .patch(url, data, axiosConfig)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
  /**
   * Save a new invitable carriers for a broker
   *
   * @param {object} payload
   * @param {object} payload.brokerId
   * @param {number} payload.carrierId
   * @param {boolean} payload.preferred
   * @returns {Promise}
   */
  saveNewBrokerInvitableCarrier({ brokerId, carrierId, preferred }) {
    return new Promise((resolve, reject) => {
      const axiosConfig = getAxiosConfig(cookieNameSpace);
      const url = `${apiUrl}/v1/admin/brokers/${brokerId}/carriers`;

      axios
        .post(url, { carrier_id: carrierId, preferred }, axiosConfig)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Delete an invitable carriers for a broker
   *
   * @param {number} brokerId
   * @param {number} carrierId
   * @returns {Promise}
   */
  deleteBrokerInvitableCarrier(brokerId, carrierId) {
    return new Promise((resolve, reject) => {
      const axiosConfig = getAxiosConfig(cookieNameSpace);
      const url = `${apiUrl}/v1/admin/brokers/${brokerId}/carriers/${carrierId}`;

      axios
        .delete(url, axiosConfig)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  /**
   * Update the preferred status of an existing carrier relationship
   *
   * @param {number} brokerId
   * @param {object} carrier
   * @param {object} carrier.id
   * @param {object} carrier.preferred
   * @returns {Promise}
   */
  updateBrokerInvitableCarrierPreferredStatus(brokerId, { id: carrierId, preferred }) {
    return new Promise((resolve, reject) => {
      const axiosConfig = getAxiosConfig(cookieNameSpace);
      const url = `${apiUrl}/v1/admin/brokers/${brokerId}/carriers/${carrierId}`;

      axios
        .patch(url, { preferred }, axiosConfig)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },
};
