<template>
  <main>
    <ElForm label-position="top">
      <ElFormItem label="Product:">
        {{ selectedProduct.label }}
      </ElFormItem>

      <ElFormItem
        key="Attribute"
        label="Attribute:"
      >
        {{ planDesignValue.plan_design_attribute_name }}
      </ElFormItem>

      <div id="form-tier-group">
        <ElFormItem label="Tier group:">
          {{ tierGroup.name }}
        </ElFormItem>
        <ElFormItem label="Subtype:">
          {{ tierGroup.subtypeName }}
        </ElFormItem>
      </div>

      <ElFormItem label="Value:">
        <div class="multi-line">
          {{ planDesignValue.value }}
        </div>
      </ElFormItem>

      <!-- if there is only one containerLabel
           and only a single (this) annotation in the array,
           we only display the label like we used to -->
      <ElFormItem
        v-if="
          !hasMultipleContainersLabels
            && !hasMultipleAnnotationsForContainersLabel[annotationContainersLabel]
        "
        :label="isClassBased ? 'Class(es):' : 'Plan(s):'"
      >
        {{ annotationContainersLabel }}
      </ElFormItem>

      <!-- otherwise, we iterate all groups and annotations -->
      <AnnotationLinks
        v-else
        :active-annotation-key="activeAnnotation.key"
        :annotations-by-container-label="annotationsByContainersLabel"
        :is-class-based="isClassBased"
        @clickAnnotation="onClickAnnotation"
      />
    </ElForm>

    <footer>
      <div
        class="btn-group"
      >
        <AppButton
          :loading="isDeleting"
          type="decline"
          size="text-small"
          icon="fa-solid fa-trash-alt"
          text="Delete annotation"
          data-test="delete annotation"
          @click="onDelete"
        />
      </div>
    </footer>
  </main>
</template>

<script>
  import { mapWritableState, mapState, mapActions } from 'pinia';
  import { useFileViewerStore } from '@/stores/fileViewer.js';
  import { useAnnotationsStore } from '@/stores/annotations.js';
  // services
  import ProductService from '@/services/product.js';
  // components
  import AnnotationLinks from '@/components/AnnotationModal/View/AnnotationLinks.vue';
  import { usePdfAnnotationStore } from '@/stores/pdfAnnotation.js';
  import { useProjectProductStore } from '@/stores/projectProduct.js';
  import { useProductStructuresStore } from '@/stores/productStructures.js';
  import { getRanges } from '@watchtowerbenefits/es-utils-public';

  export default {
    name: 'AnnotationModalView',
    components: {
      AnnotationLinks,
    },
    computed: {
      ...mapState(useProductStructuresStore, ['availablePlanDesignTierGroups']),
      ...mapState(useProjectProductStore, ['activeProjectProducts']),
      ...mapWritableState(useFileViewerStore, ['goToAnnotation']),
      ...mapWritableState(usePdfAnnotationStore, ['activeAnnotation']),
      ...mapState(useAnnotationsStore, ['annotationsByPlanDesignAttributeId']),
      ...mapState(usePdfAnnotationStore, ['isDeleting']),
      /**
       * Shorthand for the container ids of the annotation we're viewing
       *
       * @returns {Array}
       */
      containerIds() {
        return this.getContainerIds(this.activeAnnotation);
      },
      /**
       * Shorthand for the plan design value for the annotation we're viewing
       *
       * @returns {object}
       */
      planDesignValue() {
        return this.activeAnnotation
          ? this.activeAnnotation.plan_design_values[0]
          : {};
      },
      /**
       * Get all annotations for all values for this plan design attribute,
       * organized by container groupings
       *
       * @returns {object}
       */
      annotationsByContainersLabel() {
        const annotations = this.annotationsByPlanDesignAttributeId({
          productId: this.planDesignValue.product_id,
          planDesignAttributeId: this.planDesignValue.plan_design_attribute_id,
        });
        const annotationsByContainers = {};

        annotations.forEach((annotation) => {
          const label = this.getContainersLabel(annotation);

          if (!Object.prototype.hasOwnProperty.call(annotationsByContainers, label)) {
            annotationsByContainers[label] = [];
          }

          annotationsByContainers[label].push(annotation);
        });

        return annotationsByContainers;
      },
      /**
       * Returns a computed string based on the mixture IDs/labels of
       * displayValues used in the template block.
       *
       * @returns {string}
       */
      annotationContainersLabel() {
        return this.getContainersLabel(this.activeAnnotation);
      },
      /**
       * Check if there are more container combination labels
       *
       * @returns {boolean}
       */
      hasMultipleContainersLabels() {
        return Object.keys(this.annotationsByContainersLabel).length > 1;
      },
      /**
       * Check if there are more than one annotation in a container collection
       *
       * @returns {boolean}
       */
      hasMultipleAnnotationsForContainersLabel() {
        const hasMultiple = {};

        Object
          .entries(this.annotationsByContainersLabel)
          .forEach(([label, annotations]) => {
            hasMultiple[label] = annotations.length > 1;
          });

        return hasMultiple;
      },
      /**
       * Check if the selectedProduct is class based
       *
       * @returns {boolean}
       */
      isClassBased() {
        return this.selectedProduct.containers[0].container_type.toLowerCase() === 'class';
      },
      /**
       * Get tier group  & subtype group name, if available
       *
       * @returns {string}
       */
      tierGroup() {
        const tierGroupId = this.planDesignValue.tier_group_id;
        const tierSubtypeId = this.planDesignValue.tier_subtype_id;

        if (tierGroupId) {
          const tierGroup = this.availablePlanDesignTierGroups.find(
            (availablePlanDesignTierGroup) => availablePlanDesignTierGroup.tier_group_id === tierGroupId,
          );

          if (tierGroup) {
            const subtype = tierGroup.tier_subtypes.find(
              (tierSubtype) => tierSubtype.subtype_id === tierSubtypeId,
            );

            return {
              name: tierGroup.tier_group_name,
              subtypeName: subtype.subtype_name,
            };
          }
        }

        return {
          name: 'none',
          subtypeName: 'none',
        };
      },

      /**
       * Returns the selected product from the Active Products store array
       *
       * @returns {object}
       */
      selectedProduct() {
        return this.activeProjectProducts.find(
          (product) => product.inforce_product.id === this.planDesignValue.product_id,
        );
      },
    },
    methods: {
      ...mapActions(usePdfAnnotationStore, ['closeAnnotationModal']),
      ...mapActions(useAnnotationsStore, ['deleteAnnotation']),
      /**
       * Go to the selected annotation
       *
       * @param {object} annotation
       */
      onClickAnnotation(annotation) {
        const clickedAnnotation = { ...annotation };

        this.activeAnnotation = clickedAnnotation;
        this.goToAnnotation = clickedAnnotation;
      },
      /**
       * Delete the selected annotation
       */
      async onDelete() {
        const promiseArray = this.activeAnnotation.parts.reduce((acc, { id }) => {
          acc.push(ProductService.deleteAnnotation(id));
          this.deleteAnnotation(id);

          return acc;
        }, []);

        try {
          await Promise.all(promiseArray);
          this.closeAnnotationModal();
        } catch (error) {
          this.displayToast({
            message: error,
          });
        }
      },
      /**
       * Extract the container ids for any annotation
       *
       * @param {object} annotation
       * @returns {Array}
       */
      getContainerIds(annotation) {
        return annotation.parts.map(
          (part) => part.plan_design_values[0].project_products_container_id,
        );
      },
      /**
       * Returns a mapped array of the names (or IDs for class based products)
       * used to create this.displayValue.
       *
       * @param {object} annotation
       * @returns {Array}
       */
      getDisplayValues(annotation) {
        if (!annotation) {
          return [];
        }
        const containerIds = this.getContainerIds(annotation);

        return this.selectedProduct.containers.reduce((displayValues, { id, description, name }) => {
          let displayValue;

          if (containerIds.includes(id)) {
            displayValue = this.isClassBased ? Number(name) : description;
          }

          return displayValue ? displayValues.concat(displayValue) : displayValues;
        }, []);
      },
      /**
       * Returns a mapped array of the names (or IDs for class based products)
       * used to create this.displayValue.
       *
       * @param {object} annotation
       * @returns {Array}
       */
      getContainersLabel(annotation) {
        let label;
        const displayValues = this.getDisplayValues(annotation);

        if (this.selectedProduct.containers.length === displayValues.length) {
          label = 'All';
        } else if (!displayValues.some(Number.isNaN)) {
          // this is array of only numbers so lets roll them up
          label = getRanges(displayValues).join(', ');
        } else if (displayValues.length > 1) {
          label = '(Multi)';
        } else {
          label = displayValues.join(', ');
        }

        return label;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .multi-line {
    line-height: 18px;
  }
</style>
