<template>
  <TfProductSelector
    v-model="selectedIds"
    :disabled-products="disabledProducts"
    :products="activeProjectProducts"
    :show-category-titles="false"
  >
    <template #productOptionAppend="productOptionProps">
      <ValidationSlot
        :project-product-id="productOptionProps.product.id"
      />
    </template>
  </TfProductSelector>
</template>

<script>
  import { mapState } from 'pinia';
  import { useProjectProductStore } from '@/stores/projectProduct.js';
  import ValidationSlot from './ValidationSlot.vue';

  /**
   * The Project Product Product selector for all active products.
   *
   * @vuedoc
   * @exports ProjectProductSelector
   * @category Components
   */
  export default {
    name: 'ProjectProductSelector',
    components: { ValidationSlot },
    props: {
      selectedProjectProductId: {
        type: Number,
        default: null,
      },
    },
    computed: {
      ...mapState(useProjectProductStore, ['activeProjectProducts']),
      /**
       * Returns an array of alternative product IDs where the base product isn't complete.
       *
       * @returns {Array}
       */
      disabledProducts() {
        return this.activeProjectProducts
          .filter((product) => {
            const baseProduct = this.activeProjectProducts.find(
              (activeProduct) => activeProduct.product_type_id === product.product_type_id,
            );

            return product.alternative && baseProduct.inforce_product.state !== 'completed';
          })
          .map((product) => product.id);
      },
      /**
       * Get: Returns an array with the only value being the `selectedProjectProductId` prop.
       * Set: emits `selectProduct` with the 1st value that is being updated in the product selector.
       *
       * @returns {Array}
       */
      selectedIds: {
        get() {
          return [this.selectedProjectProductId];
        },
        set(productId) {
          this.$emit('selectProduct', productId[0]);
        },
      },
    },
  };
</script>
