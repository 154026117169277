/**
 * checks to see if the provided name exists within the THIRD_PARTIES environment variable.
 * finding a match dictates whether or not the third party should be enabled.
 *
 * @param {string} name - the name of a third party library to check for
 * @returns {boolean} - whether or not the third party should be enabled
 */
export const canUseThirdParty = (name) => {
  if (!name) {
    throw new Error('canUseThirdParty requires a name to check for');
  }

  const nameCaseInsensitive = new RegExp(name, 'i');
  const thirdParties = import.meta.env.VUE_APP_THIRD_PARTIES ?? '';

  return thirdParties.match(nameCaseInsensitive) !== null;
};
