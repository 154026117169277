<template>
  <div>
    <table
      class="table table-parent"
      data-test="project setup table"
    >
      <thead>
        <tr>
          <th
            v-text="product.product_type_name"
          />
          <th>
            <span
              v-show="product.value !== null"
            >
              Finalized {{ formatSimpleDate(finalizedDate, 'MM/DD/YYYY') }}
            </span>
          </th>
          <th
            :class="{ closed }"
            @click="toggleClosed"
          >
            <AppButton
              class="toggle-btn"
              data-test="btn-close-table"
              icon="fa-solid fa-angle-up"
              size="icon"
              no-border
            />
          </th>
        </tr>
      </thead>
      <tbody v-show="!closed">
        <tr>
          <td>Carrier</td>
          <td>{{ product.carrier_name }}</td>
          <td />
        </tr>
        <template v-for="info in clientSetupInfo">
          <tr
            v-if="info.value !== null"
            :key="info.carrier_name"
          >
            <td>{{ info.label }}</td>
            <td>{{ info.value }}</td>
            <td />
          </tr>
        </template>
        <tr>
          <td>Service contacts</td>
          <td>
            <div
              v-for="contact in product.service_contacts"
              :key="contact.reference_id"
              class="contact"
            >
              {{ contact.first_name }} {{ contact.last_name }}<br>
              {{ contact.email_address }}<br>
            </div>
          </td>
          <td />
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import { formatSimpleDate } from '@watchtowerbenefits/es-utils-public';

  import { useFileViewerStore } from '@/stores/fileViewer.js';
  import { useProjectStore } from '@/stores/project.js';
  import { mapState, mapActions } from 'pinia';

  /**
   * Table for Client Setup Info individual products
   *
   * @vuedoc
   * @exports SetupTable
   * @category Components
   */
  export default {
    name: 'SetupTable',
    props: {
      product: {
        type: Object,
        required: true,
      },
      closed: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      ...mapState(useProjectStore, ['currentProject']),
      /**
       * Get the finalized date
       *
       * @returns {Array}
       */
      finalizedDate() {
        if (
          !this.product.client_setup_info.length
          || this.product.client_setup_info[0].label.toLowerCase() !== 'finalized on'
        ) {
          return null;
        }

        return this.product.client_setup_info[0].value;
      },
      /**
       * Stripped down clientSetupInfo, because the first item is the finalize date
       *
       * @returns {Array}
       */
      clientSetupInfo() {
        return this.finalizedDate
          ? this.product.client_setup_info.slice(1)
          : this.product.client_setup_info;
      },
    },
    methods: {
      ...mapActions(useFileViewerStore, ['setInfoTableIdClosed']),
      formatSimpleDate,
      /**
       * Toggle closed state in store and let the prop change handle the effect in this component.
       */
      toggleClosed() {
        this.setInfoTableIdClosed({
          projectId: this.currentProject.id,
          productTypeId: this.product.product_type_id,
          value: !this.closed,
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .table-parent {
    margin-bottom: 20px;
  }

  tbody {
    tr {
      &:not(.row-parent) {
        border-top: 1px solid var(--tf-gray-light-medium);
      }
    }
  }

  th {
    font-weight: 600;

    &:last-child {
      cursor: pointer;
      width: 30px;
      transition: .3s ease-in-out;
      border-left: 1px solid var(--tf-gray-medium);

      &.closed {
        transform: rotate(180deg);
      }
    }

    span {
      color: var(--tf-gray);
      font-size: 14px;
      text-align: right;
      display: block;
    }
  }

  .toggle-btn {
    color: var(--tf-gray);
    padding: 0;
    transform: scale(1.25);
  }
</style>
