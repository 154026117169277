<template>
  <div>
    <div class="setup-jobs-header">
      <h4>Product &amp; class/plan</h4>
      <div
        v-if="!deleteAllSetupsDisabled"
        class="delete-all-btn-group btn-group align-end"
      >
        <AppButton
          data-test="delete all"
          type="decline"
          size="text-small"
          text="Delete all"
          @click="deleteAllSetups"
        />
      </div>
    </div>
    <div
      class="setup-jobs-table"
      data-test="setup jobs table"
    >
      <SetupJob
        v-for="setupJob in jobSetups"
        :key="setupJob.id"
        :setup="setupJob"
        @delete="deleteSetupJob"
      />
    </div>

    <AppAlert
      v-if="errors.length"
      class="setup-error"
      type="danger"
    >
      <ul>
        <li
          v-for="(error, index) in errors"
          :key="`error-${index}`"
        >
          {{ error }}
        </li>
      </ul>
    </AppAlert>

    <div class="new-setup-container">
      <ElSelect
        v-model="newProductId"
        size="small"
        placeholder="Select product"
        data-test="select product"
      >
        <ElOption
          v-for="product in availableProducts"
          :key="product.id"
          data-test="product option"
          :label="product.product_type_name"
          :value="product.product_type_id"
        />
      </ElSelect>
      <ElSelect
        v-model="newContainerId"
        :disabled="!newProductId"
        no-data-text="No available class/plan"
        size="small"
        placeholder="Select class/plan"
        data-test="select class plan"
      >
        <ElOption
          v-for="container in availableContainers"
          :key="container.id"
          data-test="plan option"
          :label="container.description"
          :value="container.id"
        />
      </ElSelect>
      <div class="btn-group align-end">
        <AppButton
          :is-disabled="!newProductId && !newContainerId"
          type="decline"
          size="icon"
          icon="fa-solid fa-times"
          data-test="cancel"
          @click="clearSelections"
        />
        <AppButton
          data-test="submit"
          :is-loading="isSaving"
          :is-disabled="!newContainerId || !newProductId"
          icon="fa-solid fa-check"
          size="icon"
          type="affirm"
          @click="saveSetup"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import {
    mapActions,
    mapState,
    mapWritableState,
  } from 'pinia';
  // stores
  import { useTacticEngineStore } from '@/stores/tacticEngine.js';
  import { useCarriersStore } from '@/stores/carriers.js';
  import { useFileViewerStore } from '@/stores/fileViewer.js';
  import { useProjectProductStore } from '@/stores/projectProduct.js';
  // services
  import ProductService from '@/services/product.js';
  import {
    createSearchJobSetup,
    deleteSearchJobSetup,
  } from '@/services/tacticEngine.js';
  // components
  import SetupJob from './SetupJob.vue';

  export default {
    name: 'JobSetupsTable',
    components: {
      SetupJob,
    },
    data() {
      return {
        errors: [],
        isSaving: false,
        newProductId: null,
        newContainerId: null,
        allContainers: [],
      };
    },
    computed: {
      ...mapState(useProjectProductStore, ['activeProjectProducts']),
      ...mapState(useCarriersStore, ['getAvailableCarriers']),
      ...mapState(useFileViewerStore, ['getActiveFileId']),
      ...mapState(useTacticEngineStore, [
        'jobSetups',
        'jobs',
      ]),
      ...mapWritableState(useTacticEngineStore, [
        'jobSetupDocumentType',
      ]),
      /**
       * Determines if the delete all button is disabled
       *
       * @returns {boolean}
       */
      deleteAllSetupsDisabled() {
        for (const index in this.jobSetups) {
          if (this.jobs.some(
            ({ product_id: productId, project_products_container_id: containerId }) => productId === this.jobSetups[index].product_id
              && containerId === this.jobSetups[index].project_products_container_id,
          )) {
            return true;
          }
        }

        return !(this.jobSetups.length > 1);
      },
      /**
       * Filtered list of available products
       *
       * @returns {Array}
       */
      availableProducts() {
        if (this.newContainerId) {
          return this.activeProjectProducts.filter(({ alternative }) => !alternative).filter(({ id }) => {
            const setupsMatchingContainer = this.jobSetups.filter(({ project_products_container_id: containerId }) => containerId === this.newContainerId);

            return !setupsMatchingContainer.map(({ product_id: productId }) => productId).includes(id);
          });
        }

        return this.activeProjectProducts.filter(({ alternative }) => !alternative);
      },
      /**
       * Filtered list of available containers
       *
       * @returns {Array}
       */
      availableContainers() {
        if (this.newProductId) {
          return this.allContainers.filter(({ id }) => {
            const setupsMatchingProduct = this.jobSetups.filter(({ product_type_id: productTypeId }) => productTypeId === this.newProductId);

            return !setupsMatchingProduct.map(({ project_products_container_id: containerId }) => containerId).includes(id);
          });
        }

        return this.allContainers;
      },
    },
    watch: {
      /**
       * When a product is selected we need to get the applicable containers from the API
       */
      newProductId() {
        if (this.newProductId) {
          const product = this.activeProjectProducts.find(({ product_type_id: productTypeId }) => productTypeId === this.newProductId);

          ProductService
            .getProductDetails(product.inforce_product.id)
            .then(({ project_products_containers: containers }) => {
              this.newContainerId = null;
              this.allContainers = containers;
            })
            .catch(() => {
              this.displayToast({
                message: 'There was an error retrieving the product classes.',
              });
            });
        }
      },
    },
    methods: {
      ...mapActions(useTacticEngineStore, [
        'deleteJobSetup',
        'setJobSetups',
      ]),
      /**
       * A setup can only be deleted if it has no associated jobs
       *
       * @param {object} setup
       * @returns {boolean}
       */
      canDeleteSetup(setup) {
        return !this.jobs.length || !this.jobs.find(
          ({ product_id: productId, project_products_container_id: containerId }) => productId === setup.product_id
            && containerId === setup.project_products_container_id,
        );
      },
      /**
       * Delete a single job setup
       *
       * @param {number} setupId
       */
      deleteSetupJob(setupId) {
        deleteSearchJobSetup(this.getActiveFileId, setupId)
          .then(() => {
            this.deleteJobSetup(setupId);

            /**
             * If there are no job setups, set the document type in the store to null
             * because we can't actually set it to null on the backend
             */
            if (!this.jobSetups.length) {
              this.jobSetupDocumentType = null;
            }
          })
          .catch(() => {
            this.displayToast({
              message: 'There was an error deleting the search job setup.',
            });
          });
      },
      /**
       * Delete all job setups
       */
      deleteAllSetups() {
        for (let i = 0; i < this.jobSetups.length; i += 1) {
          const setup = this.jobSetups[i];

          if (this.canDeleteSetup(setup)) {
            this.deleteSetupJob(setup.id);
          }
        }
      },
      /**
       * Save a new job setup
       */
      saveSetup() {
        this.$set(this, 'errors', []);
        this.isSaving = true;
        const selectedProduct = this.availableProducts.find(({ product_type_id: productTypeId }) => productTypeId === this.newProductId);

        createSearchJobSetup(
          this.getActiveFileId,
          selectedProduct.inforce_carrier_id,
          selectedProduct.product_type_id,
          this.newContainerId,
        )
          .then(({ search_job_setup: searchJobSetup }) => {
            this.setJobSetups(searchJobSetup.search_job_setup_entries);
            this.clearSelections();
          })
          .catch(({ data }) => {
            const carrier = this.getAvailableCarriers.find(({ id }) => id === selectedProduct.inforce_carrier_id);
            let templateNotFoundError = `Search templates not found for ${selectedProduct.product_type_name} / ${carrier.name}`;

            if (this.jobSetupDocumentType) {
              templateNotFoundError += ` / ${this.jobSetupDocumentType}`;
            }
            const errorMessage = !data || data.code === 'INVALID_PARAMS' ? templateNotFoundError : data.message;

            this.errors.push(errorMessage);
          })
          .finally(() => {
            this.isSaving = false;
          });
      },
      /**
       * Reset the product and container dropdowns
       */
      clearSelections() {
        this.newContainerId = null;
        this.newProductId = null;
        this.$set(this, 'errors', []);
      },
    },
  };
</script>

<style lang="scss" scoped>
.setup-jobs-header {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  h4 {
    font-size: 16px;
    margin-bottom: 20px;
  }
}

.delete-all-btn-group {
  margin-right: 10px;
}

.new-setup-container {
  display: flex;
  margin-top: 30px;

  .el-select {
    margin-right: 10px;
    border-color: var(--tf-gray-light-medium);
  }
}

.setup-jobs-table {
  border-top: 1px solid var(--tf-gray-medium);
}

.setup-error {
  margin-top: 20px;
}
</style>
