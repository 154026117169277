import Vue from 'vue';
import { defineStore } from 'pinia';
import { cloneDeep } from 'lodash';

/**
 * Construct an array of "rolled up" annotations on the front-end because
 * keeping them grouped on the back-end became too much of an obstacle.
 *
 * @param {Array} annotations
 * @returns {Array}
 */
export function rollUpAnnotations(annotations) {
  const rolledUpAnnotations = [];
  const matchedKeys = [];

  // we abandoned rolling up annotations because of limitations on the BE related to
  // getting more details and being able to store the annotations in a flat structure,
  // so now we rebuild a rollup locally
  annotations.forEach((baseAnnotation) => {
    // let's use a key to track the criteria on what constitutes a rolled up annotation set
    // because plan design attribute_id and word_ids need to match to be a rolled up annotation
    const getKey = (annotation) => `${annotation.plan_design_values[0].plan_design_attribute_id}_\
      ${annotation.plan_design_values[0].value}_\
      ${annotation.plan_design_values[0].tier_group_id}_\
      ${annotation.plan_design_values[0].tier_subtype_id}_\
      ${annotation.source_id}_\
      ${annotation.automated}_\
      ${annotation.word_ids.join(',')}`;
    const baseKey = getKey(baseAnnotation);

    // new annotation found
    if (!matchedKeys.includes(baseKey)) {
      // find all the annotations that share 1. plan design attribute id, 2. value and
      // 3. word_ids — these will be rolled up
      const matchingAnnotations = annotations.filter((annotation) => getKey(annotation) === baseKey);
      const rolledUpAnnotation = {
        ...matchingAnnotations[0],
        parts: [
          ...matchingAnnotations,
        ],
      };

      matchedKeys.push(baseKey);
      rolledUpAnnotations.push(rolledUpAnnotation);
    }
  });

  return rolledUpAnnotations;
}

export const useAnnotationsStore = defineStore('annotations', {
  state: () => ({
    annotations: {},
  }),
  getters: {
    /**
     * Get all annotations for a specific plan design value id
     *
     * @param {object} state
     * @returns {Array}
     */
    annotationsByPlanDesignValueIds: (state) => (planDesignValueIds) => {
      const foundAnnotations = [];

      Object.keys(state.annotations).forEach((key) => {
        const annotation = state.annotations[key];

        if (
          annotation.annotatable_type === 'PlanDesignValue'
          && planDesignValueIds.includes(annotation.annotatable_id)
        ) {
          foundAnnotations.push({ ...annotation });
        }
      });

      return rollUpAnnotations(foundAnnotations);
    },
    /**
     * Get all annotations for a specific plan design attribute id
     *
     * @param {object} state
     * @returns {Array}
     */
    annotationsByPlanDesignAttributeId: (state) => ({ productId, planDesignAttributeId, rollup = true }) => {
      const foundAnnotations = [];

      Object.keys(state.annotations).forEach((key) => {
        const annotation = state.annotations[key];

        if (
          annotation.annotatable_type === 'PlanDesignValue'
          && annotation.plan_design_values[0].plan_design_attribute_id === planDesignAttributeId
          && annotation.plan_design_values[0].product_id === productId
          // check productId too
        ) {
          foundAnnotations.push({ ...annotation });
        }
      });

      return rollup ? rollUpAnnotations(foundAnnotations) : foundAnnotations;
    },
    /**
     * Get all annotations for a specific source id
     *
     * @param {object} state
     * @returns {Array}
     */
    annotationsBySourceId: (state) => (sourceId) => {
      const foundAnnotations = [];

      // match annotations based on sourceId
      Object.entries(state.annotations).forEach(([, annotation]) => {
        if (`${annotation.source_id}` === `${sourceId}`) {
          foundAnnotations.push({ ...annotation });
        }
      });

      return rollUpAnnotations(foundAnnotations);
    },
  },
  actions: {
    /**
     * delete all annotations for a specific container
     *
     * @param {number} containerId
     */
    deleteContainerAnnotations(containerId) {
      Object.entries(this.annotations).forEach(([annotationId, annotation]) => {
        const planDesignValues = annotation.plan_design_values.filter(
          ({ project_products_container_id: ppContainerId }) => ppContainerId !== containerId,
        );

        // if there are no planDesignValues left, it means that the only thing covered by
        // this annotation is the container we just deleted.
        if (!planDesignValues.length) {
          this.deleteAnnotation(annotationId);
        } else {
          this.setAnnotationPlanDesignValues({
            annotationId,
            planDesignValues,
          });
        }
      });
    },
    /**
     * Delete all annotations for a specific source (id)
     *
     * @param {number} sourceId
     */
    deleteSourceAnnotations(sourceId) {
      const filteredAnnotations = {};

      Object.entries(this.annotations).forEach(([annotationId, annotation]) => {
        if (`${sourceId}` !== `${annotation.source_id}`) {
          filteredAnnotations[annotationId] = annotation;
        }
      });

      Vue.set(this, 'annotations', filteredAnnotations);
    },
    /**
     * Set a single annotation's plan design values
     *
     * @param {object} payload
     * @param {number} payload.annotationId
     * @param {Array} payload.planDesignValues
     */
    setAnnotationPlanDesignValues({ annotationId, planDesignValues }) {
      Vue.set(this.annotations[annotationId], 'plan_design_values', planDesignValues);
    },
    /**
     * Store or update all annotations
     * NOTE for Aaron: still looking to do a separate PR that does this without firing a large amount of Vue.sets!
     *
     * @param {Array} annotations
     */
    setAnnotations(annotations) {
      const updatedAnnotations = annotations;

      updatedAnnotations.forEach((annotation, annotationIndex) => {
        // normalize API output; source annotations format doesn't match product
        //  annotation format;  this is why the lookup was failing after
        // annotations are loaded at the source level
        if (!annotation.annotatable_id) {
          updatedAnnotations[annotationIndex].annotatable_id = annotation.plan_design_values[0].plan_design_value_id;
        }
        // not needed but this is the biggest other difference between the formats
        if (!annotation.plan_design_values[0].value) {
          updatedAnnotations[annotationIndex].plan_design_values[0].value = annotation.plan_design_values[0].plan_design_value;
          delete updatedAnnotations[annotationIndex].plan_design_values[0].plan_design_value;
        }

        // we keep comparing based on string, so might as well cast it all now
        updatedAnnotations[annotationIndex].source_id = `${annotation.source_id}`;
      });

      // In the event that an annotation has multiple placements on different pages
      // we need to split the annotation into multiple annotations so that
      // 1. the annotation marker in the product panel will be rendered as a group and
      // 2. the annotation modal will show all the annotation instances.
      //
      // For example, the tactic engine may create an annotation with word_ids like this:
      // `["p13-w7", "p13-w8", "p26-w85", "p26-w86"]`
      // We want to render this as two, separate annotations, one on page 13 and one on page 26.
      // So, we walk thru the word_ids and, if the annotation spans multiple pages,
      // we create annotations for each page.
      // DHB - 2/2022
      const splitAnnotations = [];

      updatedAnnotations.forEach((annotation) => {
        // sort the word_ids to ensure they're in correct page-order and word-order
        annotation.word_ids.sort((a, b) => {
          const [page1, word1] = a.split('-').map((bit) => bit.slice(1));
          const [page2, word2] = b.split('-').map((bit) => bit.slice(1));

          if (page1 > page2 || (page1 === page2 && word2 > word1)) {
            return 1;
          }

          return 0;
        });
        // keeps track of the previous page number
        let lastPage;
        // for tracking where to slice the word_ids
        let slicePage = 0;
        // just for adding "-1", "-2", etc to the key
        let counter = 0;

        // We don't want to try and split user-created annotations, only those generated by TE
        if (annotation.automated) {
          annotation.word_ids.forEach((id, idIdx) => {
            const [page, word] = id.split('-').map((bit) => bit.slice(1));

            // `(lastPage && page - lastPage > 1)`
            // there is more than one page difference between the last page and the current page
            // OR
            // `(lastPage && page - lastPage === 1 && word > 1)`
            // the pages ARE sequential and the current word is NOT the first word on the second page
            // this attempts to avoid splitting a single annotation that spans across a page break
            if ((lastPage && page - lastPage > 1) || (lastPage && page - lastPage === 1 && word > 1)) {
              splitAnnotations.push({
                ...annotation,
                word_ids: annotation.word_ids.slice(slicePage, idIdx),
                key: `${annotation.id}-${counter}`,
              });
              slicePage = idIdx;
              counter += 1;
            }
            lastPage = page;
          });

          // If we split the annotation we need to tack on the remaining pages
          if (slicePage > 0) {
            splitAnnotations.push({
              ...annotation,
              word_ids: annotation.word_ids.slice(slicePage),
              key: `${annotation.id}-${counter}`,
            });
          } else {
            // If we didn't split the annotation at all, just add the key
            splitAnnotations.push({
              ...annotation,
              key: annotation.id.toString(),
            });
          }
        } else {
          // User created annotation, just add a key
          splitAnnotations.push({
            ...annotation,
            key: annotation.id.toString(),
          });
        }
      });

      // Create the annotations object. Object keys are the 'key' value and values are the annotation object
      const newAnnotations = Object.assign(
        cloneDeep(this.annotations),
        ...splitAnnotations.map((obj) => ({ [obj.key]: obj })),
      );

      Vue.set(this, 'annotations', newAnnotations);
    },
    /**
     * Delete an annotation based on it's id
     *
     * @param {number} id
     */
    deleteAnnotation(id) {
      Vue.delete(this.annotations, id);
    },
  },
});
