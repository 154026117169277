import { defineStore } from 'pinia';
import { useProductContainersStore } from '@/stores/productContainers.js';
import { useProductStructuresStore } from '@/stores/productStructures.js';
import { useProductAttributesStore } from '@/stores//productAttributes.js';

export const useProductSelectionsStore = defineStore('productSelections', {
  state: () => ({
    activeAttributeId: null,
    activeCategoryId: null,
    activeProductId: null,
    activeValueIds: [],
    availableProductTypes: [],
    selectedProductId: null,
    selectedProductTypeId: null,
  }),
  actions: {
    /**
     * Resets multiple values in multiple stores.
     */
    resetProductInfo() {
      const productContainersStore = useProductContainersStore();
      const productStructuresStore = useProductStructuresStore();
      const productAttributesStore = useProductAttributesStore();

      productAttributesStore.attributesHidden = {};
      productStructuresStore.availablePlanDesignTierGroups = [];
      productStructuresStore.availablePlans = [];
      productStructuresStore.availableRateTierGroups = [];
      this.activeAttributeId = null;
      this.activeCategoryId = null;
      productStructuresStore.planDesignCategories = [];
      productAttributesStore.rateAttributes = [];
      this.selectedProductId = null;
      this.selectedProductTypeId = null;
      productContainersStore.currentContainers = [];
    },
    /**
     * Sets availablePRoductTypes
     *
     * @param {Array} availableProductTypes
     */
    setAvailableProductTypes(availableProductTypes) {
      availableProductTypes.sort((a, b) => a.display_order - b.display_order);
      this.availableProductTypes = availableProductTypes;
    },
    /**
     * Sets product ids
     *
     * @param {object} product
     */
    setActiveProjectProduct(product) {
      this.selectedProductId = product.id;
      this.selectedProductTypeId = product.project_product.product_type_id;
    },
    /**
     * Clears active values.
     */
    clearAnnotationData() {
      this.activeAttributeId = null;
      this.activeCategoryId = null;
      this.activeProductId = null;
      this.activeValueIds = [];
    },
    /**
     * Sets active values.
     *
     * @param {object} payload
     * @param {number} payload.categoryId
     * @param {number} payload.attributeId
     * @param {number} payload.valueIds
     * @param {number} payload.productId
     */
    setActiveIds({
      categoryId,
      attributeId,
      valueIds,
      productId,
    }) {
      this.activeCategoryId = categoryId;
      this.activeAttributeId = attributeId;
      this.activeValueIds = valueIds;
      this.activeProductId = productId;
    },
  },
});
