<template>
  <TfBadge
    :status="stateStatus"
    :value="state"
    is-solid
  />
</template>
<script>
  /**
   * "StateBadge" is a DRY component used throughout the application for a product or project's state.
   *
   * @vuedoc
   * @exports StateBadge
   * @category Components
   */
  export default {
    name: 'StateBadge',
    props: {
      state: {
        type: String,
        required: true,
      },
    },
    computed: {
      /**
       * Returns the state of the product or project.
       *
       * @returns {string}
       */
      stateStatus() {
        return this.state?.toLowerCase() === 'complete'
          ? 'success'
          : 'notify';
      },
    },
  };
</script>
