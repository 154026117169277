<template>
  <ElForm
    ref="domainManagementForm"
    :model="formModel"
    label-position="top"
    @submit.native.prevent
  >
    <div class="btn-group align-end add-domain-button">
      <AppButton
        data-test="add domain button"
        type="primary"
        icon="fa-solid fa-plus"
        size="medium"
        :is-disabled="addingNewDomain"
        text="Add new domain"
        @click="addingNewDomain = true"
      />
    </div>
    <table
      v-if="emailDomains"
      id="domain-table"
      class="table table-parent"
    >
      <thead>
        <tr>
          <th colspan="2">
            <span>Domain name</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="addingNewDomain">
          <td>
            <ElFormItem
              prop="newDomain"
              size="mini"
              :rules="[
                { validator: checkDomain, trigger: 'blur' },
              ]"
            >
              <ElInput
                v-model="formModel.newDomain"
                name="domain-name-input"
                placeholder="Enter domain"
                @keyup.enter.stop.native="save()"
              />
            </ElFormItem>
          </td>

          <td>
            <div class="btn-group align-end">
              <AppButton
                :is-disabled="!emailDomains.length"
                type="secondary"
                icon="fa-solid fa-times"
                size="icon"
                text="Cancel edit"
                @click="clearNewDomain"
              />
              <AppButton
                :is-disabled="isDomainInvalid()"
                data-test="domain affirm button"
                type="affirm"
                icon="fa-solid fa-check"
                size="icon"
                text="Confirm domain"
                @click="save()"
              />
            </div>
          </td>
        </tr>
        <tr
          v-for="(domain, domainIndex) in emailDomains"
          :key="domain"
          data-email-domain-row
        >
          <td v-text="domain" />
          <td>
            <div class="btn-group align-end">
              <AppButton
                type="decline"
                icon="fa-solid fa-trash-alt"
                size="text-small"
                data-test="remove domain button"
                text="Remove domain"
                @click="removeDomain(domainIndex)"
              />
            </div>
          </td>
        </tr>
        <tr v-if="!emailDomains.length">
          <td colspan="2">
            There are no domains configured.
          </td>
        </tr>
      </tbody>
    </table>
  </ElForm>
</template>

<script>
  import { validateDomain } from '@watchtowerbenefits/es-utils-public';

  /**
   * "Manage domain names associated with a broker or carrier" view
   *
   * @vuedoc
   * @exports EditDomains
   * @category Components
   */
  export default {
    name: 'EditDomains',
    props: {
      emailDomains: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      return {
        addingNewDomain: false,
        localEmailDomains: [],
        formModel: {
          newDomain: '',
        },
      };
    },
    watch: {
      /**
       * Update localEmailDomains when the emailDomains prop is refreshed
       */
      emailDomains() {
        this.$set(this, 'localEmailDomains', this.emailDomains);
      },
    },
    methods: {
      /**
       * Validator to make sure the domains are valid
       *
       * @param {object} rule
       * @param {string} value
       * @param {Function} callback
       */
      checkDomain(rule, value, callback) {
        const exists = this.emailDomains.some(
          (domain) => domain.toLowerCase() === value.toLowerCase(),
        );

        if (exists) {
          callback(new Error('This domain has already been entered.'));
        } else if (validateDomain(value)) {
          callback();
        } else {
          callback(new Error('Please enter a valid domain.'));
        }
      },
      /**
       * Check if the domain is valid
       *
       * @returns {boolean}
       */
      isDomainInvalid() {
        const exists = this.emailDomains.some(
          (domain) => domain.toLowerCase() === this.formModel.newDomain.toLowerCase(),
        );

        if (exists || !validateDomain(this.formModel.newDomain)) {
          return true;
        }

        return false;
      },
      /**
       * Reset the new domain input
       */
      clearNewDomain() {
        this.formModel.newDomain = '';
        this.addingNewDomain = false;
      },
      /**
       * Remove an already saved email domain
       *
       * @param {number} index
       */
      removeDomain(index) {
        this.$emit('delete', index);
      },
      /**
       * Save the updated domain list
       */
      save() {
        this.$emit('save', [...this.emailDomains, this.formModel.newDomain]);
        this.clearNewDomain();
      },
    },
  };
</script>

<style lang="scss" scoped>
.add-domain-button {
  margin-bottom: 20px;
}
</style>
