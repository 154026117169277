<template>
  <main>
    <ElForm label-position="top">
      <div
        v-if="wordIdsError"
        class="alert-wrapper"
      >
        <AppAlert
          :closable="false"
          :title="wordIdsError"
          type="danger"
        />
      </div>
      <!-- Product Selector -->
      <ElFormItem label="Product:">
        <ProjectProductSelector
          :selected-project-product-id="selectedProduct.id"
          @selectProduct="onSelectProduct"
        />
      </ElFormItem>
      <div
        v-if="missingContainers"
        class="alert-wrapper"
      >
        <AppAlert
          :closable="false"
          :title="`${selectedProductLabel} is missing one or more container labels and must be completed before continuing.`"
          type="danger"
        />
      </div>
      <template v-else>
        <!-- Form for products that use templated values -->
        <FormForTemplate
          v-if="usesPlanTemplates"
          :missing-containers="missingContainers"
          :selected-product="selectedProduct"
          :selected-product-id="selectedProductId"
          @addAnnotation="onAddAnnotation"
          @closeModal="handleCloseClick"
        />
        <!-- Form for products that don't use templated values -->
        <FormForNonTemplate
          v-else
          :missing-containers="missingContainers"
          :selected-product="selectedProduct"
          :selected-product-id="selectedProductId"
          @addAnnotation="onAddAnnotation"
          @closeModal="handleCloseClick"
        />
      </template>
    </ElForm>
  </main>
</template>

<script>
  import { mapActions, mapState } from 'pinia';
  // services
  import { getProductAnnotations } from '@/services/product.js';
  // components
  import FormForNonTemplate from '@/components/AnnotationModal/Create/FormForNonTemplate.vue';
  import FormForTemplate from '@/components/AnnotationModal/Create/FormForTemplate.vue';
  import ProjectProductSelector from '@/components/ProjectProductSelector/index.vue';
  import { useAnnotationsStore } from '@/stores/annotations.js';
  import { usePdfAnnotationStore } from '@/stores/pdfAnnotation.js';
  import { useProjectProductStore } from '@/stores/projectProduct.js';
  import { useProductSelectionsStore } from '@/stores/productSelections.js';
  /**
   * Create new annotation modal contents
   *
   * @vuedoc
   * @exports AnnotationModalCreate
   * @category Components
   */
  export default {
    name: 'AnnotationModalCreate',
    components: {
      FormForNonTemplate,
      FormForTemplate,
      ProjectProductSelector,
    },
    data() {
      return {
        // modeling variables
        selectedProductId: null,
      };
    },
    computed: {
      ...mapState(useProjectProductStore, [
        'activeProjectProducts',
        'activeProjectProductsTree',
        'usesPlanTemplates',
      ]),
      ...mapState(useProductSelectionsStore, [
        'activeProductId',
        'activeValueIds',
      ]),
      ...mapState(usePdfAnnotationStore, ['wordIdsError']),
      /**
       * Returns a boolean testing if all of the containers have labels.
       *
       * @returns {boolean}
       */
      missingContainers() {
        return this.selectedProduct && this.selectedProduct.containers
          ? (
            !this.selectedProduct.containers.length
            || this.selectedProduct.containers.some(
              (container) => (!container.description
                || container?.description.toLowerCase() === 'not provided'
                || container?.description.toLowerCase().endsWith('details')),
            )
          )
          : true;
      },
      /**
       * Returns the selected product from the Active Products store array
       *
       * @returns {object}
       */
      selectedProduct() {
        return this.activeProjectProducts.find((product) => product.inforce_product.id === this.selectedProductId);
      },
      /**
       * Returns the label or product type name based on if Select Plan is an alternative
       *
       * @returns {string}
       */
      selectedProductLabel() {
        return this.selectedProduct.label;
      },
    },
    watch: {
      /**
       * changes the selected product id to the active product id
       */
      activeValueIds() {
        this.selectedProductId = this.activeProductId;
      },
    },
    created() {
      const productPanelDomElement = document.querySelector('.product-panel');
      const productPanelWidth = productPanelDomElement?.offsetWidth || 500;
      const availableDocumentWidth = window.innerWidth - productPanelWidth;
      const modalWidth = 500;
      const modalHeight = 572;

      if (this.mouseX + modalWidth > availableDocumentWidth) {
        this.left = this.mouseX - modalWidth;
      } else {
        this.left = this.mouseX;
      }
      if ((this.mouseY + modalHeight) > window.innerHeight) {
        this.top = window.innerHeight - modalHeight - 100;
      } else {
        this.top = this.mouseY - 100;
      }

      this.deltaX = 0;
      this.deltaY = 100;
      this.selectedProductId = this.activeProductId;
    },
    /**
     * Clean up before the modal closes.
     */
    beforeDestroy() {
      this.clearAnnotationData();
      this.setPdfAnnotationModalActionState({
        actionKey: 'isDeleting',
        actionState: false,
      });
      this.setPdfAnnotationModalActionState({
        actionKey: 'isSaving',
        actionState: false,
      });
    },
    methods: {
      ...mapActions(useAnnotationsStore, ['setAnnotations']),
      ...mapActions(useProductSelectionsStore, ['clearAnnotationData']),
      ...mapActions(usePdfAnnotationStore, [
        'closeAnnotationModal',
        'setPdfAnnotationModalActionState',
      ]),
      /**
       * Dispatch `addAnnotationToSource` to the store based on the annotations
       * that were added by creating a new Annotation. When a new annotation is
       * made, we need to add it to the raw sources, otherwise we can't open
       * the annotation model when someone hovers over it.
       */
      async onAddAnnotation() {
        try {
          const { annotations } = await getProductAnnotations(this.selectedProductId);

          this.setAnnotations(annotations);
          this.handleCloseClick();
        } catch {
          this.displayToast({
            message: 'There was an error retrieving the list of annotations.',
          });
        }
      },
      /**
       * Close the modal via the store.
       */
      handleCloseClick() {
        this.closeAnnotationModal();
      },
      /**
       * Update the selectedProductId
       *
       * @param {number} value
       */
      onSelectProduct(value) {
        this.selectedProductId = this.activeProjectProducts.find((product) => product.id === value).inforce_product.id;
      },
    },
  };
</script>

<style lang="scss" scoped>
.alert-wrapper {
  padding: 0 20px 20px;
}
</style>
<style>
/* stylelint-disable declaration-no-important  */
.annotation-multi-select-popover {
  width: 437px !important;
  margin-left: 3px;
  margin-top: 6px !important;
}
/* stylelint-enable declaration-no-important  */
</style>
