import Vue from 'vue';
import { defineStore } from 'pinia';
import { useProductSelectionsStore } from '@/stores/productSelections.js';

export const useProjectProductStore = defineStore('projectProduct', {
  state: () => ({
    activeProjectProducts: [],
    newProjectProducts: [],
    selectedProjectProductId: null,
  }),
  getters: {
    /**
     * Returns array of active products with nested alternative products.
     *
     * @param {object} state
     * @returns {Array}
     */
    activeProjectProductsTree: (state) => {
      const options = [];

      state.activeProjectProducts
        .filter((product) => !product.alternative)
        .forEach((product) => {
          const projectProductId = product.id;

          options.push({
            activeProductIdx: state.activeProjectProducts.findIndex(
              (activeProduct) => activeProduct.inforce_product.project_product_id === projectProductId,
            ),
            alternatives: [],
            label: product.product_type_name,
            projectProductId,
            productId: product.inforce_product.id,
            productTypeId: product.product_type_id,
            policyIds: product.inforce_product.policy_ids,
            state: product.inforce_product.state, // We need the base state in here to disable alternative products
          });
        });

      state.activeProjectProducts
        .filter((product) => product.alternative)
        .forEach((product) => {
          const projectProductId = product.id;

          options
            .find((baseProduct) => baseProduct.productTypeId === product.product_type_id)
            .alternatives.push({
              activeProductIdx: state.activeProjectProducts.findIndex(
                (activeProduct) => activeProduct.inforce_product.project_product_id === projectProductId,
              ),
              label: product.label,
              projectProductId,
              productId: product.inforce_product.id,
              productTypeId: product.product_type_id,
            });
        });

      return options;
    },
    /**
     * Returns the if the product type uses template. This is currently only testing against dental.
     *
     * @param {object} state
     * @returns {boolean}
     */
    usesPlanTemplates: (state) => {
      const selectedProduct = state.activeProjectProducts.find(
        (product) => Number(state.selectedProjectProductId) === product.id,
      );

      return /dental/i.test(selectedProduct?.product_type_name) ?? false;
    },
    /**
     * Returns selected project product id.
     *
     * @param {object} state
     * @returns {number}
     */
    selectedProjectProduct: (state) => state.activeProjectProducts.find(
      (product) => Number(state.selectedProjectProductId) === product.id,
    ),
    /**
     * Returns boolean of valid plan design.
     *
     * @param {object} state
     * @returns {boolean}
     */
    validPlanDesign: (state) => (state.selectedProjectProductId && state.selectedProjectProduct
      ? state.selectedProjectProduct.inforce_product.valid_plan_design
      : null),
    /**
     * Returns boolean of valid rate structure.
     *
     * @param {object} state
     * @returns {boolean}
     */
    validRateStructure: (state) => (state.selectedProjectProductId && state.selectedProjectProduct
      ? state.selectedProjectProduct.inforce_product.valid_rate_structure
      : null),
  },
  actions: {
    /**
     * Sets activeProjectProducts.
     *
     * @param {object} payload
     * @param {Array} payload.products
     * @param {string} payload.selectedProjectProduct
     */
    setActiveProjectProducts({ products = [], selectedProjectProduct }) {
      const activeProjectProducts = [];
      const productSelectionsStore = useProductSelectionsStore();

      // 1. Filter base products and push to activeProjectProducts w/ displayOrder
      products
        .filter((product) => !product.alternative)
        .forEach((product) => {
          activeProjectProducts.push({
            displayOrder: productSelectionsStore.availableProductTypes.find(
              (productType) => productType.id === product.product_type_id,
            ).display_order,
            ...product,
          });
        });
      // 2. Sort base products by display order
      activeProjectProducts.sort((a, b) => a.displayOrder - b.displayOrder);
      // 3. Filter alternative, sort, and push to the end of the activeProjectProducts array
      products
        .filter((product) => product.alternative)
        .sort((a, b) => {
          const nameA = a.label.toLowerCase();
          const nameB = b.label.toLowerCase();
          let order = 0;

          if (nameA < nameB) {
            order = -1;
          }
          if (nameA > nameB) {
            order = 1;
          }

          return order;
        })
        .forEach((product) => {
          activeProjectProducts.push(product);
        });
      // 4. push to state
      // Clear out products if any
      this.activeProjectProducts = [];
      // Push each product to the state to make reactive
      products.forEach((product) => {
        this.activeProjectProducts.push(product);
      });
      // Set the first project product to first active product
      if (products.length && this.selectedProjectProductId === null) {
        this.selectedProjectProductId = selectedProjectProduct || products[0].id;
      }
    },
    /**
     * Adds product to activeProjectProducts.
     *
     * @param {object} payload
     * @param {Array} payload.containers
     * @param {object} payload.product
     */
    addActiveProjectProduct({ containers, product }) {
      this.activeProjectProducts.push({ containers, ...product });
    },
    /**
     * Adds product to newProjectProducts.
     *
     * @param {object} product
     */
    addNewProjectProduct(product) {
      this.newProjectProducts.push(product);
    },
    /**
     * Removes product in activeProjectProducts.
     *
     * @param {object} payload
     * @param {boolean} payload.alternativeType
     * @param {number} payload.projectProductId
     */
    removeActiveProjectProduct({ alternativeType = null, projectProductId }) {
      const baseProductIdx = this.activeProjectProductsTree.findIndex(
        (activeProduct) => (alternativeType
          ? activeProduct.productTypeId === alternativeType
          : activeProduct.projectProductId === projectProductId),
      );
      const baseProduct = this.activeProjectProductsTree[baseProductIdx];

      // IF: deleting a base product
      // ELSE: deleting an alternative
      if (!alternativeType) {
        // IF: the base product being deleted is selected || the selected is an alternative
        // select 1st active product || the second base product
        if (
          projectProductId === this.selectedProjectProductId
          || baseProduct.alternatives.map(
            (alternative) => alternative.projectProductId,
          ).includes(this.selectedProjectProductId)
        ) {
          const secondProjectProductId = this.activeProjectProducts.length > 1 && this.activeProjectProductsTree[1]
            ? this.activeProjectProductsTree[1].projectProductId
            : null;

          this.selectedProjectProductId = baseProductIdx
            ? this.activeProjectProductsTree[0].projectProductId
            : secondProjectProductId;
        }
        // delete each alternative from the store
        if (baseProduct.alternatives.length) {
          baseProduct.alternatives.forEach((alternative) => {
            Vue.delete(
              this.activeProjectProducts,
              this.activeProjectProducts.findIndex((product) => product.inforce_product.project_product_id === alternative.projectProductId),
            );
          });
        }
      } else if (projectProductId === this.selectedProjectProductId) {
        // IF: the alternative product being deleted is selected
        // set the base product to be active
        this.selectedProjectProductId = baseProduct.projectProductId;
      }
      // Delete the product from the active products array
      Vue.delete(
        this.activeProjectProducts,
        this.activeProjectProducts.findIndex((product) => product.inforce_product.project_product_id === projectProductId),
      );
    },
    /**
     * Removes container from activeProduct.
     *
     * @param {object} payload
     * @param {number} payload.projectProductId
     * @param {number} payload.containerIndex
     */
    deleteActiveProductContainer({ projectProductId, containerIndex }) {
      const productIndex = this.activeProjectProducts.findIndex((product) => product.inforce_product.id === projectProductId);

      this.activeProjectProducts[productIndex].containers.splice(containerIndex, 1);
    },
    /**
     * Updates activeProduct.
     *
     * @param {object} payload
     * @param {number} payload.activeProductIdx
     * @param {object} payload.product
     */
    updateActiveProjectProduct({ activeProductIdx, product }) {
      Vue.set(this.activeProjectProducts, [activeProductIdx], product);
    },
    /**
     * Updates activeProduct container.
     *
     * @param {object} payload
     * @param {number} payload.projectProductId
     * @param {object} payload.container
     * @param {number} payload.containerIndex
     */
    updateActiveProjectProductContainer({ projectProductId, container, containerIndex }) {
      const productIndex = this.activeProjectProducts.findIndex((product) => product.inforce_product.id === projectProductId);

      Vue.set(this.activeProjectProducts[productIndex].containers, [containerIndex], container);
    },
    /**
     * Updates projectProducts validation.
     *
     * @param {object} payload
     * @param {object} payload.projectProduct
     * @param {number} payload.productId
     * @param {boolean} payload.validPlanDesign
     * @param {boolean} payload.validRateStructure
     */
    updateProjectProductsValidation({
      projectProduct,
      productId,
      validPlanDesign = null,
      validRateStructure = null,
    }) {
      const idx = productId
        ? this.activeProjectProducts.findIndex((activeProduct) => activeProduct.inforce_product.id === productId)
        : this.activeProjectProducts.findIndex((activeProduct) => activeProduct.id === projectProduct.id);

      if (validPlanDesign !== null) {
        Vue.set(this.activeProjectProducts[idx].inforce_product, 'valid_plan_design', validPlanDesign);
      } else if (validRateStructure !== null) {
        Vue.set(this.activeProjectProducts[idx].inforce_product, 'valid_rate_structure', validRateStructure);
      } else {
        this.activeProjectProducts[idx].inforce_product = {
          ...this.activeProjectProducts[idx].inforce_product,
          ...projectProduct.inforce_product,
        };
      }
    },
    /**
     * Removes product from newProjectProducts.
     *
     * @param {number} productId
     */
    removeNewProjectProduct(productId) {
      Vue.delete(
        this.newProjectProducts,
        this.newProjectProducts.findIndex((product) => product.id === productId),
      );
    },
  },
});
