<template>
  <div class="product-overlay">
    <div
      class="overlay-background"
      @click="toggleProductOverlay"
    />
    <AppButton
      id="btn-close-product-overlay"
      type="primary"
      icon="fa-solid fa-times"
      size="medium"
      data-test="product overlay toggle"
      @click="toggleProductOverlay"
    />
    <div
      if="activeProjectProducts"
      class="overlay-body"
    >
      <header>
        <h4>Product confirmation</h4>
        <AppButton
          type="secondary"
          icon="fa-solid fa-plus"
          data-test="add product button"
          :is-disabled="isSoldCoverageAudit"
          text="Add product"
          @click="addNewBaseProduct"
        />
      </header>
      <template v-if="currentProject.inforce_document">
        <BaseProductLayout
          v-for="product in baseProducts"
          :key="product.id"
          data-test="product"
          :product="product"
          :admins="adminUsers"
        />
      </template>
    </div>
    <div class="footer">
      <div class="owner">
        <span>Project owner:</span>
        {{ currentProject.wt_owner_name || 'n/a' }}
      </div>
      <AppButton
        v-if="showFinalizeFooter"
        :is-disabled="finalizeFooterButtonDisabled"
        type="primary"
        icon="fa-solid fa-chevron-right"
        data-test="finalize button"
        :loading="finalizingProject"
        text="Finalize project"
        @click="finalizeProject"
      />
      <h4 v-else-if="showLiveFooter" data-test="h4 footer">
        Finalized
      </h4>
    </div>
  </div>
</template>

<script>
  import { mapState, mapWritableState, mapActions } from 'pinia';
  import { useAdminsStore } from '@/stores/admins.js';
  import { useProjectStore } from '@/stores/project.js';
  import { useProjectProductStore } from '@/stores/projectProduct.js';
  import MathUtil from '@/utils/math.js';
  import { getProjectInfo, setProjectState } from '@/services/project.js';
  import BaseProductLayout from '@/components/Product/BaseProductLayout.vue';

  /**
   * Product Overlay to display all products
   *
   * @vuedoc
   * @exports ProductOverlay
   * @category Components
   */
  export default {
    name: 'ProductOverlay',
    components: { BaseProductLayout },
    data() {
      return {
        finalizingProject: false,
      };
    },
    computed: {
      ...mapState(useProjectProductStore, [
        'activeProjectProducts',
        'newProjectProducts',
      ]),
      ...mapWritableState(useProjectStore, [
        'currentProject',
        'projectPanelActive',
      ]),
      ...mapState(useAdminsStore, ['adminUsers']),
      /**
       * Checks to see if all active products have a state of "completed"
       *
       * @returns {boolean}
       */
      allProductsCompleted() {
        return this.activeProjectProducts.every((product) => product.inforce_product.state === 'completed');
      },
      /**
       * Returns an array of "active" and "new" base products.
       *
       * @returns {Array}
       */
      baseProducts() {
        return [
          ...this.newProjectProducts,
          ...this.activeProjectProducts,
        ].filter((product) => !product.alternative);
      },
      /**
       * Evaluate the current project type to disable items in the template.
       *
       * @returns {boolean}
       */
      isSoldCoverageAudit() {
        return this.currentProject?.type === 'Projects::PolicyAudit';
      },
      /**
       * returns true if rfp is in a live state
       *
       * @returns {boolean}
       */
      showLiveFooter() {
        return [
          'rfp_setup_completed',
          'rfp_closed',
          'rfp_sent_to_market',
          'rfp_modified',
          'rfp_modified_info',
          'rfp_modified_invites',
          'rfp_completed',
        ].includes(this.currentProject.state);
      },
      /**
       * returns true if rfp can be finalized
       *
       * @returns {boolean}
       */
      showFinalizeFooter() {
        return [
          'rfp_started',
          'inforce_packet_requested',
          'inforce_packet_received',
          'rfp_setup_started',
        ].includes(this.currentProject.state);
      },
      /**
       * Is the footer button disabled based on if !showFinalizeFooter or any active products are not valid and if finalize button has not been clicked.
       *
       * @returns {boolean}
       */
      finalizeFooterButtonDisabled() {
        return !this.showFinalizeFooter
          || !this.allProductsCompleted
          || this.finalizingProject;
      },
    },
    methods: {
      ...mapActions(useProjectProductStore, ['addNewProjectProduct']),
      /**
       * Add an empty product shell to the newProductRows array.
       */
      addNewBaseProduct() {
        this.addNewProjectProduct({
          id: MathUtil.getRandomNumber(),
          inforce_product: { carrier_id: null, policy_ids: [''] },
          isNewProduct: true,
          label: '',
          product_type_id: null,
          prior_coverage: true,
        });
      },
      /**
       * Change this project's status to 'finalize_inforce'.
       */
      async finalizeProject() {
        const { project: serverProject } = await getProjectInfo(this.currentProject.id);
        const serverProjectProductIds = serverProject.project_products.map(({ id }) => id);
        const localProjectProductIds = this.activeProjectProducts.map(({ id }) => id);
        const difference = serverProjectProductIds.filter((id) => !localProjectProductIds.includes(id)).length;

        if (!difference) {
          this.finalizingProject = true;

          try {
            const { project } = await setProjectState(this.currentProject.id, 'finalize_inforce');

            this.currentProject = project;
          } catch {
            this.finalizingProject = false;
            this.displayToast({
              message: 'There was an error finalizing the project.',
            });
          }
        } else {
          this.displayToast({
            message: 'Your local data does not match the data on the server; most likely someone else modified the project. Please ensure all data is saved and refresh the page.',
          });
        }
      },
      /**
       * Toggle the Project Panel.
       */
      toggleProductOverlay() {
        this.projectPanelActive = !this.projectPanelActive;
      },
    },
  };
</script>

<style lang="scss" scoped>
  $close-btn-size: 45px;
  $footer-height: 60px;
  $left-bar-width: 22px;

  #btn-close-product-overlay {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    width: $close-btn-size;
    height: $close-btn-size;
  }

  .overlay-background {
    background-color: rgba(0, 0, 0, .2);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    cursor: pointer;
  }

  .overlay-body {
    position: absolute;
    top: $close-btn-size;
    bottom: 0;
    right: 20px;
    left: 0;
    background: var(--tf-base-light);
    overflow-y: scroll;
    height: calc(100% - #{$close-btn-size + $footer-height});
  }

  .product-overlay {
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid var(--tf-gray-medium);

    h4 {
      margin: 0;
    }
  }

  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    width: calc(40% - #{$left-bar-width});
    height: $footer-height;
    min-width: calc(500px - #{$left-bar-width});
    padding: 12px;
    box-sizing: border-box;
    background: var(--tf-base-light);
    border-top: 1px solid var(--tf-gray-light-medium);
    z-index: 1;

    .open & {
      right: 21px;
    }

    .owner {
      margin-bottom: 0;
      text-align: left;

      span {
        font-size: 12px;
        display: block;
      }
    }
  }
</style>
