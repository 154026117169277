<template>
  <div>
    <ManagementListing
      :table-data="brokers"
      listing-type="Broker"
      route-add-name="AddBroker"
      route-edit-name="EditBroker"
      route-edit-param="brokerId"
    />
  </div>
</template>

<script>
  import { mapState } from 'pinia';
  import { useBrokersStore } from '@/stores/brokers.js';
  import ManagementListing from '@/components/Management/Listing.vue';

  /**
   * View for the listing of Brokers.
   *
   * @vuedoc
   * @exports BrokerManagement
   * @category Views
   */
  export default {
    name: 'BrokerManagement',
    components: { ManagementListing },
    computed: {
      ...mapState(useBrokersStore, ['brokers']),
    },
  };
</script>
