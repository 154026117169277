<template>
  <div class="policy-ids">
    <p
      v-if="showPolicyIds"
      data-test="readonly policy ids"
      class="readonly-text"
    >
      Policy ID: {{ value.join(', ') }}
    </p>
    <div
      v-if="isEditing && !isNew"
      class="policy-id-inputs"
    >
      <p class="policy-id-label">
        Policy ID
      </p>
      <div
        v-for="policyId, index in localPolicyIds"
        :key="index"
        class="policy-id-row"
      >
        <ElInput
          v-model="localPolicyIds[index]"
          class="policy-id-input"
          size="medium"
          data-test="policy id input"
          :disabled="!isEditing"
          placeholder="########"
        />
        <AppButton
          data-test="delete policy id"
          type="decline"
          icon="fa-solid fa-trash-can"
          size="icon"
          :is-disabled="isOnlyPolicyId"
          @click="deletePolicyId(index)"
        />
      </div>
      <AppButton
        tag="a"
        class="add-policy-id-button"
        data-test="add policy id"
        icon="fa-solid fa-plus"
        size="text"
        text="Add another policy ID"
        @click="addPolicyId"
      />
    </div>
  </div>
</template>

<script>
  /**
   * Product policyId input and readonly text.
   *
   * @vuedoc
   * @exports ProductPolicyIds
   * @category Components
   */
  export default {
    name: 'ProductPolicyIds',
    props: {
      isEditing: {
        type: Boolean,
        required: true,
        default: false,
      },
      isNew: {
        type: Boolean,
        required: true,
        default: false,
      },
      value: {
        type: Array,
        required: true,
        default: () => [],
      },
    },
    computed: {
      isOnlyPolicyId() {
        return this.localPolicyIds?.length === 1 && this.localPolicyIds[0] === '';
      },
      localPolicyIds: {
        get() {
          return this.value.length === 0 ? [''] : this.value;
        },
        set(ids) {
          this.$emit('update:value', ids);
        },
      },
      showPolicyIds() {
        return !this.isEditing && this.value.length > 0 && this.value[0] !== '';
      },
    },
    methods: {
      deletePolicyId(index) {
        if (this.localPolicyIds[index] === '') {
          this.$delete(this.localPolicyIds, index);
        } else {
          this.$set(this.localPolicyIds, index, '');
        }
      },
      addPolicyId() {
        this.localPolicyIds.push('');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .policy-ids {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }

  .policy-id-row {
    display: flex;
    margin-top: 8px;
    margin-bottom: 8px;
  }

  .add-policy-id-button {
    margin-bottom: 16px;
  }
</style>
