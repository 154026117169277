import Vue from 'vue';
import { defineStore } from 'pinia';
import { saveSearchJobDocumentType } from '@/services/tacticEngine.js';

export const useTacticEngineStore = defineStore('tacticEngine', {
  state: () => ({
    jobs: [],
    jobSetups: [],
    jobSetupDocumentType: null,
  }),
  actions: {
    /**
     * Add a jobSetup
     *
     * @param {object} jobSetup
     */
    addJobSetup(jobSetup) {
      const newSetups = [
        ...this.jobSetups,
        jobSetup,
      ];

      Vue.set(this, 'jobSetups', newSetups);
    },
    /**
     * Delete a jobSetup
     *
     * @param {number} jobSetupId
     */
    deleteJobSetup(jobSetupId) {
      const setupIndex = this.jobSetups.map(({ id }) => id).indexOf(jobSetupId);

      this.jobSetups.splice(setupIndex, 1);
    },
    /**
     * Set all the jobSetups
     *
     * @param {Array} jobSetups
     */
    setJobSetups(jobSetups) {
      Vue.set(this, 'jobSetups', jobSetups);
    },
    /**
     * Set jobSetup document type
     *
     * @param {string} sourceId
     * @param {string} documentType
     */
    setJobSetupDocumentType(sourceId, documentType) {
      this.jobSetupDocumentType = documentType;
      saveSearchJobDocumentType(sourceId, documentType);
    },
    /**
     * Set all jobs
     *
     * @param {Array} jobs
     */
    setJobs(jobs) {
      Vue.set(this, 'jobs', jobs);
    },
    /**
     * Reset entire store
     */
    resetTacticEngineStore() {
      this.$reset();
    },
  },
});
