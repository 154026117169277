<template>
  <div class="management-content">
    <header>
      <h1>
        {{ listingType }} Management
      </h1>
      <div class="ctas">
        <slot name="cta" />
        <RouterLink :to="{ name: routeAddName }">
          <AppButton
            :text="`Add ${listingType}`"
            type="primary"
            icon="fa-solid fa-plus"
            size="medium"
            data-test="add broker"
          />
        </RouterLink>
      </div>
    </header>
    <TfTable
      :table-data="tableData"
      :table-meta="tableMeta"
    >
      <template #parentRow="{ rowData }">
        <TfTableParentRow
          :key="rowData.id"
          data-test="table row"
        >
          <TfTableTd>
            {{ rowData.name }}
          </TfTableTd>
          <TfTableTd
            class="btn-group align-end"
          >
            <RouterLink
              :to="{
                name: routeEditName,
                params: {
                  [routeEditParam]: rowData.id,
                },
              }"
            >
              <AppButton
                data-test="edit listing button"
                type="primary"
                size="medium"
                text="Edit"
              />
            </RouterLink>
          </TfTableTd>
        </TfTableParentRow>
      </template>
      <slot name="empty">
        There don't appear to be any "{{ listingType }}" users.
      </slot>
    </TfTable>
  </div>
</template>

<script>
/**
 * Management Listing Component
 *
 * @vuedoc
 * @exports ManagementListing
 * @category Components
 */
  export default {
    name: 'ManagementListing',
    props: {
      isLoaded: {
        type: Boolean,
        default: false,
      },
      isLoading: {
        type: Boolean,
        default: false,
      },
      listingType: {
        type: String,
        required: true,
        default: '',
      },
      routeAddName: {
        type: String,
        required: true,
        default: '',
      },
      routeEditName: {
        type: String,
        required: true,
        default: '',
      },
      routeEditParam: {
        type: String,
        required: true,
        default: '',
      },
      tableData: {
        type: Array,
        required: true,
        default: () => [],
      },
    },
    computed: {
      /**
       * Creates the table meta object required by the table.
       *
       * @returns {object}
       */
      tableMeta() {
        return {
          columns: [
            {
              format: 'name',
              label: `${this.listingType} Name`,
              prop: 'name',
              sortable: true,
            },
            {
              format: 'ctas',
              label: null,
              prop: 'ctas',
            },
          ],
          sort: {
            prop: 'name',
            direction: 'asc',
          },
        };
      },
    },
    methods: {
      /**
       * Redirect the user to the page based on passed parameters.
       *
       * @param {number} id
       */
      onClickEdit(id) {
        this.$router.push({
          name: this.routeName,
          params: { [this.routeEditParam]: id },
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .ctas {
    display: flex;
    justify-content: flex-end;

    a + a {
      margin-left: 14px;
    }
  }

  h1 {
    margin: 0;
  }
</style>
