<template>
  <div
    v-if="isLoaded"
    class="setup-container"
    data-test="setup job"
  >
    <div>
      <div class="product-label label">
        {{ currentProduct.label }}
      </div>
      <div class="container-label label">
        {{ currentContainer.description }}
      </div>
      <div class="carrier-label label">
        <span class="uppercase">Carrier</span>: {{ currentProduct.inforce_product.carrier.name }}
      </div>
    </div>
    <div
      v-if="canDelete"
      class="btn-group align-end"
    >
      <AppButton
        data-test="delete button"
        class="no-border"
        type="decline"
        size="icon"
        icon="fa-solid fa-trash-alt"
        @click="$emit('delete', setup.id)"
      />
    </div>
  </div>
</template>

<script>
  // vue
  import { mapState } from 'pinia';
  import { useProductContainersStore } from '@/stores/productContainers.js';
  import { useProjectProductStore } from '@/stores/projectProduct.js';
  import { useTacticEngineStore } from '@/stores/tacticEngine.js';
  import ProductService from '@/services/product.js';

  export default {
    name: 'SetupJob',
    props: {
      setup: {
        type: Object,
        default: () => {},
      },
    },
    data() {
      return {
        allContainers: [],
        isLoaded: false,
      };
    },
    computed: {
      ...mapState(useProductContainersStore, ['currentContainers']),
      ...mapState(useProjectProductStore, ['activeProjectProducts']),
      ...mapState(useTacticEngineStore, ['jobs']),
      /**
       * Whether a setup can be deleted
       * Setups can only be deleted if they have no jobs
       *
       * @returns {boolean}
       */
      canDelete() {
        return !this.jobs.length || !this.jobs.find(
          ({ product_id: productId, project_products_container_id: containerId }) => productId === this.setup.product_id
            && containerId === this.setup.project_products_container_id,
        );
      },
      /**
       * Get the current product
       *
       * @returns {object}
       */
      currentProduct() {
        return this.activeProjectProducts.find(({ product_type_id: productTypeId }) => productTypeId === this.setup.product_type_id);
      },
      /**
       * Get the current container (plan/class)
       *
       * @returns {object}
       */
      currentContainer() {
        return this.allContainers.find(({ id }) => id === this.setup.project_products_container_id);
      },
    },
    created() {
      ProductService
        .getProductDetails(this.currentProduct.inforce_product.id)
        .then(({ project_products_containers: containers }) => {
          this.isLoaded = true;
          this.allContainers = containers;
        })
        .catch(() => {
          this.isLoaded = true;
          this.displayToast({
            message: 'There was an error retrieving the product classes.',
          });
        });
    },
  };
</script>

<style lang="scss" scoped>
.setup-container {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-left: 1px solid var(--tf-gray-medium);
  border-right: 1px solid var(--tf-gray-medium);
  border-bottom: 1px solid var(--tf-gray-medium);
  font-size: 14px;

  .label {
    font-size: 14px;

    &.carrier-label {
      margin-top: 16px;
      font-weight: 600;
      font-size: 12px;
      text-transform: uppercase;
    }
  }
}

.uppercase {
  text-transform: uppercase;
}
</style>
