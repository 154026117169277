<template>
  <div class="broker-content management-content">
    <ManagementHeader
      :breadcrumb="{ name: 'BrokerManagement' }"
      breadcrumb-text="Back to brokers"
    >
      Add new broker
    </ManagementHeader>

    <TfTabs :value="'EditBrokerSettings'">
      <ElTabPane
        v-for="pane in panes"
        :key="pane.label"
        :label="pane.label"
        :name="pane.name"
        :disabled="pane.disabled"
      />
    </TfTabs>
    <RouterView />
  </div>
</template>

<script>
  // pinia
  import { mapWritableState } from 'pinia';
  import { useBrokersStore } from '@/stores/brokers.js';
  // services
  import BrokerService from '@/services/broker.js';
  // components
  import ManagementHeader from '@/components/Management/Header.vue';

  /**
   * Add brokerage view ("Add" doesn't expose all three tabs that Edit has; after creation, user is redirected to Edit view)
   *
   * @vuedoc
   * @exports AddBroker
   * @category Views
   */
  export default {
    name: 'AddBroker',
    components: {
      ManagementHeader,
    },
    props: {
      brokerId: {
        type: [Number, String],
        default: null,
      },
    },
    data() {
      return {
        panes: [
          {
            label: 'Users',
            name: 'EditBrokerUsers',
            disabled: true,
          },
          {
            label: 'Invitable carriers',
            name: 'EditBrokerInvitableCarriers',
            disabled: true,
          },
          {
            label: 'Settings',
            name: 'EditBrokerSettings',
          },
        ],
      };
    },
    computed: {
      ...mapWritableState(useBrokersStore, ['currentBrokerId']),
    },
    /**
     * On create we want to hit the api and grab the current broker (if there is a broker id)
     */
    created() {
      if (this.brokerId) {
        BrokerService
          .getBroker(this.brokerId)
          .then((data) => {
            const { broker } = data;
            const currentBroker = {
              ...data.broker,
              editableName: broker.name,
              carriers: broker.carriers.sort((a, b) => a.name.localeCompare(b.name)),
              users: broker.users.sort((a, b) => {
                // we sort our users by last name, however when testing I found some users that were created without last names
                // so these first two checks just help move them to the bottom of the last - DR 9/23/20
                if (!a.last_name) {
                  return 1;
                }
                if (!b.last_name) {
                  return -1;
                }

                return a.last_name.localeCompare(b.last_name);
              }),
            };

            this.currentBrokerId = currentBroker.id;
          })
          .catch(() => {
            this.displayToast({
              message: 'There was an error creating the broker record.',
            });
          });
      }
    },
  };
</script>

<style lang="scss">
.broker-content .el-tabs__content {
  margin-top: 40px;
}
</style>
