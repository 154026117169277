<template>
  <TfTableParentRow
    v-bind="{ rowData }"
    :key="rowData.id"
    data-test="table row"
  >
    <TfTableTd
      v-for="column in columns"
      :key="column.id"
      :data-test="`${column.prop} cell`"
    >
      <datepicker
        v-if="column.format === 'datepicker'"
        :value="formatSimpleDate(rowData[column.prop], 'MM/DD/YY')"
        :inline="false"
        format="MM/dd/yy"
        placeholder="Please enter a date"
        wrapper-class="datepicker-container"
        @selected="dateSelected(column.prop, $event)"
      />
      <ElSelect
        v-else-if="column.format === 'ownerSelect'"
        :value="rowData.wt_owner_id"
        data-test="change owner input"
        @input="changeProjectOwner"
      >
        <ElOptionGroup label="Reassign project">
          <ElOption
            v-for="option in adminUsers"
            :key="option.id"
            :value="option.id"
            :label="option.fullName"
          />
        </ElOptionGroup>
      </ElSelect>
      <div
        v-else-if="column.format === 'ctas'"
        class="btn-group align-end"
      >
        <RouterLink
          v-if="dashboardType !== 'completed'"
          :to="{
            name: 'ProjectSetup',
            params: { projectId: rowData.id },
          }"
        >
          <AppButton
            data-test="open product view"
            :text="btnText"
            type="primary"
            size="medium"
          />
        </RouterLink>
        <TfPopover
          v-model="showPopover"
          placement="bottom-end"
          popper-class="project-settings-popover"
          :offset="20"
          :append-to-body="false"
          class="project-settings"
          width="200"
        >
          <AppButton
            slot="reference"
            data-test="open project actions menu button"
            :class="{ 'is-active': showPopover }"
            icon="fa-solid fa-cog"
            size="icon"
            type="secondary"
          />
          <ul>
            <li v-if="auditable">
              <AppButton
                data-test="policy audit button"
                :is-disabled="isCreatingPolicyAudit"
                size="text"
                type="primary"
                text="Start Policy Audit"
                @click="startPolicyAudit"
              />
            </li>
            <li v-if="showResetBtn">
              <AppButton
                data-test="reset project button"
                size="text"
                type="primary"
                text="Reset project"
                @click="emitAndClosePopover('openResetModal')"
              />
            </li>
            <li v-if="rowData.can_be_completed">
              <AppButton
                data-test="complete project button"
                size="text"
                type="primary"
                text="Complete project"
                @click="emitAndClosePopover('openCompleteProjectModal')"
              />
            </li>
            <li v-if="rowData.can_be_closed">
              <AppButton
                data-test="close project button"
                size="text"
                type="primary"
                text="Close project"
                @click="emitAndClosePopover('openCloseProjectModal')"
              />
            </li>
            <li>
              <AppButton
                data-test="edit broker users button"
                size="text"
                type="primary"
                text="Edit broker users"
                @click="emitAndClosePopover('openEditBrokerUsers')"
              />
            </li>
            <li>
              <RouterLink
                :to="{
                  name: 'ManageRFP',
                  params: { projectId: rowData.id },
                }"
              >
                <AppButton
                  size="text"
                  type="primary"
                  text="Manage RFP"
                />
              </RouterLink>
            </li>
          </ul>
        </TfPopover>
        <AppButton
          v-if="rowData.brokerUrl"
          :href="rowData.brokerUrl"
          icon="fa-solid fa-external-link-alt"
          size="icon-no-fill"
          no-padding
          tag="a"
          target="_blank"
          rel="noopener noreferrer"
          type="primary"
        />
      </div>
      <template v-else-if="column.format === 'date'">
        {{ formatSimpleDate(rowData[column.prop]) }}
      </template>
      <template v-else>
        {{ rowData[column.prop] }}
      </template>
    </TfTableTd>
  </TfTableParentRow>
</template>

<script>
  import { formatSimpleDate, trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';
  import ProjectService, {
    startPolicyAudit as startPolicyAuditApi,
  } from '@/services/project.js';
  import { mapState } from 'pinia';
  import { useUserInfoStore } from '@/stores/userInfo.js';
  import { policyAuditFlag } from '@/utils/featureFlags.js';

  /**
   * Dashboard table Uses TfTable to display all our projects in various states
   * TODO: This file has been deprecated and should be removed
   *
   * @vuedoc
   * @exports DashboardTable
   * @category Components
   */
  export default {
    name: 'ProjectCellData',
    props: {
      adminUsers: {
        type: Array,
        default: () => [],
      },
      columns: {
        type: Array,
        required: true,
      },
      rowData: {
        type: Object,
        default: () => {},
        required: true,
      },
      dashboardType: {
        type: String,
        default: '',
      },
    },
    data: () => ({
      isActive: false,
      showPopover: false,
      isCreatingPolicyAudit: false,
    }),
    computed: {
      ...mapState(useUserInfoStore, ['broker', 'email']),
      /**
       * Button text
       *
       * @returns {string}
       */
      btnText() {
        let btnText = 'Edit';

        if (this.dashboardType === 'setup') {
          btnText = 'View';
        }

        if (this.dashboardType === 'qa') {
          btnText = 'Review';
        }

        return btnText;
      },
      /**
       * Move this logic
       *
       * @returns {boolean}
       */
      showResetBtn() {
        const isSameOrganization = this.broker?.name === this.rowData.broker_name;
        const isResettable = [
          'rfp_setup_completed',
          'rfp_closed',
          'rfp_sent_to_market',
          'rfp_modified',
          'rfp_modified_info',
          'rfp_modified_invites',
          'rfp_completed',
          'implementation',
          'policy_audit',
          'project_closed',
        ].includes(this.rowData.state);

        return isSameOrganization && isResettable;
      },
      /**
       * Evaluate the the can_be_audited prop and policyAuditFlag.
       *
       * @returns {boolean}
       */
      auditable() {
        return this.rowData.can_be_audited && this.policyAuditFlag;
      },
      /**
       * Evaluate the "policyAuditFlag" feature flag.
       *
       * @returns {boolean}
       */
      policyAuditFlag() {
        return this.$ld.checkFlags(policyAuditFlag);
      },
    },
    methods: {
      formatSimpleDate,
      /**
       * Emit the event to open the modal, but close the popover at the same time.
       *
       * @param {string} event
       */
      emitAndClosePopover(event) {
        this.closePopover();
        this.$emit(event);
      },
      /**
       * Close the popover.
       *
       */
      closePopover() {
        this.showPopover = false;
      },
      /**
       * Start policy audit- hit API, close popover, and display message.
       *
       */
      async startPolicyAudit() {
        let message = 'Policy audit project has successfully been created.';
        let type = 'success';

        this.isCreatingPolicyAudit = true;

        try {
          await startPolicyAuditApi(this.rowData.id);

          trackSegmentEvent(
            'clicked start policy audit',
            {
              user_email: this.email,
              project_id: this.rowData.id,
            },
          );
          this.$emit('projectCanBeAuditedChanged', false);
        } catch (error) {
          message = error.response.data.message ? error.response.data.message : 'Failed to create policy audit';
          type = 'error';
        } finally {
          this.isCreatingPolicyAudit = false;
          this.closePopover();
          this.$message({
            showClose: true,
            duration: 3000,
            message,
            type,
          });
        }
      },
      /**
       * Change the project owner based on userInfo Id.
       *
       * @param {number} userId
       */
      changeProjectOwner(userId) {
        ProjectService
          .setProjectData(this.rowData.id, { wt_owner_id: userId })
          .then(({ project }) => {
            this.$emit('createMessage', { label: 'Owner', value: project.wt_owner_name });
            this.$emit('projectPropChanged', { prop: 'wt_owner_id', value: project.wt_owner_id });
          }).catch(() => {
            this.$emit('createMessage', { label: 'Owner', type: 'error' });
          });
      },
      /**
       * sets the date in project when date is selected
       *
       * @param {string} prop
       * @param {Date} date
       */
      dateSelected(prop, date) {
        const { label } = this.columns.find(({ prop: columnProp }) => columnProp === prop);

        ProjectService
          .setProjectData(this.rowData.id, { [prop]: date })
          .then(({ project }) => {
            const newDate = project[prop];

            this.$emit('createMessage', { label, value: formatSimpleDate(newDate) });
            this.$emit('projectPropChanged', { prop, value: newDate });
          }).catch(() => {
            this.$emit('createMessage', { label, type: 'error' });
          });
      },
    },
  };
</script>

<style lang="scss" scoped>
  a:not(:first-child),
  .project-settings {
    margin-left: 14px;
  }

  li {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  :deep(.project-settings-popover) {
    &:focus {
      outline: none;
    }
  }

  .datepicker-container {
    margin-top: 0;
  }

  :deep(.vdp-datepicker) {
    input {
      width: 100px;
      border-color: transparent;
      transition: border-color .2s ease-out;

      &:hover {
        border-color: var(--tf-gray-medium);
        cursor: pointer;
      }

      &:focus {
        border-color: var(--tf-blue);
      }
    }
  }
</style>
