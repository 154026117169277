<template>
  <LfcModal
    :title="title"
    :subtitle="subtitle"
    :visible.sync="modalVisible"
    :show-close="false"
    @closed="cancelChanges"
    @modalClosed="$emit('modalClosed')"
  >
    <div
      v-if="loadingState"
      class="dashboard-modal-loading"
    >
      <div v-loading="true" />
      <h3>{{ savingTitle }}</h3>
      <p>This can take up to 1 minute.</p>
    </div>

    <slot name="default" />
    <p>
      <slot name="disclaimer">
        Type "{{ confirmText }}" below to continue.
      </slot>
    </p>

    <ElForm @submit.native.prevent="saveChanges">
      <ElFormItem
        for="reset-project"
        size="medium"
      >
        <ElInput
          v-model="inputValue"
          data-test="edit confirmation value"
          :maxlength="requireReason ? 50 : confirmText.length"
        />
      </ElFormItem>
    </ElForm>

    <template #footer>
      <AppButton
        size="text"
        data-test="cancel confirmation text"
        text="Cancel"
        @click="cancelChanges"
      />
      <AppButton
        :is-disabled="saveDisabled"
        :text="title"
        type="primary"
        icon="fa-solid fa-check"
        size="large"
        data-test="save confirmation text"
        @click="saveChanges"
      />
    </template>
  </LfcModal>
</template>

<script>
  /**
   *
   * @vuedoc
   * @exports ConfirmTextModal
   * @category Components
   */
  export default {
    name: 'ConfirmTextModal',
    props: {
      /**
       * The action text that is rendered in the disclaimer.
       */
      confirmText: {
        type: String,
        required: true,
      },
      /**
       * Requiring a reason updates the disclaimer text and the way the input is validated.
       */
      requireReason: {
        type: Boolean,
        default: false,
      },
      /**
       * The action text that is rendered while the modal is in a loading state.
       */
      savingTitle: {
        type: String,
        default: 'Saving',
      },
      /**
       * The subtitle that is rendered in the LfcModal component.
       */
      subtitle: {
        type: String,
        required: true,
      },
      /**
       * The title that is rendered in the LfcModal component.
       */
      title: {
        type: String,
        required: true,
      },
    },
    data: () => ({
      loadingState: null,
      loadText: '',
      inputValue: '',
      modalVisible: true,
    }),
    computed: {
      /**
       * Tests to see if the input model matches the confirmText prop.
       *
       * @returns {boolean}
       */
      saveDisabled() {
        return this.requireReason
          ? !this.inputValue.length
          : this.inputValue !== this.confirmText;
      },
    },
    methods: {
      /**
       * Close modal and confirm changes are canceled with a notification
       */
      cancelChanges() {
        this.$emit('update:visible', false);
        this.$emit('modalClosed');
        this.displayToast({
          message: 'Your changes have been canceled.',
          type: 'info',
        });
        this.modalVisible = false;
      },
      /**
       * Run all necessary services to update changes made in this modal session
       */
      saveChanges() {
        this.loadText = 'Saving changes';
        this.loadingState = true;
        this.$emit('save', this.inputValue);
      },
    },
  };
</script>

<style lang="scss" scoped>
  p:last-child {
    margin-bottom: 10px;
  }
</style>
