<template>
  <div class="premiums-compensation">
    <h3>Premiums &amp; Compensation</h3>
    <template v-if="projectProducts.length">
      <ProjectProduct
        v-for="(projectProduct, index) in productTree"
        :key="projectProduct.productTypeName"
        :project-product="projectProduct"
        @update="updateProjectProduct($event, index)"
      />
      <div class="btn-group">
        <AppButton
          data-test="save info"
          type="primary"
          size="large"
          text="Save info"
          @click="saveInfo()"
        />
      </div>
    </template>
    <div
      class="empty"
    >
      <AppIcon size="2x" icon="fa-light fa-magnifying-glass-dollar" />
      <span>No products have been staged for this project.</span>
    </div>
  </div>
</template>

<script>
  import ProjectService from '@/services/project.js';
  import ProjectProduct from './ProjectProduct.vue';

  /**
   * Premiums and Compensation - RFP Page
   *
   * @vuedoc
   * @exports PremiumsCompensation
   * @category Components
   */
  export default {
    name: 'PremiumsCompensation',
    components: {
      ProjectProduct,
    },
    props: {
      projectProducts: {
        type: Array,
        default: null,
      },
    },
    computed: {
      /**
       * create a product tree so we can add a flag if this project has other products of the same time (hasAlternatives)
       *
       * @returns {Array}
       */
      productTree() {
        return this.projectProducts.map((projectProduct) => {
          const productTypeId = projectProduct.product_type_id;
          /* TODO RLH - Revisit to identify if we can make a copy of the projectProduct without breaking things
        Current code appeases no-param-reassign Linter rule, but is still passing a reference */
          const updatedProjectProduct = projectProduct;

          updatedProjectProduct.hasAlternatives = this.projectProducts.filter(
            (projectProductObj) => productTypeId === projectProductObj.product_type_id,
          ).length > 1;

          return updatedProjectProduct;
        });
      },
    },
    methods: {
      /**
       * We run this when update is emitted form a project product and have to use $set to update a specific project product
       *
       * @param {object} projectProduct
       * @param {number} index
       */
      updateProjectProduct(projectProduct, index) {
        this.$set(this.productTree, index, projectProduct);
      },
      /**
       * On press of the save info button we run through each product to see if it's 'dirty' and then update the api individually
       */
      async saveInfo() {
        const productsToUpdate = this.productTree.filter((product) => product.dirty);
        const promiseArray = productsToUpdate.reduce((acc, projectProduct) => (
          acc.concat(ProjectService.updateProjectProduct(projectProduct.id, {
            compensation_eligible: projectProduct.compensation_eligible,
            compensation_received: projectProduct.compensation_received,
            estimated_annual_premium: projectProduct.estimated_annual_premium,
          }))
        ), []);

        try {
          await Promise.all(promiseArray);

          this.displayToast({
            message: 'Your request has been successfully processed.',
            type: 'success',
          });
        } catch ({ config }) {
          // Gets the id from the failed request.
          const id = config.url.split('/').at(-1);

          this.displayToast({
            message: `There was an error updating a project product ${id}.`,
          });
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  h3 {
    margin: 0 0 14px 20px;
  }

  .btn-group {
    margin-top: 20px;
  }

  .empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 135px;
    margin: 20px 10px 0 20px;
    padding: 15px 20px;
    border: 1px solid var(--tf-gray-light-medium);
    color: var(--tf-gray-medium);
    font-size: 16px;

    .app-icon {
      height: 25px;
      width: 25px;
      margin-bottom: 25px;
    }
  }
</style>
