<template>
  <LfcApiTableParentRow
    :data-test="`${rowData?.product_type_name?.toLowerCase() ?? 'null product'}, ${rowData?.plan_design_attribute_name?.toLowerCase() ?? 'null attribute'} row`"
  >
    <TfTableTd>
      <ElSelect
        v-if="isNew"
        v-model="newProductTypeId"
        no-match-text="Product not found"
        placeholder="Select product"
        data-test="select product input"
      >
        <ElOption
          v-for="product in availableProductTypes"
          :key="product.id"
          :label="product.name"
          :value="product.id"
        />
      </ElSelect>
      <span v-else>
        {{ rowData.product_type_name }}
      </span>
    </TfTableTd>
    <TfTableTd>
      <ElSelect
        v-if="isNew"
        v-model="newAttributeId"
        :disabled="!newProductTypeId"
        no-match-text="Attribute not found"
        placeholder="Select attribute"
        no-data-text="No attributes found"
        data-test="select attribute input"
      >
        <ElOptionGroup
          v-for="category in productTypeAttributes"
          :key="category.id"
          :label="category.name"
        >
          <ElOption
            v-for="attribute in category.plan_design_attributes"
            :key="attribute.id"
            :label="attribute.name"
            :value="attribute.id"
          />
        </ElOptionGroup>
      </ElSelect>
      <span v-else>
        {{ rowData.plan_design_attribute_name }}
      </span>
    </TfTableTd>
    <TfTableTd>
      <ElInput
        v-if="isEditing || isNew"
        v-model="newValue"
        data-test="normalized value input"
      />
      <span
        v-else
        :data-test="`${rowData?.product_type_name?.toLowerCase() ?? 'null product'}, ${rowData?.plan_design_attribute_name?.toLowerCase() ?? 'null attribute'} attribute`"
      >
        {{ rowData.value }}
      </span>
    </TfTableTd>
    <TfTableTd
      v-if="!isNew && !isEditing"
      class="timestamp-column"
    >
      {{ timestamp }}
    </TfTableTd>
    <TfTableTd
      v-if="isEditing || isNew"
      class="edit-cell"
    >
      <div class="btn-group">
        <AppButton
          type="decline"
          size="icon"
          icon="fa-solid fa-times"
          data-test="cancel button"
          @click="$emit('cancelValue', rowData.id)"
        />
        <AppButton
          data-test="submit button"
          :is-disabled="isSaveDisabled()"
          icon="fa-solid fa-check"
          size="icon"
          type="affirm"
          @click="saveValue"
        />
      </div>
    </TfTableTd>
    <TfTableTd class="edit-button-cell">
      <div class="edit-delete">
        <AppButton
          v-if="!isEditing && !isNew"
          :data-test="`edit ${rowData?.product_type_name?.toLowerCase() ?? 'null product'} ${rowData?.plan_design_attribute_name?.toLowerCase() ?? 'null attribute'}`"
          size="small"
          type="primary"
          text="Edit"
          @click="$emit('editValue', rowData.id)"
        />
        <AppButton
          v-if="isEditing && !isNew"
          data-test="delete button"
          :is-disabled="false"
          size="text"
          type="primary"
          text="Delete value"
          @click="$emit('deleteValue', rowData.id)"
        />
      </div>
    </TfTableTd>
  </LfcApiTableParentRow>
</template>

<script>
  import { mapState, mapActions } from 'pinia';
  import {
    getProductTypes as getProductTypesService,
  } from '@/services/product.js';
  import ValuesService from '@/services/normalizedValues.js';
  import { useProductSelectionsStore } from '@/stores/productSelections.js';

  /**
   * Normalized Values table row
   *
   * @vuedoc
   * @exports NormalizedValueRow
   * @category Components
   */
  export default {
    name: 'NormalizedValueRow',
    props: {
      rowData: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        productTypeAttributes: [],
        newProductTypeId: null,
        newAttributeId: null,
        newValue: '',
      };
    },
    computed: {
      ...mapState(useProductSelectionsStore, ['availableProductTypes']),
      /**
       * Is new record
       *
       * @returns {boolean}
       */
      isNew() {
        return !this.rowData.id;
      },
      /**
       * Is editing
       *
       * @returns {boolean}
       */
      isEditing() {
        return this.rowData.isEditing;
      },
      /**
       * Grammatically correct timestamp
       *
       * @returns {string}
       */
      timestamp() {
        const createdDate = new Date(this.rowData.created_at);
        const editedDate = new Date(this.rowData.updated_at);

        if (createdDate.toString() === 'Invalid Date') {
          return '';
        }

        const createdDateString = [createdDate.getMonth() + 1, createdDate.getDate(), createdDate.getFullYear()].join('/');
        const editedDateString = [editedDate.getMonth() + 1, editedDate.getDate(), editedDate.getFullYear()].join('/');

        return `${editedDate > createdDate ? 'Edited' : 'Created'} ${editedDate > createdDate ? editedDateString : createdDateString}`;
      },
    },
    watch: {
      /**
       * Load the normalized value data to populate the product type dropdown
       */
      isNew() {
        if (this.isNew) {
          this.newProductTypeId = null;
          this.newAttributeId = null;
          this.newValue = '';
          this.getProductTypes();
        }
      },
      /**
       * Set newValue to the existing row value when edit is clicked
       */
      isEditing() {
        this.newValue = this.isEditing ? this.rowData.value : '';
      },
      newProductTypeId: {
        /**
         * Retrieve the attributes when the product type changes
         * Also reset the attribute selection dropdown
         */
        handler() {
          if (this.isNew && this.newProductTypeId) {
            this.getAttributes();
            this.newAttributeId = null;
          }
        },
      },
    },
    methods: {
      ...mapActions(useProductSelectionsStore, ['setAvailableProductTypes']),
      /**
       * Get product types from the API
       */
      async getProductTypes() {
        try {
          const data = await getProductTypesService();

          this.setAvailableProductTypes(data.product_types);
        } catch {
          this.displayToast({
            message: 'There was a problem getting the product types.',
          });
        }
      },
      /**
       * Get product attributes from the API
       */
      getAttributes() {
        ValuesService
          .getProductTypeAttributes(this.newProductTypeId)
          .then((data) => {
            this.productTypeAttributes = data;
          })
          .catch(() => {
            this.displayToast({
              message: 'There was a problem getting the product type attributes.',
            });
          });
      },
      /**
       * Disable save button
       *
       * @returns {boolean}
       */
      isSaveDisabled() {
        if (this.isNew) {
          return !this.newValue || !this.newProductTypeId || !this.newAttributeId;
        }

        return this.newValue === this.rowData.value;
      },
      /**
       * Save an updated value
       */
      saveValue() {
        this.$emit('saveValue', {
          id: this.rowData.id,
          productTypeId: this.newProductTypeId || this.rowData.product_type_id,
          planDesignAttributeId: this.newAttributeId || this.rowData.plan_design_attribute_id,
          value: this.newValue.trim(),
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .edit-cell {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .edit-button-cell {
    vertical-align: middle;
  }

  .edit-delete {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
  }

  .timestamp-column {
    font-size: 12px;
    color: var(--tf-gray-medium);
    text-transform: uppercase;
  }
</style>
