<template>
  <div>
    <header>
      <h4>{{ containerTypeSelection }} {{ usesPlanTemplates ? 'Type' : 'Description' }}(s)</h4>
      <div class="btn-group">
        <AppButton
          class="add-container-button"
          :is-disabled="missingContainers"
          :text="`Add ${containerTypeSelection}`"
          icon="fa-solid fa-plus"
          type="primary"
          size="text-small"
          data-test="add type button"
          @click="addContainer"
        />
        <AppButton
          key="toggle containers"
          class="expand-button"
          type="secondary"
          :icon="`fa-solid fa-chevron-${expanded ? 'up' : 'down'}`"
          size="icon"
          data-test="expand"
          @click="expanded = !expanded"
        />
      </div>
    </header>
    <ul
      v-loading="reordering"
      v-drag-and-drop:options="dragDropOptions"
      class="container-wrapper"
      @reordered="reordered($event)"
    >
      <ProductContainer
        v-for="(container, containerIndex) in currentContainers"
        v-show="expanded"
        :key="`container-id-${container.id}`"
        :container="container"
        :container-index="containerIndex"
        data-test="plan container"
      />
    </ul>
  </div>
</template>

<script>
  import { mapState, mapWritableState, mapActions } from 'pinia';
  import MathUtil from '@/utils/math.js';
  import ProductContainer from '@/components/ProductContainers/ProductContainer.vue';
  import { useProjectProductStore } from '@/stores/projectProduct.js';
  import { useProductContainersStore } from '@/stores/productContainers.js';
  import { useProductSelectionsStore } from '@/stores/productSelections.js';

  /**
   * Product Containers
   *
   * @vuedoc
   * @exports PlanBasedContainer
   * @category Components
   */
  export default {
    name: 'PlanBasedContainer',
    components: { ProductContainer },
    data() {
      return {
        dragDropOptions: {
          dropzoneSelector: '.container-wrapper',
          draggableSelector: '.sortable-row',
        },
        expanded: true,
        reordering: false,
      };
    },
    computed: {
      ...mapState(useProjectProductStore, ['usesPlanTemplates']),
      ...mapState(useProductContainersStore, ['containerTypeSelection']),
      ...mapWritableState(useProductContainersStore, [
        'currentContainers',
        'isClassBased',
        'isProductContainersLoaded',
      ]),
      ...mapState(useProductSelectionsStore, ['selectedProductId']),
      /**
       * returns true if any missing containers are found
       *
       * @returns {boolean}
       */
      missingContainers() {
        const missingContainers = this.currentContainers.filter((container) => !container.description);

        return missingContainers
          ? missingContainers.length > 0
          : false;
      },
    },
    methods: {
      ...mapActions(useProductContainersStore, [
        'addProductContainer',
        'setCurrentContainers',
        'updateProductContainer',
        'syncProductDetails',
      ]),
      /**
       * Handle the dragging and reordering of containers
       *
       * @param {Event} event
       */
      async reordered(event) {
        this.reordering = true;

        const containers = [...this.currentContainers];
        const newIndex = event.detail.index;
        const oldIndex = this.currentContainers.findIndex((container) => container.id === Number(event.detail.ids[0]));

        containers.splice(newIndex, 0, containers.splice(oldIndex, 1)[0]);
        // Patch the new container position
        try {
          await this.updateProductContainer({
            container: this.currentContainers[oldIndex],
            data: { position: newIndex + 1 },
            reordering: true,
          });
          this.setCurrentContainers(containers.map((container, index) => {
            const newPosition = index + 1;

            return {
              ...container,
              name: this.isClassBased ? newPosition : container.name,
              position: newPosition,
            };
          }));
          this.reordering = false;
        } catch {
          this.displayToast({
            message: 'There was an error updating the container.',
          });
        }
      },
      /**
       * Add a new blank container.
       * Class-based products are set with a container type of `1` by default. `syncProductDetails` is called after the container has been added to the DB.
       * Plan-based products aren't in the DB until a container type has been established so we just push to productContainers store currentContainers state.
       */
      async addContainer() {
        if (this.isClassBased) {
          this.isProductContainersLoaded = false;

          try {
            const container = await this.addProductContainer({
              productId: this.selectedProductId,
            });

            this.syncProductDetails({
              newId: container.id,
            });
          } catch {
            this.displayToast({
              message: 'There was an error adding the container.',
            });
          }
          this.isProductContainersLoaded = true;

          return;
        }

        const id = MathUtil.getRandomNumber();

        this.currentContainers.push({
          annotations: [],
          container_type: null,
          container_type_id: null,
          container_type_name: null,
          description: null,
          id,
          newId: id,
          name: null,
          position: this.currentContainers.length + 1,
          state: 'editing',
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    height: 46px;
    text-align: left;
    border-bottom: 1px solid var(--tf-gray-light-medium);
  }

  ul {
    position: relative;
  }

  h4 {
    font-size: 14px;
    font-weight: normal;
    margin: 0;
  }

  .add-container-button {
    font-size: 12px;
    margin-right: 10px;
    padding-top: 20px;
  }
</style>
