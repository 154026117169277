<template>
  <AppSignIn
    v-bind="{
      userInfo: { email },
    }"
    :email.sync="email"
    :password.sync="password"
    :show-sso-method="forceSSO"
    :hide-email="forceSSO"
  >
    <template #ctaSubmit>
      <AppButton
        :is-disabled="!email || !password"
        :is-loading="isAuthorizing"
        class="sign-in-btn"
        data-test="submit sign in"
        native-type="submit"
        type="primary"
        text="Sign in"
        @click="signInUser"
      />
    </template>
    <template #ctaSsoSubmit>
      <AppButton
        :is-loading="isAuthorizing"
        data-test="sign in with google"
        native-type="submit"
        type="primary"
        is-block
        text="Sign in with Google"
        @click.prevent="openGoogleOAuthPopup"
      />
    </template>
  </AppSignIn>
</template>

<script>
  import { trackSegmentEvent } from '@watchtowerbenefits/es-utils-public';

  import { getGoogleOAuthSession, signIn, setSignedIn } from '@/services/account.js';
  import { config } from '@/utils/config.js';

  export default {
    name: 'AdminSignIn',
    data: () => ({
      email: '',
      isAuthorizing: false,
      password: '',
      rememberMe: false,
      toast: null,
      googleOAuthClient: null,
      googleOAuthCode: null,
      signIn,
    }),
    computed: {
      /**
       * Determine if we are we requiring SSO sign-in or not.
       *
       * @returns {boolean}
       */
      forceSSO() {
        return config.VUE_APP_FORCE_SSO === 'true';
      },
    },
    methods: {
      /**
       * Sign in user
       */
      async signInUser() {
        this.isAuthorizing = true;

        try {
          const data = this.forceSSO
            ? await getGoogleOAuthSession(this.googleOAuthCode)
            : await signIn({ email: this.email, password: this.password });

          if (this.rememberMe) {
            window.localStorage.setItem('email', this.email);
          } else {
            window.localStorage.removeItem('email');
          }
          setSignedIn(data);

          this.$router.replace({ name: 'Dashboard' });
          trackSegmentEvent('Admin User Sign In Successful');
        } catch ({ response: { data: { message } } }) {
          this.toast = this.$message({
            duration: 10000,
            message,
            showClose: true,
            type: 'error',
          });
          this.isAuthorizing = false;
        }
      },
      /**
       * Trigger popup for Google OAuth sign-in
       */
      openGoogleOAuthPopup() {
        try {
          this.googleOAuthClient = window.google.accounts.oauth2.initCodeClient({
            client_id: config.VUE_APP_OAUTH_CLIENT_ID,
            scope: 'email profile',
            ux_mode: 'popup',
            callback: this.handleGoogleOAuthResponse,
          });

          this.googleOAuthClient.requestCode();
        } catch {
          this.$message({
            duration: 10000,
            message: 'An issue occurred while trying to load Sign in with Google functionality. Please try again by reloading the page; if the problem persists, please contact support.',
            showClose: true,
            type: 'error',
          });
        }
      },
      /**
       * Handle Google OAuth response
       *
       * @param {object} response
       */
      async handleGoogleOAuthResponse(response) {
        if (response && !response.error) {
          // google authentication succeeded, now process.
          this.googleOAuthCode = response.code;
          this.signInUser();
        } else {
          // google authentication failed
          this.$message({
            duration: 10000,
            message: 'An issue occurred while trying to sign in with Google. Please try again; if the problem persists, please contact support.',
            showClose: true,
            type: 'error',
          });
        }
      },
    },
  };
</script>
